import React from 'react';
import cx from 'classnames';

import CardContent from './CardContent';
import './Card.style.scss';

export default class Card extends React.Component {
  static Content = CardContent;
  render() {
    const { children, type, centered, bgColor, style, className } = this.props;
    const baseCN = 'card';
    const typeCN = type && `${baseCN}-type-${type}`;
    const centeredCN = centered ? `${baseCN}-centered` : null;
    const bgColorCN = bgColor
      ? `${baseCN}-background-color-${bgColor}`
      : `${baseCN}-background-color-white`;

    const CardCX = cx(baseCN, typeCN, centeredCN, bgColorCN, className);

    return (
      <div className={CardCX} style={style}>
        {children}
      </div>
    );
  }
}
