import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import AuthenticationRoute from 'Routes/Extenders/AuthenticationRoute';
import AuthenticatedRoute from 'Routes/Extenders/AuthenticatedRoute';
import InvestmentRoute from 'Routes/Extenders/InvestmentRoute';

import ProjectListingContainer from 'Containers/ProjectListing/ProjectListing.container';
import ProjectDetailContainer from 'Containers/ProjectDetail/ProjectDetail.container';
import CommitmentContainer from 'Containers/Commitment/Commitment.container';
import InvestmentContainer from 'Containers/Investment/Investment.container';
import RegisterContainer from 'Containers/Authentication/Register.container';
import VerifyRegisterContainer from 'Containers/Authentication/VerifyRegister.container';
import LoginContainer from 'Containers/Authentication/Login.container';
import ResetPasswordContainer from 'Containers/Authentication/ResetPassword.container';
import VerifyResetPasswordContainer from 'Containers/Authentication/VerifyResetPassword.container';
import WithdrawContainer from 'Containers/Withdraw/Withdraw.container';
import ExchangeContainer from 'Containers/Exchange/Exchange.container';
import Logout from 'Components/Logout/Logout.component';
import UserDashboardContainer from 'Containers/Account/UserDashboard.container';
import PhoneActivationContainer from 'Containers/Account/PhoneActivation.container';
import KYCContainer from 'Containers/KYC/KYC.container';
import NotFoundContainer from 'Containers/Error/NotFound.container';
import WorkInProgress from 'Components/WIP/WorkInProgress.component';

import { history } from 'Store/store';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

const MainRoutes = () => {
  return (
    <Router history={history}>
      <Switch>
        <AuthenticatedRoute
          path={APP_ROUTES.DASHBOARD}
          exact
          component={UserDashboardContainer}
        />
        <AuthenticationRoute
          path={APP_ROUTES.REGISTER}
          exact
          component={RegisterContainer}
        />
        <AuthenticationRoute
          path={APP_ROUTES.REGISTER_CONFIRMATION}
          exact
          component={VerifyRegisterContainer}
        />
        <AuthenticationRoute
          path={APP_ROUTES.LOGIN}
          exact
          component={LoginContainer}
        />
        <AuthenticationRoute
          path={APP_ROUTES.RESET_PASSWORD}
          exact
          component={ResetPasswordContainer}
        />
        <AuthenticationRoute
          path={APP_ROUTES.RESET_PASSWORD_CONFIRMATION}
          exact
          component={VerifyResetPasswordContainer}
        />
        <AuthenticatedRoute
          path={APP_ROUTES.PHONE_ACTIVATION}
          exact
          component={PhoneActivationContainer}
        />
        <AuthenticatedRoute
          path={APP_ROUTES.KYC}
          exact
          component={KYCContainer}
        />
        <AuthenticatedRoute
          path={APP_ROUTES.WITHDRAW}
          exact
          component={WithdrawContainer}
        />
        <AuthenticatedRoute
          path={APP_ROUTES.EXCHANGE}
          exact
          component={ExchangeContainer}
        />
        <Route path={APP_ROUTES.LOGOUT} component={Logout} />
        <Route
          path={APP_ROUTES.PROJECT_LISTING}
          component={ProjectListingContainer}
        />
        <Route
          path={APP_ROUTES.PROJECT_DETAIL}
          exact
          component={ProjectDetailContainer}
        />
        <InvestmentRoute
          path={APP_ROUTES.COMMITMENT}
          exact
          component={CommitmentContainer}
        />
        <InvestmentRoute
          path={APP_ROUTES.INVESTMENT}
          exact
          component={InvestmentContainer}
        />
        <Route path={APP_ROUTES.WORK_IN_PROGRESS} component={WorkInProgress} />
        <Route component={NotFoundContainer} />
      </Switch>
    </Router>
  );
};

export default MainRoutes;
