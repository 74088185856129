import React, { Component } from 'react';
import Vivus from 'vivus';

import 'Components/Reusable/Loading/loading.style.scss';
const VoultAnimationLogo = require('Assets/Logo/voult-animation-logo.svg');

class Loading extends Component {
  componentDidMount() {
    new Vivus(
      'voult',
      {
        type: 'delayed',
        animTimingFunction: Vivus.EASE,
        duration: 60,
        file: VoultAnimationLogo
      },
      (vivus) => {
        vivus.play(vivus.getStatus() === 'end' ? -1 : 1);
      }
    );
  }

  render() {
    return (
      <div className="loading-wrapper">
        <div id="voult" />
      </div>
    );
  }
}

export default Loading;
