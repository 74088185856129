import * as RouteExtenderConstants from 'Constants/RouteExtender/RouteExtender.constants';

export function saveRedirectRoute(redirectBack) {
  return {
    type: RouteExtenderConstants.SAVE_REDIRECT_ROUTE,
    payload: { redirectBack }
  };
}

export function removeRedirectRoute() {
  return {
    type: RouteExtenderConstants.REMOVE_REDIRECT_ROUTE
  };
}
