/**
 * This is a mockup screen currently so some sub components lie in the same file.
 * Based on the backend integration they might be lying as a separate component or
 * refactored.
 * Same for some locale files
 *
 * Please take this into account.
 * - Mustafa
 */
import React from 'react';
import { Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import * as _ from 'lodash';

import ActivationMessage from 'Components/Account/UserDashboard/Components/ActivationMessage.component';
import WalletPane from 'Components/Account/UserDashboard/Components/WalletPane.component';
import GlobalLayout from 'Layouts/Global.layout';
import { Container, InvestmentTable, Header } from 'UILib';

import 'Components/Account/UserDashboard/userDashboard.style.scss';

const leyData = [
  {
    year: '2018',
    month: 'February',
    produced: 98.345,
    estimation: 98.377
  },
  {
    year: '2018',
    month: 'March',
    produced: 152.148,
    estimation: 165.034
  },
  {
    year: '2018',
    month: 'April',
    produced: 214.597,
    estimation: 242.496
  },
  {
    year: '2018',
    month: 'May',
    produced: 189.888,
    estimation: 209.751
  },
  {
    year: '2018',
    month: 'June',
    produced: 201.472,
    estimation: 224.301
  },
  {
    year: '2018',
    month: 'July',
    produced: 222.643,
    estimation: 246.986
  },
  {
    year: '2018',
    month: 'August',
    produced: 215.827,
    estimation: 239.207
  },
  {
    year: '2018',
    month: 'September',
    produced: 192.175,
    estimation: 216.552
  },
  {
    year: '2018',
    month: 'October',
    produced: 167.539,
    estimation: 191.44
  },
  {
    year: '2018',
    month: 'November',
    produced: 120.911,
    estimation: 133.289
  },
  {
    year: '2018',
    month: 'December',
    produced: 81.362,
    estimation: 88.062
  },
  {
    year: '2019',
    month: 'January',
    produced: 67.061,
    estimation: 75.462
  },
  {
    year: '2019',
    month: 'February',
    produced: 120.596,
    estimation: 133.35
  },
  {
    year: '2019',
    month: 'March',
    produced: 176.502,
    estimation: 200.179
  }
];

const chartLabel = _.reduce(
  leyData.slice(leyData.length - 14, leyData.length),
  (result, item) => {
    result.push(`${item.month.substring(0, 3)} - ${item.year}`);
    return result;
  },
  []
);

/**
 * Sol bar 50'lik
 * sol bar max değeri arttır üstte boş alan kalsın
 *
 */

const chartData = [
  {
    label: 'Produced',
    fill: true,
    lineTension: 0.1,
    backgroundColor: 'rgba(140,84,255,0.1)',
    borderColor: 'rgba(140,84,255,1)',
    borderWidth: 2,
    borderCapStyle: 'butt',
    borderJoinStyle: 'miter',
    pointBorderColor: 'rgba(140,84,255,1)',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(255,255,255,1)',
    pointHoverBorderColor: 'rgba(140,84,255,1)',
    pointHoverBorderWidth: 2,
    data: _.reduce(
      leyData.slice(leyData.length - 14, leyData.length),
      (result, item) => {
        result.push(item.produced);
        return result;
      },
      []
    )
  },
  {
    label: 'Estimated',
    fill: '-1',
    lineTension: 0.1,
    backgroundColor: 'rgba(0,51,255,0.1)',
    borderColor: 'rgba(0,51,255,1)',
    borderWidth: 2,
    borderCapStyle: 'butt',
    borderJoinStyle: 'miter',
    pointBorderColor: 'rgba(0,51,255,1)',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(255,255,255,1)',
    pointHoverBorderColor: 'rgba(0,51,255,1)',
    pointHoverBorderWidth: 2,
    data: _.reduce(
      leyData.slice(leyData.length - 14, leyData.length),
      (result, item) => {
        result.push(item.estimation);
        return result;
      },
      []
    )
  }
];

const chartOptions = {
  legend: {
    display: true,
    position: 'bottom',
    labels: {
      usePointStyle: true,
      fontSize: 12,
      padding: 10
    }
  },
  layout: {
    padding: {
      left: 20,
      right: 40,
      top: 40,
      bottom: 20
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          labelOffset: 0,
          padding: 10
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          suggestedMin: 0, // minimum will be 0, unless there is a lower value.
          suggestedMax:
            _.max(
              _.map(leyData, (item) => {
                return Math.max(item.produced, item.estimation);
              })
            ) * 1.5,
          labelOffset: 0,
          padding: 10
        }
      }
    ]
  }
};

defaults.global.defaultColor = '#000000';
defaults.global.defaultFontColor = '#777777';
defaults.global.defaultFontFamily = 'Rajdhani';

const InvestmentChart = (props) => {
  return (
    <React.Fragment>
      <InvestmentTable.Header
        content="MONTHLY ENERGY PRODUCTION"
        style={{ marginBottom: '40px' }}
      />
      <div className="dashboard-line-chart">
        <Line
          data={{ labels: chartLabel, datasets: chartData }}
          options={chartOptions}
        />
      </div>
    </React.Fragment>
  );
};

const InvestmentPane = (props) => {
  const {
    user_invested_projects,
    user_investable_projects,
    user_commited_projects
  } = props.account;

  return (
    <React.Fragment>
      <div className="investment-pane">
        <InvestmentChart data={chartData} />
        {user_commited_projects.user_commitments.length > 0 && (
          <div className="invested-projects-zone">
            <InvestmentTable.Header content="COMMITTED PROJECTS" />
            <InvestmentTable.Table
              type="committed"
              data={user_commited_projects}
            />
          </div>
        )}
        {user_invested_projects.projects.length > 0 && (
          <div className="invested-projects-zone">
            <InvestmentTable.Header content="INVESTED PROJECTS" />
            <InvestmentTable.Table
              type="invested"
              data={user_invested_projects}
            />
          </div>
        )}
        <div className="investable-projects-zone">
          <InvestmentTable.Header content="INVESTABLE PROJECTS" />
          <InvestmentTable.Table
            type="investable"
            data={user_investable_projects}
            invested={user_invested_projects}
          />
        </div>
      </div>
      <div className="mobile-investment-pane">
        {user_commited_projects.user_commitments.length > 0 && (
          <div className="invested-projects-zone">
            <Header
              content="COMMITTED PROJECTS"
              lined
              color="light-grey"
              bgColor="grey-0"
              className="header-section"
            />
            <InvestmentTable.Table
              type="committed"
              data={user_commited_projects}
            />
          </div>
        )}
        {user_invested_projects.projects.length > 0 && (
          <div className="invested-projects-zone">
            <Header
              content="INVESTED PROJECTS"
              lined
              color="light-grey"
              bgColor="grey-0"
              className="header-section"
            />
            <InvestmentTable.Table
              type="invested"
              data={user_invested_projects}
            />
          </div>
        )}
        <div className="investable-projects-zone">
          <Header
            content="LISTED PROJECTS"
            lined
            color="light-grey"
            bgColor="grey-0"
            className="header-section"
          />
          <InvestmentTable.Table
            type="investable"
            data={user_investable_projects}
            invested={user_invested_projects}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const UserDashboard = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <Container type="content" bgColor="grey-0">
          {props.activationMessage.showActivationMessage && (
            <ActivationMessage {...props} />
          )}
          <div
            className={`dashboard-content-wrapper ${!props.activationMessage
              .showActivationMessage &&
              'dashboard-content-wrapper-margin-fix'}`}
          >
            <InvestmentPane {...props} />
            <WalletPane {...props} />
          </div>
        </Container>
      </GlobalLayout>
    </Container>
  );
};

export default UserDashboard;
