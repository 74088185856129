import React from 'react';
import cx from 'classnames';

import { LeftArrow, RightArrow } from './SliderArrows';
import Slide from './Slide';

import './Slider.style.scss';
const replacement = require('Assets/Logo/voult_image.svg');

export default class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      remaining: this.props.interval || 5,
      sliderSize: this.props.images.length
    };
  }

  componentDidMount() {
    this.sliderInterval = setInterval(() => {
      if (this.state.remaining > 0) {
        this.setState({ remaining: this.state.remaining - 1 });
      } else {
        this.handleGoToNextSlide();
      }
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.images.length === 0 && this.props.images.length > 0) {
      this.setState({ sliderSize: this.props.images.length });
    }
  }

  componentWillUnmount() {
    clearInterval(this.sliderInterval);
  }

  handleGoToPrevSlide = () => {
    if (this.state.currentIndex > 0) {
      this.setState({
        currentIndex: this.state.currentIndex - 1,
        remaining: 5
      });
    } else {
      this.setState({ currentIndex: this.state.sliderSize - 1, remaining: 5 });
    }
  };

  handleGoToNextSlide = () => {
    if (this.state.currentIndex < this.state.sliderSize - 1) {
      this.setState({
        currentIndex: this.state.currentIndex + 1,
        remaining: 5
      });
    } else {
      this.setState({ currentIndex: 0, remaining: 5 });
    }
  };

  render() {
    const { className: wrapperClass, images } = this.props;

    const baseSliderCN = 'slider';
    const SliderCX = cx(wrapperClass, baseSliderCN);

    return (
      <div className={SliderCX}>
        {this.state.sliderSize > 1 && (
          <React.Fragment>
            <LeftArrow handleGoToPrevSlide={this.handleGoToPrevSlide} />
            <RightArrow handleGoToNextSlide={this.handleGoToNextSlide} />
          </React.Fragment>
        )}
        {this.state.sliderSize > 0 ? (
          <Slide image={images[this.state.currentIndex]} />
        ) : (
          <div className="placeholder">
            <img src={replacement} alt="replacement" />
          </div>
        )}
      </div>
    );
  }
}
