import React from 'react';
import cx from 'classnames';

import './Icon.style.scss';

export default class StackedIcon extends React.Component {
  render() {
    const { size, reverseOrder, icons } = this.props;

    const baseCN = 'icon';

    const StackSizeCN = size && `fa-${size}`;
    const StackCX = cx('fa-stack', StackSizeCN);

    const [baseIcon, stackIcon] = icons;

    const baseIconBaseCN = baseIcon.base ? `fa${baseIcon.base[0]}` : 'fas';
    const baseIconCN = `fa-${baseIcon.name}`;
    const baseIconColorCN =
      baseIcon.color && `${baseCN}-color-${baseIcon.color}`;
    const baseIconCX = cx(
      baseIconBaseCN,
      baseIconCN,
      baseIconColorCN,
      'fa-stack-2x'
    );

    const stackIconBaseCN = stackIcon.base ? `fa${stackIcon.base[0]}` : 'fas';
    const stackIconCN = `fa-${stackIcon.name}`;
    const stackIconColorCN =
      stackIcon.color && `${baseCN}-color-${stackIcon.color}`;
    const stackIconInverseCN = stackIcon.inverse && 'fa-inverse';
    const stackIconCX = cx(
      stackIconBaseCN,
      stackIconCN,
      stackIconColorCN,
      stackIconInverseCN,
      'fa-stack-1x'
    );

    return (
      <span className={StackCX}>
        <i className={reverseOrder ? baseIconCX : stackIconCX} />
        <i className={reverseOrder ? stackIconCX : baseIconCX} />
      </span>
    );
  }
}
