import { all, put, takeLatest } from 'redux-saga/effects';

import apiGenerator from 'Utils/Helpers/api.helpers';
import {
  getStatusCodeFamily,
  apiErrorHandler,
  dataURLtoFile
} from 'Utils/Helpers/saga.helpers';
import {
  API_ENDPOINTS,
  STATUS_TYPE
} from 'Constants/GlobalConstants/Global.constants';
import * as KYCConstants from 'Constants/KYC/KYC.constants';

function* kycGetCurrentStepSaga(action) {
  const { access_token } = action.payload;

  const api = apiGenerator('get', access_token)(API_ENDPOINTS.KYC.GET_STEP);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: KYCConstants.KYC_GET_CURRENT_STEP_SUCCESS,
        payload: {
          ...response.data,
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: KYCConstants.KYC_GET_CURRENT_STEP_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* kycFlowStartSaga(action) {
  const { access_token } = action.payload;

  const api = apiGenerator('post', access_token)(API_ENDPOINTS.KYC.START);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: KYCConstants.KYC_FLOW_START_SUCCESS,
        payload: {
          ...response.data,
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: KYCConstants.KYC_FLOW_START_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* kycFlowPersonalSaga(action) {
  const { access_token, values } = action.payload;

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.KYC.PERSONAL_INFORMATION,
    { ...values, national_id: parseInt(values.national_id, 10) }
  );

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: KYCConstants.KYC_FLOW_PERSONAL_SUCCESS,
        payload: {
          ...response.data,
          personal_information: {
            ...values
          },
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: KYCConstants.KYC_FLOW_PERSONAL_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* kycFlowPersonalPatchSaga(action) {
  const { access_token, values } = action.payload;

  const api = apiGenerator('patch', access_token)(
    API_ENDPOINTS.KYC.PERSONAL_INFORMATION,
    { ...values, national_id: parseInt(values.national_id, 10) }
  );

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: KYCConstants.KYC_FLOW_PERSONAL_SUCCESS,
        payload: {
          ...response.data,
          personal_information: {
            ...values
          },
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: KYCConstants.KYC_FLOW_PERSONAL_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* kycFlowDocumentsSaga(action) {
  const { access_token, state } = action.payload;

  const formData = new FormData();
  formData.append('type', state.selected);
  formData.append('files[]', state.front_file);
  formData.append('files[]', state.rear_file);

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.KYC.DOCUMENTS,
    formData
  );

  try {
    const response = yield api;
    const identification_documents = { ...state, from: 'frontend' };

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: KYCConstants.KYC_FLOW_DOCUMENTS_SUCCESS,
        payload: {
          ...response.data,
          identification_documents,
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: KYCConstants.KYC_FLOW_DOCUMENTS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* kycFlowDocumentsPatchSaga(action) {
  const { access_token, state } = action.payload;

  const formData = new FormData();
  formData.append('type', state.selected);
  formData.append('files[]', state.front_file);
  formData.append('files[]', state.rear_file);

  const api = apiGenerator('patch', access_token)(
    API_ENDPOINTS.KYC.DOCUMENTS,
    formData
  );

  try {
    const response = yield api;
    const identification_documents = { ...state, from: 'frontend' };

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: KYCConstants.KYC_FLOW_DOCUMENTS_SUCCESS,
        payload: {
          ...response.data,
          identification_documents,
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: KYCConstants.KYC_FLOW_DOCUMENTS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* kycFlowProfileSaga(action) {
  const { access_token, image, mobile } = action.payload;

  if (!mobile) {
    const file = dataURLtoFile(image, 'profile.png');

    const formData = new FormData();
    formData.append('files[]', file);

    const api = apiGenerator('post', access_token)(
      API_ENDPOINTS.KYC.PROFILE,
      formData
    );

    try {
      const response = yield api;
      const profile_picture = { image, from: 'frontend' };

      if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
        yield put({
          type: KYCConstants.KYC_FLOW_PROFILE_SUCCESS,
          payload: { ...response.data, profile_picture, access_token }
        });
      }
    } catch (err) {
      yield put({
        type: KYCConstants.KYC_FLOW_PROFILE_FAILURE,
        payload: apiErrorHandler(err)
      });
    }
  } else {
    const formData = new FormData();
    formData.append('files[]', image);

    const api = apiGenerator('post', access_token)(
      API_ENDPOINTS.KYC.PROFILE,
      formData
    );

    try {
      const response = yield api;
      const profile_picture = { image, from: 'frontend' };

      if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
        yield put({
          type: KYCConstants.KYC_FLOW_PROFILE_SUCCESS,
          payload: { ...response.data, profile_picture, access_token }
        });
      }
    } catch (err) {
      yield put({
        type: KYCConstants.KYC_FLOW_PROFILE_FAILURE,
        payload: apiErrorHandler(err)
      });
    }
  }
}

function* kycFlowProfilePatchSaga(action) {
  const { access_token, image, mobile } = action.payload;

  if (!mobile) {
    const file = dataURLtoFile(image, 'profile.png');

    const formData = new FormData();
    formData.append('files[]', file);

    const api = apiGenerator('patch', access_token)(
      API_ENDPOINTS.KYC.PROFILE,
      formData
    );

    try {
      const response = yield api;
      const profile_picture = { image, from: 'frontend' };

      if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
        yield put({
          type: KYCConstants.KYC_FLOW_PROFILE_SUCCESS,
          payload: { ...response.data, profile_picture, access_token }
        });
      }
    } catch (err) {
      yield put({
        type: KYCConstants.KYC_FLOW_PROFILE_FAILURE,
        payload: apiErrorHandler(err)
      });
    }
  } else {
    const formData = new FormData();
    formData.append('files[]', image);

    const api = apiGenerator('patch', access_token)(
      API_ENDPOINTS.KYC.PROFILE,
      formData
    );

    try {
      const response = yield api;
      const profile_picture = { image, from: 'frontend' };

      if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
        yield put({
          type: KYCConstants.KYC_FLOW_PROFILE_SUCCESS,
          payload: { ...response.data, profile_picture, access_token }
        });
      }
    } catch (err) {
      yield put({
        type: KYCConstants.KYC_FLOW_PROFILE_FAILURE,
        payload: apiErrorHandler(err)
      });
    }
  }
}

function* kycFlowAddressSaga(action) {
  const { access_token, state } = action.payload;

  const formData = new FormData();
  formData.append('files[]', state.document);
  formData.append('address', state.address);
  formData.append('country', state.country);
  formData.append('city', state.city);

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.KYC.ADDRESS,
    formData
  );

  try {
    const response = yield api;
    const address_information = { ...state, from: 'frontend' };

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: KYCConstants.KYC_FLOW_ADDRESS_SUCCESS,
        payload: {
          ...response.data,
          address_information,
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: KYCConstants.KYC_FLOW_ADDRESS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* kycFlowAddressPatchSaga(action) {
  const { access_token, state } = action.payload;

  const formData = new FormData();
  formData.append('files[]', state.document);
  formData.append('address', state.address);
  formData.append('country', state.country);
  formData.append('city', state.city);

  const api = apiGenerator('patch', access_token)(
    API_ENDPOINTS.KYC.ADDRESS,
    formData
  );

  try {
    const response = yield api;
    const address_information = { ...state, from: 'frontend' };

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: KYCConstants.KYC_FLOW_ADDRESS_SUCCESS,
        payload: {
          ...response.data,
          address_information,
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: KYCConstants.KYC_FLOW_ADDRESS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* kycFlowSubmitSaga(action) {
  const { access_token } = action.payload;

  const api = apiGenerator('post', access_token)(API_ENDPOINTS.KYC.SUBMIT, {
    terms_of_service: true
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: KYCConstants.KYC_FLOW_SUBMIT_SUCCESS,
        payload: {
          ...response.data,
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: KYCConstants.KYC_FLOW_SUBMIT_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      KYCConstants.KYC_GET_CURRENT_STEP_PENDING,
      kycGetCurrentStepSaga
    ),
    takeLatest(KYCConstants.KYC_FLOW_START_PENDING, kycFlowStartSaga),
    takeLatest(KYCConstants.KYC_FLOW_PERSONAL_PENDING, kycFlowPersonalSaga),
    takeLatest(
      KYCConstants.KYC_FLOW_PERSONAL_PATCH_PENDING,
      kycFlowPersonalPatchSaga
    ),
    takeLatest(KYCConstants.KYC_FLOW_DOCUMENTS_PENDING, kycFlowDocumentsSaga),
    takeLatest(
      KYCConstants.KYC_FLOW_DOCUMENTS_PATCH_PENDING,
      kycFlowDocumentsPatchSaga
    ),
    takeLatest(KYCConstants.KYC_FLOW_PROFILE_PENDING, kycFlowProfileSaga),
    takeLatest(
      KYCConstants.KYC_FLOW_PROFILE_PATCH_PENDING,
      kycFlowProfilePatchSaga
    ),
    takeLatest(KYCConstants.KYC_FLOW_ADDRESS_PENDING, kycFlowAddressSaga),
    takeLatest(
      KYCConstants.KYC_FLOW_ADDRESS_PATCH_PENDING,
      kycFlowAddressPatchSaga
    ),
    takeLatest(KYCConstants.KYC_FLOW_SUBMIT_PENDING, kycFlowSubmitSaga)
  ]);
}
