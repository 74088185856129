import React from 'react';
import { Link } from 'react-router-dom';

import { Card, Text, Icon, Button } from 'UILib';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

const UserNotLoggedIn = (props) => {
  return (
    <div className="require-login-zone-wrapper">
      <Card type="auth" centered className="require-login">
        <Text
          type="h1"
          content="investment.requireLogin.header"
          className="header"
          translation
        />
        <Icon
          base="regular"
          icon={{ name: 'comments', color: 'orange-3', size: '10x' }}
        />
        <Text
          type="intro"
          content="investment.requireLogin.subheader"
          className="subheader"
          translation
        />
        <div className="actions">
          <Link
            to={APP_ROUTES.LOGIN}
            onClick={() => {
              props.saveRedirectRoute(
                APP_ROUTES.INVESTMENT_WITH_ID(props.projectDetail.projectId)
              );
            }}
          >
            <Button
              content="investment.requireLogin.login"
              translation
              bgColor="orange-3"
              borderColor="orange-3"
              color="white"
              className="action-button"
            />
          </Link>
          <Link to={APP_ROUTES.REGISTER}>
            <Button
              content="investment.requireLogin.signup"
              translation
              bgColor="white"
              borderColor="orange-3"
              color="orange-3"
              className="action-button"
            />
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default UserNotLoggedIn;
