import React from 'react';
import { Formik } from 'formik';
import * as _ from 'lodash';
import NumberFormat from 'react-number-format';

import GlobalLayout from 'Layouts/Global.layout';
import { Container, Text, FormElements, Button, Icon, Modal } from 'UILib';
import { CURRENCY_TYPE } from 'Constants/Exchange/Exchange.constants';

import 'Components/Exchange/exchange.style.scss';

class Trade extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      from: {
        currency: '',
        amount: null
      },
      to: {
        currency: '',
        amount: null
      },
      safeOrder: false,
      hasValidationError: false,
      hasExchangeRateError: false,
      isConfirmationModalVisible: false,
      isConfirmationGiven: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.exchange.error) {
      if (
        !this.state.hasExchangeRateError &&
        this.props.exchange.error.client_code === 500
      ) {
        this.setState({ hasExchangeRateError: true });
      }
      if (
        this.props.exchange.error.client_code !== 500 &&
        prevProps.exchange.error.client_code === 500
      ) {
        this.setState({ hasExchangeRateError: false });
      }
    }
  }

  changeSelectedCurrency = (zone, currency) => {
    if (
      (zone === 'to') & (this.state.from.currency !== '') &&
      this.state.from !== currency
    ) {
      this.setState({
        [zone]: { ...this.state[zone], currency },
        hasValidationError: false
      });
    }
    if (zone === 'from') {
      this.setState({
        [zone]: { ...this.state[zone], currency, amount: null },
        to: { ...this.state.to, currency: '', amount: null },
        hasValidationError: false
      });
    }
  };

  changeAmountValue = (zone, amount, exchangeRate) => {
    if (zone === 'from') {
      this.setState({
        [zone]: { ...this.state[zone], amount: amount },
        to: { ...this.state.to, amount: amount * exchangeRate.attributes.rate },
        hasValidationError: false
      });
    }
    if (zone === 'to') {
      this.setState({
        [zone]: { ...this.state[zone], amount: amount },
        from: {
          ...this.state.from,
          amount: amount * exchangeRate.attributes.rate,
          hasValidationError: false
        }
      });
    }
  };

  determineExchangeRates = () => {
    const { exchange_rates } = this.props.exchange;

    return (
      _.find(exchange_rates, (exchange_rate) => {
        return (
          exchange_rate.attributes.from === this.state.from.currency &&
          exchange_rate.attributes.to === this.state.to.currency
        );
      }) || { attributes: { rate: 0 } }
    );
  };

  determineInputExchangeRates = () => {
    const { exchange_rates } = this.props.exchange;

    const exchange_ratio = _.find(exchange_rates, (exchange_rate) => {
      return (
        exchange_rate.attributes.from === this.state.from.currency &&
        exchange_rate.attributes.to === this.state.to.currency
      );
    }).attributes.rate;

    return exchange_ratio;
  };

  checkExchangeRateValidity = (fromCurrency, toCurrency) => {
    const { exchange_rates } = this.props.exchange;

    const exchange_ratio =
      _.find(exchange_rates, (exchange_rate) => {
        return (
          exchange_rate.attributes.from === fromCurrency &&
          exchange_rate.attributes.to === toCurrency
        );
      }).attributes.rate || null;

    if (!exchange_ratio) {
      return false;
    }
    return true;
  };

  switchCurrencies = () => {
    if (this.state.to.currency !== '' && this.state.from.currency !== '') {
      this.setState({
        from: {
          currency: this.state.to.currency,
          amount: this.state.to.amount
        },
        to: {
          currency: this.state.from.currency,
          amount: this.state.from.amount
        },
        hasValidationError: false
      });
    }
  };

  toggleSafeOrder = () => {
    this.setState({ safeOrder: !this.state.safeOrder });
  };

  handleExchangeSubmit = () => {
    if (this.state.from.amount && this.state.to.amount) {
      this.props.tradeExchange(this.props.account.access_token, this.state);
    }
    this.hideConfirmationModal();
  };

  determineValidationErrorsAndSubmit = () => {
    if (
      this.state.from.currency !== '' &&
      this.state.from.amount !== null &&
      this.state.to.currency !== ''
    ) {
      this.setState({ hasValidationError: false });
    } else {
      this.setState({ hasValidationError: true });
    }
  };

  showConfirmationModal = () => {
    this.setState({ isConfirmationModalVisible: true });
  };

  hideConfirmationModal = () => {
    this.setState({ isConfirmationModalVisible: false });
  };

  render() {
    const initialValues = {
      toc: false
    };
    const exchangeRate = this.determineExchangeRates();
    return (
      <React.Fragment>
        {this.state.isConfirmationModalVisible && (
          <Modal
            closeIcon
            show={this.state.isConfirmationModalVisible}
            handleClose={() => this.hideConfirmationModal()}
          >
            <div className="confirmation-modal">
              <Text
                color="light-grey"
                content="exchange.trade.confirmation.header"
                translation
                className="confirmation-modal-header"
              />
              <Text
                color="light-grey"
                content="exchange.trade.confirmation.subheader"
                translation
                className="confirmation-modal-subheader"
              />
              <Icon
                base="regular"
                icon={{ name: 'question-circle' }}
                className="confirmation-modal-icon"
              />
              <div className="confirmation-actions">
                <Button
                  color="orange-3"
                  bgColor="white"
                  borderColor="orange-3"
                  content="exchange.trade.confirmation.no"
                  translation
                  onClick={() => {
                    this.hideConfirmationModal();
                  }}
                  className="button"
                />
                <Button
                  color="white"
                  bgColor="orange-3"
                  borderColor="orange-3"
                  content="exchange.trade.confirmation.yes"
                  translation
                  onClick={() => {
                    this.handleExchangeSubmit();
                  }}
                  className="button"
                />
              </div>
            </div>
          </Modal>
        )}
        <Container type="page" bgColor="grey-0">
          <GlobalLayout>
            <div className="exchange-trade-wrapper">
              <Text
                type="h1"
                textAlign="left"
                content="exchange.trade.header"
                translation
                className="exchange-header"
              />
              <Text
                type="intro"
                textAlign="left"
                content="exchange.trade.subheader"
                translation
                className="exchange-subheader"
              />
              {this.state.hasExchangeRateError && (
                <div className="exchange-rates-server-error">
                  <FormElements.ValidationErrors
                    content="exchange.trade.exchangeRatesServerError"
                    translation
                  />
                </div>
              )}

              <div className="trade-zone">
                <div className="currency-selection-wrapper">
                  <div className="currency-selection-zone">
                    <Text
                      type="h1"
                      textAlign="left"
                      content="exchange.trade.convertFrom.header"
                      translation
                      className="header"
                    />
                    <Text
                      type="intro"
                      textAlign="left"
                      content="exchange.trade.convertFrom.subheader"
                      translation
                      className="subheader"
                    />
                    <div className="currency-selection-row">
                      <div
                        className={`currency-button ${this.state.from
                          .currency === CURRENCY_TYPE.BTC && 'selected'}`}
                        onClick={() => {
                          if (!this.state.hasExchangeRateError) {
                            this.changeSelectedCurrency(
                              'from',
                              CURRENCY_TYPE.BTC
                            );
                          }
                        }}
                      >
                        Bitcoin
                      </div>
                      <div
                        className={`currency-button ${this.state.from
                          .currency === CURRENCY_TYPE.ETH && 'selected'}`}
                        onClick={() => {
                          if (!this.state.hasExchangeRateError) {
                            this.changeSelectedCurrency(
                              'from',
                              CURRENCY_TYPE.ETH
                            );
                          }
                        }}
                      >
                        Ethereum
                      </div>
                      <div
                        className={`currency-button ${this.state.from
                          .currency === CURRENCY_TYPE.USDC && 'selected'}`}
                        onClick={() => {
                          if (!this.state.hasExchangeRateError) {
                            this.changeSelectedCurrency(
                              'from',
                              CURRENCY_TYPE.USDC
                            );
                          }
                        }}
                      >
                        USDC
                      </div>
                      <div className="currency-button disabled">USD</div>
                    </div>
                  </div>
                  <div className="currency-selection-zone">
                    <Text
                      type="h1"
                      textAlign="left"
                      content="exchange.trade.convertTo.header"
                      translation
                      className="header"
                    />
                    <Text
                      type="intro"
                      textAlign="left"
                      content="exchange.trade.convertTo.subheader"
                      translation
                      className="subheader"
                    />
                    <div className="currency-selection-row">
                      <div
                        className={`currency-button ${this.state.to.currency ===
                          CURRENCY_TYPE.BTC && 'selected'} ${this.state.from
                          .currency === CURRENCY_TYPE.BTC && 'disabled'}`}
                        onClick={() => {
                          if (
                            this.state.from.currency !== CURRENCY_TYPE.BTC &&
                            this.checkExchangeRateValidity(
                              this.state.from.currency,
                              CURRENCY_TYPE.BTC
                            )
                          ) {
                            this.changeSelectedCurrency(
                              'to',
                              CURRENCY_TYPE.BTC
                            );
                          }
                        }}
                      >
                        Bitcoin
                      </div>
                      <div
                        className={`currency-button ${this.state.to.currency ===
                          CURRENCY_TYPE.ETH && 'selected'} ${this.state.from
                          .currency === CURRENCY_TYPE.ETH && 'disabled'}`}
                        onClick={() => {
                          if (
                            this.state.from.currency !== CURRENCY_TYPE.ETH &&
                            this.checkExchangeRateValidity(
                              this.state.from.currency,
                              CURRENCY_TYPE.ETH
                            )
                          ) {
                            this.changeSelectedCurrency(
                              'to',
                              CURRENCY_TYPE.ETH
                            );
                          }
                        }}
                      >
                        Ethereum
                      </div>
                      <div
                        className={`currency-button ${this.state.to.currency ===
                          CURRENCY_TYPE.USDC && 'selected'} ${this.state.from
                          .currency === CURRENCY_TYPE.USDC && 'disabled'}`}
                        onClick={() => {
                          if (
                            this.state.from.currency !== CURRENCY_TYPE.USDC &&
                            this.checkExchangeRateValidity(
                              this.state.from.currency,
                              CURRENCY_TYPE.USDC
                            )
                          ) {
                            this.changeSelectedCurrency(
                              'to',
                              CURRENCY_TYPE.USDC
                            );
                          }
                        }}
                      >
                        USDC
                      </div>
                      <div className="currency-button disabled">USD</div>
                    </div>
                  </div>
                </div>
                <div className="amount-inputs-wrapper">
                  <div className="from-input-zone">
                    <Text
                      textAlign="left"
                      content="exchange.trade.amount.from.header"
                      translation
                      className="input-header"
                    />
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      placeholder={
                        this.state.from.currency !== '' &&
                        this.state.to.currency !== ''
                          ? `1 ${this.state.from.currency}`
                          : ''
                      }
                      value={this.state.from.amount}
                      isNumericString={true}
                      displayType="input"
                      disabled={this.state.to.currency === ''}
                      onValueChange={(values) => {
                        this.changeAmountValue(
                          'from',
                          values.value,
                          exchangeRate
                        );
                      }}
                      suffix={` ${this.state.from.currency}`}
                      className="input"
                    />
                  </div>
                  <div
                    onClick={() => {
                      this.switchCurrencies();
                    }}
                  >
                    <Icon
                      base="solid"
                      icon={{ name: 'exchange-alt', color: 'light-grey' }}
                      className="icon"
                    />
                  </div>
                  <div className="to-input-zone">
                    <Text
                      textAlign="left"
                      content="exchange.trade.amount.to.header"
                      translation
                      className="input-header"
                    />
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      value={
                        this.state.from.currency !== '' &&
                        this.state.to.currency !== ''
                          ? `${this.determineInputExchangeRates() *
                              (this.state.from.amount !== null &&
                              this.state.from.amount !== ''
                                ? this.state.from.amount
                                : 1)}`
                          : ''
                      }
                      isNumericString={true}
                      displayType="input"
                      disabled
                      suffix={` ${this.state.to.currency}`}
                      className="input"
                    />
                  </div>
                </div>
                <div className="safe-order-wrapper">
                  <div
                    className="safe-order-zone"
                    onClick={() => {
                      this.toggleSafeOrder();
                    }}
                  >
                    {this.state.safeOrder ? (
                      <Icon
                        base="regular"
                        icon={{ name: 'check-circle', color: 'light-grey' }}
                        className="icon"
                      />
                    ) : (
                      <Icon
                        base="regular"
                        icon={{ name: 'circle', color: 'light-grey' }}
                        className="icon"
                      />
                    )}
                    <Text
                      content="exchange.trade.safeOrder"
                      translation
                      className="text"
                    ></Text>
                  </div>
                  <Text
                    textAlign="left"
                    content="exchange.trade.information.content1"
                    translation
                    className="text"
                  />
                  <Text
                    textAlign="left"
                    content="exchange.trade.information.content2"
                    translation
                    className="text"
                  />
                </div>
                <div className="submit-wrapper">
                  <Formik
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={this.props.ExchangeTradeValidationSchema}
                    onSubmit={(values, { submitForm, setSubmitting }) => {
                      this.determineValidationErrorsAndSubmit();
                      if (!this.state.hasValidationError) {
                        this.showConfirmationModal();
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          {this.state.hasValidationError ||
                          _.has(errors, 'toc') ? (
                            <div className="error-container">
                              <FormElements.ValidationErrors
                                content="exchange.trade.validation_errors"
                                translation
                              />
                            </div>
                          ) : null}

                          <FormElements.Checkbox
                            name="toc"
                            error={errors.toc && touched.toc}
                            checked={values.toc}
                            setFieldValue={setFieldValue}
                            onChange={handleChange}
                            label="exchange.trade.toc"
                            translation
                            className="toc-zone"
                          />
                          <Button
                            content="exchange.trade.submit"
                            disabled={this.state.hasExchangeRateError}
                            translation
                            className="action"
                            color="white"
                            bgColor="orange-3"
                            borderColor="orange-3"
                            type="submit"
                          />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="information-zone">
                  <div className="divider" />
                  <div className="currency-exchange-rate-wrapper">
                    <Text
                      content="exchange.trade.rate.header"
                      translation
                      className="header"
                    />
                    {this.state.from.currency === '' ||
                    this.state.to.currency === '' ? (
                      <Text
                        content="exchange.trade.rate.informer"
                        translation
                        className="content"
                      />
                    ) : (
                      <Text
                        content="exchange.trade.rate.fromTo"
                        data={{
                          from: this.state.from.currency,
                          to: this.state.to.currency,
                          rate: exchangeRate.attributes.rate
                        }}
                        translation
                        className="content"
                      />
                    )}
                  </div>

                  <div className="divider" />
                </div>
              </div>
            </div>
          </GlobalLayout>
        </Container>
      </React.Fragment>
    );
  }
}

export default Trade;
