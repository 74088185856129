import { TOC_LINK } from 'Constants/GlobalConstants/Global.constants';

export const register = {
  form: {
    header: 'Welcome to voult!',
    description:
      'Create your voult account to invest in the rapidly growing energy market. Your account password must be at least 8 characters.',
    email_placeholder: 'E-Mail Address',
    password_placeholder: 'Password',
    confirm_password_placeholder: 'Confirm Password',
    toc_aggrement_label: `I've agree the <a target='_blank' href=${TOC_LINK}>Terms and Conditions.</a>`,
    submit_label: 'Sign Up',
    loading_label: 'Loading...',
    footer: {
      content: 'Already a member?',
      cta: 'Login now!'
    },
    validation_error: 'Please check indicated area then submit the form again.'
  },
  success: {
    header: 'One Mone Step to Continue',
    description:
      'We need to verify your registered e-mail address before going further. Please check your mailbox and verify your e-mail address to continue to voult.',
    footer: {
      cta: 'Done'
    }
  },
  failure: {
    header: 'Sorry, registration attempt has failed.',
    errors: {
      0: 'Something went wrong, please try again to sign up.',
      409: "The e-mail address that you provided is already in use. Please check the information that you've provided or try to login."
    },
    footer: {
      cta: 'Back'
    }
  }
};
