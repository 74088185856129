import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';

import LoadingContainer from 'Containers/Loading/Loading.container';

import { retrieveAccessTokenFromStorageAndGetUserInformation } from 'Actions/AppInit/init.actions';

class AppInitContainer extends Component {
  /**
   * A global wrapper container with no render
   * - Retrieves storage based values and saves to reducer
   * - Initiates GA and other analytics tools
   */

  constructor(props) {
    super(props);

    this.props.retrieveAccessTokenFromStorageAndGetUserInformation();
  }

  handleInitControlsCheck = () => {
    return !_.includes(_.values(this.props.init.controls), false);
  };

  render() {
    if (this.handleInitControlsCheck()) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    } else {
      return <LoadingContainer />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    init: state.init
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveAccessTokenFromStorageAndGetUserInformation
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppInitContainer);
