import React from 'react';
import { Translate } from 'react-localize-redux';

import './Tab.style.scss';

const TabItem = () => {
  /**
   * This component is just an indicator that is used to wrap TabItems.
   * Please check state constructor of Tab Component to see why it is used.
   */
  return;
};

export default class Tab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bindIndex: props.defaultIndex,
      tabItems: this.props.children
    };
  }

  static TabItem = TabItem;

  changeTab = (newIndex) => {
    this.setState({ bindIndex: newIndex });
  };

  render() {
    const { bindIndex, tabItems } = this.state;
    const { className } = this.props;
    return (
      <div className="tab-wrapper">
        <div className="tab-menu">
          {tabItems.map(({ props: { index, label, translation } }) => (
            <div
              onClick={() => {
                this.changeTab(index);
              }}
              className={`tab-menu-item ${
                bindIndex === index
                  ? 'tab-menu-item-active'
                  : 'tab-menu-item-passive'
              }`}
              key={index}
            >
              <Translate>
                {({ translate }) => (translation ? translate(label) : label)}
              </Translate>
            </div>
          ))}
        </div>
        <div className={`tab-panel ${className}`}>
          {tabItems.map(({ props }) => (
            <div
              className={`tab-panel-item ${
                bindIndex === props.index
                  ? 'tab-panel-item-active'
                  : 'tab-panel-item-passive'
              }`}
              key={props.index}
            >
              {props.children}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
