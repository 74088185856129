import React, { Component } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import GlobalLayout from 'Layouts/Global.layout';
import { Container, Card, Text, FormElements, Button } from 'UILib';
import { isPhoneNumberValid } from 'Utils/Helpers/component.helpers';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import 'Components/Account/PhoneActivation/phoneActivation.style.scss';
import 'react-intl-tel-input/dist/main.css';

const initialValues = {
  phone_number: ''
};

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasValidationError: false
    };
  }

  handleSubmit = (phoneNumber) => {
    const isValid = isPhoneNumberValid(phoneNumber);
    if (isValid) {
      this.setState({ hasValidationError: false });
      this.props.phoneActivationFlowAdd(
        this.props.phoneActivation.access_token,
        this.props.phoneActivation.action_token,
        phoneNumber
      );
    } else {
      this.setState({ hasValidationError: true });
    }
  };

  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <Card type="auth" centered className="add-card">
            <Text
              type="h1"
              content="phoneActivation.add.header"
              className="header"
              translation
            />
            <Container className="content">
              <Text
                type="intro"
                content="phoneActivation.add.description"
                className="descriptive-text"
                translation
              />
              <Card.Content>
                <Formik
                  initialValues={initialValues}
                  validationSchema={
                    this.props.PhoneActivationAddValidationSchema
                  }
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={(values, { submitForm, setSubmitting }) => {
                    const phoneNumber = values.phone_number.replace(/\s+/g, '');
                    this.props.phoneActivationAddErrorClearing();
                    this.handleSubmit(phoneNumber);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Container className="form-wrapper">
                        <IntlTelInput
                          name="phone_number"
                          error={this.state.hasValidationError}
                          containerClassName="intl-tel-input"
                          inputClassName="form-control"
                          preferredCountries={['tr']}
                          useMobileFullscreenDropdown={true}
                          format={true}
                          nationalMode={false}
                          onPhoneNumberChange={(
                            validity,
                            placeholder,
                            obj,
                            output
                          ) => {
                            setFieldValue('phone_number', output);
                          }}
                        />
                      </Container>
                      {this.state.hasValidationError && (
                        <Container className="error-container">
                          <FormElements.ValidationErrors
                            content="phoneActivation.add.phone_number_is_not_valid"
                            translation
                          />
                        </Container>
                      )}
                      {this.props.phoneActivation.error && (
                        <Container className="error-container">
                          <FormElements.ValidationErrors
                            content="phoneActivation.add.phone_number_is_taken"
                            translation
                          />
                        </Container>
                      )}
                      <Container
                        className={`submit-container ${!this.state
                          .hasValidationError && 'margin-top-75'}`}
                      >
                        <Button
                          bgColor="orange-3"
                          borderColor="orange-3"
                          color="white"
                          type="submit"
                          content="phoneActivation.add.submit"
                          translation
                        />
                      </Container>
                    </form>
                  )}
                </Formik>
              </Card.Content>
            </Container>
            <Text type="intro" className="bottom-section">
              <Link to={APP_ROUTES.DASHBOARD}>
                <Translate id="phoneActivation.add.footer.cta" />
              </Link>
            </Text>
          </Card>
        </GlobalLayout>
      </Container>
    );
  }
}

export default Add;
