import * as _ from 'lodash';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

export const hasValidationError = (errors) => {
  /**
   * Checks if there is validation errors
   */
  return _.some(errors);
};

export const getErrorContent = (
  apiError,
  validationErrors,
  validationErrorMsg,
  localeBase,
  definedClientCodes
) => {
  /**
   * Returns a single error case based on both validation & api errors
   */
  if (hasValidationError(validationErrors)) {
    return validationErrorMsg;
  }
  const errorCode = determineErrorLocale(
    apiError.client_code,
    definedClientCodes
  );
  return `${localeBase}.${errorCode}`;
};

export const determineErrorLocale = (client_code, definedClientCodes) => {
  const defined = _.includes(definedClientCodes, client_code);
  return defined ? client_code : 0;
};

export const handleOTPTimerOutput = (remaining) => {
  const minutes = Math.floor(remaining / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (remaining % 60).toString().padStart(2, '0');
  return { minutes, seconds };
};

export const isPhoneNumberValid = (phoneNumber) => {
  if (parsePhoneNumberFromString(`${phoneNumber}`) === undefined) {
    return false;
  } else {
    return parsePhoneNumberFromString(`${phoneNumber}`).isValid();
  }
};

export const genericComponentTimer = (callback, interval = 1000) => {
  return setInterval(() => {
    callback();
  }, interval);
};

export const transformBalance = (balance, decimal = 2) => {
  const with_decimal = decimal > 0;
  const value = parseFloat(balance)
    .toFixed(decimal || 1)
    .replace('.', '_,')
    .replace(/\d(?=(\d{3})+_,)/g, '$&.')
    .split('_');

  return with_decimal ? value : _.first(value);
};

export const copyTextToClipboard = (text) => {
  /**
   * This function works with the listed steps:
   * - Creates a textarea component in html document
   * - Passes text value to this textarea component
   * - selecting textfield
   * - running copy html document action
   * - removing textfield from the dom
   *
   * This is a cross-browser function and possibly the safest way to copy a text value
   * because it only relies on the html document elements and its properties.
   */
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const downloadFileByUrl = (url, fileName) => {
  const linkElement = document.createElement('a');

  document.body.appendChild(linkElement);

  linkElement.setAttribute('download', fileName);
  linkElement.setAttribute('href', url);
  linkElement.setAttribute('target', '_blank');
  linkElement.click();
  linkElement.remove();
};

export const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const scrollElementIntoView = (el) => {
  const position = el.offsetTop - 100;
  window.scroll({ top: position, behavior: 'smooth' });
};

export const scrollToTop = () => {
  window.scroll({ top: 0, behavior: 'smooth' });
};

export const isItActiveSection = (previousProps, currentProps, property) => {
  return (
    _.get(previousProps, 'activeSection') !== property &&
    _.get(currentProps, 'activeSection') === property
  );
};

export const determineRemainingDays = (date) => {
  return moment.duration(moment(date).diff(moment(new Date()))).asDays();
};
