import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

class AuthenticationRoute extends Component {
  shouldRedirectToDashboard = () => {
    const { access_token } = this.props.account;
    return Boolean(access_token);
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        render={(props) =>
          this.shouldRedirectToDashboard() ? (
            <Redirect
              to={{
                pathname: APP_ROUTES.DASHBOARD
              }}
            />
          ) : (
            <Component {...rest} />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

export default withRouter(connect(mapStateToProps)(AuthenticationRoute));
