import React, { Component } from 'react';

import AuthenticationResult from 'Components/Authentication/Reusable/AuthenticationResult.component';

class ResendVerificationEmail extends Component {
  handleResendEmail = () => {
    this.props.userLoginResendVerificationEmail(this.props.login.access_token);
    this.props.userLoginFlowReset();
  };

  render() {
    return (
      <AuthenticationResult
        header={{
          type: 'h1',
          content: 'login.resend_verification.header',
          translation: true
        }}
        icon={{
          base: 'regular',
          name: 'envelope-open',
          color: 'orange-3',
          size: '10x'
        }}
        description={{
          type: 'intro',
          color: 'grey-5',
          content: 'login.resend_verification.description',
          translation: true
        }}
        onClick={{
          bgColor: 'orange-3',
          borderColor: 'orange-3',
          color: 'white',
          content: 'login.resend_verification.footer.cta',
          translation: true,
          action: () => {
            this.handleResendEmail();
          }
        }}
      />
    );
  }
}

export default ResendVerificationEmail;
