import { all, put, takeLatest } from 'redux-saga/effects';

import apiGenerator from 'Utils/Helpers/api.helpers';
import * as InitConstants from 'Constants/AppInit/init.constants';

import {
  getStatusCodeFamily,
  apiErrorHandler,
  sagaDelayer
} from 'Utils/Helpers/saga.helpers';

import {
  STATUS_TYPE,
  API_ENDPOINTS
} from 'Constants/GlobalConstants/Global.constants';

function* retrieveUserInformationsOnInitSaga(action) {
  const { access_token } = action.payload;

  const api = apiGenerator('get', access_token)(
    API_ENDPOINTS.LOGIN.GET_USER_INFORMATION
  );

  yield sagaDelayer(500);
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: InitConstants.RETRIEVE_USER_INFORMATION_ON_INIT_SUCCESS,
        payload: { ...response.data, access_token }
      });
    }
  } catch (err) {
    yield put({
      type: InitConstants.RETRIEVE_USER_INFORMATION_ON_INIT_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      InitConstants.RETRIEVE_USER_INFORMATION_ON_INIT_PENDING,
      retrieveUserInformationsOnInitSaga
    )
  ]);
}
