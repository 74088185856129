export const EXCHANGE_STEP = {
  TRADE: 'trade'
};

export const CURRENCY_TYPE = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDC: 'USDC'
};

export const EXCHANGE_FLOW_RESET = 'EXCHANGE_FLOW_RESET';
export const RETRIEVE_CURRENT_EXCHANGE_RATES_PENDING =
  'RETRIEVE_CURRENT_EXCHANGE_RATES_PENDING';
export const RETRIEVE_CURRENT_EXCHANGE_RATES_SUCCESS =
  'RETRIEVE_CURRENT_EXCHANGE_RATES_SUCCESS';
export const RETRIEVE_CURRENT_EXCHANGE_RATES_FAILURE =
  'RETRIEVE_CURRENT_EXCHANGE_RATES_FAILURE';
export const TRADE_EXCHANGE_PENDING = 'TRADE_EXCHANGE_PENDING';
export const TRADE_EXCHANGE_SUCCESS = 'TRADE_EXCHANGE_SUCCESS';
export const TRADE_EXCHANGE_FAILURE = 'TRADE_EXCHANGE_FAILURE';
