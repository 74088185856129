import React from 'react';
import Webcam from 'react-webcam';
import * as _ from 'lodash';

import GlobalLayout from 'Layouts/Global.layout';
import KYCStepHeader from 'Components/KYC/components/KYCStepHeader.component';

import { Container, Text, Button, Icon, FormElements } from 'UILib';
import 'Components/KYC/kyc.style.scss';
import { KYC_STEP } from 'Constants/KYC/KYC.constants';
import {
  URL_TEST_PATTERN,
  REQUEST_STATUS
} from 'Constants/GlobalConstants/Global.constants.js';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      web_profile_picture: this.getProfilePicture(),
      profile_picture: this.getProfilePicture(),
      validation_errors: false
    };
  }

  handleWebcamImage = (baseImage) => {
    this.setState({ web_profile_picture: baseImage, validation_errors: false });
  };

  handleWebClearImage = () => {
    this.setState({ web_profile_picture: null, validation_errors: false });
  };

  getProfilePicture = () => {
    if (_.has(this.props.kyc.persistent_data, 'profile_picture')) {
      if (this.props.kyc.persistent_data.profile_picture.from === 'frontend') {
        return this.props.kyc.persistent_data.profile_picture.image;
      } else {
        return this.props.kyc.persistent_data.profile_picture.files[0].url;
      }
    } else {
      return null;
    }
  };

  handleOnChangeFile = (section, file) => {
    this.setState({
      [section]: file,
      validation_errors: false
    });
  };

  checkValidationErrorsAndSubmit = (mobile) => {
    if (
      this.state.web_profile_picture !== null ||
      this.state.profile_picture !== null
    ) {
      if (mobile) {
        _.has(this.props.kyc.persistent_data, 'profile_picture')
          ? this.props.kycFlowProfilePatch(
              this.props.account.access_token,
              this.state.profile_picture,
              true
            )
          : this.props.kycFlowProfile(
              this.props.account.access_token,
              this.state.profile_picture,
              true
            );
      } else {
        _.has(this.props.kyc.persistent_data, 'profile_picture')
          ? this.props.kycFlowProfilePatch(
              this.props.account.access_token,
              this.state.web_profile_picture,
              false
            )
          : this.props.kycFlowProfile(
              this.props.account.access_token,
              this.state.web_profile_picture,
              false
            );
      }
    } else {
      this.setState({ validation_errors: true });
    }
  };

  render() {
    const videoConstraints = {
      width: 640,
      height: 480,
      facingMode: 'user'
    };
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <div className="kyc-page-wrapper">
            <div className="kyc-profile-wrapper">
              <KYCStepHeader currentStep={3} />
              <div className="section-wrapper">
                <Text
                  content="kyc.profile.header"
                  translation
                  className="header"
                />
                <Text
                  content="kyc.profile.content"
                  translation
                  className="content"
                />
                <div className="selfie-section">
                  <div className="section">
                    <Text
                      content="kyc.profile.selfie"
                      translation
                      className="header"
                    />
                    <div className="selfie-zone">
                      {this.state.web_profile_picture ? (
                        <React.Fragment>
                          <img
                            src={this.state.web_profile_picture}
                            alt="front-side"
                            className="image"
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Webcam
                            ref={(ref) => (this.webcam = ref)}
                            className="webcam"
                            audio={false}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                          />
                        </React.Fragment>
                      )}
                    </div>
                    {this.state.web_profile_picture ? (
                      <div
                        onClick={() => {
                          this.handleWebClearImage();
                        }}
                      >
                        <Icon
                          base="solid"
                          icon={{ name: 'redo', color: 'grey-5' }}
                          className="capture"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          this.handleWebcamImage(this.webcam.getScreenshot());
                        }}
                      >
                        <Icon
                          base="solid"
                          icon={{ name: 'camera', color: 'grey-5' }}
                          className="capture"
                        />
                      </div>
                    )}
                  </div>
                  <div className="mobile-section">
                    <Text
                      content="kyc.profile.selfie"
                      translation
                      className="header"
                    />
                    <div
                      className="dropzone"
                      onClick={() => {
                        this.mobileProfile.click();
                      }}
                    >
                      <input
                        type="file"
                        ref={(ref) => (this.mobileProfile = ref)}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          this.handleOnChangeFile(
                            'profile_picture',
                            event.target.files[0]
                          );
                        }}
                      />
                      {this.state.profile_picture ? (
                        <React.Fragment>
                          <img
                            src={
                              URL_TEST_PATTERN.test(this.state.profile_picture)
                                ? this.state.profile_picture
                                : URL.createObjectURL(
                                    this.state.profile_picture
                                  )
                            }
                            alt="front-side"
                            className="image"
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Icon
                            base="regular"
                            icon={{ name: 'clone', color: 'grey-5' }}
                            className="icon"
                          />
                          <Text
                            content="kyc.profile.dropzone.text"
                            translation
                            className="text"
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.validation_errors && (
                  <div className="error-zone">
                    <FormElements.ValidationErrors
                      content="kyc.profile.validation_error"
                      translation
                    />
                  </div>
                )}
                <div className="action-zone">
                  <Button
                    content="kyc.profile.action.back"
                    translation
                    borderColor="orange-3"
                    bgColor="white"
                    color="orange-3"
                    className="back"
                    onClick={() => {
                      this.props.kycMoveToGivenStep(KYC_STEP.DOCUMENTS);
                    }}
                  />

                  {this.props.kyc.status === REQUEST_STATUS.PENDING ? (
                    <React.Fragment>
                      <Button
                        type="submit"
                        color="white"
                        bgColor="orange-3"
                        borderColor="orange-3"
                        loading
                        className="continue"
                      />
                      <Button
                        type="submit"
                        color="white"
                        bgColor="orange-3"
                        borderColor="orange-3"
                        loading
                        className="mobile-continue"
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        content="kyc.profile.action.continue"
                        translation
                        borderColor="orange-3"
                        bgColor="orange-3"
                        color="white"
                        className="continue"
                        onClick={() => {
                          this.checkValidationErrorsAndSubmit(false);
                        }}
                      />
                      <Button
                        content="kyc.profile.action.continue"
                        translation
                        borderColor="orange-3"
                        bgColor="orange-3"
                        color="white"
                        className="mobile-continue"
                        onClick={() => {
                          this.checkValidationErrorsAndSubmit(true);
                        }}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </GlobalLayout>
      </Container>
    );
  }
}

export default Profile;
