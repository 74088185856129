import { all, fork } from 'redux-saga/effects';
import init from 'Sagas/AppInit/init.saga';
import projectListing from 'Sagas/ProjectListing/ProjectListing.saga';
import projectDetail from 'Sagas/ProjectDetail/ProjectDetail.saga';
import register from 'Sagas/Authentication/Register.saga';
import login from 'Sagas/Authentication/Login.saga';
import resetPassword from 'Sagas/Authentication/ResetPassword.saga';
import account from 'Sagas/Account/Account.saga';
import phoneActivation from 'Sagas/Account/PhoneActivation.saga';
import kyc from 'Sagas/KYC/KYC.saga';
import userDashboard from 'Sagas/Account/UserDashboard.saga';
import commitment from 'Sagas/Commitment/Commitment.saga';
import investment from 'Sagas/Investment/Investment.saga';
import withdraw from 'Sagas/Withdraw/Withdraw.saga';
import exchange from 'Sagas/Exchange/Exchange.saga';

// RootSaga
export default function* root() {
  yield all([
    fork(init),
    fork(projectListing),
    fork(projectDetail),
    fork(register),
    fork(login),
    fork(resetPassword),
    fork(account),
    fork(phoneActivation),
    fork(kyc),
    fork(userDashboard),
    fork(commitment),
    fork(investment),
    fork(withdraw),
    fork(exchange)
  ]);
}
