import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

import { Button, Text } from 'UILib';
import { transformBalance } from 'Utils/Helpers/component.helpers';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import 'Components/ProjectDetail/projectDetail.style.scss';

class BuyShares extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      calculatedROI: ''
    };
  }

  calculation = (value) => {
    const annual_revenue = parseInt(
      this.props.financial.compensation_details.annual_revenue,
      10
    );
    const offer_percentage =
      parseInt(
        this.props.contribution.compensation_details.offer_percentage,
        10
      ) / 100;

    const amount_to_be_raised = parseInt(
      this.props.contribution.compensation_details.amount_to_be_raised,
      10
    );

    return (
      ((annual_revenue * offer_percentage * value) / amount_to_be_raised) * 10
    );
  };

  handleInputChange = (values) => {
    let { value } = values;
    if (value < 1000000) {
      const calculatedROI = this.calculation(value).toFixed(2);
      if (value) {
        this.setState({
          inputValue: value,
          calculatedROI
        });
      } else {
        this.setState({ inputValue: '', calculatedROI: '' });
      }
    }
  };

  render() {
    return (
      <div className="buy-shares-zone">
        <div className="action-zone">
          <div className="roi-zone">
            <div className="input-zone">
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                placeholder="$ 10.000"
                value={this.state.inputValue}
                isNumericString={true}
                displayType="input"
                onValueChange={(values) => {
                  this.handleInputChange(values);
                }}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === '' || floatValue <= 1000000;
                }}
              />
              <label htmlFor="value">$</label>
            </div>

            <div className="calculation-zone">
              <Text
                type="intro"
                content="projectDetail.buyShares.earn"
                translation
                color="white"
                className="header"
                textAlign="left"
              />
              <Text
                type="intro"
                content="projectDetail.buyShares.calculated"
                data={{
                  amount: transformBalance(
                    this.state.calculatedROI === ''
                      ? this.calculation(10000)
                      : this.state.calculatedROI,
                    0
                  ),
                  lifetime: this.props.financial.investment_performance
                    .project_estimated_lifetime
                }}
                translation
                color="white"
                className="content"
                textAlign="left"
              />
            </div>
          </div>
          <div>
            <Button
              content="projectDetail.buyShares.cta"
              translation
              borderColor="white"
              color="white"
              bgColor="blue-3"
              onClick={() => {
                this.props.history.push(
                  this.props.investment_status
                    ? APP_ROUTES.INVESTMENT_WITH_ID(this.props.statistics.id)
                    : APP_ROUTES.COMMITMENT_WITH_ID(this.props.statistics.id)
                );
              }}
            />
          </div>
        </div>
        <div className="information-zone">
          <Text
            type="intro"
            content="projectDetail.buyShares.subheader"
            translation
            color="white"
            className="text"
          />
        </div>
      </div>
    );
  }
}

export default BuyShares;
