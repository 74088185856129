import React from 'react';

import { Icon, Text } from 'UILib';

import { downloadFileByUrl } from 'Utils/Helpers/component.helpers';

const FileZone = (props) => {
  return (
    <div className="file-zone">
      {props.files.map((file, idx) => (
        <div key={idx} className="file-item">
          <Icon
            base="regular"
            icon={{ name: 'file', color: 'blue-3' }}
            className="icon"
          />
          <div className="text-area">
            <Text content={file.attributes.name} className="header" />
            <div
              onClick={() => {
                downloadFileByUrl(file.attributes.url, file.attributes.name);
              }}
            >
              <Text
                content="projectDetail.fileZone.download"
                translation
                className="download"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FileZone;
