import React from 'react';
import { Translate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { Animate } from 'react-move';

import { Text } from 'UILib';
import { scrollToTop, transformBalance } from 'Utils/Helpers/component.helpers';

import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

const HangingNavBar = (props) => {
  return (
    <Animate
      show={props.hangingNavbarVisible}
      start={{
        opacity: 0.2,
        top: -80
      }}
      enter={{
        opacity: [1],
        top: [0],
        timing: { duration: 300 }
      }}
      update={{
        // catch interrupts e.g. click button in middle of leave
        opacity: [1],
        top: [0],
        timing: { duration: 300 }
      }}
      leave={{
        opacity: [0.2],
        top: [-80],
        timing: { duration: 300 }
      }}
    >
      {({ opacity, top }) => (
        <div className="hanging-nav-bar-wrapper" style={{ opacity, top }}>
          <div className="nav-bar-container">
            <div onClick={() => scrollToTop()}>
              <Text
                content={props.information.name}
                className="nav-bar-header"
              />
            </div>

            <div className="nav-bar-right-pane">
              <div className="section-wrapper">
                <ul>
                  <li
                    className={
                      props.activeSection === 'overview' ? 'active' : ''
                    }
                    onClick={() => {
                      props.handleActiveSection('overview');
                    }}
                  >
                    <Translate id="projectDetail.hangingNavBar.menu.overview" />
                  </li>
                  <li
                    className={
                      props.activeSection === 'information' ? 'active' : ''
                    }
                    onClick={() => {
                      props.handleActiveSection('information');
                    }}
                  >
                    <Translate id="projectDetail.hangingNavBar.menu.information" />
                  </li>
                  <li
                    className={
                      props.activeSection === 'finance' ? 'active' : ''
                    }
                    onClick={() => {
                      props.handleActiveSection('finance');
                    }}
                  >
                    <Translate id="projectDetail.hangingNavBar.menu.financial" />
                  </li>
                  <li
                    className={
                      props.activeSection === 'technology' ? 'active' : ''
                    }
                    onClick={() => {
                      props.handleActiveSection('technology');
                    }}
                  >
                    <Translate id="projectDetail.hangingNavBar.menu.technology" />
                  </li>
                  <li
                    className={props.activeSection === 'market' ? 'active' : ''}
                    onClick={() => {
                      props.handleActiveSection('market');
                    }}
                  >
                    <Translate id="projectDetail.hangingNavBar.menu.market" />
                  </li>
                </ul>
              </div>
              <div className="statistics-wrapper">
                <div className="statistics-item">
                  <Text
                    type="intro"
                    content={transformBalance(
                      props.statistics.total_view.toString(),
                      0
                    )}
                    color="black"
                    className="header"
                  />
                  <Text
                    type="intro"
                    content="viewers"
                    transform="title"
                    color="grey-3"
                    className="subheader"
                  />
                </div>
                <div className="statistics-item">
                  <Text
                    type="intro"
                    content={transformBalance(
                      props.statistics.backer_count.toString(),
                      0
                    )}
                    color="black"
                    className="header"
                  />
                  <Text
                    type="intro"
                    content="backers"
                    transform="title"
                    color="grey-3"
                    className="subheader"
                  />
                </div>
              </div>
              <div
                className="buy-action"
                onClick={() => {
                  props.history.push(
                    props.investment_status
                      ? APP_ROUTES.WORK_IN_PROGRESS
                      : `/project/${props.statistics.id}/commitment`
                  );
                }}
              >
                <Translate id="projectDetail.hangingNavBar.action.buyshares" />
              </div>
            </div>
          </div>
        </div>
      )}
    </Animate>
  );
};

export default withRouter(HangingNavBar);
