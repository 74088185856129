import * as AccountConstants from 'Constants/Account/Account.constants';
import { removeFromLocalStorage } from 'Utils/Helpers/localstorage.helpers';

export function logOutUser(access_token) {
  removeFromLocalStorage('access_token');
  return {
    type: AccountConstants.LOG_OUT_USER,
    payload: { access_token }
  };
}
