import * as ProjectListingConstants from 'Constants/ProjectListing/ProjectListing.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

const initialState = {
  status: REQUEST_STATUS.NOT_DEFINED,
  error: null,
  projects: []
};

export default function projectList(state = initialState, action) {
  switch (action.type) {
    case ProjectListingConstants.RETRIEVE_PROJECT_LIST_PENDING:
      return modifyReducer(state, {
        ...state,
        status: REQUEST_STATUS.PENDING
      });
    case ProjectListingConstants.RETRIEVE_PROJECT_LIST_FAILURE:
      return modifyReducer(state, {
        ...state,
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    case ProjectListingConstants.RETRIEVE_PROJECT_LIST_SUCCESS:
      return modifyReducer(state, {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        error: null,
        projects: action.payload
      });
    default:
      return state;
  }
}
