import React from 'react';

import WalletPane from 'Components/Account/UserDashboard/Components/WalletPane.component';
import CommitmentPane from 'Components/Commitment/Components/CommitmentPane.component';

const CommitmentForm = (props) => {
  return (
    <div className="commitment-main-wrapper">
      <CommitmentPane {...props} />
      <WalletPane {...props} />
    </div>
  );
};

export default CommitmentForm;
