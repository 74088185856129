import { all, put, takeLatest } from 'redux-saga/effects';

import apiGenerator from 'Utils/Helpers/api.helpers';
import {
  getStatusCodeFamily,
  apiErrorHandler
} from 'Utils/Helpers/saga.helpers';
import {
  API_ENDPOINTS,
  STATUS_TYPE
} from 'Constants/GlobalConstants/Global.constants';
import * as InvestmentConstants from 'Constants/Investment/Investment.constants';

function* investmentFlowStartSaga(action) {
  const { projectId, access_token } = action.payload;

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.INVESTMENT.START(projectId)
  );
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: InvestmentConstants.INVESTMENT_FLOW_START_SUCCESS,
        payload: {
          ...response.data,
          access_token: access_token,
          projectId: projectId
        }
      });
    }
  } catch (err) {
    yield put({
      type: InvestmentConstants.INVESTMENT_FLOW_START_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* InvestmentFlowAmountSaga(action) {
  const { projectId, access_token, transaction_id, amount } = action.payload;

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.INVESTMENT.AMOUNT(projectId),
    {
      transaction_id: transaction_id,
      amount: parseFloat(amount)
    }
  );

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: InvestmentConstants.INVESTMENT_FLOW_AMOUNT_SUCCESS,
        payload: {
          ...response.data,
          access_token: access_token,
          projectId: projectId,
          amount: amount
        }
      });
    }
  } catch (err) {
    yield put({
      type: InvestmentConstants.INVESTMENT_FLOW_AMOUNT_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* InvestmentFlowConfirmSaga(action) {
  const { projectId, access_token, transaction_id } = action.payload;

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.INVESTMENT.CONFIRM(projectId),
    { transaction_id: transaction_id, agreement: true }
  );

  try {
    const response = yield api;
    yield put({
      type: InvestmentConstants.INVESTMENT_FLOW_CONFIRM_SUCCESS,
      payload: {
        ...response.data,
        access_token: access_token,
        projectId: projectId
      }
    });
  } catch (err) {
    yield put({
      type: InvestmentConstants.INVESTMENT_FLOW_CONFIRM_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      InvestmentConstants.INVESTMENT_FLOW_START_PENDING,
      investmentFlowStartSaga
    ),
    takeLatest(
      InvestmentConstants.INVESTMENT_FLOW_AMOUNT_PENDING,
      InvestmentFlowAmountSaga
    ),
    takeLatest(
      InvestmentConstants.INVESTMENT_FLOW_CONFIRM_PENDING,
      InvestmentFlowConfirmSaga
    )
  ]);
}
