import React from 'react';
import cx from 'classnames';
import MaskedInput from 'react-text-mask';
import { Translate } from 'react-localize-redux';

import './FormElements.style.scss';

export default class Input extends React.Component {
  render() {
    const {
      bgColor,
      borderColor,
      color,
      name,
      type,
      maxLength,
      masked,
      mask,
      min,
      keepCharPositions,
      guide,
      value,
      error,
      onChange,
      onBlur,
      placeholder,
      translation,
      style,
      className
    } = this.props;

    const baseCN = 'input';
    const bgColorCN = bgColor
      ? `${baseCN}-background-color-${bgColor}`
      : `${baseCN}-background-color-white`;
    const borderColorCN = borderColor
      ? `${baseCN}-border-color-${borderColor}`
      : `${baseCN}-border-color-grey-2`;

    const errorBorderColorCN = error && `${baseCN}-error-border-color`;

    const colorCN = color
      ? `${baseCN}-color-${color}`
      : `${baseCN}-color-grey-6`;

    const labelCN = 'label';

    const InputCX = cx(
      baseCN,
      bgColorCN,
      borderColorCN,
      colorCN,
      errorBorderColorCN,
      className
    );

    const LabelCX = cx(labelCN);

    return masked ? (
      <Translate>
        {({ translate }) => (
          <div className="input-wrapper">
            <MaskedInput
              type="text"
              id={name}
              name={name}
              value={value}
              maxLength={maxLength}
              placeholder={translation ? translate(placeholder) : placeholder}
              mask={mask}
              keepCharPositions={keepCharPositions}
              guide={guide}
              onChange={onChange}
              onBlur={onBlur}
              style={style}
              className={InputCX}
            />
            <label htmlFor={name} className={LabelCX}>
              {translation ? translate(placeholder) : placeholder}
            </label>
          </div>
        )}
      </Translate>
    ) : (
      <Translate>
        {({ translate }) => (
          <div className="input-wrapper">
            <input
              type={type}
              id={name}
              name={name}
              value={value}
              min={min}
              maxLength={maxLength}
              placeholder={translation ? translate(placeholder) : placeholder}
              onChange={onChange}
              onBlur={onBlur}
              style={style}
              className={InputCX}
            />
            <label htmlFor={name} className={LabelCX}>
              {translation ? translate(placeholder) : placeholder}
            </label>
          </div>
        )}
      </Translate>
    );
  }
}
