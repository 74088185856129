/**
 * TODO: This component doesnt seem well written
 * This might be needed a rework.
 */
import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import './Header.style.scss';

export default class Header extends React.Component {
  render() {
    const {
      content,
      translation,
      lined,
      color,
      bgColor,
      style,
      className
    } = this.props;

    const baseCN = 'header-ui';

    const linedCN = `${baseCN}-lined`;
    const colorCN = color
      ? `${baseCN}-color-${color}`
      : `${baseCN}-color-grey-6`;
    const contentBGColor = bgColor
      ? `${baseCN}-background-color-${bgColor}`
      : `${baseCN}-background-color-grey-0`;

    const HeaderCX = cx(baseCN, linedCN, colorCN, className);

    return (
      <Translate>
        {({ translate }) => (
          <div className={HeaderCX} style={style}>
            {lined ? (
              <div className={`content ${contentBGColor}`}>
                <div className={`text ${colorCN}`}>
                  {translation ? translate(content) : content}{' '}
                </div>
                <div className={`line ${colorCN}`} />
              </div>
            ) : (
              <div>{translation ? translate(content) : content} </div>
            )}
          </div>
        )}
      </Translate>
    );
  }
}
