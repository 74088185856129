import * as RegisterConstants from 'Constants/Authentication/Register.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

const initialState = {
  status: REQUEST_STATUS.NOT_DEFINED,
  error: null
};

const pendingState = {
  status: REQUEST_STATUS.PENDING,
  error: null
};

const successState = {
  status: REQUEST_STATUS.SUCCESS,
  error: null
};

const failureState = (error) => ({
  status: REQUEST_STATUS.FAILURE,
  error: error
});

export default function register(state = initialState, action) {
  switch (action.type) {
    case RegisterConstants.USER_REGISTER_STATE_RESET:
      return modifyReducer(state, initialState);
    case RegisterConstants.USER_REGISTER_PENDING:
      return modifyReducer(state, pendingState);
    case RegisterConstants.USER_REGISTER_SUCCESS:
      return modifyReducer(state, successState);
    case RegisterConstants.USER_REGISTER_FAILURE:
      return modifyReducer(state, failureState(action.payload));
    case RegisterConstants.USER_REGISTER_VERIFY_PENDING:
      return modifyReducer(state, pendingState);
    case RegisterConstants.USER_REGISTER_VERIFY_SUCCESS:
      return modifyReducer(state, successState);
    case RegisterConstants.USER_REGISTER_VERIFY_FAILURE:
      return modifyReducer(state, failureState(action.payload));
    default:
      return state;
  }
}
