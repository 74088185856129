import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import { Icon, Button } from 'UILib';

import './Message.style.scss';

export default class Message extends React.Component {
  render() {
    const {
      bgColor,
      borderColor,
      icon,
      header,
      content,
      translation,
      action,
      dismissable,
      dismissAction,
      style,
      className
    } = this.props;

    const baseCN = 'message';
    const bgColorCN = bgColor
      ? `${baseCN}-background-color-${bgColor}`
      : `${baseCN}-background-color-white`;
    const borderColorCN = borderColor
      ? `${baseCN}-border-color-${borderColor}`
      : `${baseCN}-border-color-black`;

    const CX = cx(baseCN, bgColorCN, borderColorCN, className);
    return (
      <div className={CX} style={style}>
        {dismissable && (
          <div className="dismiss" onClick={dismissAction}>
            <Icon
              base="regular"
              icon={{ name: 'times-circle', color: 'grey-2' }}
            />
          </div>
        )}
        {icon && (
          <Icon base={icon.base} icon={icon.icon} className="message-icon" />
        )}
        <Translate>
          {({ translate }) => (
            <div className="content">
              <div className="header">
                {translation ? translate(header) : header}
              </div>
              <div className="content">
                {translation ? translate(content) : content}
              </div>
            </div>
          )}
        </Translate>

        {action && (
          <div className="action">
            <Button
              content={action.content}
              translation={action.translation}
              color={action.color}
              bgColor={action.bgColor}
              borderColor={action.borderColor}
              onClick={action.action}
            />
          </div>
        )}
      </div>
    );
  }
}
