export const COMMITMENT_TO_PROJECT_PENDING = 'COMMITMENT_TO_PROJECT_PENDING';
export const COMMITMENT_TO_PROJECT_SUCCESS = 'COMMITMENT_TO_PROJECT_SUCCESS';
export const COMMITMENT_TO_PROJECT_FAILURE = 'COMMITMENT_TO_PROJECT_FAILURE';
export const RESET_COMMITMENT_TO_PROJECT = 'RESET_COMMITMENT_TO_PROJECT';

export const COMMITMENT_AMOUNTS = [
  '100',
  '2500',
  '10000',
  '50000',
  '100000',
  'later'
];
