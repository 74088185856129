export const INVESTMENT_STEP = {
  START: 'start',
  AMOUNT: 'amount',
  CONFIRM: 'confirm',
  INSUFFICIENT_FUNDS: 'insufficient_funds'
};

export const INVESTMENT_AMOUNTS = [
  '100',
  '2500',
  '10000',
  '50000',
  '75000',
  '100000'
];

export const INSUFFICIENT_FUNDS_CODE = 480;

export const INVESTMENT_FLOW_RESET = 'INVESTMENT_FLOW_RESET';
export const INVESTMENT_FLOW_START_PENDING = 'INVESTMENT_FLOW_START_PENDING';
export const INVESTMENT_FLOW_START_SUCCESS = 'INVESTMENT_FLOW_START_SUCCESS';
export const INVESTMENT_FLOW_START_FAILURE = 'INVESTMENT_FLOW_START_FAILURE';
export const INVESTMENT_TIMER_REDUCTION = 'INVESTMENT_TIMER_REDUCTION';
export const INVESTMENT_FLOW_AMOUNT_PENDING = 'INVESTMENT_FLOW_AMOUNT_PENDING';
export const INVESTMENT_FLOW_AMOUNT_SUCCESS = 'INVESTMENT_FLOW_AMOUNT_SUCCESS';
export const INVESTMENT_FLOW_AMOUNT_FAILURE = 'INVESTMENT_FLOW_AMOUNT_FAILURE';
export const INVESTMENT_FLOW_CONFIRM_PENDING =
  'INVESTMENT_FLOW_CONFIRM_PENDING';
export const INVESTMENT_FLOW_CONFIRM_SUCCESS =
  'INVESTMENT_FLOW_CONFIRM_SUCCESS';
export const INVESTMENT_FLOW_CONFIRM_FAILURE =
  'INVESTMENT_FLOW_CONFIRM_FAILURE';
