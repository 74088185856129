import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import VerifyRegister from 'Components/Authentication/VerifyRegister.component';

import {
  userRegisterStateReset,
  userRegisterVerify
} from 'Actions/Authentication/Register.actions';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

class VerifyRegisterContainer extends Component {
  constructor(props) {
    super(props);

    this.props.userRegisterStateReset();
  }

  componentDidMount() {
    this.props.userRegisterVerify(
      this.props.computedMatch.params.verification_code
    );
  }

  render() {
    const { register } = this.props;

    switch (register.status) {
      case REQUEST_STATUS.SUCCESS:
        return <VerifyRegister.Success />;
      case REQUEST_STATUS.FAILURE:
        return <VerifyRegister.Failure />;
      default:
        return <VerifyRegister.Pending />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.register
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { userRegisterStateReset, userRegisterVerify },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyRegisterContainer);
