import { all, put, takeLatest } from 'redux-saga/effects';
import apiGenerator from 'Utils/Helpers/api.helpers';
import {
  getStatusCodeFamily,
  apiErrorHandler
} from 'Utils/Helpers/saga.helpers';

import {
  STATUS_TYPE,
  API_ENDPOINTS
} from 'Constants/GlobalConstants/Global.constants';
import * as UserDashboardConstants from 'Constants/Account/UserDashboard.constants';

function* getUserWalletsSaga(action) {
  const { access_token } = action.payload;
  const api = apiGenerator('get', access_token)(
    API_ENDPOINTS.USER_DASHBOARD.GET_USER_WALLETS
  );

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: UserDashboardConstants.GET_USER_WALLETS_SUCCESS,
        payload: response.data.wallets
      });
    }
  } catch (err) {
    yield put({
      type: UserDashboardConstants.GET_USER_WALLETS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* getUserSelectedWalletDetailsSaga(action) {
  const { access_token, currency } = action.payload;
  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.USER_DASHBOARD.GET_USER_WALLETS,
    { currency }
  );

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: UserDashboardConstants.GET_USER_SELECTED_WALLET_DETAILS_SUCCESS,
        payload: response.data.attributes
      });
    }
  } catch (err) {
    yield put({
      type: UserDashboardConstants.GET_USER_SELECTED_WALLET_DETAILS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* regenerateUserSelectedWalletAddressSaga(action) {
  const { access_token, currency } = action.payload;
  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.USER_DASHBOARD.REGENERATE_WALLET_ADDRESS,
    { currency }
  );
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type:
          UserDashboardConstants.REGENERATE_USER_SELECTED_WALLET_ADDRESS_SUCCESS,
        payload: response.data.attributes
      });
    }
  } catch (err) {
    yield put({
      type:
        UserDashboardConstants.REGENERATE_USER_SELECTED_WALLET_ADDRESS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* getUserDashboardProjectsSaga(action) {
  const { access_token } = action.payload;
  const investedProjectsApi = apiGenerator('get', access_token)(
    API_ENDPOINTS.USER_DASHBOARD.GET_USER_INVESTED_PROJECTS
  );

  const investableProjectsApi = apiGenerator('get')(
    API_ENDPOINTS.PROJECTS.GET_LISTABLE_INVESTABLE_PROJECTS
  );

  try {
    const investedProjectsResponse = yield investedProjectsApi;
    if (
      getStatusCodeFamily(investedProjectsResponse.status) ===
      STATUS_TYPE.SUCCESS
    ) {
      yield put({
        type: UserDashboardConstants.GET_USER_INVESTED_PROJECTS_SUCCESS,
        payload: investedProjectsResponse.data.projects
      });
    }
  } catch (err) {
    yield put({
      type: UserDashboardConstants.GET_USER_INVESTED_PROJECTS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }

  try {
    const investableProjectsResponse = yield investableProjectsApi;
    if (
      getStatusCodeFamily(investableProjectsResponse.status) ===
      STATUS_TYPE.SUCCESS
    ) {
      yield put({
        type: UserDashboardConstants.GET_USER_INVESTABLE_PROJECTS_SUCCESS,
        payload: investableProjectsResponse.data.projects
      });
    }
  } catch (err) {
    yield put({
      type: UserDashboardConstants.GET_USER_INVESTABLE_PROJECTS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* getUserCommitedProjectsSaga(action) {
  const { access_token } = action.payload;
  const api = apiGenerator('get', access_token)(
    API_ENDPOINTS.USER_DASHBOARD.GET_USER_COMMITED_PROJECTS
  );
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: UserDashboardConstants.GET_USER_COMMITED_PROJECTS_SUCCESS,
        payload: {
          projects: response.data.projects,
          user_commitments: response.data.user_commitments
        }
      });
    }
  } catch (err) {
    yield put({
      type: UserDashboardConstants.GET_USER_COMMITED_PROJECTS_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      UserDashboardConstants.GET_USER_WALLETS_PENDING,
      getUserWalletsSaga
    ),
    takeLatest(
      UserDashboardConstants.GET_USER_SELECTED_WALLET_DETAILS_PENDING,
      getUserSelectedWalletDetailsSaga
    ),
    takeLatest(
      UserDashboardConstants.REGENERATE_USER_SELECTED_WALLET_ADDRESS_PENDING,
      regenerateUserSelectedWalletAddressSaga
    ),
    takeLatest(
      UserDashboardConstants.GET_USER_DASHBOARD_PROJECTS_PENDING,
      getUserDashboardProjectsSaga
    ),
    takeLatest(
      UserDashboardConstants.GET_USER_COMMITED_PROJECTS_PENDING,
      getUserCommitedProjectsSaga
    )
  ]);
}
