import {
  KYC_FLOW_RESET,
  KYC_MOVE_TO_GIVEN_STEP,
  KYC_GET_CURRENT_STEP_PENDING,
  KYC_FLOW_START_PENDING,
  KYC_FLOW_PERSONAL_PENDING,
  KYC_FLOW_PERSONAL_PATCH_PENDING,
  KYC_FLOW_DOCUMENTS_PENDING,
  KYC_FLOW_DOCUMENTS_PATCH_PENDING,
  KYC_FLOW_PROFILE_PENDING,
  KYC_FLOW_PROFILE_PATCH_PENDING,
  KYC_FLOW_ADDRESS_PENDING,
  KYC_FLOW_ADDRESS_PATCH_PENDING,
  KYC_FLOW_SUBMIT_PENDING
} from 'Constants/KYC/KYC.constants';

export function kycFlowReset() {
  return {
    type: KYC_FLOW_RESET
  };
}

export function kycMoveToGivenStep(step) {
  return {
    type: KYC_MOVE_TO_GIVEN_STEP,
    payload: { step }
  };
}

export function kycGetCurrentStep(access_token) {
  return {
    type: KYC_GET_CURRENT_STEP_PENDING,
    payload: { access_token }
  };
}

export function kycFlowStart(access_token) {
  return {
    type: KYC_FLOW_START_PENDING,
    payload: { access_token }
  };
}

export function kycFlowPersonal(access_token, values) {
  return {
    type: KYC_FLOW_PERSONAL_PENDING,
    payload: { access_token, values }
  };
}

export function kycFlowPersonalPatch(access_token, values) {
  return {
    type: KYC_FLOW_PERSONAL_PATCH_PENDING,
    payload: { access_token, values }
  };
}

export function kycFlowDocuments(access_token, state) {
  return {
    type: KYC_FLOW_DOCUMENTS_PENDING,
    payload: { access_token, state }
  };
}

export function kycFlowDocumentsPatch(access_token, state) {
  return {
    type: KYC_FLOW_DOCUMENTS_PATCH_PENDING,
    payload: { access_token, state }
  };
}

export function kycFlowProfile(access_token, image, mobile) {
  return {
    type: KYC_FLOW_PROFILE_PENDING,
    payload: { access_token, image, mobile }
  };
}

export function kycFlowProfilePatch(access_token, image, mobile) {
  return {
    type: KYC_FLOW_PROFILE_PATCH_PENDING,
    payload: { access_token, image, mobile }
  };
}

export function kycFlowAddress(access_token, state) {
  return {
    type: KYC_FLOW_ADDRESS_PENDING,
    payload: { access_token, state }
  };
}

export function kycFlowAddressPatch(access_token, state) {
  return {
    type: KYC_FLOW_ADDRESS_PATCH_PENDING,
    payload: { access_token, state }
  };
}

export function kycFlowSubmit(access_token) {
  return {
    type: KYC_FLOW_SUBMIT_PENDING,
    payload: { access_token }
  };
}
