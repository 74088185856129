import {
  GET_USER_WALLETS_PENDING,
  GET_USER_SELECTED_WALLET_DETAILS_PENDING,
  REGENERATE_USER_SELECTED_WALLET_ADDRESS_PENDING,
  CLEAR_USER_SELECTED_WALLET_DETAILS,
  GET_USER_DASHBOARD_PROJECTS_PENDING,
  GET_USER_COMMITED_PROJECTS_PENDING
} from 'Constants/Account/UserDashboard.constants';

export function getUserWallets(access_token) {
  return {
    type: GET_USER_WALLETS_PENDING,
    payload: { access_token }
  };
}

export function getUserSelectedWalletDetails(access_token, currency) {
  return {
    type: GET_USER_SELECTED_WALLET_DETAILS_PENDING,
    payload: { access_token, currency }
  };
}

export function regenerateUserSelectedWalletAddress(access_token, currency) {
  return {
    type: REGENERATE_USER_SELECTED_WALLET_ADDRESS_PENDING,
    payload: { access_token, currency }
  };
}

export function clearUserSelectedWalletDetails() {
  return {
    type: CLEAR_USER_SELECTED_WALLET_DETAILS
  };
}

export function getUserInvestedProjects(access_token) {
  return {
    type: GET_USER_DASHBOARD_PROJECTS_PENDING,
    payload: { access_token }
  };
}

export function getUserCommitedProjects(access_token) {
  return {
    type: GET_USER_COMMITED_PROJECTS_PENDING,
    payload: { access_token }
  };
}
