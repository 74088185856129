import React, { Component } from 'react';
import numeral from 'numeral';
import * as _ from 'lodash';

import FileZone from 'Components/ProjectDetail/Components/FileZone.component';
import TabDataColumn from 'Components/ProjectDetail/Components/TabDataColumn.component';
import MobileTabDataColumn from 'Components/ProjectDetail/Components/MobileTabDataColumn.component';

import { Text, Tab, Bar } from 'UILib';
import {
  scrollElementIntoView,
  isItActiveSection
} from 'Utils/Helpers/component.helpers';

import 'Components/ProjectDetail/projectDetail.style.scss';

class CostsProfitsGraphZone extends Component {
  generateGraphRevenue = (financial) => {
    const revenueText = numeral(
      financial.investment_performance.total_estimated_revenue
    ).format('0.0a');

    return [
      financial.investment_performance.total_estimated_revenue,
      revenueText
    ];
  };

  generateGraphCost = (financial) => {
    const { opex, capex } = financial.cost_details;

    let opexCosts = [];
    let capexCosts = [];

    _.forEach(opex, (value, key) => {
      if (key !== 'cost_items') {
        opexCosts.push(isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10));
      } else {
        value.forEach((item) => {
          opexCosts.push(
            isNaN(parseInt(item.value, 10)) ? 0 : parseInt(item.value, 10)
          );
        });
      }
    });

    _.forEach(capex, (value, key) => {
      if (key !== 'cost_items') {
        capexCosts.push(isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10));
      } else {
        value.forEach((item) => {
          capexCosts.push(
            isNaN(parseInt(item.value, 10)) ? 0 : parseInt(item.value, 10)
          );
        });
      }
    });

    const totalCapexCost = _.sum(capexCosts);
    const totalOpexCost =
      _.sum(opexCosts) *
      financial.investment_performance.project_estimated_lifetime;
    const totalCost = totalCapexCost + totalOpexCost;
    return [totalCost, numeral(totalCost).format('0.0a')];
  };

  render() {
    const [revenue, revenueText] = this.generateGraphRevenue(
      this.props.financial
    );
    const [cost, costText] = this.generateGraphCost(this.props.financial);

    return (
      <div className="chart-container">
        <div className="chart-wrapper">
          <div className="single-chart">
            <Text content={costText} className="header" color="blue-3" />
            <Text content="COSTS" className="subheader" color="blue-3" />
            <Bar.Vertical
              barLabel="COST"
              size={(cost / revenue) * 80} // must be in % unit
              base={60} // must be in px unit
              colors={['#0033ff', '#3366ff', '#6699ff']}
            />
          </div>
          <div className="single-chart">
            <Text
              content={revenueText}
              className="header"
              color="green-3"
              textAlign="center"
            />
            <Text content="REVENUE" className="subheader" color="green-3" />
            <Bar.Vertical
              barLabel="REVENUE"
              size={80} // must be in % unit
              base={60} // must be in px unit
              colors={['#33cb00', '#66ff33', '#99ff99']}
            />
          </div>
        </div>
        <div className="content-wrapper">
          <Text
            content={numeral(revenue - cost).format('0.000a')}
            className="content-difference"
            color="green-3"
            textAlign="left"
          />
          <Text
            content="Total Profit in USD"
            className="content-difference-header"
            color="green-3"
            textAlign="left"
          />
          <div className="separator" />
          <Text
            content="The costs and revenues are summed up for the first 10-year period. The power plant has 25 years lifetime with guaranteed production."
            className="content-description"
            color="grey-2"
            textAlign="left"
          />
        </div>
      </div>
    );
  }
}

class FinancialDetails extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (isItActiveSection(prevProps, this.props, 'finance')) {
      scrollElementIntoView(this.projectFinancialElement);
    }
  }

  handleCosts = (costs, type) => {
    const { cost_items, ...rest } = costs;

    const noTranslation = cost_items.map((item) => {
      rest[item.key] = `${item.value} ${
        type === 'capex' ? 'USD' : 'USD / year'
      }`;
      return item.key;
    });

    return { rest, noTranslation };
  };

  generateOpexTotal = (opex) => {
    let opexCosts = [];
    _.forEach(opex, (value, key) => {
      if (key !== 'cost_items') {
        opexCosts.push(isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10));
      } else {
        value.forEach((item) => {
          opexCosts.push(
            isNaN(parseInt(item.value, 10)) ? 0 : parseInt(item.value, 10)
          );
        });
      }
    });
    return { opexTotal: _.sum(opexCosts) };
  };

  generateEbitda = (revenue, opex) => {
    return {
      ebitda: parseInt(revenue, 10) - this.generateOpexTotal(opex).opexTotal
    };
  };

  render() {
    const {
      description,
      compensation_details,
      cost_details,
      investment_performance
    } = this.props.financial;
    const { fit_price } = this.props.market.details;

    return (
      <React.Fragment>
        <div
          className="financial-details-zone"
          ref={(el) => {
            this.projectFinancialElement = el;
          }}
        >
          <Text
            type="h1"
            color="black"
            content="projectDetail.financialDetails.header"
            translation
            transform="toUpper"
            className="header"
          />
          <Tab defaultIndex="1">
            <Tab.TabItem
              label="projectDetail.financialDetails.labels.financial_summary"
              translation
              index="1"
            >
              <div className="divided-tab-panel">
                <TabDataColumn
                  data={_.assign(
                    _.omit(compensation_details, ['annual_revenue']),
                    {
                      fit_price: fit_price
                    }
                  )}
                  title="Pricing Regime"
                  family="financialDetails"
                  from="revenues"
                />
                <TabDataColumn
                  data={_.assign(
                    {
                      annual_revenue: this.props.financial.compensation_details
                        .annual_revenue
                    },
                    _.pick(cost_details.capex, 'construction'),
                    this.generateOpexTotal(
                      _.omit(cost_details.opex, ['insurance_type'])
                    ),
                    this.generateEbitda(
                      this.props.financial.compensation_details.annual_revenue,
                      _.omit(cost_details.opex, ['insurance_type'])
                    )
                  )}
                  title="Profit & Loss"
                  family="financialDetails"
                  from="capex"
                />
                <TabDataColumn
                  data={
                    this.handleCosts(
                      _.omit(cost_details.opex, ['insurance_type']),
                      'opex'
                    ).rest
                  }
                  noTranslation={
                    this.handleCosts(cost_details.opex).noTranslation
                  }
                  title="OPEX"
                  family="financialDetails"
                  from="opex"
                />
              </div>
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.financialDetails.labels.investment_contribution"
              translation
              index="2"
            >
              <div className="divided-tab-panel">
                <TabDataColumn
                  data={investment_performance}
                  family="financialDetails"
                  from="investment_performance"
                />
                <TabDataColumn
                  data={this.props.contribution.compensation_details}
                  family="financialDetails"
                  from="contribution_details"
                />
                <TabDataColumn
                  data={_.assign(
                    {
                      sale_period_start: this.props.contribution.sale_period
                        .start_date
                    },
                    {
                      sale_period_end: this.props.contribution.sale_period
                        .end_date
                    },
                    {
                      dividend_period_start: this.props.contribution
                        .dividend_period.start_date
                    },
                    {
                      dividend_period_end: this.props.contribution
                        .dividend_period.end_date
                    }
                  )}
                  family="financialDetails"
                  from="dates"
                />
              </div>
            </Tab.TabItem>
          </Tab>
          <div className="costs-zone">
            <div className="graph-area">
              <CostsProfitsGraphZone financial={this.props.financial} />
            </div>
            <div className="you-should-know-area">
              <Text
                type="intro"
                content="projectDetail.financialDetails.youShouldKnow.header"
                translation
                className="header"
              />
              <Text
                type="intro"
                content={description.multiline}
                className="content"
              />
              <Text
                type="intro"
                content="projectDetail.financialDetails.youShouldKnow.description"
                translation
                className="description"
              />
            </div>
          </div>
          {this.props.financial.files &&
            this.props.financial.files.length > 0 && (
              <FileZone files={this.props.financial.files} />
            )}
        </div>

        <div className="mobile-financial-details-zone">
          <Text
            type="h1"
            color="black"
            content="projectDetail.financialDetails.header"
            translation
            transform="toUpper"
            className="header"
          />
          <Tab defaultIndex="1">
            <Tab.TabItem
              label="projectDetail.financialDetails.labels.financial_summary"
              translation
              index="1"
            >
              <MobileTabDataColumn
                data={_.assign(
                  _.omit(compensation_details, ['annual_revenue']),
                  {
                    fit_price: fit_price
                  }
                )}
                family="financialDetails"
                from="revenues"
              />
              <MobileTabDataColumn
                data={_.assign(
                  {
                    annual_revenue: this.props.financial.compensation_details
                      .annual_revenue
                  },
                  _.pick(cost_details.capex, 'construction'),
                  this.generateOpexTotal(
                    _.omit(cost_details.opex, ['insurance_type'])
                  ),
                  this.generateEbitda(
                    this.props.financial.compensation_details.annual_revenue,
                    _.omit(cost_details.opex, ['insurance_type'])
                  )
                )}
                family="financialDetails"
                from="capex"
              />
              <MobileTabDataColumn
                data={
                  this.handleCosts(
                    _.omit(cost_details.opex, ['insurance_type']),
                    'opex'
                  ).rest
                }
                noTranslation={
                  this.handleCosts(cost_details.opex).noTranslation
                }
                family="financialDetails"
                from="opex"
              />
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.financialDetails.labels.investment_contribution"
              translation
              index="2"
            >
              <MobileTabDataColumn
                data={investment_performance}
                family="financialDetails"
                from="investment_performance"
              />
              <MobileTabDataColumn
                data={this.props.contribution.compensation_details}
                family="financialDetails"
                from="contribution_details"
              />
              <MobileTabDataColumn
                data={_.assign(
                  {
                    sale_period_start: this.props.contribution.sale_period
                      .start_date
                  },
                  {
                    sale_period_end: this.props.contribution.sale_period
                      .end_date
                  },
                  {
                    dividend_period_start: this.props.contribution
                      .dividend_period.start_date
                  },
                  {
                    dividend_period_end: this.props.contribution.dividend_period
                      .end_date
                  }
                )}
                family="financialDetails"
                from="dates"
              />
            </Tab.TabItem>
          </Tab>
          <div className="costs-zone">
            <div className="graph-area">
              <CostsProfitsGraphZone financial={this.props.financial} />
            </div>
          </div>
          {this.props.financial.files &&
            this.props.financial.files.length > 0 && (
              <FileZone files={this.props.financial.files} />
            )}
        </div>
      </React.Fragment>
    );
  }
}

export default FinancialDetails;
