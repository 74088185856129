import { all, put, takeLatest } from 'redux-saga/effects';

import apiGenerator from 'Utils/Helpers/api.helpers';
import * as LoginConstants from 'Constants/Authentication/Login.constants';

import {
  getStatusCodeFamily,
  apiErrorHandler,
  sagaDelayer
} from 'Utils/Helpers/saga.helpers';
import {
  STATUS_TYPE,
  API_ENDPOINTS
} from 'Constants/GlobalConstants/Global.constants';

function* userLoginSaga(action) {
  const { formState } = action.payload;
  const api = apiGenerator('post')(API_ENDPOINTS.LOGIN.LOGIN, formState);
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: LoginConstants.USER_LOGIN_SUCCESS,
        payload: { ...response.data }
      });
    }
  } catch (err) {
    const errType =
      err.response && err.response.data.client_code === 460
        ? LoginConstants.USER_LOGIN_REQUIRE_RECAPTCHA
        : LoginConstants.USER_LOGIN_FAILURE;

    yield put({
      type: errType,
      payload: apiErrorHandler(err)
    });
  }
}

function* userLoginWithMFASaga(action) {
  const { access_token, otp_code } = action.payload;

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.LOGIN.LOGIN_WITH_MFA,
    { otp_code }
  );
  yield sagaDelayer();

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: LoginConstants.USER_LOGIN_WITH_MFA_SUCCESS,
        payload: { ...response.data }
      });
    }
  } catch (err) {
    yield put({
      type: LoginConstants.USER_LOGIN_WITH_MFA_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* userLoginGetUserInformationSaga(action) {
  const { access_token } = action.payload;

  const api = apiGenerator('get', access_token)(
    API_ENDPOINTS.LOGIN.GET_USER_INFORMATION
  );
  yield sagaDelayer();

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: LoginConstants.USER_LOGIN_GET_USER_INFORMATION_SUCCESS,
        payload: { ...response.data, access_token }
      });
    }
  } catch (err) {
    yield put({
      type: LoginConstants.USER_LOGIN_GET_USER_INFORMATION_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* userLoginResendVerificationEmailSaga(action) {
  const { access_token } = action.payload;

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.LOGIN.RESEND_VERIFICATION_EMAIL
  );
  yield api;
}

export default function* root() {
  yield all([
    takeLatest(LoginConstants.USER_LOGIN_PENDING, userLoginSaga),
    takeLatest(
      LoginConstants.USER_LOGIN_WITH_MFA_PENDING,
      userLoginWithMFASaga
    ),
    takeLatest(
      LoginConstants.USER_LOGIN_GET_USER_INFORMATION_PENDING,
      userLoginGetUserInformationSaga
    ),
    takeLatest(
      LoginConstants.USER_LOGIN_RESEND_VERIFICATION_EMAIL,
      userLoginResendVerificationEmailSaga
    )
  ]);
}
