import React from 'react';

import Input from './Input';
import Dropdown from './Dropdown';
import Digit from './Digit';
import Checkbox from './Checkbox';
import ValidationErrors from './ValidationErrors';

export default class FormElements extends React.Component {
  static Input = Input;
  static Dropdown = Dropdown;
  static Digit = Digit;
  static Checkbox = Checkbox;
  static ValidationErrors = ValidationErrors;
}
