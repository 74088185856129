/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';

import './Bar.style.scss';

export default class VerticalBar extends React.Component {
  render() {
    const { barLabel, base, size, colors } = this.props;

    return (
      <div className="vertical-bar">
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
          `}
        >
          <div
            css={css`
              position: relative;
              z-index: 100;
              height: ${`${size}%`};
              width: ${`${base}px`};
              left: ${`${-base / 4}px`};
              background-color: ${colors[0]};
              &:before {
                content: '';
                position: absolute;
                left: ${`${base}px`};
                bottom: ${`${base / 4}px`};
                height: 100%;
                width: ${`${base / 2}px`};
                background-color: ${colors[1]};
                transform: skewY(-45deg);
              }
              &:after {
                content: '';
                position: absolute;
                left: ${`${base / 4}px`};
                top: ${`${-base / 2}px`};
                height: ${`${base / 2}px`};
                width: ${`${base}px`};
                background-color: ${colors[2]};
                transform: skewX(-45deg);
              }
            `}
          />
        </div>
        <div
          css={css`
            width: ${`${base}px`};
            margin-left: ${`${(2 * base) / 5}px`};
          `}
          className="bar-label"
        >
          {barLabel}
        </div>
      </div>
    );
  }
}
