import Start from 'Components/Authentication/ResetPassword/Start.component';
import SendVerification from 'Components/Authentication/ResetPassword/SendVerification.component';
import VerifyCodeOTP from 'Components/Authentication/ResetPassword/VerifyCodeOTP.component';
import VerifyCodeEmail from 'Components/Authentication/ResetPassword/VerifyCodeEmail.component';
import VerifyUserInformation from 'Components/Authentication/ResetPassword/VerifyUserInformation.component';
import Execute from 'Components/Authentication/ResetPassword/Execute.component';
import Failure from 'Components/Authentication/ResetPassword/Failure.component';
import Success from 'Components/Authentication/ResetPassword/Success.component';

import { RESET_PASSWORD_STEP } from 'Constants/Authentication/ResetPassword.constants';

export const resetpasswordflow = {
  flow: [
    {
      step: RESET_PASSWORD_STEP.START,
      component: Start
    },
    {
      step: RESET_PASSWORD_STEP.SEND_VERIFICATION,
      component: SendVerification
    },
    {
      step: RESET_PASSWORD_STEP.VERIFY_CODE,
      options: ['verification_type'],
      component: { email: VerifyCodeEmail, sms: VerifyCodeOTP }
    },
    {
      step: RESET_PASSWORD_STEP.VERIFY_USER_INFORMATION,
      component: VerifyUserInformation
    },
    {
      step: RESET_PASSWORD_STEP.EXECUTE,
      component: Execute
    }
  ],
  success: {
    component: Success
  },
  failure: {
    component: Failure
  }
};
