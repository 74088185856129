import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Register from 'Components/Authentication/Register.component';
import RegisterValidationSchema from 'Containers/Authentication/Validations/Register.validation';

import { hasValidationError } from 'Utils/Helpers/component.helpers';
import {
  userRegisterStateReset,
  userRegister
} from 'Actions/Authentication/Register.actions';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

const initialValues = {
  email: '',
  password: '',
  password_confirmation: '',
  terms_of_service_accept: false
};

class RegisterContainer extends Component {
  constructor(props) {
    super(props);

    this.props.userRegisterStateReset();
  }

  handleSubmit = (values) => {
    this.props.userRegister(values);
  };

  render() {
    const { register, userRegisterStateReset } = this.props;
    switch (register.status) {
      case REQUEST_STATUS.SUCCESS:
        return <Register.Success />;
      case REQUEST_STATUS.FAILURE:
        return (
          <Register.Failure
            register={register}
            userRegisterStateReset={userRegisterStateReset}
          />
        );
      default:
        return (
          <Register.Form
            initialValues={initialValues}
            validationSchema={RegisterValidationSchema}
            handleSubmit={this.handleSubmit}
            hasValidationError={hasValidationError}
          />
        );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.register
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      userRegisterStateReset,
      userRegister
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterContainer);
