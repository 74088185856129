import React from 'react';
import moment from 'moment';
import * as _ from 'lodash';

import { Text } from 'UILib';
import { transformBalance } from 'Utils/Helpers/component.helpers';

const getUSDCWallet = (wallets) => {
  return _.filter(wallets, (wallet) => {
    return wallet.attributes.currency === 'USDC';
  })[0];
};

const ConfirmPane = (props) => {
  return (
    <div className="confirm-pane">
      <div className="confirm-information-zone">
        <div className="information-line">
          <div className="main-information">
            <Text
              content="investment.confirmInformation.amount"
              translation
              color="light-grey"
              className="subheader"
            />
            <Text
              content={`${transformBalance(props.investment.amount, 0)} USD`}
              color="light-grey"
              className="header"
            />
          </div>
          <div className="main-information">
            <Text
              content="investment.confirmInformation.shares"
              translation
              color="light-grey"
              className="subheader"
            />
            <Text
              content={`${transformBalance(
                props.customInvestmentAmount.sharesToBuy,
                0
              )} Shares`}
              color="light-grey"
              className="header"
            />
          </div>
        </div>
        <div className="information-line">
          <div className="supplementary-information">
            <Text
              content="investment.confirmInformation.date"
              translation
              color="grey-3"
              className="subheader"
            />
            <Text
              content={moment(props.investment.datetime).format(
                'DD.MM.YYYY HH:mm'
              )}
              color="light-grey"
              className="header"
            />
          </div>
        </div>
        <div className="information-line">
          <div className="supplementary-information">
            <Text
              content="investment.confirmInformation.currentBalance"
              translation
              color="grey-3"
              className="subheader"
            />
            <Text
              content={`${
                getUSDCWallet(props.account.user_wallets.wallets).attributes
                  .usd_balance
              } USD`}
              color="light-grey"
              className="header"
            />
          </div>
          <div className="supplementary-information">
            <Text
              content="investment.confirmInformation.afterBalance"
              translation
              color="grey-3"
              className="subheader"
            />
            <Text
              content={`${_.subtract(
                getUSDCWallet(props.account.user_wallets.wallets).attributes
                  .usd_balance,
                parseFloat(props.investment.amount)
              )} USD`}
              color="light-grey"
              className="header"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPane;
