export const commitment = {
  header: {
    viewer_count: 'Viewers',
    backer_count: 'Backers'
  },
  requireLogin: {
    header: 'You need to login to commit.',
    subheader:
      'If you have an existing voult account please log in, or create a new account and take your place in energy investments.',
    login: 'Login',
    signup: 'Sign Up'
  },
  commitmentPane: {
    confirmation: {
      header: 'Are you sure?',
      subheader:
        'You are making a commitment to this project, right now. Please be sure that this is what you are intending.',
      yes: 'YES',
      no: 'NO'
    },
    header: 'This project is not open for investments yet.',
    subheader:
      'You can express your interest in the project to get updates from the project and get notified when the sale  starts.',
    preferred: 'RECOMMENDED',
    decide: 'Decide',
    later: 'Later',
    usdc: 'USDC',
    investment_amount: '${amount} USD',
    decide_later: "I'll Decide Later.",
    effective_irr: '${irr} % ',
    finePrint:
      'This commitment neither changes your wallet balances nor will grant your investment for the project. Commitments only represent your interest in the project.',
    action: "I'm Interested"
  },
  failure: {
    header: 'Your commitment request has been failed.',
    errors: {
      409: 'It seems that you have already indicated your interest in this project.',
      403: 'It seems that currently this project do not accept any commitments. Thanks for your interest.'
    },
    action: 'Return to Project'
  },
  success: {
    header: 'Congrats! You completed your commitment.',
    description:
      'You declared your interest in ${name} to buy shares that equals to ${amount} USD. You can find the details of your commitment below, we’ll inform you when the project is ready for investment.',
    decideLater:
      'You declared your interest in ${name} to buy shares. You can find the details of your commitment below, we’ll inform you when the project is ready for investment.',
    action: 'Go to Dashboard'
  }
};
