import React from 'react';
import { Link } from 'react-router-dom';

import { Card, Text, Icon, Button } from 'UILib';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

const CommitmentFailure = (props) => {
  return (
    <Card type="auth" centered className="commitment-failure-wrapper">
      <Text type="h1" content="commitment.failure.header" translation />
      <Icon
        base="regular"
        className="icon"
        icon={{ name: 'times-circle', color: 'black', size: '10x' }}
      />
      <Text
        type="intro"
        content={`commitment.failure.errors.${props.commitment.error.client_code}`}
        translation
        className="description"
      />
      <Link
        to={APP_ROUTES.PROJECT_DETAIL_WITH_ID(props.projectDetail.projectId)}
      >
        <Button
          bgColor="orange-3"
          borderColor="orange-3"
          color="white"
          content="commitment.failure.action"
          translation
          className="action"
        />
      </Link>
    </Card>
  );
};

export default CommitmentFailure;
