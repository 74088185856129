export const KYC_STEP = {
  GET_STEP: 'get_step',
  CONTINUE: 'continue',
  START: 'start',
  PERSONAL: 'personal_information',
  DOCUMENTS: 'identification_documents',
  PROFILE: 'profile_picture',
  ADDRESS: 'address_information',
  SUBMIT: 'submit'
};

export const KYC_FLOW_RESET = 'KYC_FLOW_RESET';
export const KYC_MOVE_TO_GIVEN_STEP = 'KYC_MOVE_TO_GIVEN_STEP';
export const KYC_GET_CURRENT_STEP_PENDING = 'KYC_GET_CURRENT_STEP_PENDING';
export const KYC_GET_CURRENT_STEP_SUCCESS = 'KYC_GET_CURRENT_STEP_SUCCESS';
export const KYC_GET_CURRENT_STEP_FAILURE = 'KYC_GET_CURRENT_STEP_FAILURE';
export const KYC_FLOW_START_PENDING = 'KYC_FLOW_START_PENDING';
export const KYC_FLOW_START_SUCCESS = 'KYC_FLOW_START_SUCCESS';
export const KYC_FLOW_START_FAILURE = 'KYC_FLOW_START_FAILURE';
export const KYC_FLOW_PERSONAL_PENDING = 'KYC_FLOW_PERSONAL_PENDING';
export const KYC_FLOW_PERSONAL_PATCH_PENDING =
  'KYC_FLOW_PERSONAL_PATCH_PENDING';
export const KYC_FLOW_PERSONAL_SUCCESS = 'KYC_FLOW_PERSONAL_SUCCESS';
export const KYC_FLOW_PERSONAL_FAILURE = 'KYC_FLOW_PERSONAL_FAILURE';
export const KYC_FLOW_DOCUMENTS_PENDING = 'KYC_FLOW_DOCUMENTS_PENDING';
export const KYC_FLOW_DOCUMENTS_PATCH_PENDING =
  'KYC_FLOW_DOCUMENTS_PATCH_PENDING';
export const KYC_FLOW_DOCUMENTS_SUCCESS = 'KYC_FLOW_DOCUMENTS_SUCCESS';
export const KYC_FLOW_DOCUMENTS_FAILURE = 'KYC_FLOW_DOCUMENTS_FAILURE';
export const KYC_FLOW_PROFILE_PENDING = 'KYC_FLOW_PROFILE_PENDING';
export const KYC_FLOW_PROFILE_PATCH_PENDING = 'KYC_FLOW_PROFILE_PATCH_PENDING';
export const KYC_FLOW_PROFILE_SUCCESS = 'KYC_FLOW_PROFILE_SUCCESS';
export const KYC_FLOW_PROFILE_FAILURE = 'KYC_FLOW_PROFILE_FAILURE';
export const KYC_FLOW_ADDRESS_PENDING = 'KYC_FLOW_ADDRESS_PENDING';
export const KYC_FLOW_ADDRESS_PATCH_PENDING = 'KYC_FLOW_ADDRESS_PATCH_PENDING';
export const KYC_FLOW_ADDRESS_SUCCESS = 'KYC_FLOW_ADDRESS_SUCCESS';
export const KYC_FLOW_ADDRESS_FAILURE = 'KYC_FLOW_ADDRESS_FAILURE';
export const KYC_FLOW_SUBMIT_PENDING = 'KYC_FLOW_SUBMIT_PENDING';
export const KYC_FLOW_SUBMIT_SUCCESS = 'KYC_FLOW_SUBMIT_SUCCESS';
export const KYC_FLOW_SUBMIT_FAILURE = 'KYC_FLOW_SUBMIT_FAILURE';
