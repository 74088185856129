import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import VerifyResetPassword from 'Components/Authentication/VerifyResetPassword.component';

import { resetPasswordFlowVerifyCodeEmail } from 'Actions/Authentication/ResetPassword.actions';

class VerifyResetPasswordContainer extends Component {
  componentDidMount() {
    this.props.resetPasswordFlowVerifyCodeEmail(
      this.props.computedMatch.params.verification_code
    );
  }

  render() {
    return <VerifyResetPassword.Pending />;
  }
}

const mapStateToProps = (state) => {
  return {
    resetPassword: state.resetPassword
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetPasswordFlowVerifyCodeEmail
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyResetPasswordContainer);
