import * as CommitmentConstants from 'Constants/Commitment/Commitment.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

const initialState = {
  status: REQUEST_STATUS.NOT_DEFINED,
  error: null
};

export default function commitment(state = initialState, action) {
  switch (action.type) {
    case CommitmentConstants.RESET_COMMITMENT_TO_PROJECT:
      return initialState;
    case CommitmentConstants.COMMITMENT_TO_PROJECT_PENDING:
      return { ...state, status: REQUEST_STATUS.PENDING };
    case CommitmentConstants.COMMITMENT_TO_PROJECT_SUCCESS:
      return { ...state, status: REQUEST_STATUS.SUCCESS };
    case CommitmentConstants.COMMITMENT_TO_PROJECT_FAILURE:
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      };
    default:
      return state;
  }
}
