import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import init from 'Reducers/AppInit/init.reducer';
import register from 'Reducers/Authentication/Register.reducer';
import login from 'Reducers/Authentication/Login.reducer';
import resetPassword from 'Reducers/Authentication/ResetPassword.reducer';
import account from 'Reducers/Account/Account.reducer';
import phoneActivation from 'Reducers/Account/PhoneActivation.reducer';
import kyc from 'Reducers/KYC/KYC.reducer';
import projectDetail from 'Reducers/ProjectDetail/ProjectDetail.reducer';
import projectList from 'Reducers/ProjectList/ProjectList.reducer';
import commitment from 'Reducers/Commitment/Commitment.reducer';
import investment from 'Reducers/Investment/Investment.reducer';
import withdraw from 'Reducers/Withdraw/Withdraw.reducer';
import exchange from 'Reducers/Exchange/Exchange.reducer';
import routeExtender from 'Reducers/RouteExtender/RouteExtender.reducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    init: init,
    register: register,
    login: login,
    resetPassword: resetPassword,
    account: account,
    phoneActivation: phoneActivation,
    kyc: kyc,
    projectDetail: projectDetail,
    projectList: projectList,
    commitment: commitment,
    investment: investment,
    withdraw: withdraw,
    exchange: exchange,
    routeExtender: routeExtender
  });
