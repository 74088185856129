import React from 'react';

import AuthenticationResult from 'Components/Authentication/Reusable/AuthenticationResult.component';
import { determineIcon } from 'Utils/Helpers/icon.helpers';

const Success = (props) => {
  return (
    <AuthenticationResult
      header={{
        type: 'h1',
        content: `resetPassword.success.${props.resetPassword.step}.header`,
        translation: true
      }}
      icon={{
        base: 'regular',
        name: determineIcon('authentication.resetPassword.success'),
        color: 'green-3',
        size: '10x'
      }}
      description={{
        type: 'intro',
        color: 'grey-5',
        content: `resetPassword.success.${
          props.resetPassword.step
        }.description`,
        translation: true
      }}
      link={{
        to: '/',
        bgColor: 'orange-3',
        borderColor: 'orange-3',
        color: 'white',
        content: `resetPassword.success.${props.resetPassword.step}.cta`,
        translation: true
      }}
    />
  );
};

export default Success;
