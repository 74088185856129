import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { determineFlowComponent } from 'Utils/Helpers/flow.helpers';
import {
  withdrawFlowReset,
  changeActiveCurrencyToWithdraw,
  createWithdraw,
  confirmWithdraw,
  withdrawConfirmNoTime
} from 'Actions/Withdraw/Withdraw.actions';
import { getUserWallets } from 'Actions/Account/UserDashboard.actions';

import { withdrawFlow } from 'Containers/Withdraw/Flows/Withdraw.flow';
import * as validations from 'Containers/Withdraw/Validations/Withdraw.validation';

class WithdrawContainer extends Component {
  componentWillUnmount() {
    this.props.withdrawFlowReset();
  }

  render() {
    const ElementTag = determineFlowComponent(
      withdrawFlow,
      this.props.withdraw
    );
    return <ElementTag {...this.props} {...validations} />;
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    withdraw: state.withdraw
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      withdrawFlowReset,
      changeActiveCurrencyToWithdraw,
      createWithdraw,
      confirmWithdraw,
      withdrawConfirmNoTime,
      getUserWallets
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawContainer);
