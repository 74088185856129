import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import InvestmentHeader from 'Components/Investment/Components/InvestmentHeader.component';
import InsufficientFundsPane from 'Components/Investment/Components/InsufficientFundsPane.component';
import WalletPane from 'Components/Account/UserDashboard/Components/WalletPane.component';

import { transformBalance } from 'Utils/Helpers/component.helpers';
import { Container, Text, Button } from 'UILib';
import 'Components/Investment/investment.style.scss';

const calculation = (value, properties) => {
  const annual_revenue = parseInt(
    properties.financial.compensation_details.annual_revenue,
    10
  );
  const offer_percentage =
    parseInt(
      properties.contribution.compensation_details.offer_percentage,
      10
    ) / 100;

  const amount_to_be_raised = parseInt(
    properties.contribution.compensation_details.amount_to_be_raised,
    10
  );

  return (
    ((annual_revenue * offer_percentage * value) / amount_to_be_raised) * 10
  );
};

const InsufficientFunds = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div className="investment-page-wrapper">
          <InvestmentHeader
            information={props.projectDetail.attributes.properties.information}
            statistics={props.projectDetail.attributes.statistics}
          />
          <div className="investment-insufficient-funds-wrapper">
            <div className="investment-insufficient-funds-left-pane-wrapper">
              <div className="investment-general-information-zone">
                <div className="information">
                  <Text
                    content="investment.investmentHeader.amountToBeRaised"
                    translation
                    color="grey-3"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.projectDetail.attributes.properties.contribution
                        .compensation_details.amount_to_be_raised,
                      0
                    )} USD`}
                    color="black"
                    className="header"
                  />
                </div>
                <div className="information">
                  <Text
                    content="investment.investmentHeader.raisedCapital"
                    translation
                    color="grey-3"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.projectDetail.attributes.statistics.raised_capital,
                      0
                    )} USD`}
                    color="black"
                    className="header"
                  />
                </div>
                <div className="information">
                  <Text
                    content="investment.investmentHeader.totalShares"
                    translation
                    color="grey-3"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.projectDetail.attributes.properties.contribution
                        .compensation_details.share_count,
                      0
                    )}`}
                    color="black"
                    className="header"
                  />
                </div>
              </div>
              <div className="investment-deposit-selection-panel">
                <InsufficientFundsPane {...props} />
              </div>

              <div className="investment-total-calculation-panel">
                <div className="information">
                  <Text
                    content="investment.calculation.investment"
                    translation
                    color="light-grey"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.customInvestmentAmount.toBeRaised,
                      0
                    )} USD`}
                    color="black"
                    className="header"
                  />
                </div>
                <div className="information">
                  <Text
                    content="investment.calculation.shares"
                    translation
                    color="light-grey"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.customInvestmentAmount.sharesToBuy,
                      0
                    )} Shares`}
                    color="black"
                    className="header"
                  />
                </div>
                <div className="information">
                  <Text
                    content="investment.calculation.estimated"
                    translation
                    color="light-grey"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      calculation(
                        props.selectedInvestmentAmount,
                        props.projectDetail.attributes.properties
                      ),
                      0
                    )} USD`}
                    color="orange-3"
                    className="header"
                  />
                </div>
              </div>
              <div className="investment-action-zone">
                <Button
                  content="investment.actionTryAgain"
                  translation
                  className="action"
                  color="white"
                  bgColor="orange-3"
                  borderColor="orange-3"
                  onClick={() => {
                    props.investmentFlowReset();
                  }}
                />
              </div>
            </div>
            <WalletPane {...props} />
          </div>
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default InsufficientFunds;
