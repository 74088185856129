import * as Yup from 'yup';

const ConfirmValidationSchema = Yup.object().shape({
  investment_aggrement: Yup.bool()
    .test(
      'investment_aggrement',
      'Must agree to Investment Conditions',
      (value) => value === true
    )
    .required('Required'),
  validate: Yup.bool()
    .test(
      'validate',
      'Must agree to Validate Source of Wealth',
      (value) => value === true
    )
    .required('Required')
});

export default ConfirmValidationSchema;
