import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import MoonLoader from 'Components/Reusable/Loading/MoonLoader.component';
import InvestmentHeader from './Components/InvestmentHeader.component';

import { Container } from 'UILib';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

import 'Components/Investment/investment.style.scss';
import UserNotLoggedIn from 'Components/Investment/Components/UserNotLoggedIn.component';

const Start = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div
          className={`investment-page-wrapper ${props.investment.status ===
            REQUEST_STATUS.FAILURE && 'investment-page-padding'}`}
        >
          {props.projectDetail.status === REQUEST_STATUS.SUCCESS ? (
            <React.Fragment>
              <InvestmentHeader
                information={
                  props.projectDetail.attributes.properties.information
                }
                statistics={props.projectDetail.attributes.statistics}
              />
              {props.requireLogin ? (
                <UserNotLoggedIn
                  saveRedirectRoute={props.saveRedirectRoute}
                  projectDetail={props.projectDetail}
                />
              ) : (
                <div className="start-step-loading-wrapper">
                  <MoonLoader />
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="loading-wrapper">
              <MoonLoader />
            </div>
          )}
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default Start;
