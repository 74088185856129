import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';

import LoadingContainer from 'Containers/Loading/Loading.container';
import ProjectDetailComponent from 'Components/ProjectDetail/ProjectDetail.component';

import { retrieveProjectDetailWithProjectId } from 'Actions/ProjectDetail/ProjectDetail.actions';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

class ProjectDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      heroZone: {
        visuals: []
      },
      hangingNavbarVisible: false,
      activeSection: null
    };
  }

  componentDidMount() {
    const { projectId } = this.props.match.params;
    this.props.retrieveProjectDetailWithProjectId(projectId);
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * Retrieving hero zone images from projectDetail reducer
     */
    if (
      prevProps.projectDetail.status === REQUEST_STATUS.PENDING &&
      this.props.projectDetail.status === REQUEST_STATUS.SUCCESS
    ) {
      const { information } = this.props.projectDetail.attributes.properties;

      const files = information.files || [];
      const heroZoneVisuals = files
        .filter((file) => {
          return file.attributes.kind === 'project_image';
        })
        .map((file) => {
          return file.attributes.url;
        });
      this.setState({
        heroZone: { ...this.state.heroZone, visuals: heroZoneVisuals }
      });
    }
  }

  toggleHangingNavbarVisible = (boolean) => {
    this.setState({ hangingNavbarVisible: boolean });
  };

  handleActiveSection = (section) => {
    if (section !== this.state.activeSection) {
      this.setState({ activeSection: section });
    }
  };

  normalizeRoadmapData = (projectDetail) => {
    let normalizedDates = {};
    let orderedEvents = [];

    if (projectDetail.status === REQUEST_STATUS.SUCCESS) {
      const { roadmap, contribution } = projectDetail.attributes.properties;

      normalizedDates = roadmap;
      normalizedDates['sale_period'] = contribution.sale_period;
      normalizedDates['dividend_period'] = contribution.dividend_period;

      orderedEvents = _.orderBy(
        _.keys(normalizedDates).map((key) => {
          return { key: key, start_date: normalizedDates[key]['start_date'] };
        }),
        ['start_date'],
        ['asc']
      ).map((item) => item.key);
    }

    return { normalizedDates, orderedEvents };
  };

  render() {
    const { projectDetail } = this.props;

    const { normalizedDates, orderedEvents } = this.normalizeRoadmapData(
      projectDetail
    );

    switch (projectDetail.status) {
      case REQUEST_STATUS.PENDING:
        return <LoadingContainer />;
      case REQUEST_STATUS.SUCCESS:
        return (
          <ProjectDetailComponent
            {...this.props}
            {...this.state}
            roadmapDates={normalizedDates}
            roadmapOrder={orderedEvents}
            toggleHangingNavbarVisible={this.toggleHangingNavbarVisible}
            hangingNavbarVisible={this.state.hangingNavbarVisible}
            activeSection={this.state.activeSection}
            handleActiveSection={this.handleActiveSection}
          />
        );
      case REQUEST_STATUS.FAILURE:
        return (
          <div>
            {/* TODO: Discuss with serdar for a component architecture on this case */}
            This is a custom failure page should retrieve client code based
            errors
          </div>
        );
      default:
        return <LoadingContainer />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    projectDetail: state.projectDetail
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveProjectDetailWithProjectId
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailContainer);
