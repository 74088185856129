import React from 'react';
import { Link } from 'react-router-dom';

import { Text } from 'UILib';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';
import { textTransform } from 'UILib/Utils/textTransform';

const InvestmentHeader = (props) => {
  return (
    <div>
      <div className="tags-wrapper">
        {props.information.tags &&
          props.information.tags.map((tag, idx) => (
            <div className="tag" key={idx}>
              {textTransform(tag, 'toUpper')}
            </div>
          ))}
      </div>
      <div className="header-zone-wrapper">
        <div className="header-zone">
          <Link to={APP_ROUTES.PROJECT_DETAIL_WITH_ID(props.statistics.id)}>
            <Text content={props.information.name} className="header" />
          </Link>
          <Text
            content={props.information.owner_company}
            className="subheader"
          />
        </div>
        <div className="statistics-zone">
          <div className="project-statistics">
            <div className="viewer-zone">
              <Text
                type="intro"
                content={props.statistics.total_view}
                color="black"
                className="header"
              />
              <Text
                type="intro"
                content="investment.header.viewer_count"
                transform="title"
                translation
                color="grey-3"
                className="subheader"
              />
            </div>
            <div className="backer-zone">
              <Text
                type="intro"
                content={props.statistics.backer_count.toString()}
                color="black"
                className="header"
              />
              <Text
                type="intro"
                content="investment.header.backer_count"
                transform="title"
                translation
                color="grey-3"
                className="subheader"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentHeader;
