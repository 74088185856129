import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'Utils/Workers/serviceWorker';

import AppProvider from './AppProvider';

import 'normalize.css';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import 'Assets/Styles/global.scss';

const voultRoot = document.getElementById('voult-root');
ReactDOM.render(<AppProvider />, voultRoot);

serviceWorker.register();
