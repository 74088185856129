import React, { Component } from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import { Container, Text, Button } from 'UILib';

import 'Containers/Error/NotFound.style.scss';

class NotFoundContainer extends Component {
  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <div className="not-found-zone">
            <Text content="notFound.text" translation className="text" />
            <div className="action-zone">
              <Button
                content="notFound.action"
                translation
                bgColor="orange-3"
                borderColor="orange-3"
                color="white"
                className="action"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_LANDING_PAGE;
                }}
              />
              <Button
                content="notFound.goBack"
                translation
                bgColor="white"
                borderColor="orange-3"
                color="orange-3"
                className="action"
                onClick={() => {
                  this.props.history.goBack();
                }}
              />
            </div>
          </div>
        </GlobalLayout>
      </Container>
    );
  }
}

export default NotFoundContainer;
