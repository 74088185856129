import React from 'react';

import { Text, Icon } from 'UILib';

const InsufficientFundsPane = (props) => {
  return (
    <div className="insufficient-funds-pane">
      <div className="investment-selection-zone">
        <Text
          content="investment.insufficient.header"
          translation
          color="light-grey"
          className="header"
        />
        <Text
          content="investment.insufficient.subheader"
          translation
          color="light-grey"
          className="subheader"
        />
        <div className="deposit-area">
          <div className="button-zone">
            <Icon
              base="solid"
              icon={{ name: 'exchange-alt', color: 'orange-3' }}
              className="button-icon"
            />
            <Text
              content="investment.insufficient.exchange"
              translation
              color="light-grey"
              className="button-text"
            />
          </div>
          <div
            className="button-zone"
            onClick={() => {
              props.showWalletModal('USDC');
            }}
          >
            <Icon
              base="solid"
              icon={{ name: 'arrow-down', color: 'orange-3' }}
              className="button-icon"
            />
            <Text
              content="investment.insufficient.deposit"
              translation
              color="light-grey"
              className="button-text"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsufficientFundsPane;
