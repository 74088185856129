import React from 'react';
import cx from 'classnames';

import './InvestmentTable.style.scss';

export default class Header extends React.Component {
  render() {
    const { content, style, className } = this.props;

    const baseCN = 'investment-table-header';

    const HeaderCX = cx(baseCN, className);
    return (
      <div className={HeaderCX} style={style}>
        {content}
      </div>
    );
  }
}
