import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import GlobalLayout from 'Layouts/Global.layout';
import { Container, Card, Text, FormElements, Button } from 'UILib';
import { hasValidationError } from 'Utils/Helpers/component.helpers';
import {
  REQUEST_STATUS,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';

import 'Components/Authentication/authentication.style.scss';

const initialValues = {
  password: '',
  password_confirmation: ''
};

class Execute extends Component {
  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <Card type="auth" centered className="custom-form-zone">
            <Text
              type="h1"
              content="resetPassword.execute.header"
              className="execute-header"
              translation
            />
            <Text
              type="intro"
              content="resetPassword.execute.description"
              className="execute-description"
              translation
            />
            <Container className="custom-form-container">
              <Card.Content>
                <Formik
                  initialValues={initialValues}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={
                    this.props.ResetPasswordExecuteValidationSchema
                  }
                  onSubmit={(values, { submitForm, setSubmitting }) => {
                    this.props.resetPasswordFlowExecute({
                      ...values,
                      email: this.props.resetPassword.email,
                      action_token: this.props.resetPassword.action_token
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Container className="form-container">
                        <FormElements.Input
                          type="password"
                          name="password"
                          error={errors.password && touched.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="resetPassword.execute.password_placeholder"
                          translation
                        />
                        <FormElements.Input
                          type="password"
                          name="password_confirmation"
                          error={errors.password && touched.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="resetPassword.execute.password_confirmation_placeholder"
                          translation
                        />
                        {hasValidationError(errors) && (
                          <Container className="execute-error-container">
                            <FormElements.ValidationErrors
                              content="resetPassword.execute.validation_errors"
                              translation
                            />
                          </Container>
                        )}
                        <Container
                          className={
                            hasValidationError(errors)
                              ? 'execute-submit-container-with-error'
                              : 'execute-submit-container'
                          }
                        >
                          <Button
                            bgColor="orange-3"
                            borderColor="orange-3"
                            color="white"
                            type="submit"
                            content={
                              this.props.resetPassword.status ===
                              REQUEST_STATUS.PENDING
                                ? 'resetPassword.execute.loading'
                                : 'resetPassword.execute.submit'
                            }
                            translation
                          />
                        </Container>
                      </Container>
                    </form>
                  )}
                </Formik>
              </Card.Content>
            </Container>
            <Text type="intro" className="bottom-section">
              <Translate id="resetPassword.execute.footer.content" />{' '}
              <Link to={APP_ROUTES.LOGIN}>
                <Translate id="resetPassword.execute.footer.cta" />
              </Link>
            </Text>
          </Card>
        </GlobalLayout>
      </Container>
    );
  }
}
export default Execute;
