import React, { Component } from 'react';
import * as _ from 'lodash';

import FileZone from 'Components/ProjectDetail/Components/FileZone.component';
import TabDataColumn from 'Components/ProjectDetail/Components/TabDataColumn.component';
import MobileTabDataColumn from 'Components/ProjectDetail/Components/MobileTabDataColumn.component';

import { Text, Tab, Button } from 'UILib';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import {
  scrollElementIntoView,
  isItActiveSection
} from 'Utils/Helpers/component.helpers';

import 'Components/ProjectDetail/projectDetail.style.scss';

const TechnologyDescription = (props) => {
  return (
    <div className="description-column">
      <Text
        type="intro"
        content="projectDetail.financialDetails.youShouldKnow.header"
        translation
        className="header"
      />
      <Text type="intro" content={props.description} className="content" />
    </div>
  );
};

class TechnologyDetails extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (isItActiveSection(prevProps, this.props, 'technology')) {
      scrollElementIntoView(this.projectTechnologyElement);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="technology-details-zone"
          ref={(el) => {
            this.projectTechnologyElement = el;
          }}
        >
          <Text
            type="h1"
            color="black"
            content="projectDetail.technologyDetails.header"
            translation
            transform="toUpper"
            className="header"
          />
          <Tab defaultIndex="1" className="technology-tabs">
            <Tab.TabItem
              label="projectDetail.technologyDetails.labels.production"
              translation
              index="1"
            >
              <div className="technology-tab-content-wrapper">
                <TabDataColumn
                  data={this.props.technology.production_details}
                  family="technologyDetails"
                  from="production"
                />
                <TechnologyDescription
                  description={this.props.technology.description.multiline}
                />
              </div>
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.technologyDetails.labels.modules"
              translation
              index="2"
            >
              <div className="technology-tab-content-wrapper-with-3">
                <TabDataColumn
                  data={_.assign(
                    {
                      pv_area:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details.pv_area) ||
                        ''
                    },
                    {
                      array_type:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .array_type) ||
                        ''
                    },
                    {
                      module_power:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .module_power) ||
                        ''
                    },
                    {
                      dc_to_ac_ratio:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .dc_to_ac_ratio) ||
                        ''
                    },
                    {
                      number_of_arrays:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .number_of_arrays) ||
                        ''
                    },
                    {
                      module_dimensions:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .module_dimensions) ||
                        ''
                    }
                  )}
                  family="technologyDetails"
                  from="construction_details"
                />
                <TabDataColumn
                  data={_.assign(
                    {
                      number_of_pv_modules:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .number_of_pv_modules) ||
                        ''
                    },
                    {
                      power_to_array_ratio:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .power_to_array_ratio) ||
                        ''
                    },
                    {
                      row_to_row_clearance:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .row_to_row_clearance) ||
                        ''
                    },
                    {
                      total_number_of_scbs:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .total_number_of_scbs) ||
                        ''
                    },
                    {
                      mechanical_construction:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .mechanical_construction) ||
                        ''
                    },
                    {
                      transformer_power_level:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .transformer_power_level) ||
                        ''
                    }
                  )}
                  family="technologyDetails"
                  from="construction_details"
                />
                <TabDataColumn
                  data={_.assign(
                    {
                      mounting_system_and_method:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .mounting_system_and_method) ||
                        ''
                    },
                    {
                      inverter_type:
                        (this.props.technology
                          .inventer_and_monitoring_details &&
                          this.props.technology.inventer_and_monitoring_details
                            .inverter_type) ||
                        ''
                    },
                    {
                      inverter_quantity:
                        (this.props.technology
                          .inventer_and_monitoring_details &&
                          this.props.technology.inventer_and_monitoring_details
                            .inverter_quantity) ||
                        ''
                    },
                    {
                      substation:
                        (this.props.technology
                          .inventer_and_monitoring_details &&
                          this.props.technology.inventer_and_monitoring_details
                            .substation) ||
                        ''
                    },
                    {
                      distribution_region:
                        (this.props.technology
                          .inventer_and_monitoring_details &&
                          this.props.technology.inventer_and_monitoring_details
                            .distribution_region) ||
                        ''
                    },
                    {
                      connection_to_distribution_system:
                        (this.props.technology
                          .inventer_and_monitoring_details &&
                          this.props.technology.inventer_and_monitoring_details
                            .connection_to_distribution_system) ||
                        ''
                    }
                  )}
                  family="technologyDetails"
                  from="construction_details"
                />
              </div>
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.technologyDetails.labels.contractors_suppliers"
              translation
              index="3"
            >
              <div className="technology-tab-content-wrapper">
                <TabDataColumn
                  data={_.assign(
                    {
                      epc_contractor:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .epc_contractor) ||
                        ''
                    },
                    {
                      module_manufacturer:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .module_manufacturer) ||
                        ''
                    },
                    {
                      module_distribution_company:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .module_distribution_company) ||
                        ''
                    },
                    {
                      module_supplier:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .module_supplier) ||
                        ''
                    },
                    {
                      inverter_manufacturer:
                        (this.props.technology
                          .inventer_and_monitoring_details &&
                          this.props.technology.inventer_and_monitoring_details
                            .inverter_manufacturer) ||
                        ''
                    },
                    {
                      inverter_brand:
                        (this.props.technology.inverter_details &&
                          this.props.technology.inverter_details.brand) ||
                        ''
                    },
                    {
                      mv_transformer:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .mv_transformer) ||
                        ''
                    },
                    {
                      om_service_provider:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details
                            .om_service_provider) ||
                        ''
                    },
                    {
                      cables:
                        (this.props.technology.construction_details &&
                          this.props.technology.construction_details.cables) ||
                        ''
                    },
                    {
                      monitoring_system:
                        (this.props.technology
                          .inventer_and_monitoring_details &&
                          this.props.technology.inventer_and_monitoring_details
                            .monitoring_system) ||
                        ''
                    }
                  )}
                  family="technologyDetails"
                  from="contractors_suppliers"
                />
                <TechnologyDescription
                  description={this.props.technology.description.multiline}
                />
              </div>
            </Tab.TabItem>
          </Tab>
          {this.props.technology.files &&
            this.props.technology.files.length > 0 && (
              <FileZone files={this.props.technology.files} />
            )}
        </div>
        <div className="mobile-technology-details-zone">
          <Text
            type="h1"
            color="black"
            content="projectDetail.technologyDetails.header"
            translation
            transform="toUpper"
            className="header"
          />
          <Tab defaultIndex="1">
            <Tab.TabItem
              label="projectDetail.technologyDetails.labels.production"
              translation
              index="1"
            >
              <MobileTabDataColumn
                data={this.props.technology.production_details}
                family="technologyDetails"
                from="production"
              />
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.technologyDetails.labels.modules"
              translation
              index="2"
            >
              <MobileTabDataColumn
                data={_.assign(
                  {
                    pv_area:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details.pv_area) ||
                      ''
                  },
                  {
                    array_type:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .array_type) ||
                      ''
                  },
                  {
                    module_power:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .module_power) ||
                      ''
                  },
                  {
                    dc_to_ac_ratio:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .dc_to_ac_ratio) ||
                      ''
                  },
                  {
                    number_of_arrays:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .number_of_arrays) ||
                      ''
                  },
                  {
                    module_dimensions:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .module_dimensions) ||
                      ''
                  }
                )}
                family="technologyDetails"
                from="construction_details"
              />
              <MobileTabDataColumn
                data={_.assign(
                  {
                    number_of_pv_modules:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .number_of_pv_modules) ||
                      ''
                  },
                  {
                    power_to_array_ratio:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .power_to_array_ratio) ||
                      ''
                  },
                  {
                    row_to_row_clearance:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .row_to_row_clearance) ||
                      ''
                  },
                  {
                    total_number_of_scbs:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .total_number_of_scbs) ||
                      ''
                  },
                  {
                    mechanical_construction:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .mechanical_construction) ||
                      ''
                  },
                  {
                    transformer_power_level:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .transformer_power_level) ||
                      ''
                  }
                )}
                family="technologyDetails"
                from="construction_details"
              />
              <MobileTabDataColumn
                data={_.assign(
                  {
                    mounting_system_and_method:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .mounting_system_and_method) ||
                      ''
                  },
                  {
                    inverter_type:
                      (this.props.technology.inventer_and_monitoring_details &&
                        this.props.technology.inventer_and_monitoring_details
                          .inverter_type) ||
                      ''
                  },
                  {
                    inverter_quantity:
                      (this.props.technology.inventer_and_monitoring_details &&
                        this.props.technology.inventer_and_monitoring_details
                          .inverter_quantity) ||
                      ''
                  },
                  {
                    substation:
                      (this.props.technology.inventer_and_monitoring_details &&
                        this.props.technology.inventer_and_monitoring_details
                          .substation) ||
                      ''
                  },
                  {
                    distribution_region:
                      (this.props.technology.inventer_and_monitoring_details &&
                        this.props.technology.inventer_and_monitoring_details
                          .distribution_region) ||
                      ''
                  },
                  {
                    connection_to_distribution_system:
                      (this.props.technology.inventer_and_monitoring_details &&
                        this.props.technology.inventer_and_monitoring_details
                          .connection_to_distribution_system) ||
                      ''
                  }
                )}
                family="technologyDetails"
                from="construction_details"
              />
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.technologyDetails.labels.contractors_suppliers"
              translation
              index="3"
            >
              <MobileTabDataColumn
                data={_.assign(
                  {
                    epc_contractor:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .epc_contractor) ||
                      ''
                  },
                  {
                    module_manufacturer:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .module_manufacturer) ||
                      ''
                  },
                  {
                    module_distribution_company:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .module_distribution_company) ||
                      ''
                  },
                  {
                    module_supplier:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .module_supplier) ||
                      ''
                  },
                  {
                    inverter_manufacturer:
                      (this.props.technology.inventer_and_monitoring_details &&
                        this.props.technology.inventer_and_monitoring_details
                          .inverter_manufacturer) ||
                      ''
                  },
                  {
                    inverter_brand:
                      (this.props.technology.inverter_details &&
                        this.props.technology.inverter_details.brand) ||
                      ''
                  },
                  {
                    mv_transformer:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .mv_transformer) ||
                      ''
                  },
                  {
                    om_service_provider:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details
                          .om_service_provider) ||
                      ''
                  },
                  {
                    cables:
                      (this.props.technology.construction_details &&
                        this.props.technology.construction_details.cables) ||
                      ''
                  },
                  {
                    monitoring_system:
                      (this.props.technology.inventer_and_monitoring_details &&
                        this.props.technology.inventer_and_monitoring_details
                          .monitoring_system) ||
                      ''
                  }
                )}
                family="technologyDetails"
                from="contractors_suppliers"
              />
            </Tab.TabItem>
          </Tab>
          {this.props.technology.files &&
            this.props.technology.files.length > 0 && (
              <FileZone files={this.props.technology.files} />
            )}
          <Button
            content={
              this.props.investment_status
                ? 'projectDetail.heroZone.cta.button'
                : 'projectDetail.heroZone.cta.commitment'
            }
            translation
            bgColor="green-4"
            borderColor="green-4"
            color="white"
            className="button"
            onClick={() => {
              this.props.history.push(
                this.props.investment_status
                  ? APP_ROUTES.INVESTMENT_WITH_ID(this.props.statistics.id)
                  : APP_ROUTES.COMMITMENT_WITH_ID(this.props.statistics.id)
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default TechnologyDetails;
