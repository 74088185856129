import React from 'react';
import cx from 'classnames';

import { Translate } from 'react-localize-redux';

import { getTextElement } from 'UILib/Utils/getTextElement';
import { textTransform } from 'UILib/Utils/textTransform';

import './Text.style.scss';

export default class Text extends React.Component {
  render() {
    const {
      children,
      content,
      data,
      transform,
      type,
      textAlign,
      color,
      translation,
      style,
      className
    } = this.props;

    const { ElementTag, ElementCN } = getTextElement(type);

    const baseCN = 'text';
    const textAligCN = textAlign
      ? `${baseCN}-align-${textAlign}`
      : `${baseCN}-align-center`;
    const colorCN = color
      ? `${baseCN}-color-${color}`
      : `${baseCN}-color-grey-6`;

    const TextCX = cx(baseCN, ElementCN, textAligCN, colorCN, className);

    return (
      <Translate>
        {({ translate }) => (
          <ElementTag className={TextCX} style={style}>
            {content
              ? translation
                ? textTransform(translate(content, data), transform)
                : textTransform(content, transform)
              : children}
          </ElementTag>
        )}
      </Translate>
    );
  }
}
