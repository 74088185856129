import React from 'react';

import AuthenticationResult from 'Components/Authentication/Reusable/AuthenticationResult.component';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

const Success = (props) => {
  return (
    <AuthenticationResult
      header={{
        type: 'h1',
        content: 'phoneActivation.success.header',
        translation: true
      }}
      icon={{
        base: 'regular',
        name: 'check-circle',
        color: 'green-3',
        size: '10x'
      }}
      description={{
        type: 'intro',
        color: 'grey-5',
        content: 'phoneActivation.success.description',
        translation: true
      }}
      link={{
        to: APP_ROUTES.DASHBOARD,
        bgColor: 'orange-3',
        borderColor: 'orange-3',
        color: 'white',
        content: 'phoneActivation.success.footer.cta',
        translation: true
      }}
    />
  );
};

export default Success;
