import React from 'react';
import moment from 'moment';

import GlobalLayout from 'Layouts/Global.layout';
import KYCStepHeader from 'Components/KYC/components/KYCStepHeader.component';

import { Container, Text, Button } from 'UILib';
import 'Components/KYC/kyc.style.scss';
import { KYC_STEP } from 'Constants/KYC/KYC.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

const Submit = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div className="kyc-page-wrapper">
          <div className="kyc-submit-wrapper">
            <KYCStepHeader currentStep={5} />
            <div className="section-wrapper">
              <Text
                content="kyc.submit.header"
                translation
                className="header"
              />
              <Text
                content="kyc.submit.content"
                translation
                className="content"
              />
              <div className="submit-section-wrapper">
                <div className="personal-row">
                  <div className="item">
                    <Text
                      content="kyc.submit.personal.name"
                      textAlign="left"
                      translation
                      className="item-header"
                    />
                    <Text
                      content={
                        props.kyc.persistent_data.personal_information
                          .first_name
                      }
                      textAlign="left"
                      className="item-text"
                    />
                  </div>
                  <div className="item">
                    <Text
                      content="kyc.submit.personal.surname"
                      textAlign="left"
                      translation
                      className="item-header"
                    />
                    <Text
                      content={
                        props.kyc.persistent_data.personal_information.last_name
                      }
                      textAlign="left"
                      className="item-text"
                    />
                  </div>
                  <div className="item">
                    <Text
                      content="kyc.submit.personal.date_of_birth"
                      textAlign="left"
                      translation
                      className="item-header"
                    />
                    <Text
                      content={moment(
                        props.kyc.persistent_data.personal_information
                          .date_of_birth
                      ).format('DD.MM.YYYY')}
                      textAlign="left"
                      className="item-text"
                    />
                  </div>
                  <div className="item">
                    <Text
                      content="kyc.submit.personal.nationality"
                      textAlign="left"
                      translation
                      className="item-header"
                    />
                    <Text
                      content={
                        props.kyc.persistent_data.personal_information
                          .nationality
                      }
                      textAlign="left"
                      className="item-text"
                    />
                  </div>
                  <div className="item">
                    <Text
                      content="kyc.submit.personal.national_id"
                      textAlign="left"
                      translation
                      className="item-header"
                    />
                    <Text
                      content={
                        props.kyc.persistent_data.personal_information
                          .national_id
                      }
                      textAlign="left"
                      className="item-text"
                    />
                  </div>
                </div>
                <div className="address-row">
                  <div className="item address-item">
                    <Text
                      content="kyc.submit.address.address"
                      textAlign="left"
                      translation
                      className="item-header"
                    />
                    <Text
                      content={
                        props.kyc.persistent_data.address_information.address
                      }
                      textAlign="left"
                      className="item-text"
                    />
                  </div>
                  <div className="item">
                    <Text
                      content="kyc.submit.address.country"
                      textAlign="left"
                      translation
                      className="item-header"
                    />
                    <Text
                      content={
                        props.kyc.persistent_data.address_information.country
                      }
                      textAlign="left"
                      className="item-text"
                    />
                  </div>
                  <div className="item">
                    <Text
                      content="kyc.submit.address.city"
                      textAlign="left"
                      translation
                      className="item-header"
                    />
                    <Text
                      content={
                        props.kyc.persistent_data.address_information.city
                      }
                      textAlign="left"
                      className="item-text"
                    />
                  </div>
                </div>
                <React.Fragment>
                  <div className="document-row">
                    <div className="item general-documents-item">
                      <Text
                        content={`kyc.submit.documents.${
                          props.kyc.persistent_data.identification_documents
                            .from === 'frontend'
                            ? props.kyc.persistent_data.identification_documents
                                .selected
                            : props.kyc.persistent_data.identification_documents
                                .files[0].type
                        }`}
                        textAlign="left"
                        translation
                        className="item-header"
                      />
                      <div className="document-zone">
                        {props.kyc.persistent_data.identification_documents
                          .from === 'frontend' ? (
                          <img
                            src={URL.createObjectURL(
                              props.kyc.persistent_data.identification_documents
                                .front_file
                            )}
                            alt="front_file"
                            className="image"
                          />
                        ) : (
                          <img
                            src={
                              props.kyc.persistent_data.identification_documents
                                .files[0].url
                            }
                            alt="front_file"
                            className="image"
                          />
                        )}
                        {props.kyc.persistent_data.identification_documents
                          .from === 'frontend' ? (
                          <img
                            src={URL.createObjectURL(
                              props.kyc.persistent_data.identification_documents
                                .rear_file
                            )}
                            alt="front_file"
                            className="image"
                          />
                        ) : (
                          <img
                            src={
                              props.kyc.persistent_data.identification_documents
                                .files[1].url
                            }
                            alt="front_file"
                            className="image"
                          />
                        )}
                      </div>
                    </div>
                    <div className="item">
                      <Text
                        content="kyc.submit.documents.address"
                        textAlign="left"
                        translation
                        className="item-header"
                      />
                      <div className="document-zone">
                        {props.kyc.persistent_data.address_information.from ===
                        'frontend' ? (
                          <img
                            src={URL.createObjectURL(
                              props.kyc.persistent_data.address_information
                                .document
                            )}
                            alt="profile"
                            className="image"
                          />
                        ) : (
                          <img
                            src={
                              props.kyc.persistent_data.address_information
                                .files[0].url
                            }
                            alt="profile"
                            className="image"
                          />
                        )}
                      </div>
                    </div>
                    <div className="item">
                      <Text
                        content="kyc.submit.documents.profile"
                        textAlign="left"
                        translation
                        className="item-header"
                      />
                      <div className="document-zone">
                        {props.kyc.persistent_data.profile_picture.from ===
                        'frontend' ? (
                          <img
                            src={
                              props.kyc.persistent_data.profile_picture.image
                            }
                            alt="profile"
                            className="image"
                          />
                        ) : (
                          <img
                            src={
                              props.kyc.persistent_data.profile_picture.files[0]
                                .url
                            }
                            alt="profile"
                            className="image"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mobile-document-row">
                    <div className="item general-documents-item">
                      <Text
                        content={`kyc.submit.documents.${
                          props.kyc.persistent_data.identification_documents
                            .from === 'frontend'
                            ? props.kyc.persistent_data.identification_documents
                                .selected
                            : props.kyc.persistent_data.identification_documents
                                .files[0].type
                        }`}
                        textAlign="left"
                        translation
                        className="item-header"
                      />
                      <div className="document-zone">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            props.kyc.persistent_data.identification_documents
                              .from === 'frontend'
                              ? URL.createObjectURL(
                                  props.kyc.persistent_data
                                    .identification_documents.front_file
                                )
                              : props.kyc.persistent_data
                                  .identification_documents.files[0].url
                          }
                        >
                          <Text
                            content={'[ FRONT SIDE ]'}
                            className="document-text"
                          />
                        </a>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            props.kyc.persistent_data.identification_documents
                              .from === 'frontend'
                              ? URL.createObjectURL(
                                  props.kyc.persistent_data
                                    .identification_documents.rear_file
                                )
                              : props.kyc.persistent_data
                                  .identification_documents.files[1].url
                          }
                        >
                          <Text
                            content={'[ BACK SIDE ]'}
                            className="document-text"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="item">
                      <Text
                        content="kyc.submit.documents.profile"
                        textAlign="left"
                        translation
                        className="item-header"
                      />
                      <div className="document-zone">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            props.kyc.persistent_data.profile_picture.from ===
                            'frontend'
                              ? props.kyc.persistent_data.profile_picture.image
                              : props.kyc.persistent_data.profile_picture
                                  .files[0].url
                          }
                        >
                          <Text
                            content={'[ PERSONAL SELFIE ]'}
                            className="document-text"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="item">
                      <Text
                        content="kyc.submit.documents.address"
                        textAlign="left"
                        translation
                        className="item-header"
                      />
                      <div className="document-zone">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            props.kyc.persistent_data.address_information
                              .from === 'frontend'
                              ? URL.createObjectURL(
                                  props.kyc.persistent_data.address_information
                                    .document
                                )
                              : props.kyc.persistent_data.address_information
                                  .files[0].url
                          }
                        >
                          <Text
                            content={'[ ADDRESS DOCUMENT ]'}
                            className="document-text"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
              <div className="action-zone">
                <Button
                  content="kyc.document.action.back"
                  translation
                  borderColor="orange-3"
                  bgColor="white"
                  color="orange-3"
                  className="back"
                  onClick={() => {
                    props.kycMoveToGivenStep(KYC_STEP.ADDRESS);
                  }}
                />
                {props.kyc.status === REQUEST_STATUS.PENDING ? (
                  <Button
                    type="submit"
                    color="white"
                    bgColor="orange-3"
                    borderColor="orange-3"
                    loading
                    className="continue"
                  />
                ) : (
                  <Button
                    content="kyc.submit.action.submit"
                    translation
                    borderColor="orange-3"
                    bgColor="orange-3"
                    color="white"
                    className="continue"
                    onClick={() => {
                      props.kycFlowSubmit(props.account.access_token);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default Submit;
