import React from 'react';
import * as _ from 'lodash';

import GlobalLayout from 'Layouts/Global.layout';
import KYCStepHeader from 'Components/KYC/components/KYCStepHeader.component';

import { Container, Text, Button, Icon, FormElements } from 'UILib';
import 'Components/KYC/kyc.style.scss';
import { KYC_STEP } from 'Constants/KYC/KYC.constants';
import {
  URL_TEST_PATTERN,
  REQUEST_STATUS
} from 'Constants/GlobalConstants/Global.constants.js';

class Documents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.getDocumentState(),
      front_file: this.getDocumentFile('front'),
      rear_file: this.getDocumentFile('rear'),
      validation_errors: false
    };
  }

  getDocumentFile = (document_side) => {
    if (_.has(this.props.kyc.persistent_data, 'identification_documents')) {
      if (
        this.props.kyc.persistent_data.identification_documents.from ===
        'frontend'
      ) {
        return this.props.kyc.persistent_data.identification_documents[
          `${document_side}_file`
        ];
      } else {
        if (document_side === 'front') {
          return this.props.kyc.persistent_data.identification_documents
            .files[0].url;
        } else {
          return this.props.kyc.persistent_data.identification_documents
            .files[1].url;
        }
      }
    } else {
      return null;
    }
  };

  getDocumentState = () => {
    if (_.has(this.props.kyc.persistent_data, 'identification_documents')) {
      if (
        this.props.kyc.persistent_data.identification_documents.from ===
        'frontend'
      ) {
        return this.props.kyc.persistent_data.identification_documents.selected;
      } else {
        return this.props.kyc.persistent_data.identification_documents.files[0]
          .type;
      }
    } else {
      return 'id';
    }
  };

  handleSelectionChange = (name) => {
    this.setState({ selected: name, validation_errors: false });
  };

  handleOnChangeFile = (section, file) => {
    this.setState({
      [section]: file,
      validation_errors: false
    });
  };

  checkValidationErrorsAndSubmit = () => {
    if (
      this.state.selected !== '' &&
      this.state.front_file !== null &&
      this.state.rear_file !== null
    ) {
      _.has(this.props.kyc.persistent_data, 'identification_documents')
        ? this.props.kycFlowDocumentsPatch(
            this.props.account.access_token,
            this.state
          )
        : this.props.kycFlowDocuments(
            this.props.account.access_token,
            this.state
          );
    } else {
      this.setState({ validation_errors: true });
    }
  };

  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <div className="kyc-page-wrapper">
            <div className="kyc-document-wrapper">
              <KYCStepHeader currentStep={2} />
              <div className="section-wrapper">
                <Text
                  content="kyc.document.header"
                  translation
                  className="header"
                />
                <Text
                  content="kyc.document.content"
                  translation
                  className="content"
                />
                <div className="button-section">
                  <Button
                    content="kyc.document.button.id"
                    translation
                    bgColor={
                      this.state.selected === 'id' ? 'orange-3' : 'white'
                    }
                    borderColor="orange-3"
                    color={this.state.selected === 'id' ? 'white' : 'orange-3'}
                    onClick={() => {
                      this.handleSelectionChange('id');
                    }}
                    className="button"
                  />
                  <Button
                    content="kyc.document.button.licence"
                    translation
                    bgColor={
                      this.state.selected === 'licence' ? 'orange-3' : 'white'
                    }
                    borderColor="orange-3"
                    color={
                      this.state.selected === 'licence' ? 'white' : 'orange-3'
                    }
                    onClick={() => {
                      this.handleSelectionChange('licence');
                    }}
                    className="button"
                  />
                  <Button
                    content="kyc.document.button.passport"
                    translation
                    bgColor={
                      this.state.selected === 'passport' ? 'orange-3' : 'white'
                    }
                    borderColor="orange-3"
                    color={
                      this.state.selected === 'passport' ? 'white' : 'orange-3'
                    }
                    onClick={() => {
                      this.handleSelectionChange('passport');
                    }}
                    className="button"
                  />
                  <Button
                    content="kyc.document.button.other"
                    translation
                    bgColor={
                      this.state.selected === 'other' ? 'orange-3' : 'white'
                    }
                    borderColor="orange-3"
                    color={
                      this.state.selected === 'other' ? 'white' : 'orange-3'
                    }
                    onClick={() => {
                      this.handleSelectionChange('other');
                    }}
                    className="button"
                  />
                </div>
                <div className="upload-section">
                  <div className="section">
                    <Text
                      content="kyc.document.dropzone.front"
                      translation
                      className="header"
                    />
                    <div
                      className="dropzone"
                      onClick={() => {
                        this.frontFile.click();
                      }}
                    >
                      <input
                        type="file"
                        ref={(ref) => (this.frontFile = ref)}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          this.handleOnChangeFile(
                            'front_file',
                            event.target.files[0]
                          );
                        }}
                      />
                      {this.state.front_file ? (
                        <React.Fragment>
                          <img
                            src={
                              URL_TEST_PATTERN.test(this.state.front_file)
                                ? this.state.front_file
                                : URL.createObjectURL(this.state.front_file)
                            }
                            alt="front-side"
                            className="image"
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Icon
                            base="regular"
                            icon={{ name: 'clone', color: 'grey-5' }}
                            className="icon"
                          />
                          <Text
                            content="kyc.document.dropzone.text"
                            translation
                            className="text"
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="section">
                    <Text
                      content="kyc.document.dropzone.rear"
                      translation
                      className="header"
                    />
                    <div
                      className="dropzone"
                      onClick={() => {
                        this.rearFile.click();
                      }}
                    >
                      <input
                        type="file"
                        ref={(ref) => (this.rearFile = ref)}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          this.handleOnChangeFile(
                            'rear_file',
                            event.target.files[0]
                          );
                        }}
                      />
                      {this.state.rear_file ? (
                        <React.Fragment>
                          <img
                            src={
                              URL_TEST_PATTERN.test(this.state.rear_file)
                                ? this.state.rear_file
                                : URL.createObjectURL(this.state.rear_file)
                            }
                            alt="front-side"
                            className="image"
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Icon
                            base="regular"
                            icon={{ name: 'clone', color: 'grey-5' }}
                            className="icon"
                          />
                          <Text
                            content="kyc.document.dropzone.text"
                            translation
                            className="text"
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.validation_errors && (
                  <div className="error-zone">
                    <FormElements.ValidationErrors
                      content="kyc.document.validation_error"
                      translation
                    />
                  </div>
                )}
                <div className="action-zone">
                  <Button
                    content="kyc.document.action.back"
                    translation
                    borderColor="orange-3"
                    bgColor="white"
                    color="orange-3"
                    className="back"
                    onClick={() => {
                      this.props.kycMoveToGivenStep(KYC_STEP.PERSONAL);
                    }}
                  />
                  {this.props.kyc.status === REQUEST_STATUS.PENDING ? (
                    <Button
                      type="submit"
                      color="white"
                      bgColor="orange-3"
                      borderColor="orange-3"
                      loading
                      className="continue"
                    />
                  ) : (
                    <Button
                      content="kyc.document.action.continue"
                      translation
                      borderColor="orange-3"
                      bgColor="orange-3"
                      color="white"
                      className="continue"
                      onClick={() => {
                        this.checkValidationErrorsAndSubmit();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </GlobalLayout>
      </Container>
    );
  }
}

export default Documents;
