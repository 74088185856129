import React from 'react';
import { Formik } from 'formik';
import * as _ from 'lodash';
import SelectSearch from 'react-select-search';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

// import DatePicker from 'react-datepicker';

import GlobalLayout from 'Layouts/Global.layout';
import KYCStepHeader from 'Components/KYC/components/KYCStepHeader.component';

import { hasValidationError } from 'Utils/Helpers/component.helpers';

import { Container, Text, FormElements, Button } from 'UILib';
import 'react-datepicker/dist/react-datepicker.css';
import 'Components/KYC/kyc.style.scss';
import 'Components/KYC/select-search.style.scss';

import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { NATIONALITY_CODES } from 'Constants/GlobalConstants/Global.constants';

const getMaxYear = () => {
  const year = new Date().getFullYear().toString();
  return year;
};

const getMinYear = () => {
  const year = (new Date().getFullYear() - 100).toString();
  return year;
};

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy', {
  maxYear: `${getMaxYear()}`,
  minYear: `${getMinYear()}`
});

const handleDatePickerOutput = (selectedDate) => {
  const [day, month, year] = selectedDate.split('/');
  return `${year}-${month}-${day}`;
};

const handleInitialValues = (props) => {
  const initialValues = _.has(props.kyc.persistent_data, 'personal_information')
    ? props.kyc.persistent_data.personal_information
    : props.initialValues.personal;
  const [year, month, day] = initialValues.date_of_birth
    .split('T')[0]
    .split('-');
  const newDate = `${day}/${month}/${year}`;
  initialValues.date_of_birth = newDate;
  return initialValues;
};

const handleDateOfBirthValue = (value) => {
  const [year, month, day] = value.split('-');
  return `${day}/${month}/${year}`;
};

const Personal = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div className="kyc-page-wrapper">
          <div className="kyc-personal-wrapper">
            <KYCStepHeader currentStep={1} />
            <div className="section-wrapper">
              <Text
                content="kyc.personal.header"
                translation
                className="header"
              />
              <Text
                content="kyc.personal.content"
                translation
                className="content"
              />
              <div className="personal-form-wrapper">
                <Formik
                  enableReinitialized={true}
                  initialValues={handleInitialValues(props)}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={props.PersonalValidationSchema}
                  onSubmit={(values, { submitForm, setSubmitting }) => {
                    _.has(props.kyc.persistent_data, 'personal_information')
                      ? props.kycFlowPersonalPatch(
                          props.account.access_token,
                          values
                        )
                      : props.kycFlowPersonal(
                          props.account.access_token,
                          values
                        );
                  }}
                >
                  {({
                    initialValues,
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="form-zone">
                          <div className="form-column">
                            <FormElements.Input
                              value={values.first_name}
                              type="text"
                              name="first_name"
                              error={errors.first_name && touched.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="kyc.personal.form.first_name"
                              translation
                              className="input"
                            />
                            <FormElements.Input
                              value={values.last_name}
                              type="text"
                              name="last_name"
                              onChange={handleChange}
                              error={errors.last_name && touched.last_name}
                              onBlur={handleBlur}
                              placeholder="kyc.personal.form.last_name"
                              translation
                              className="input"
                            />
                            <MaskedInput
                              mask={[
                                /\d/,
                                /\d/,
                                '/',
                                /\d/,
                                /\d/,
                                '/',
                                /[1-2]/,
                                /\d/,
                                /\d/,
                                /\d/
                              ]}
                              name="date_of_birth"
                              id="date_of_birth"
                              pipe={autoCorrectedDatePipe}
                              keepCharPositions={true}
                              className="input custom-datepicker"
                              value={handleDateOfBirthValue(
                                values.date_of_birth
                              )}
                              placeholder="DD/MM/YYYY"
                              guide={true}
                              onBlur={handleBlur}
                              onChange={({ target }) => {
                                const isDateCompleted = /\d\d\/\d\d\/\d\d\d\d/.test(
                                  target.value
                                );
                                isDateCompleted &&
                                  setFieldValue(
                                    'date_of_birth',
                                    handleDatePickerOutput(target.value)
                                  );
                              }}
                            />
                            {/* <DatePicker
                              name="date_of_birth"
                              selected={getSelectedDateObject(
                                values.date_of_birth
                              )}
                              error={
                                errors.date_of_birth && touched.date_of_birth
                              }
                              onChangeRaw={(e) => {
                                e.preventDefault();
                              }}
                              onChange={(value) => {
                                setFieldValue(
                                  'date_of_birth',
                                  handleDatePickerOutput(value)
                                );
                              }}
                              onBlur={handleBlur}
                              minDate={getMinMaxDates(100)}
                              maxDate={getMinMaxDates(18)}
                              openToDate={getMinMaxDates(18)}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="input custom-datepicker"
                            /> */}
                          </div>
                          <div className="form-column">
                            <SelectSearch
                              name="nationality"
                              error={errors.nationality && touched.nationality}
                              mode="input"
                              onChange={(value) => {
                                setFieldValue('nationality', value.value);
                              }}
                              value={values.nationality}
                              options={NATIONALITY_CODES}
                              placeholder="Country of Birth"
                            />
                            <FormElements.Input
                              type="text"
                              name="national_id"
                              value={values.national_id}
                              error={errors.national_id && touched.national_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="kyc.personal.form.national_id"
                              translation
                              className="input"
                            />
                          </div>
                        </div>
                        {hasValidationError(errors) ? (
                          <Container className="error-zone">
                            <FormElements.ValidationErrors
                              content="kyc.personal.validation_error"
                              translation
                            />
                          </Container>
                        ) : null}
                        <div className="action-zone">
                          {props.kyc.status === REQUEST_STATUS.PENDING ? (
                            <Button
                              type="submit"
                              color="white"
                              bgColor="orange-3"
                              borderColor="orange-3"
                              loading
                              className="button"
                            />
                          ) : (
                            <Button
                              type="submit"
                              color="white"
                              bgColor="orange-3"
                              borderColor="orange-3"
                              content="kyc.personal.form.action"
                              translation
                              className="button"
                            />
                          )}
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default Personal;
