import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import WalletDepositModal from 'Components/Account/UserDashboard/Components/WalletDepositModal.component';
import { Modal, Button, Icon, Text } from 'UILib';

import {
  investmentFlowReset,
  investmentStart,
  investmentTimerReduction,
  investmentAmount,
  investmentConfirm
} from 'Actions/Investment/Investment.actions';
import {
  getUserWallets,
  getUserSelectedWalletDetails,
  regenerateUserSelectedWalletAddress,
  clearUserSelectedWalletDetails
} from 'Actions/Account/UserDashboard.actions';

import {
  saveRedirectRoute,
  removeRedirectRoute
} from 'Actions/RouteExtender/RouteExtender.actions';

import { determineFlowComponent } from 'Utils/Helpers/flow.helpers';
import { genericComponentTimer } from 'Utils/Helpers/component.helpers';

import { investmentFlow } from 'Containers/Investment/Flows/Investment.flow';
import { INVESTMENT_STEP } from 'Constants/Investment/Investment.constants';

class InvestmentContainer extends Component {
  constructor(props) {
    super(props);
    this.props.removeRedirectRoute();

    this.state = {
      requireLogin: this.determineLoginRequirement(props),
      activeCurrency: 'usd',
      walletModal: {
        show: false,
        selectedCurrency: null
      },
      selectedInvestmentAmount: '50000',
      isConfirmationModalVisible: false,
      isConfirmationGiven: false,
      customInvestmentAmount: {
        toBeRaised: '50000',
        sharesToBuy: ''
      }
    };
  }

  componentDidMount() {
    if (this.props.projectDetail.projectId && this.props.account.access_token) {
      this.setState({
        customInvestmentAmount: {
          toBeRaised: '50000',
          sharesToBuy:
            parseInt('50000', 10) /
            this.props.projectDetail.attributes.properties.contribution
              .compensation_details.share_price
        }
      });
      this.props.investmentStart(
        this.props.projectDetail.projectId,
        this.props.account.access_token
      );
    }
    this.getUserWallets();
    this.getUserWalletsTimer = genericComponentTimer(
      this.getUserWallets,
      30000
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.projectDetail.projectId === null &&
      this.props.projectDetail.projectId !== null &&
      prevProps.investment.project_id === null &&
      prevProps.account.access_token !== null
    ) {
      this.setState({
        customInvestmentAmount: {
          toBeRaised: '50000',
          sharesToBuy:
            parseInt('50000', 10) /
            this.props.projectDetail.attributes.properties.contribution
              .compensation_details.share_price
        }
      });
      this.props.investmentStart(
        this.props.projectDetail.projectId,
        this.props.account.access_token
      );
    }

    if (
      prevProps.investment.step !== INVESTMENT_STEP.START &&
      this.props.investment.step === INVESTMENT_STEP.START
    ) {
      this.setState({
        customInvestmentAmount: {
          toBeRaised: '50000',
          sharesToBuy:
            parseInt('50000', 10) /
            this.props.projectDetail.attributes.properties.contribution
              .compensation_details.share_price
        }
      });
      this.props.investmentStart(
        this.props.projectDetail.projectId,
        this.props.account.access_token
      );
    }

    if (
      prevProps.investment.step === INVESTMENT_STEP.START &&
      this.props.investment.step === INVESTMENT_STEP.AMOUNT
    ) {
      this.reduceInvestmentTimerCT = genericComponentTimer(
        this.reduceInvestmentTimer,
        1000
      );
    }
    if (
      !(
        this.props.investment.step === INVESTMENT_STEP.AMOUNT ||
        this.props.investment.step === INVESTMENT_STEP.CONFIRM
      )
    ) {
      clearInterval(this.reduceInvestmentTimerCT);
    }
  }

  componentWillUnmount() {
    clearInterval(this.getUserWalletsTimer);
    this.props.investmentFlowReset();
  }

  getUserWallets = () => {
    if (
      this.props.account.access_token !== null &&
      this.props.account.user_informations.client_code === 200
    ) {
      this.props.getUserWallets(this.props.account.access_token);
    }
  };

  reduceInvestmentTimer = () => {
    if (
      this.props.investment.step === INVESTMENT_STEP.AMOUNT ||
      this.props.investment.step === INVESTMENT_STEP.CONFIRM
    ) {
      this.props.investmentTimerReduction();
    }
  };

  determineLoginRequirement = (props) => {
    return (
      props.account.access_token === null &&
      props.account.user_informations.client_code !== 200
    );
  };

  changeActiveCurrency = (currency) => {
    this.setState({ activeCurrency: currency });
  };

  showWalletModal = (wallet) => {
    this.setState((prevState) => ({
      ...prevState,
      walletModal: {
        ...prevState.walletModal,
        show: true,
        selectedCurrency: wallet
      }
    }));
  };

  hideWalletModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      walletModal: {
        ...prevState.walletModal,
        show: false,
        selectedCurrency: null
      }
    }));
  };

  showConfirmationModal = () => {
    this.setState({ isConfirmationModalVisible: true });
  };

  hideConfirmationModal = () => {
    this.setState({ isConfirmationModalVisible: false });
  };

  changeSelectedInvestmentAmount = (amount) => {
    if (amount !== 'later') {
      this.setState({
        selectedInvestmentAmount: amount,
        customInvestmentAmount: {
          toBeRaised: amount,
          sharesToBuy:
            parseInt(amount, 10) /
            this.props.projectDetail.attributes.properties.contribution
              .compensation_details.share_price
        }
      });
    } else {
      this.setState({
        selectedInvestmentAmount: amount,
        customInvestmentAmount: {
          toBeRaised: '',
          sharesToBuy: ''
        }
      });
    }
  };

  determineCustomInvestmentAmountCalculator = (name, value) => {
    if (name === 'toBeRaised' && value !== '') {
      this.setState({
        selectedInvestmentAmount: value,
        customInvestmentAmount: {
          toBeRaised: value,
          sharesToBuy:
            parseInt(value, 10) /
            this.props.projectDetail.attributes.properties.contribution
              .compensation_details.share_price
        }
      });
    } else if (name === 'sharesToBuy' && value !== 'null') {
      this.setState({
        selectedInvestmentAmount: value,
        customInvestmentAmount: {
          toBeRaised:
            parseInt(value, 10) *
            this.props.projectDetail.attributes.properties.contribution
              .compensation_details.share_price,
          sharesToBuy: value
        }
      });
    }

    if (value === '') {
      this.setState({
        selectedInvestmentAmount: '50000',
        customInvestmentAmount: {
          toBeRaised: '',
          sharesToBuy: ''
        }
      });
    }
  };

  useAllMyBalanceAction = (amount) => {
    this.setState({
      selectedInvestmentAmount: null,
      customInvestmentAmount: {
        toBeRaised: amount.toFixed(2),
        sharesToBuy:
          parseInt(amount.toFixed(2), 10) /
          this.props.projectDetail.attributes.properties.contribution
            .compensation_details.share_price
      }
    });
  };

  render() {
    const ElementTag = determineFlowComponent(
      investmentFlow,
      this.props.investment
    );
    const { walletModal } = this.state;
    const {
      access_token,
      user_wallet_detail,
      user_wallets
    } = this.props.account;

    return (
      <React.Fragment>
        {this.state.isConfirmationModalVisible && (
          <Modal
            closeIcon
            show={this.state.isConfirmationModalVisible}
            handleClose={() => this.hideConfirmationModal()}
          >
            <div className="confirmation-modal">
              <Text
                color="light-grey"
                content="investment.confirmation.header"
                translation
                className="confirmation-modal-header"
              />
              <Text
                color="light-grey"
                content="investment.confirmation.subheader"
                translation
                className="confirmation-modal-subheader"
              />
              <Icon
                base="regular"
                icon={{ name: 'question-circle' }}
                className="confirmation-modal-icon"
              />
              <div className="confirmation-actions">
                <Button
                  color="orange-3"
                  bgColor="white"
                  borderColor="orange-3"
                  content="investment.confirmation.no"
                  translation
                  onClick={() => {
                    this.hideConfirmationModal();
                  }}
                  className="button"
                />
                <Button
                  color="white"
                  bgColor="orange-3"
                  borderColor="orange-3"
                  content="investment.confirmation.yes"
                  translation
                  onClick={() => {
                    this.hideConfirmationModal();
                    this.props.investmentAmount(
                      this.props.projectDetail.projectId,
                      this.props.account.access_token,
                      this.props.investment.transaction_id,
                      this.state.selectedInvestmentAmount
                    );
                  }}
                  className="button"
                />
              </div>
            </div>
          </Modal>
        )}
        {walletModal.show && (
          <WalletDepositModal
            show={walletModal.show}
            selectedCurrency={walletModal.selectedCurrency}
            access_token={access_token}
            user_wallet_detail={user_wallet_detail}
            user_wallets={user_wallets}
            handleClose={this.hideWalletModal}
            getUserSelectedWalletDetails={
              this.props.getUserSelectedWalletDetails
            }
            regenerateUserSelectedWalletAddress={
              this.props.regenerateUserSelectedWalletAddress
            }
            clearUserSelectedWalletDetails={
              this.props.clearUserSelectedWalletDetails
            }
          />
        )}
        <ElementTag
          {...this.props}
          {...this.state}
          changeActiveCurrency={this.changeActiveCurrency}
          changeSelectedInvestmentAmount={this.changeSelectedInvestmentAmount}
          determineCustomInvestmentAmountCalculator={
            this.determineCustomInvestmentAmountCalculator
          }
          showConfirmationModal={this.showConfirmationModal}
          useAllMyBalanceAction={this.useAllMyBalanceAction}
          showWalletModal={this.showWalletModal}
          hideWalletModal={this.hideWalletModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectDetail: state.projectDetail,
    account: state.account,
    investment: state.investment
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      investmentFlowReset,
      investmentStart,
      investmentTimerReduction,
      investmentAmount,
      investmentConfirm,
      getUserWallets,
      getUserSelectedWalletDetails,
      regenerateUserSelectedWalletAddress,
      clearUserSelectedWalletDetails,
      saveRedirectRoute,
      removeRedirectRoute
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentContainer);
