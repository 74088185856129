import React, { Component } from 'react';

import { Text, Icon } from 'UILib';
import {
  scrollElementIntoView,
  isItActiveSection
} from 'Utils/Helpers/component.helpers';

import 'Components/ProjectDetail/projectDetail.style.scss';

const OverviewCard = (props) => {
  return (
    <div className="overview-card">
      <div className="header-zone">
        <Icon
          base="regular"
          icon={{ name: 'star', color: 'black' }}
          className="icon"
        />
        <Text
          content={`projectDetail.projectOverview.cards.${props.card}.header`}
          translation
          color="black"
          className="card-header"
        />
      </div>
      <div className="separator" />
      <div className="content-zone">
        <div className="subheader-zone">
          <Text
            content={`projectDetail.projectOverview.cards.${props.card}.subheader`}
            translation
            color="black"
            className="flex-content content"
          />
          <Text
            content={props.content}
            color="blue-3"
            className="content content-bold"
          />
        </div>
        <Text
          type="intro"
          content={props.short_description}
          className="short-description"
        />
        <div onClick={props.onDetailsClick}>
          <Text
            type="intro"
            content="projectDetail.projectOverview.details"
            translation
            transform="toUpper"
            className="details-cta"
          />
        </div>
      </div>
    </div>
  );
};

class ProjectOverview extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (isItActiveSection(prevProps, this.props, 'overview')) {
      scrollElementIntoView(this.projectOverviewElement);
    }
  }

  render() {
    return (
      <div
        className="project-overview-zone"
        ref={(el) => {
          this.projectOverviewElement = el;
        }}
      >
        <Text
          type="h1"
          color="black"
          content="projectDetail.projectOverview.header"
          translation
          transform="toUpper"
          className="header"
        />
        <div className="project-overview-card-wrapper">
          <OverviewCard
            card="financial"
            content={
              this.props.financial.investment_performance.irr
                ? `${this.props.financial.investment_performance.irr} %`
                : ''
            }
            short_description={this.props.financial.description.inline || ''}
            onDetailsClick={() => {
              this.props.handleActiveSection('finance');
            }}
          />
          <OverviewCard
            card="technical"
            content={
              this.props.information.plant_capacity
                ? `${this.props.information.plant_capacity} kWp`
                : ''
            }
            short_description={this.props.technology.description.inline || ''}
            onDetailsClick={() => {
              this.props.handleActiveSection('technology');
            }}
          />
          <OverviewCard
            card="market"
            content={this.props.market.details.pricing_regime_type || ''}
            short_description={this.props.market.description.inline || ''}
            onDetailsClick={() => {
              this.props.handleActiveSection('market');
            }}
          />
        </div>
      </div>
    );
  }
}

export default ProjectOverview;
