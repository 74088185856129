import { delay } from 'redux-saga';
export function getStatusCodeFamily(status) {
  /**
   * Checks the first digit of the status_code of api response
   * to determine which client_code family it is in.
   */
  return parseInt(String(status).charAt(0), 10);
}

export function apiErrorHandler(err) {
  return err.response ? err.response.data : { client_code: 700 };
}

export function* sagaDelayer(delayAmount = 400) {
  yield delay(delayAmount);
}

export function dataURLtoFile(image, filename) {
  var arr = image.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
