import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import { textTransform } from 'UILib/Utils/textTransform';

import './Tag.style.scss';

export default class Tag extends React.Component {
  render() {
    const { content, translation, transform, style, className } = this.props;

    const baseCN = 'tag-ui';
    const TagCX = cx(baseCN, className);

    return (
      <Translate>
        {({ translate }) => (
          <div className={TagCX} style={style}>
            {translation
              ? textTransform(translate(content), transform)
              : textTransform(content, transform)}
          </div>
        )}
      </Translate>
    );
  }
}
