import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import MoonLoader from 'Components/Reusable/Loading/MoonLoader.component';
import CommitmentHeader from 'Components/Commitment/Components/CommitmentHeader.component';
import UserNotLoggedIn from 'Components/Commitment/Components/UserNotLoggedIn.component';
import CommitmentForm from 'Components/Commitment/Components/CommitmentForm.component';
import CommitmentSuccess from 'Components/Commitment/Components/CommitmentSuccess.component';
import CommitmentFailure from 'Components/Commitment/Components/CommitmentFailure.component';

import { Container } from 'UILib';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

import 'Components/Commitment/commitment.style.scss';

const Commitment = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div
          className={`commitment-page-wrapper ${props.commitment.status ===
            REQUEST_STATUS.FAILURE && 'commitment-page-padding'}`}
        >
          {props.projectDetail.status === REQUEST_STATUS.SUCCESS ? (
            <React.Fragment>
              <CommitmentHeader
                information={
                  props.projectDetail.attributes.properties.information
                }
                statistics={props.projectDetail.attributes.statistics}
              />
              {props.requireLogin ? (
                <UserNotLoggedIn
                  saveRedirectRoute={props.saveRedirectRoute}
                  projectDetail={props.projectDetail}
                />
              ) : (
                (() => {
                  switch (props.commitment.status) {
                    case REQUEST_STATUS.SUCCESS:
                      return <CommitmentSuccess {...props} />;
                    case REQUEST_STATUS.FAILURE:
                      return (
                        <CommitmentFailure
                          commitment={props.commitment}
                          projectDetail={props.projectDetail}
                        />
                      );
                    default:
                      return <CommitmentForm {...props} />;
                  }
                })()
              )}
            </React.Fragment>
          ) : (
            <div className="loading-wrapper">
              <MoonLoader />
            </div>
          )}
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default Commitment;
