import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import { Slider, Text, Button } from 'UILib';
import {
  APP_ROUTES,
  PROJECT_VISIBLE_TAGS
} from 'Constants/GlobalConstants/Global.constants';
import { determineRemainingDays } from 'Utils/Helpers/component.helpers';

class MobileProjectCard extends Component {
  getProjectImage = () => {
    const { information } = this.props.project.attributes.properties;

    const projectImage = _.chain(information.files)
      .find((obj) => {
        return obj.attributes.kind === 'project_image';
      })
      .get('attributes.url')
      .value();

    return projectImage ? [projectImage] : [];
  };

  handleRemainingDays = (date) => {
    const remainingDays = Math.floor(determineRemainingDays(date));
    return remainingDays > 1 ? `${remainingDays} Days` : `${remainingDays} Day`;
  };

  shouldRenderVisibleTags = (tags) => {
    return (
      _.intersectionWith(
        PROJECT_VISIBLE_TAGS.map((tag) => {
          return tag.tag;
        }),
        tags,
        (permittedTag, tag) => {
          return permittedTag.toLowerCase() === tag.toLowerCase();
        }
      ).length > 0
    );
  };

  determineProjectVisibleTags = (tags) => {
    return _.filter(PROJECT_VISIBLE_TAGS, (permittedTag) => {
      return _.some(tags, (tag) => {
        return tag.toLowerCase() === permittedTag.tag.toLowerCase();
      });
    });
  };

  render() {
    const {
      information,
      financial,
      contribution
    } = this.props.project.attributes.properties;
    const { investment_status } = this.props.project.attributes;

    return (
      <div className="mobile-project-card-zone">
        <div className="project-information-wrapper">
          <div className="header-wrapper">
            <Link to={APP_ROUTES.PROJECT_DETAIL_WITH_ID(this.props.project.id)}>
              <Text
                content={information.name}
                textAlign="left"
                className="header"
              />
            </Link>
            <Text
              content={`${information.city} / ${information.country}`}
              textAlign="left"
              className="subheader"
            />
          </div>
          <div className="information-wrapper">
            <div className="information first-column">
              <Text
                content="projectListing.projectCard.informations.capacity.header"
                translation
                className="header"
              />
              <Text
                content="projectListing.projectCard.informations.capacity.subheader"
                translation
                data={{ plant_capacity: information.plant_capacity }}
                className="subheader"
              />
            </div>
            <div className="information center-column">
              <Text
                content="projectListing.projectCard.informations.company.header"
                translation
                className="header"
              />
              <Text content={information.owner_company} className="subheader" />
            </div>

            <div className="information last-column">
              <Text
                content="projectListing.projectCard.informations.stage.header"
                translation
                className="header"
              />
              <Text
                content={information.status}
                transform="title"
                className="subheader"
              />
            </div>
          </div>
          <div className="project-image-wrapper">
            <Slider images={this.getProjectImage()} className="project-image" />

            {this.shouldRenderVisibleTags(information.tags) && (
              <div className="project-tag-zone">
                {this.determineProjectVisibleTags(information.tags).map(
                  (tag, idx) => (
                    <div
                      className={`project-tag project-tag-${tag.color}`}
                      key={idx}
                    >
                      {_.upperCase(tag.tag)}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          {investment_status ? (
            <div className="project-action-wrapper">
              <div className="information">
                <Text
                  content="projectListing.projectCard.informations.return.header"
                  translation
                  className="header"
                />
                <Text
                  content="projectListing.projectCard.informations.return.subheader"
                  translation
                  data={{
                    effective_irr:
                      financial.investment_performance.effective_irr
                  }}
                  className="subheader return"
                />
              </div>
              <div className="information">
                <Text
                  content="projectListing.projectCard.actions.investmentOpen.header"
                  translation
                  className="header"
                />
                <Text
                  content="projectListing.projectCard.actions.investmentOpen.subheader"
                  translation
                  data={{ amount: 'backend' }}
                  className="amount"
                />
              </div>
              <div className="information">
                <Link
                  to={APP_ROUTES.PROJECT_DETAIL_WITH_ID(this.props.project.id)}
                >
                  <Button
                    bgColor="orange-3"
                    borderColor="orange-3"
                    color="white"
                    content="projectListing.projectCard.actions.investmentOpen.buyShares"
                    translation
                  />
                </Link>
              </div>
            </div>
          ) : (
            <div className="project-action-wrapper">
              <div className="information">
                <Text
                  content="projectListing.projectCard.informations.return.header"
                  translation
                  className="header"
                />
                <Text
                  content="projectListing.projectCard.informations.return.subheader"
                  translation
                  data={{
                    effective_irr:
                      financial.investment_performance.effective_irr
                  }}
                  className="subheader return"
                />
              </div>
              <div className="information">
                <Text
                  content="projectListing.projectCard.actions.investmentClose.header"
                  translation
                  className="header"
                />
                <Text
                  content={moment(contribution.sale_period.start_date).format(
                    'DD.MM.YYYY'
                  )}
                  className="subheader"
                />
              </div>
              <div className="information">
                <Link
                  to={APP_ROUTES.PROJECT_DETAIL_WITH_ID(this.props.project.id)}
                >
                  <Button
                    bgColor="white"
                    color="orange-3"
                    borderColor="orange-3"
                    content="VIEW"
                  />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MobileProjectCard;
