import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import UserDashboard from 'Components/Account/UserDashboard/UserDashboard.component';
import WalletDepositModal from 'Components/Account/UserDashboard/Components/WalletDepositModal.component';
import ActivateYourAccountModal from 'Components/Account/UserDashboard/Components/ActivateYourAccountModal.component';

import {
  getUserWallets,
  getUserSelectedWalletDetails,
  regenerateUserSelectedWalletAddress,
  clearUserSelectedWalletDetails,
  getUserInvestedProjects,
  getUserCommitedProjects
} from 'Actions/Account/UserDashboard.actions';
import { retrieveAccessTokenFromStorageAndGetUserInformation } from 'Actions/AppInit/init.actions';

import { genericComponentTimer } from 'Utils/Helpers/component.helpers';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';
import { USER_VERIFICATION_LEVEL } from 'Constants/Account/Account.constants';

class UserDashboardContainer extends Component {
  constructor(props) {
    super(props);

    this.props.retrieveAccessTokenFromStorageAndGetUserInformation();

    this.state = {
      activationMessage: this.determineActivationMessage(
        this.props.account.user_informations.attributes.verification_level
      ),
      activeCurrency: 'raw',
      disabledWalletModal: {
        show: false
      },
      walletModal: {
        show: false,
        selectedCurrency: null
      }
    };
  }

  componentDidMount() {
    this.getUserWallets();
    this.getUserInvestedProjects();
    this.getUserCommitedProjects();
    this.getUserWalletsTimer = genericComponentTimer(
      this.getUserWallets,
      30000
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.walletModal.show && this.state.walletModal.show) {
      setTimeout(() => {
        this.getUserWallets();
      }, 1000);
    }
    if (
      prevProps.account.user_informations.attributes.verification_level !==
      this.props.account.user_informations.attributes.verification_level
    ) {
      this.setState({
        activationMessage: this.determineActivationMessage(
          this.props.account.user_informations.attributes.verification_level
        )
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.getUserWalletsTimer);
  }

  getUserWallets = () => {
    this.props.getUserWallets(this.props.account.access_token);
  };

  getUserInvestedProjects = () => {
    this.props.getUserInvestedProjects(this.props.account.access_token);
  };

  getUserCommitedProjects = () => {
    this.props.getUserCommitedProjects(this.props.account.access_token);
  };

  determineActivationMessage = (verification_level) => {
    switch (verification_level) {
      case USER_VERIFICATION_LEVEL.EMAIL_VERIFIED:
        return {
          showActivationMessage: true,
          messageType: 'phoneActivationMessage',
          messageAction: true,
          action: () => {
            this.phoneActivationRedirect();
          }
        };
      case USER_VERIFICATION_LEVEL.PHONE_VERIFIED:
        return {
          showActivationMessage: true,
          messageType: 'kycVerificationMessage',
          messageAction: true,
          action: () => {
            this.kycVerificationRedirect();
          }
        };
      case USER_VERIFICATION_LEVEL.DOCUMENT_PENDING:
        return {
          showActivationMessage: true,
          messageType: 'kycApprovalMessage',
          messageAction: false,
          action: null
        };
      default:
        return {
          showActivationMessage: false,
          messageType: null,
          messageAction: null,
          action: null
        };
    }
  };

  dismissActivationMessage = () => {
    this.setState({
      activationMessage: {
        ...this.state.activationMessage,
        showActivationMessage: false
      }
    });
  };

  changeActiveCurrency = (currency) => {
    this.setState({ activeCurrency: currency });
  };

  phoneActivationRedirect = () => {
    this.props.history.push(APP_ROUTES.PHONE_ACTIVATION);
  };

  kycVerificationRedirect = () => {
    this.props.history.push(APP_ROUTES.KYC);
  };

  showWalletModal = (wallet) => {
    this.setState((prevState) => ({
      ...prevState,
      walletModal: {
        ...prevState.walletModal,
        show: true,
        selectedCurrency: wallet
      }
    }));
  };

  hideWalletModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      walletModal: {
        ...prevState.walletModal,
        show: false,
        selectedCurrency: null
      }
    }));
  };

  showDisabledWalletModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      disabledWalletModal: {
        ...prevState.disabledWalletModal,
        show: true
      }
    }));
  };

  hideDisabledWalletModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      disabledWalletModal: {
        ...prevState.disabledWalletModal,
        show: false
      }
    }));
  };

  render() {
    const {
      activationMessage,
      activeCurrency,
      walletModal,
      disabledWalletModal
    } = this.state;
    const {
      access_token,
      user_wallet_detail,
      user_wallets
    } = this.props.account;
    return (
      <React.Fragment>
        {walletModal.show && (
          <WalletDepositModal
            show={walletModal.show}
            selectedCurrency={walletModal.selectedCurrency}
            access_token={access_token}
            user_wallet_detail={user_wallet_detail}
            user_wallets={user_wallets}
            handleClose={this.hideWalletModal}
            getUserSelectedWalletDetails={
              this.props.getUserSelectedWalletDetails
            }
            regenerateUserSelectedWalletAddress={
              this.props.regenerateUserSelectedWalletAddress
            }
            clearUserSelectedWalletDetails={
              this.props.clearUserSelectedWalletDetails
            }
          />
        )}
        {disabledWalletModal.show && (
          <ActivateYourAccountModal
            show={disabledWalletModal.show}
            handleClose={this.hideDisabledWalletModal}
          />
        )}
        <UserDashboard
          {...this.props}
          activationMessage={activationMessage}
          dismissActivationMessage={this.dismissActivationMessage}
          activeCurrency={activeCurrency}
          changeActiveCurrency={this.changeActiveCurrency}
          showWalletModal={this.showWalletModal}
          showDisabledWalletModal={this.showDisabledWalletModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserWallets,
      getUserSelectedWalletDetails,
      regenerateUserSelectedWalletAddress,
      clearUserSelectedWalletDetails,
      getUserInvestedProjects,
      getUserCommitedProjects,
      retrieveAccessTokenFromStorageAndGetUserInformation
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDashboardContainer));
