import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { Formik } from 'formik';
import * as _ from 'lodash';

import GlobalLayout from 'Layouts/Global.layout';

import { Container, Text, FormElements, Button, Modal, Icon } from 'UILib';
import {
  genericComponentTimer,
  transformBalance,
  hasValidationError,
  getErrorContent
} from 'Utils/Helpers/component.helpers';
import { WITHDRAW_TYPE } from 'Constants/Withdraw/Withdraw.constants';

import 'Components/Withdraw/withdraw.style.scss';

const initialValues = {
  walletAddress: '',
  amount: '',
  terms_of_service_accept: false
};

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmationModalVisible: false,
      isConfirmationGiven: false,
      walletAddress: null,
      amount: null
    };
  }

  componentDidMount() {
    this.getUserWallets();
    this.getUserWalletsTimer = genericComponentTimer(
      this.getUserWallets,
      30000
    );
  }

  componentWillUnmount() {
    clearInterval(this.getUserWalletsTimer);
  }

  getUserWallets = () => {
    this.props.getUserWallets(this.props.account.access_token);
  };

  getWalletBasedOnSelectedCurrency = (
    wallets,
    walletCurrency,
    activeCurrency
  ) => {
    return _.get(
      _.find(wallets, ['attributes.currency', walletCurrency]),
      `attributes.${activeCurrency}_balance`,
      0
    );
  };

  showConfirmationModal = () => {
    this.setState({ isConfirmationModalVisible: true });
  };

  hideConfirmationModal = () => {
    this.setState({ isConfirmationModalVisible: false });
  };

  render() {
    const definedClientCodes = [480, 423];

    const balance = this.getWalletBasedOnSelectedCurrency(
      this.props.account.user_wallets.wallets,
      this.props.withdraw.type,
      'raw'
    );
    const [integer, float] = transformBalance(
      balance,
      this.props.withdraw.type !== WITHDRAW_TYPE.USDC ? 8 : 2
    );
    return (
      <React.Fragment>
        {this.state.isConfirmationModalVisible && (
          <Modal
            closeIcon
            show={this.state.isConfirmationModalVisible}
            handleClose={() => this.hideConfirmationModal()}
          >
            <div className="confirmation-modal">
              <Text
                color="light-grey"
                content="withdraw.create.confirmation.header"
                translation
                className="confirmation-modal-header"
              />
              <Text
                color="light-grey"
                content="withdraw.create.confirmation.subheader"
                translation
                className="confirmation-modal-subheader"
              />
              <Icon
                base="regular"
                icon={{ name: 'question-circle' }}
                className="confirmation-modal-icon"
              />
              <div className="confirmation-actions">
                <Button
                  color="orange-3"
                  bgColor="white"
                  borderColor="orange-3"
                  content="withdraw.create.confirmation.no"
                  translation
                  onClick={() => {
                    this.hideConfirmationModal();
                  }}
                  className="button"
                />
                <Button
                  color="white"
                  bgColor="orange-3"
                  borderColor="orange-3"
                  content="withdraw.create.confirmation.yes"
                  translation
                  onClick={() => {
                    this.props.createWithdraw(
                      this.props.account.access_token,
                      this.state.walletAddress,
                      this.props.withdraw.type,
                      this.state.amount
                    );
                  }}
                  className="button"
                />
              </div>
            </div>
          </Modal>
        )}
        <Container type="page" bgColor="grey-0">
          <GlobalLayout>
            <div className="withdraw-create-wrapper">
              <Text
                type="h1"
                textAlign="left"
                content="withdraw.create.header"
                translation
                className="withdraw-header"
              />
              <Text
                type="intro"
                textAlign="left"
                content="withdraw.create.subheader"
                translation
                className="withdraw-text"
              />
              <div className="withdraw-zone">
                <div className="wallet-selection-zone">
                  <div className="wallet-selection">
                    <Text
                      type="h2"
                      textAlign="left"
                      content="withdraw.create.form.header"
                      translation
                      className="header"
                    />
                    <Text
                      type="intro"
                      textAlign="left"
                      content="withdraw.create.form.subheader"
                      translation
                      className="subheader"
                    />
                    <div className="wallet-selector">
                      {_.keys(WITHDRAW_TYPE).map((type, idx) => {
                        return (
                          <div
                            key={idx}
                            className={`wallet-selector-item ${this.props
                              .withdraw.type === WITHDRAW_TYPE[type] &&
                              'selected-wallet'}`}
                            onClick={() => {
                              this.props.changeActiveCurrencyToWithdraw(
                                WITHDRAW_TYPE[type]
                              );
                            }}
                          >
                            <Translate
                              id={`withdraw.create.form.wallet.${type}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="wallet-details">
                    <div className="wallet">
                      <Translate>
                        {({ translate }) => (
                          <div className="header-zone">
                            <div className="header">
                              {translate(
                                `userDashboard.walletPane.${this.props.withdraw.type.toLowerCase()}.header`
                              )}
                            </div>
                            <div className="subheader">
                              {translate(
                                `userDashboard.walletPane.${this.props.withdraw.type.toLowerCase()}.subheader`
                              )}
                            </div>
                          </div>
                        )}
                      </Translate>
                      <div className="balance-zone">
                        <div>
                          <span className="integer">{integer}</span>
                          <span className="float">{float}</span>
                          <span className="currency">
                            {this.props.withdraw.type}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="withdraw-form-zone">
                  <Formik
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={this.props.WithdrawValidationSchema}
                    onSubmit={(values, { submitForm, setSubmitting }) => {
                      this.showConfirmationModal();
                      this.setState({
                        walletAddress: values.walletAddress,
                        amount: values.amount
                      });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="form-container">
                            <div className="wallet-address">
                              <Text
                                type="intro"
                                textAlign="left"
                                content="withdraw.create.form.walletAddress"
                                translation
                                className="input-text"
                              />
                              <input
                                name="walletAddress"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`input ${errors.walletAddress &&
                                  touched.walletAddress &&
                                  'error-input'}`}
                              />
                            </div>
                            <div className="amount">
                              <Text
                                type="intro"
                                textAlign="left"
                                content="withdraw.create.form.amount"
                                translation
                                className="input-text"
                              />
                              <input
                                name="amount"
                                type="number"
                                step="any"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                min="0"
                                className={`input ${errors.amount &&
                                  touched.amount &&
                                  'error-input'}`}
                              />
                            </div>
                            {hasValidationError(errors) ||
                            this.props.withdraw.error ? (
                              <div className="error">
                                <FormElements.ValidationErrors
                                  content={getErrorContent(
                                    this.props.withdraw.error,
                                    errors,
                                    'withdraw.create.validation_error',
                                    'withdraw.create.failure.errors',
                                    definedClientCodes
                                  )}
                                  translation
                                />
                              </div>
                            ) : null}
                            <FormElements.Checkbox
                              name="terms_of_service_accept"
                              error={
                                errors.terms_of_service_accept &&
                                touched.terms_of_service_accept
                              }
                              checked={values.terms_of_service_accept}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              label="withdraw.create.form.toc"
                              translation
                              className="toc"
                            />
                            <Button
                              bgColor="orange-3"
                              borderColor="orange-3"
                              color="white"
                              type="submit"
                              content="withdraw.create.form.action"
                              translation
                              className="action"
                            />
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </GlobalLayout>
        </Container>
      </React.Fragment>
    );
  }
}

export default Create;
