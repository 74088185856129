export const phoneActivation = {
  start: {
    header: 'Starting Phone Activation Process'
  },
  add: {
    header: 'One More Step for Account Verification',
    description:
      "We'll send a 6-digit one time password to the phone number that you'll provide to verify your account.",
    phone_number_placeholder: 'Phone Number',
    phone_number_is_not_valid:
      'The given phone number appears to be not valid. Please provide a valid phone number.',
    phone_number_is_taken:
      'The given phone number seems to be taken. Please provide a new and valid phone number.',
    submit: 'Send Code',
    footer: {
      cta: 'Return to Homepage'
    }
  },
  confirm: {
    header: 'Please use the code that we sent to your phone number.',
    description:
      'Please enter the 6-digit one time password to validate your phone number.',
    loading: 'Loading',
    submit: 'Verify',
    footer: {
      cta: 'Return to Homepage'
    }
  },
  success: {
    header: "Thanks! You've successfully verified your account.",
    description:
      'Now, you can easily deposit money to your account and submit commitments for listed projects.',
    footer: {
      cta: 'Back to Home'
    }
  },
  failure: {
    header: 'Sorry, we couldn’t verify your phone number.',
    description:
      'This may have happened due to a mismatch of the one time password that we sent to your phone. Please, try to verify your account again.',
    footer: {
      cta: 'Back to Home'
    }
  }
};
