import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from 'Reducers';
import rootSaga from 'Sagas';

const initialState = {};
const enhancers = [];
const middlewares = [];

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

middlewares.push(routerMiddleware(history));
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV === 'development') {
  /**
   * // INFO
   * Development tool injections
   *
   * Implementing Redux-devtools to enhancers
   * This tool utilizes browser extension (please refer to https://github.com/reduxjs/redux-devtools)
   *
   * Implementing Redux-Logger to middlewares
   * This tool listens and reports store state changes (please refer to https://github.com/LogRocket/redux-logger)
   */
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  typeof devToolsExtension === 'function' &&
    enhancers.push(devToolsExtension());

  middlewares.push(logger);
}

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
);

sagaMiddleware.run(rootSaga);

export default store;
