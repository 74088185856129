import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { Navbar, Container, Button, Icon } from 'UILib';

import { logOutUser } from 'Actions/Account/Account.actions';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import './Navbar.scss';

const voultLogo = require('Assets/Logo/voult-logo.svg');

class NavbarWithNoLoggedIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileNavbarOpenState: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  _handleClickOutside = (event) => {
    if (this.menuNode && !this.menuNode.contains(event.target)) {
      this.setState({ mobileNavbarOpenState: false });
    }
  };

  toggleMobileNavbarOpenState = () => {
    this.setState({ mobileNavbarOpenState: !this.state.mobileNavbarOpenState });
  };

  render() {
    return (
      <React.Fragment>
        <Container className="navbar-region">
          <Button
            content="Home"
            color="light-grey"
            bgColor="white"
            borderColor="white"
            onClick={() => {
              window.location.href = process.env.REACT_APP_LANDING_PAGE;
            }}
          />
          <Link to={APP_ROUTES.PROJECT_LISTING}>
            <Button
              content="Projects"
              color="light-grey"
              bgColor="white"
              borderColor="white"
              className="margin-right"
            />
          </Link>
          <Link to={APP_ROUTES.LOGIN}>
            <Button
              bgColor="white"
              borderColor="orange-3"
              color="orange-3"
              content="Login"
              className="margin-right"
            />
          </Link>
          <Link to={APP_ROUTES.REGISTER}>
            <Button
              bgColor="orange-3"
              borderColor="orange-3"
              color="white"
              content="Sign Up"
            />
          </Link>
        </Container>
        <Container
          className={`mobile-navbar-region ${this.state.mobileNavbarOpenState &&
            'overlay'}`}
        >
          <React.Fragment>
            <div
              className="menu-button"
              onClick={() => this.toggleMobileNavbarOpenState()}
            >
              <Icon
                base="solid"
                icon={{ name: 'bars', color: 'light-grey' }}
                className="icon"
              />
            </div>
            <div
              className={`menu-zone ${
                this.state.mobileNavbarOpenState
                  ? 'display entrance'
                  : 'display-none'
              }`}
              ref={(r) => (this.menuNode = r)}
            >
              <div
                className="menu-button"
                onClick={() => this.toggleMobileNavbarOpenState()}
              >
                <Icon
                  base="solid"
                  icon={{ name: 'bars', color: 'light-grey' }}
                  className="icon"
                />
              </div>
              <Button
                content="Home"
                color="light-grey"
                bgColor="white"
                borderColor="white"
                className="full-width"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_LANDING_PAGE;
                }}
              />
              <Link to={APP_ROUTES.PROJECT_LISTING}>
                <Button
                  content="Projects"
                  color="light-grey"
                  bgColor="white"
                  borderColor="white"
                  className="full-width"
                />
              </Link>
              <Link to={APP_ROUTES.LOGIN}>
                <Button
                  bgColor="white"
                  borderColor="orange-3"
                  color="orange-3"
                  content="Login"
                  className="full-width"
                />
              </Link>
              <Link to={APP_ROUTES.REGISTER}>
                <Button
                  bgColor="orange-3"
                  borderColor="orange-3"
                  color="white"
                  content="Sign Up"
                  className="full-width"
                />
              </Link>
            </div>
          </React.Fragment>
        </Container>
      </React.Fragment>
    );
  }
}

class NavbarWithLoggedIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileNavbarOpenState: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  _handleClickOutside = (event) => {
    if (this.menuNode && !this.menuNode.contains(event.target)) {
      this.setState({ mobileNavbarOpenState: false });
    }
  };

  toggleMobileNavbarOpenState = () => {
    this.setState({ mobileNavbarOpenState: !this.state.mobileNavbarOpenState });
  };

  render() {
    const { account } = this.props;
    return (
      <React.Fragment>
        <Container className="navbar-region">
          <Button
            content="Home"
            color="light-grey"
            bgColor="white"
            borderColor="white"
            onClick={() => {
              window.location.href = process.env.REACT_APP_LANDING_PAGE;
            }}
          />
          <Link to={APP_ROUTES.PROJECT_LISTING}>
            <Button
              content="Projects"
              color="light-grey"
              bgColor="white"
              borderColor="white"
              className="margin-right"
            />
          </Link>
          <Link to={APP_ROUTES.DASHBOARD}>
            <Button
              bgColor="white"
              borderColor="orange-3"
              color="orange-3"
              content="Dashboard"
              className="margin-right"
            />
          </Link>
          <Button
            bgColor="orange-3"
            borderColor="orange-3"
            color="white"
            content="Log Out"
            onClick={() => {
              this.props.logOutUser(account.access_token);
            }}
          />
        </Container>
        <Container
          className={`mobile-navbar-region ${this.state.mobileNavbarOpenState &&
            'overlay'}`}
        >
          <React.Fragment>
            <div
              className="menu-button"
              onClick={() => this.toggleMobileNavbarOpenState()}
            >
              <Icon
                base="solid"
                icon={{ name: 'bars', color: 'light-grey' }}
                className="icon"
              />
            </div>
            <div
              className={`menu-zone ${
                this.state.mobileNavbarOpenState
                  ? 'display entrance'
                  : 'display-none'
              }`}
              ref={(r) => (this.menuNode = r)}
            >
              <div
                className="menu-button"
                onClick={() => this.toggleMobileNavbarOpenState()}
              >
                <Icon
                  base="solid"
                  icon={{ name: 'bars', color: 'light-grey' }}
                  className="icon"
                />
              </div>
              <Button
                content="Home"
                color="light-grey"
                bgColor="white"
                borderColor="white"
                className="full-width"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_LANDING_PAGE;
                }}
              />
              <Link to={APP_ROUTES.PROJECT_LISTING}>
                <Button
                  content="Projects"
                  color="light-grey"
                  bgColor="white"
                  borderColor="white"
                  className="full-width"
                />
              </Link>
              <Link to={APP_ROUTES.DASHBOARD}>
                <Button
                  bgColor="white"
                  borderColor="orange-3"
                  color="orange-3"
                  content="Dashboard"
                  className="full-width"
                />
              </Link>
              <Button
                bgColor="orange-3"
                borderColor="orange-3"
                color="white"
                content="Log Out"
                className="full-width"
                onClick={() => {
                  this.props.logOutUser(account.access_token);
                }}
              />
            </div>
          </React.Fragment>
        </Container>
      </React.Fragment>
    );
  }
}

class NavbarContainer extends Component {
  render() {
    const { account } = this.props;
    return (
      <Navbar type="fixed-top">
        <Container className="navbar-container">
          <div
            onClick={() => {
              window.location.href = process.env.REACT_APP_LANDING_PAGE;
            }}
            className="logo-wrapper"
          >
            <img src={voultLogo} alt="voult-logo" className="logo" />
          </div>
          {account.access_token ? (
            <NavbarWithLoggedIn
              account={account}
              logOutUser={this.props.logOutUser}
            />
          ) : (
            <NavbarWithNoLoggedIn
              account={account}
              logOutUser={this.props.logOutUser}
            />
          )}
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logOutUser
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarContainer);
