import { all, put, takeLatest } from 'redux-saga/effects';
import apiGenerator from 'Utils/Helpers/api.helpers';
import {
  getStatusCodeFamily,
  apiErrorHandler,
  sagaDelayer
} from 'Utils/Helpers/saga.helpers';

import {
  STATUS_TYPE,
  API_ENDPOINTS
} from 'Constants/GlobalConstants/Global.constants';
import * as PhoneActivationConstants from 'Constants/Account/PhoneActivation.constants';

function* phoneActivationFlowStartSaga(action) {
  const { access_token } = action.payload;
  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.PHONE_ACTIVATION.START
  );
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PhoneActivationConstants.PHONE_ACTIVATION_START_SUCCESS,
        payload: { ...response.data, access_token: access_token }
      });
    }
  } catch (err) {
    yield put({
      type: PhoneActivationConstants.PHONE_ACTIVATION_START_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* phoneActivationFlowAddSaga(action) {
  const { access_token, action_token, phone_number } = action.payload;

  const phone = `${phone_number}`;
  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.PHONE_ACTIVATION.ADD,
    { phone, action_token }
  );
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PhoneActivationConstants.PHONE_ACTIVATION_ADD_SUCCESS,
        payload: { ...response.data, phone }
      });
    }
  } catch (err) {
    yield put({
      type: PhoneActivationConstants.PHONE_ACTIVATION_ADD_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* phoneActivationFlowConfirmSaga(action) {
  const { access_token, action_token, otp_code } = action.payload;

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.PHONE_ACTIVATION.CONFIRM,
    { otp_code, action_token }
  );
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PhoneActivationConstants.PHONE_ACTIVATION_CONFIRM_SUCCESS,
        payload: { ...response.data }
      });
    }
  } catch (err) {
    yield put({
      type: PhoneActivationConstants.PHONE_ACTIVATION_CONFIRM_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      PhoneActivationConstants.PHONE_ACTIVATION_START_PENDING,
      phoneActivationFlowStartSaga
    ),
    takeLatest(
      PhoneActivationConstants.PHONE_ACTIVATION_ADD_PENDING,
      phoneActivationFlowAddSaga
    ),
    takeLatest(
      PhoneActivationConstants.PHONE_ACTIVATION_CONFIRM_PENDING,
      phoneActivationFlowConfirmSaga
    )
  ]);
}
