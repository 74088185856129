import React from 'react';
import moment from 'moment';
import * as _ from 'lodash';

import { Text } from 'UILib';
import { transformBalance } from 'Utils/Helpers/component.helpers';

const getUSDCWallet = (wallets) => {
  return _.filter(wallets, (wallet) => {
    return wallet.attributes.currency === 'USDC';
  })[0];
};

const calculation = (value, properties) => {
  const annual_revenue = parseInt(
    properties.financial.compensation_details.annual_revenue,
    10
  );
  const offer_percentage =
    parseInt(
      properties.contribution.compensation_details.offer_percentage,
      10
    ) / 100;

  const amount_to_be_raised = parseInt(
    properties.contribution.compensation_details.amount_to_be_raised,
    10
  );

  return (
    ((annual_revenue * offer_percentage * value) / amount_to_be_raised) * 10
  );
};

const ResultPane = (props) => {
  return (
    <div className="result-pane">
      <div className="result-information-zone">
        <div className="information-line">
          <div className="main-information">
            <Text
              content="investment.confirmInformation.amount"
              translation
              color="light-grey"
              className="subheader"
            />
            <Text
              content={`${transformBalance(
                props.customInvestmentAmount.toBeRaised,
                0
              )} USD`}
              color="light-grey"
              className="header"
            />
          </div>
          <div className="main-information">
            <Text
              content="investment.confirmInformation.shares"
              translation
              color="light-grey"
              className="subheader"
            />
            <Text
              content={`${transformBalance(
                props.customInvestmentAmount.sharesToBuy,
                0
              )} Shares`}
              color="light-grey"
              className="header"
            />
          </div>
          <div className="main-information pull-left-information">
            <Text
              content="investment.calculation.estimated"
              translation
              color="light-grey"
              className="subheader"
            />
            <Text
              content={`${transformBalance(
                calculation(
                  props.selectedInvestmentAmount,
                  props.projectDetail.attributes.properties
                ),
                0
              )} USD`}
              color="orange-3"
              className="header"
            />
          </div>
        </div>
        <div className="information-line">
          <div className="supplementary-information">
            <Text
              content="investment.confirmInformation.date"
              translation
              color="grey-3"
              className="subheader"
            />
            <Text
              content={moment(props.investment.datetime).format(
                'DD.MM.YYYY HH:mm'
              )}
              color="light-grey"
              className="header"
            />
          </div>
        </div>
        <div className="information-line">
          <div className="supplementary-information">
            <Text
              content="investment.confirmInformation.currentBalance"
              translation
              color="grey-3"
              className="subheader"
            />
            <Text
              content={`${
                getUSDCWallet(props.account.user_wallets.wallets).attributes
                  .usd_balance
              } USD`}
              color="light-grey"
              className="header"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultPane;
