import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Commitment from 'Components/Commitment/Commitment.component';
import WalletDepositModal from 'Components/Account/UserDashboard/Components/WalletDepositModal.component';

import { Modal, Button, Text, Icon } from 'UILib';
import {
  getUserWallets,
  getUserSelectedWalletDetails,
  regenerateUserSelectedWalletAddress,
  clearUserSelectedWalletDetails
} from 'Actions/Account/UserDashboard.actions';
import {
  resetCommitmentToProject,
  commitmentToProject
} from 'Actions/Commitment/Commitment.actions';
import {
  saveRedirectRoute,
  removeRedirectRoute
} from 'Actions/RouteExtender/RouteExtender.actions';
import { genericComponentTimer } from 'Utils/Helpers/component.helpers';

class CommitmentContainer extends Component {
  constructor(props) {
    super(props);
    this.props.removeRedirectRoute();

    this.state = {
      requireLogin: this.determineLoginRequirement(props),
      activeCurrency: 'usd',
      walletModal: {
        show: false,
        selectedCurrency: null
      },
      isConfirmationModalVisible: false,
      isConfirmationGiven: false,
      selectedCommitmentAmount: '50000'
    };
  }

  componentDidMount() {
    this.props.resetCommitmentToProject();
    this.getUserWallets();
    this.getUserWalletsTimer = genericComponentTimer(
      this.getUserWallets,
      30000
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.commitment.status !== this.props.commitment.status) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    clearInterval(this.getUserWalletsTimer);
  }

  getUserWallets = () => {
    if (
      this.props.account.access_token !== null &&
      this.props.account.user_informations.client_code === 200
    ) {
      this.props.getUserWallets(this.props.account.access_token);
    }
  };

  determineLoginRequirement = (props) => {
    return (
      props.account.access_token === null &&
      props.account.user_informations.client_code !== 200
    );
  };

  changeActiveCurrency = (currency) => {
    this.setState({ activeCurrency: currency });
  };

  showWalletModal = (wallet) => {
    this.setState((prevState) => ({
      ...prevState,
      walletModal: {
        ...prevState.walletModal,
        show: true,
        selectedCurrency: wallet
      }
    }));
  };

  hideWalletModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      walletModal: {
        ...prevState.walletModal,
        show: false,
        selectedCurrency: null
      }
    }));
  };

  showConfirmationModal = () => {
    this.setState({ isConfirmationModalVisible: true });
  };

  hideConfirmationModal = () => {
    this.setState({ isConfirmationModalVisible: false });
  };

  changeSelectedCommitmentAmount = (amount) => {
    this.setState({
      selectedCommitmentAmount: amount
    });
  };

  handleCommitmentToProjects = () => {
    this.props.commitmentToProject(
      this.props.projectDetail.projectId,
      this.props.account.access_token,
      this.state.selectedCommitmentAmount !== 'later'
        ? parseInt(this.state.selectedCommitmentAmount, 10)
        : 0
    );
    this.hideConfirmationModal();
  };

  render() {
    const {
      requireLogin,
      activeCurrency,
      walletModal,
      selectedCommitmentAmount
    } = this.state;
    const {
      access_token,
      user_wallet_detail,
      user_wallets
    } = this.props.account;
    return (
      <React.Fragment>
        {this.state.isConfirmationModalVisible && (
          <Modal
            closeIcon
            show={this.state.isConfirmationModalVisible}
            handleClose={() => this.hideConfirmationModal()}
          >
            <div className="confirmation-modal">
              <Text
                color="light-grey"
                content="commitment.commitmentPane.confirmation.header"
                translation
                className="confirmation-modal-header"
              />
              <Text
                color="light-grey"
                content="commitment.commitmentPane.confirmation.subheader"
                translation
                className="confirmation-modal-subheader"
              />
              <Icon
                base="regular"
                icon={{ name: 'question-circle' }}
                className="confirmation-modal-icon"
              />
              <div className="confirmation-actions">
                <Button
                  color="orange-3"
                  bgColor="white"
                  borderColor="orange-3"
                  content="commitment.commitmentPane.confirmation.no"
                  translation
                  onClick={() => {
                    this.hideConfirmationModal();
                  }}
                  className="button"
                />
                <Button
                  color="white"
                  bgColor="orange-3"
                  borderColor="orange-3"
                  content="commitment.commitmentPane.confirmation.yes"
                  translation
                  onClick={() => {
                    this.handleCommitmentToProjects();
                  }}
                  className="button"
                />
              </div>
            </div>
          </Modal>
        )}

        {walletModal.show && (
          <WalletDepositModal
            show={walletModal.show}
            selectedCurrency={walletModal.selectedCurrency}
            access_token={access_token}
            user_wallet_detail={user_wallet_detail}
            user_wallets={user_wallets}
            handleClose={this.hideWalletModal}
            getUserSelectedWalletDetails={
              this.props.getUserSelectedWalletDetails
            }
            regenerateUserSelectedWalletAddress={
              this.props.regenerateUserSelectedWalletAddress
            }
            clearUserSelectedWalletDetails={
              this.props.clearUserSelectedWalletDetails
            }
          />
        )}
        <Commitment
          {...this.props}
          requireLogin={requireLogin}
          activeCurrency={activeCurrency}
          selectedCommitmentAmount={selectedCommitmentAmount}
          changeActiveCurrency={this.changeActiveCurrency}
          showWalletModal={this.showWalletModal}
          showConfirmationModal={this.showConfirmationModal}
          changeSelectedCommitmentAmount={this.changeSelectedCommitmentAmount}
          handleCommitmentToProjects={this.handleCommitmentToProjects}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectDetail: state.projectDetail,
    account: state.account,
    commitment: state.commitment
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserWallets,
      getUserSelectedWalletDetails,
      regenerateUserSelectedWalletAddress,
      clearUserSelectedWalletDetails,
      resetCommitmentToProject,
      commitmentToProject,
      saveRedirectRoute,
      removeRedirectRoute
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommitmentContainer);
