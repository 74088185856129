import * as InitConstants from 'Constants/AppInit/init.constants';

import { retrieveFromLocalStorage } from 'Utils/Helpers/localstorage.helpers';

export function retrieveAccessTokenFromStorageAndGetUserInformation() {
  const access_token = retrieveFromLocalStorage('access_token');

  if (access_token) {
    return {
      type: InitConstants.RETRIEVE_USER_INFORMATION_ON_INIT_PENDING,
      payload: { access_token }
    };
  }

  return {
    type: InitConstants.NO_ACCESS_TOKEN_PRESENT_ON_INIT
  };
}
