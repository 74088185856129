import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';

import FileZone from 'Components/ProjectDetail/Components/FileZone.component';
import TabDataColumn from 'Components/ProjectDetail/Components/TabDataColumn.component';
import MobileTabDataColumn from 'Components/ProjectDetail/Components/MobileTabDataColumn.component';

import { Text, Tab, Bar, Button } from 'UILib';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import {
  scrollElementIntoView,
  isItActiveSection
} from 'Utils/Helpers/component.helpers';

import 'Components/ProjectDetail/projectDetail.style.scss';

const priceChartLabel = ['2015', '2016', '2017', '2018'];

const priceChartData = [
  {
    fill: true,
    lineTension: 0.1,
    backgroundColor: 'rgba(0,51,255,0.4)',
    borderColor: 'rgba(0,51,255,1)',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: 'rgba(0,51,255,1)',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 5,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(255,255,255,1)',
    pointHoverBorderColor: 'rgba(0,51,255,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: [90, 59, 80, 81, 56, 55, 40]
  }
];

const priceChartOptions = {
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          drawOnChartArea: false
        }
      }
    ]
  }
};
defaults.global.defaultColor = '#000000';
defaults.global.defaultFontColor = '#b0bac9';
defaults.global.defaultFontFamily = 'Rajdhani';

class MarketDetails extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (isItActiveSection(prevProps, this.props, 'market')) {
      scrollElementIntoView(this.projectMarketElement);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="market-details-zone"
          ref={(el) => {
            this.projectMarketElement = el;
          }}
        >
          <Text
            type="h1"
            color="black"
            content="projectDetail.marketDetails.header"
            translation
            transform="toUpper"
            className="header"
          />
          <Tab defaultIndex="1" className="market-tabs">
            <Tab.TabItem
              label="projectDetail.marketDetails.labels.market_index"
              translation
              index="1"
            >
              <div className="market-tab-content-wrapper">
                <div className="you-should-know-column">
                  <Text
                    type="intro"
                    content="projectDetail.marketDetails.youShouldKnow.header"
                    translation
                    className="header"
                  />
                  <Text
                    type="intro"
                    content={this.props.market.description.multiline}
                    className="content"
                  />
                </div>
                <div className="market-index-chart-area">
                  <Text
                    content="Market Index"
                    className="market-index-chart-title"
                  />
                  <div className="market-index-chart-wrapper">
                    <Bar.Horizontal
                      barLabel="1st Market - UK"
                      size={80} // must be in % unit
                      base={20} // must be in px unit
                      colors={['#999999', '#cccccc', '#eaeaea']}
                    />
                    <Bar.Horizontal
                      barLabel="2st Market - SPAIN"
                      size={50} // must be in % unit
                      base={20} // must be in px unit
                      colors={['#0033ff', '#3366ff', '#6699ff']}
                    />
                    <Bar.Horizontal
                      barLabel="3st Market - Germany"
                      size={20} // must be in % unit
                      base={20} // must be in px unit
                      colors={['#999999', '#cccccc', '#eaeaea']}
                    />
                  </div>
                </div>
              </div>
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.marketDetails.labels.price"
              translation
              index="2"
            >
              <div className="market-tab-content-wrapper">
                <TabDataColumn
                  data={this.props.market.details}
                  family="marketDetails"
                  from="price"
                />

                <div className="chart-area">
                  <Line
                    data={{ labels: priceChartLabel, datasets: priceChartData }}
                    options={priceChartOptions}
                  />
                </div>
              </div>
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.marketDetails.labels.investment"
              translation
              index="3"
            >
              <div className="market-tab-content-wrapper">
                <TabDataColumn
                  data={{
                    ...this.props.market.capacity,
                    ...this.props.market.taxation
                  }}
                  family="marketDetails"
                  from="investment"
                />

                <div className="investment-chart-area">
                  <Bar.Vertical
                    barLabel="2016"
                    size={30} // must be in % unit
                    base={60} // must be in px unit
                    colors={['#0033ff', '#3366ff', '#6699ff']}
                  />
                  <Bar.Vertical
                    barLabel="2017"
                    size={53} // must be in % unit
                    base={60} // must be in px unit
                    colors={['#0033ff', '#3366ff', '#6699ff']}
                  />
                  <Bar.Vertical
                    barLabel="2018"
                    size={77} // must be in % unit
                    base={60} // must be in px unit
                    colors={['#0033ff', '#3366ff', '#6699ff']}
                  />
                </div>
              </div>
            </Tab.TabItem>
          </Tab>
          {this.props.market.files && this.props.market.files.length > 0 && (
            <FileZone files={this.props.market.files} />
          )}
        </div>
        <div className="mobile-market-details-zone">
          <Text
            type="h1"
            color="black"
            content="projectDetail.marketDetails.header"
            translation
            transform="toUpper"
            className="header"
          />
          <Tab defaultIndex="1">
            <Tab.TabItem
              label="projectDetail.marketDetails.labels.market_index"
              translation
              index="1"
            >
              <div className="market-index-wrapper">
                <Text
                  content="Market Index"
                  className="market-index-chart-title"
                />
                <div className="market-index-chart-wrapper">
                  <Bar.Horizontal
                    barLabel="1st Market - UK"
                    size={50} // must be in % unit
                    base={20} // must be in px unit
                    colors={['#999999', '#cccccc', '#eaeaea']}
                  />
                  <Bar.Horizontal
                    barLabel="2st Market - SPAIN"
                    size={40} // must be in % unit
                    base={20} // must be in px unit
                    colors={['#0033ff', '#3366ff', '#6699ff']}
                  />
                  <Bar.Horizontal
                    barLabel="3st Market - Germany"
                    size={20} // must be in % unit
                    base={20} // must be in px unit
                    colors={['#999999', '#cccccc', '#eaeaea']}
                  />
                </div>
              </div>
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.marketDetails.labels.price"
              translation
              index="2"
            >
              <MobileTabDataColumn
                data={this.props.market.details}
                family="marketDetails"
                from="price"
              />
              <div className="chart-area">
                <Line
                  data={{ labels: priceChartLabel, datasets: priceChartData }}
                  options={priceChartOptions}
                />
              </div>
            </Tab.TabItem>
            <Tab.TabItem
              label="projectDetail.marketDetails.labels.investment"
              translation
              index="3"
            >
              <MobileTabDataColumn
                data={{
                  ...this.props.market.capacity,
                  ...this.props.market.taxation
                }}
                family="marketDetails"
                from="investment"
              />
            </Tab.TabItem>
          </Tab>
          {this.props.market.files && this.props.market.files.length > 0 && (
            <FileZone files={this.props.market.files} />
          )}
          <Button
            content={
              this.props.investment_status
                ? 'projectDetail.heroZone.cta.button'
                : 'projectDetail.heroZone.cta.commitment'
            }
            translation
            bgColor="green-4"
            borderColor="green-4"
            color="white"
            className="button"
            onClick={() => {
              this.props.history.push(
                this.props.investment_status
                  ? APP_ROUTES.INVESTMENT_WITH_ID(this.props.statistics.id)
                  : APP_ROUTES.COMMITMENT_WITH_ID(this.props.statistics.id)
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default MarketDetails;
