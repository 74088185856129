import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import GlobalLayout from 'Layouts/Global.layout';
import { Container, Card, Text, Button, FormElements } from 'UILib';
import { hasValidationError } from 'Utils/Helpers/component.helpers';
import {
  REQUEST_STATUS,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';

const initialValues = {
  national_id: '',
  date_of_birth: ''
};

class VerifyUserInformation extends Component {
  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <Card type="auth" centered className="custom-form-zone">
            <Text
              type="h1"
              content="resetPassword.verify_user_information.header"
              translation
              className="verify-user-info-header"
            />
            <Text
              type="intro"
              content="resetPassword.verify_user_information.description"
              className="verify-user-info-description"
              translation
            />
            <Container className="custom-form-container">
              <Card.Content>
                <Formik
                  initialValues={initialValues}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={
                    this.props
                      .ResetPasswordVerifyUserInformationValidationSchema
                  }
                  onSubmit={(values, { submit, setSubmitting }) => {
                    const { email, action_token } = this.props.resetPassword;
                    this.props.resetPasswordFlowVerifyUserInformation(
                      email,
                      action_token,
                      values.national_id,
                      values.date_of_birth
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Container className="form-container">
                        <FormElements.Input
                          type="text"
                          name="national_id"
                          error={errors.national_id && touched.national_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="resetPassword.verify_user_information.national_id_placeholder"
                          translation
                        />
                        <FormElements.Input
                          type="text"
                          name="date_of_birth"
                          error={errors.date_of_birth && touched.date_of_birth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="resetPassword.verify_user_information.date_of_birth_placeholder"
                          translation
                        />
                        {hasValidationError(errors) && (
                          <Container className="error-container">
                            <FormElements.ValidationErrors
                              content="resetPassword.verify_user_information.validation_errors"
                              translation
                            />
                          </Container>
                        )}
                        <Container className="submit-container">
                          <Button
                            bgColor="orange-3"
                            borderColor="orange-3"
                            color="white"
                            type="Submit"
                            content={
                              this.props.resetPassword.status === REQUEST_STATUS
                                ? 'resetPassword.verify_user_information.loading'
                                : 'resetPassword.verify_user_information.submit'
                            }
                            translation
                            className="verify-user-information-action"
                          />
                        </Container>
                      </Container>
                    </form>
                  )}
                </Formik>
              </Card.Content>
            </Container>
            <Text type="intro" className="bottom-section">
              <Translate id="resetPassword.verify_user_information.footer.content" />{' '}
              <Link to={APP_ROUTES.LOGIN}>
                <Translate id="resetPassword.verify_user_information.footer.cta" />
              </Link>
            </Text>
          </Card>
        </GlobalLayout>
      </Container>
    );
  }
}

export default VerifyUserInformation;
