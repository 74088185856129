export const investment = {
  header: {
    viewer_count: 'Viewers',
    backer_count: 'Backers'
  },
  confirmation: {
    header: 'Are you sure?',
    subheader:
      'You are making an investment to this project, right now. Please be sure that this is what you are intending.',
    yes: 'YES',
    no: 'NO'
  },
  requireLogin: {
    header: 'You need to login to invest.',
    subheader:
      'If you have an existing voult account please log in, or create a new account and take your place in energy investments.',
    login: 'Login',
    signup: 'Sign Up'
  },
  investmentHeader: {
    amountToBeRaised: 'Amount to be Raised',
    raisedCapital: 'Raised Capital',
    totalShares: 'Total Shares'
  },
  timer: {
    complete: 'COMPLETE YOUR ORDER'
  },
  investmentPane: {
    preferred: 'RECOMMENDED',
    decide: 'Decide',
    later: 'Later',
    usdc: 'USDC'
  },
  calculation: {
    investment: 'Investment Amount',
    shares: 'Shares to Buy',
    estimated: 'Estimated Total Income'
  },
  action: 'Buy Shares',
  actionDashboard: 'Dashboard',
  actionTryAgain: 'Try Again',
  success: {
    header: 'Congrats! You completed your invesment.',
    subheader:
      'You bought ${shares} shares from Barcelona Solar Power Plant for ${amount} USD. You can find the details of your investment below and monitor your earnings from your dashboard.'
  },
  failure: {
    header: 'Sorry, your investment couldn’t completed.',
    subheader:
      'Your investment couldn’t completed due to some reasons. Please check your wallet balance and investment details below and try again a while later.'
  },
  confirmInformation: {
    amount: 'Investment Amount',
    shares: 'Shares to Buy',
    date: 'Date & Time',
    currentBalance: 'Current USDC Balance',
    afterBalance: 'After Investment USDC Balance',
    aggrements: {
      investment: "I've aggre the Investment Conditions",
      validate: 'I can validate the source of my wealth'
    },
    action: 'Confirm'
  },
  insufficient: {
    header: 'You don’t have enough funds.',
    subheader:
      'Sorry for interruption, but you don’t have enough funds in your USDC wallet to complete this investment. You can transfer funds from other wallets or deposit instantly to continue to investment.',
    exchange: 'Exchange to USDC',
    deposit: 'Deposit USDC'
  }
};
