export const status_codes = {
  client_codes: {
    200: 'OK',
    400: 'Bad Request',
    401: 'Unauthorized',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not acceptable',
    407: 'Proxy Authentication Required',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed',
    413: 'Payload Too Large',
    414: 'Request-URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Requested Range Not Satifiable',
    417: 'Expectation Failed',
    421: 'Misdirected Request',
    422: 'Unprocessable Entity',
    423: 'Locked',
    424: 'Failed Dependency',
    426: 'Upgrade Required',
    428: 'Precondition Required',
    429: 'Too Many Requests',
    451: 'Unavailable For Legal Reasons',
    460: 'Recaptcha required',
    468: 'Below Expected User Verification Level',
    469: 'Above Expected User Verification Level',
    470: 'Verify E-mail',
    471: 'Verify Phone',
    472: 'Verify Identity',
    473: 'Identity Verification Processing',
    480: 'Insufficient Funds',
    481: 'Insufficient Share',
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'API Version Not Supported',
    507: 'Insufficient Storage',
    508: 'Loop Detected',
    510: 'Not Extended',
    511: 'Network Authentication Required',
    555: 'Unknown Client Code',
    599: 'Network Connect Timeout Error',
    // Allocating 7XX codes to utilize in front end based error conditions
    700: 'Network Error',
    701: 'Process timed-out'
  }
};
