import * as ProjectDetailConstants from 'Constants/ProjectDetail/ProjectDetail.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

// TODO: Reducer state should also contain a possible place for placing failure responses

const initialState = {
  projectId: null,
  clientCode: null,
  attributes: {},
  status: REQUEST_STATUS.NOT_DEFINED
};

export default function projectDetail(state = initialState, action) {
  switch (action.type) {
    case ProjectDetailConstants.RETRIEVE_WITH_PROJECT_ID_PENDING:
      return { ...state, status: REQUEST_STATUS.PENDING };
    case ProjectDetailConstants.RETRIEVE_WITH_PROJECT_ID_SUCCESS:
      return retrieveProjectDetailsWithProjectIdSuccess(state, action);
    case ProjectDetailConstants.RETRIEVE_WITH_PROJECT_ID_FAILURE:
      return { ...state, status: REQUEST_STATUS.FAILURE };
    default:
      return state;
  }
}

function retrieveProjectDetailsWithProjectIdSuccess(state, action) {
  const { data } = action.payload;
  return {
    ...state,
    projectId: data.id,
    clientCode: data.client_code,
    attributes: data.attributes,
    status: REQUEST_STATUS.SUCCESS
  };
}
