import React, { Component } from 'react';

import NavbarContainer from 'Containers/Navbar/Navbar.container';

class GlobalLayout extends Component {
  render() {
    return (
      <React.Fragment>
        <NavbarContainer minWidth={this.props.minWidth} />
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default GlobalLayout;
