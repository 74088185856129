export const resetPassword = {
  start: {
    header: 'Did You Forgot Your Password?',
    description:
      "You can reset your account password by re-submitting the information you've provided before. Only users with verified e-mail addresses can reset their password.",
    email_placeholder: 'E-mail Address',
    validation_errors:
      'Please check indicated area then submit the form again.',
    submit: 'Remind Me',
    loading: 'Loading ...',
    footer: {
      content: 'Did You Remember Your Password?',
      cta: 'Login Now!'
    }
  },
  send_verification: {
    header: 'Choose Your Validation Method.',
    description:
      'You can reset your password by using either your registered e-mail address or phone number. Please select the method use want to use.',
    email_text: 'Reset via E-mail',
    sms_text: 'Reset via Phone',
    footer: {
      content: 'Did You Remember Your Password?',
      cta: 'Login Now!'
    }
  },
  verify_code_otp: {
    header: 'Please use the code that we sent to your phone number.',
    description:
      'We sent a 6-digit one time password to your registered phone number.',
    resend_cta: 'Resend',
    continue_cta: 'Continue',
    loading: 'Loading',
    footer: {
      content: 'Did You Remember Your Password?',
      cta: 'Login Now!'
    }
  },
  verify_user_information: {
    header: 'Validate Your Personal Information',
    description:
      'Great! Your e-mail address has been validated successfully. Please validate your personal information to reset your password.',
    national_id_placeholder: 'ID Number',
    date_of_birth_placeholder: 'Date of Birth',
    validation_errors:
      'Please check indicated area then submit the form again.',
    submit: 'Continue',
    loading: 'Loading ...',
    footer: {
      content: 'Did You Remember Your Password?',
      cta: 'Login Now!'
    }
  },
  execute: {
    header: 'Great! Now you can define your new password.',
    description:
      'Great! Your account has been succesfully validated. Please create and confirm you new password.',
    password_placeholder: 'Password',
    password_confirmation_placeholder: 'Confirm Password',
    validation_errors:
      'Please check indicated area then submit the form again.',
    submit: 'Complete',
    loading: 'Loading...',
    footer: {
      content: 'Did You Remember Your Password?',
      cta: 'Login Now!'
    }
  },
  failure: {
    header: 'Something Went Wrong',
    errors: {
      403: 'You have to verify your registered e-mail address to reset your account password. If you’re having problems to reach that e-mail address please contact us for further help.'
    },
    cta: 'Back to Home'
  },
  success: {
    send_verification: {
      header: 'Validation e-mail has been sent',
      description:
        'We sent a validation e-mail to your registered e-mail address. Please, follow instructions in that mail to proceed with password reset.',
      cta: 'Back To Home'
    },
    execute: {
      header: 'Your Password Has Been Successfully Changed!',
      description:
        'Congrats! Now, you can use your new password to login to voult platform.',
      cta: 'Back To Home'
    }
  }
};
