import { all, put, takeLatest } from 'redux-saga/effects';

import apiGenerator from 'Utils/Helpers/api.helpers';
import * as RegisterConstants from 'Constants/Authentication/Register.constants';

import {
  getStatusCodeFamily,
  apiErrorHandler,
  sagaDelayer
} from 'Utils/Helpers/saga.helpers';
import {
  STATUS_TYPE,
  API_ENDPOINTS
} from 'Constants/GlobalConstants/Global.constants';

function* userRegisterSaga(action) {
  const { formState } = action.payload;
  const api = apiGenerator('post')(API_ENDPOINTS.REGISTER, formState);
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: RegisterConstants.USER_REGISTER_SUCCESS
      });
    }
  } catch (err) {
    yield put({
      type: RegisterConstants.USER_REGISTER_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* userRegisterVerifySaga(action) {
  const { verification_code } = action.payload;
  const url = API_ENDPOINTS.REGISTER_VERIFY;
  const api = apiGenerator('post')(url, { code: verification_code });
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: RegisterConstants.USER_REGISTER_VERIFY_SUCCESS
      });
    }
  } catch (err) {
    yield put({
      type: RegisterConstants.USER_REGISTER_VERIFY_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(RegisterConstants.USER_REGISTER_PENDING, userRegisterSaga),
    takeLatest(
      RegisterConstants.USER_REGISTER_VERIFY_PENDING,
      userRegisterVerifySaga
    )
  ]);
}
