import {
  USER_REGISTER_STATE_RESET,
  USER_REGISTER_PENDING,
  USER_REGISTER_VERIFY_PENDING
} from 'Constants/Authentication/Register.constants';
import { removeFromLocalStorage } from 'Utils/Helpers/localstorage.helpers';

export function userRegisterStateReset() {
  return {
    type: USER_REGISTER_STATE_RESET
  };
}

export function userRegister(formState) {
  return {
    type: USER_REGISTER_PENDING,
    payload: { formState }
  };
}

export function userRegisterVerify(verification_code) {
  removeFromLocalStorage('access_token');
  return {
    type: USER_REGISTER_VERIFY_PENDING,
    payload: { verification_code }
  };
}
