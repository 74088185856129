import * as RouteExtenderConstants from 'Constants/RouteExtender/RouteExtender.constants';

import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

export const initialState = {
  redirectBack: null
};

export default function routerExtender(state = initialState, action) {
  switch (action.type) {
    case RouteExtenderConstants.SAVE_REDIRECT_ROUTE: {
      const { redirectBack } = action.payload;
      return modifyReducer(state, { redirectBack });
    }
    case RouteExtenderConstants.REMOVE_REDIRECT_ROUTE: {
      return modifyReducer(state, { redirectBack: null });
    }
    default:
      return state;
  }
}
