import React from 'react';

import { Message } from 'UILib';
import { determineIcon } from 'Utils/Helpers/icon.helpers';

import 'Components/Account/UserDashboard/userDashboard.style.scss';

const ActivationMessage = (props) => {
  return (
    <Message
      borderColor="white"
      dismissable
      dismissAction={() => {
        props.dismissActivationMessage();
      }}
      icon={{
        base: 'solid',
        icon: {
          name: determineIcon(
            `userDashboard.activationMessage.${
              props.activationMessage.messageType
            }`
          ),
          color: 'grey-2'
        }
      }}
      header={`userDashboard.${props.activationMessage.messageType}.header`}
      content={`userDashboard.${props.activationMessage.messageType}.content`}
      translation
      action={
        props.activationMessage.messageAction && {
          content: `userDashboard.${
            props.activationMessage.messageType
          }.action`,
          action: () => {
            props.activationMessage.action();
          },
          translation: true,
          color: 'white',
          borderColor: 'orange-3',
          bgColor: 'orange-3'
        }
      }
      className="activation-message"
    />
  );
};

export default ActivationMessage;
