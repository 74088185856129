import React from 'react';
import numeral from 'numeral';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import { Icon } from 'UILib';
import { transformBalance } from 'Utils/Helpers/component.helpers';

import MoonLoaderComponent from 'Components/Reusable/Loading/MoonLoader.component';

import {
  REQUEST_STATUS,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';

import './InvestmentTable.style.scss';

const determineImageLetters = (title) => {
  const titleArray = title.split(' ');
  return _.take(titleArray, 2)
    .map((item) => item.charAt(0).toUpperCase())
    .join('');
};

const generateMobileProjectTitle = (title, type) => {
  if (type === 'wind' || type === 'solar') {
    if (title.length > 13) {
      return title.substring(0, 13).concat('...');
    } else {
      return title;
    }
  } else {
    if (title.length > 15) {
      return title.substring(0, 15).concat('...');
    } else {
      return title;
    }
  }
};

const generateUserInvestedProjects = (invested_projects) => {
  let data = { projects: [], total: { invested: 0, shares: 0, earnings: 0 } };

  data.projects = _.map(invested_projects, (project) => {
    return {
      id: project.id,
      title: project.attributes.properties.information.name,
      location: project.attributes.properties.information.city,
      invested: 'nodata',
      shares: 'nodata',
      earning: { available: true, amount: 'no' },
      actionable: true
    };
  });

  return data;
};

const generateUserInvestableProjects = (
  investable_projects,
  invested_projects
) => {
  return {
    projects: _.map(
      _.differenceWith(
        investable_projects.projects,
        invested_projects.projects,
        _.isEqual
      ),
      (project) => {
        return {
          id: project.id,
          title: project.attributes.properties.information.name,
          location: project.attributes.properties.information.city,
          type: project.attributes.properties.information.type,
          total_investment:
            project.attributes.properties.contribution.compensation_details
              .amount_to_be_raised,
          backers: project.attributes.statistics.backer_count,
          irr:
            project.attributes.properties.financial.investment_performance
              .effective_irr,
          audited: true
        };
      }
    )
  };
};

const generateUserCommittedProjects = (user_commitments, projects) => {
  let returnObj = [];
  user_commitments.forEach((commitment) => {
    const relatedProject = _.filter(projects, (project) => {
      return project.id === commitment.attributes.project_id.toString();
    })[0];
    returnObj.push({
      id: commitment.attributes.project_id,
      type: relatedProject.attributes.properties.information.type,
      title: relatedProject.attributes.properties.information.name,
      location: relatedProject.attributes.properties.information.city,
      capacity: relatedProject.attributes.properties.information.plant_capacity,
      backer_count: relatedProject.attributes.statistics.backer_count,
      irr:
        relatedProject.attributes.properties.financial.investment_performance
          .effective_irr,
      commitment: commitment.attributes.min
    });
  });

  return returnObj;
};

const Loading = (props) => {
  return (
    <div className="general-table-wrapper">
      <div className="table-loading">
        <MoonLoaderComponent />
      </div>
    </div>
  );
};

const CommittedTable = (props) => {
  const { status, user_commitments, projects } = props.data;
  switch (status) {
    case REQUEST_STATUS.SUCCESS:
      return (
        <div className="committed-table-wrapper">
          <div className="table-header-zone">
            <div className="table-header" />
            <div className="table-header">LOCATION</div>
            <div className="table-header">CAPACITY</div>
            <div className="table-header">COMMITMENT</div>
            <div className="table-header">BACKERS</div>
            <div className="table-header">RETURN</div>
            <div className="table-header" />
          </div>
          <div className="mobile-table-header-zone">
            <div className="table-header"></div>
            <div className="table-header">COMMIT</div>
            <div className="table-header">CAPACITY</div>
            <div className="table-header">IRR</div>
            <div className="table-header" />
          </div>
          {generateUserCommittedProjects(user_commitments, projects).map(
            (project, idx) => (
              <React.Fragment key={idx}>
                <div className="table-item-zone" key={idx}>
                  <div className="table-item">{project.title}</div>
                  <div className="table-item">{project.location}</div>
                  <div className="table-item">{`${project.capacity} kWp`}</div>
                  <div className="table-item">{`${transformBalance(
                    project.commitment,
                    0
                  )} USD`}</div>
                  <div className="table-item">
                    {transformBalance(project.backer_count, 0)}
                  </div>
                  <div className="table-item">{`${project.irr} %`}</div>
                  <div className="table-item">
                    <Link to={APP_ROUTES.PROJECT_DETAIL_WITH_ID(project.id)}>
                      View
                    </Link>
                  </div>
                </div>
                <div className="mobile-table-item-zone" key={`mobile-${idx}`}>
                  <div className="table-item">
                    <div className="title">
                      {project.type === 'wind' && (
                        <Icon
                          base="solid"
                          icon={{ name: 'wind' }}
                          className="icon"
                        />
                      )}
                      {project.type === 'solar' && (
                        <Icon
                          base="solid"
                          icon={{ name: 'sun' }}
                          className="icon"
                        />
                      )}
                      {generateMobileProjectTitle(project.title, project.type)}
                    </div>
                    <div className="location">{project.location}</div>
                  </div>
                  <div className="table-item">
                    {`${numeral(project.commitment).format('0.0a')} $`}
                  </div>
                  <div className="table-item">{`${numeral(
                    project.capacity
                  ).format('0.0a')} kWp`}</div>
                  <div className="table-item">{`${project.irr} %`}</div>
                  <div className="table-item">
                    <Link to={APP_ROUTES.PROJECT_DETAIL_WITH_ID(project.id)}>
                      View
                    </Link>
                  </div>
                </div>
              </React.Fragment>
            )
          )}
        </div>
      );
    case REQUEST_STATUS.FAILURE:
      return (
        <div className="invested-table-wrapper">
          <div className="table-error-wrapper">
            <div className="error-content">
              <Translate id="investmentTable.invested.error.custom" />
            </div>
          </div>
        </div>
      );
    default:
      return <Loading />;
  }
};

const InvestedTable = (props) => {
  const { status, projects } = props.data;
  const invested_projects = generateUserInvestedProjects(projects);
  switch (status) {
    case REQUEST_STATUS.SUCCESS:
      return invested_projects.projects.length === 0 ? (
        <div className="invested-table-wrapper">
          <div className="table-error-wrapper">
            <div className="error-content">
              <Translate id="investmentTable.invested.noInvestmentFound" />
            </div>
          </div>
        </div>
      ) : (
        <div className="invested-table-wrapper">
          <div className="table-header-zone">
            <div className="table-header" />
            <div className="table-header">
              <Translate id="investmentTable.invested.header.location" />
            </div>
            <div className="table-header">
              <Translate id="investmentTable.invested.header.invested" />
            </div>
            <div className="table-header">
              <Translate id="investmentTable.invested.header.shares" />
            </div>
            <div className="table-header">
              <Translate id="investmentTable.invested.header.earnings" />
            </div>
            <div className="table-header" />
          </div>
          <div className="mobile-table-header-zone">
            <div className="table-header" />
            <div className="table-header">INVESTED</div>
            <div className="table-header">SHARES</div>
            <div className="table-header">EARN</div>
            <div className="table-header" />
          </div>
          {invested_projects.projects.map((project, idx) => (
            <React.Fragment key={idx}>
              <div className="table-item-zone" key={idx}>
                <div className="table-item">
                  <div className="project-image">
                    <div className="image-placeholder">
                      {determineImageLetters(project.title)}
                    </div>
                  </div>
                  {project.title}
                </div>
                <div className="table-item">{project.location}</div>
                <div className="table-item">
                  <Translate
                    id="investmentTable.invested.table.invested"
                    data={{ invested: transformBalance(project.invested, 0) }}
                  />
                </div>
                <div className="table-item">{project.shares}</div>
                <div className="table-item earnings">
                  <div
                    className={`indicator ${project.earning.available &&
                      'active'}`}
                  />
                  {project.earning.available
                    ? `${project.earning.amount} USD`
                    : project.earning.date}
                </div>
                <div className="table-item actions">
                  <Link to={APP_ROUTES.INVESTMENT_WITH_ID(project.id)}>
                    <span className="action">
                      <Translate id="investmentTable.invested.table.actions.buy" />
                    </span>
                  </Link>{' '}
                  /{' '}
                  <Link to={APP_ROUTES.WORK_IN_PROGRESS}>
                    <span className="action">
                      <Translate id="investmentTable.invested.table.actions.sell" />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="mobile-table-item-zone" key={`mobile-${idx}`}>
                <div className="table-item">
                  <div className="title">
                    {project.type === 'wind' && (
                      <Icon
                        base="solid"
                        icon={{ name: 'wind' }}
                        className="icon"
                      />
                    )}
                    {project.type === 'solar' && (
                      <Icon
                        base="solid"
                        icon={{ name: 'sun' }}
                        className="icon"
                      />
                    )}
                    {generateMobileProjectTitle(project.title, project.type)}
                  </div>
                  <div className="location">{project.location}</div>
                </div>
                <div className="table-item">
                  {`${numeral(project.invested).format('0.0a')} $`}
                </div>
                <div className="table-item">{`${numeral(project.shares).format(
                  '0.0a'
                )}`}</div>
                <div className="table-item earnings">
                  <div
                    className={`indicator ${project.earning.available &&
                      'active'}`}
                  />
                  {project.earning.available
                    ? `${numeral(project.earning.amount).format('0.0a')}`
                    : project.earning.date}
                </div>
                <div className="table-item actions">
                  <Link to={APP_ROUTES.INVESTMENT_WITH_ID(project.id)}>
                    <span className="action">
                      <Translate id="investmentTable.invested.table.actions.buy" />
                    </span>
                  </Link>
                  <Link to={APP_ROUTES.WORK_IN_PROGRESS}>
                    <span className="action">
                      <Translate id="investmentTable.invested.table.actions.sell" />
                    </span>
                  </Link>
                </div>
              </div>
            </React.Fragment>
          ))}
          <div className="table-total-zone">
            <div className="table-total">
              <Translate id="investmentTable.invested.table.total.total" />
            </div>
            <div className="table-total">
              <Translate
                id="investmentTable.invested.table.total.invested"
                data={{ invested: invested_projects.total.invested }}
              />
            </div>
            <div className="table-total">{invested_projects.total.shares}</div>
            <div className="table-total earnings">
              <div className="indicator" />
              <Translate
                id="investmentTable.invested.table.total.earnings"
                data={{ earnings: invested_projects.total.earnings }}
              />
            </div>
            <div className="table-total actions">
              <Link to={APP_ROUTES.WORK_IN_PROGRESS}>
                <span className="action">
                  <Translate id="investmentTable.invested.table.total.details" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      );
    case REQUEST_STATUS.FAILURE:
      return (
        <div className="invested-table-wrapper">
          <div className="table-error-wrapper">
            <div className="error-content">
              <Translate id="investmentTable.invested.error.custom" />
            </div>
          </div>
        </div>
      );
    default:
      return <Loading />;
  }
};

const InvestableTable = (props) => {
  const { data, invested } = props;
  if (invested.status === REQUEST_STATUS.SUCCESS) {
    const investable_projects = generateUserInvestableProjects(data, invested);
    switch (data.status) {
      case REQUEST_STATUS.SUCCESS:
        return investable_projects.projects.length === 0 ? (
          <div className="invested-table-wrapper">
            <div className="table-error-wrapper">
              <div className="error-content">
                <Translate id="investmentTable.investable.noInvestableFound" />
              </div>
            </div>
          </div>
        ) : (
          <div className="investable-table-wrapper">
            <div className="table-header-zone">
              <div className="table-header" />
              <div className="table-header">
                <Translate id="investmentTable.investable.header.type" />
              </div>
              <div className="table-header">
                <Translate id="investmentTable.investable.header.total" />
              </div>
              <div className="table-header">
                <Translate id="investmentTable.investable.header.backers" />
              </div>
              <div className="table-header">
                <Translate id="investmentTable.investable.header.irr" />
              </div>
              <div className="table-header" />
            </div>
            <div className="mobile-table-header-zone">
              <div className="table-header"></div>
              <div className="table-header">INVEST</div>
              <div className="table-header">CAPACITY</div>
              <div className="table-header">IRR</div>
              <div className="table-header" />
            </div>
            {investable_projects.projects.map((project, idx) => (
              <React.Fragment key={idx}>
                <div className="table-item-wrapper" key={idx}>
                  {project.audited && (
                    <div className="table-item-audited">
                      <Translate id="investmentTable.investable.audited" />
                    </div>
                  )}
                  <div className="table-item-zone">
                    <div className="table-item">
                      <Link to={APP_ROUTES.PROJECT_DETAIL_WITH_ID(project.id)}>
                        {project.title} / {project.location}
                      </Link>
                    </div>
                    <div className="table-item">
                      {project.type.toUpperCase()}
                    </div>
                    <div className="table-item">
                      <Translate
                        id="investmentTable.investable.table.total"
                        data={{
                          total_investment: transformBalance(
                            project.total_investment,
                            0
                          )
                        }}
                      />
                    </div>
                    <div className="table-item">{project.backers}</div>
                    <div className="table-item">{project.irr} %</div>
                    <div className="table-item actions">
                      <Link to={APP_ROUTES.INVESTMENT_WITH_ID(project.id)}>
                        <span className="action">
                          <Translate id="investmentTable.investable.table.actions.buy" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="mobile-table-item-wrapper"
                  key={`mobile-${idx}`}
                >
                  <div className="table-item-zone">
                    <div className="table-item">
                      <div className="title">
                        {project.type === 'wind' && (
                          <Icon
                            base="solid"
                            icon={{ name: 'wind' }}
                            className="icon"
                          />
                        )}
                        {project.type === 'solar' && (
                          <Icon
                            base="solid"
                            icon={{ name: 'sun' }}
                            className="icon"
                          />
                        )}
                        {generateMobileProjectTitle(
                          project.title,
                          project.type
                        )}
                      </div>
                      <div className="location">{project.location}</div>
                    </div>
                    <div className="table-item">
                      {`${numeral(project.total_investment).format('0.0a')} $`}
                    </div>
                    <div className="table-item">{`${numeral(
                      project.capacity
                    ).format('0.0a')} kWp`}</div>
                    <div className="table-item">{project.irr} %</div>
                    <div className="table-item actions">
                      <Link to={APP_ROUTES.INVESTMENT_WITH_ID(project.id)}>
                        <span className="action">
                          <Translate id="investmentTable.investable.table.actions.buy" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        );
      case REQUEST_STATUS.FAILURE:
        return (
          <div className="investable-table-wrapper">
            <div className="table-error-wrapper">
              <div className="error-content">
                <Translate id="investmentTable.investable.error.custom" />
              </div>
            </div>
          </div>
        );
      default:
        return <Loading />;
    }
  } else {
    return <Loading />;
  }
};

export default class Table extends React.Component {
  render() {
    const { type } = this.props;
    switch (type) {
      case 'investable':
        return <InvestableTable {...this.props} />;
      case 'committed':
        return <CommittedTable {...this.props} />;
      default:
        return <InvestedTable {...this.props} />;
    }
  }
}
