import React, { Component } from 'react';
import * as _ from 'lodash';

import MoonLoaderComponent from 'Components/Reusable/Loading/MoonLoader.component';
import { Modal, Text, Button, Icon, FormElements } from 'UILib';

import { copyTextToClipboard } from 'Utils/Helpers/component.helpers';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import 'Components/Account/UserDashboard/userDashboard.style.scss';

const Loading = (props) => {
  return (
    <Modal {...props} closeIcon>
      <div className="wallet-deposit-loader">
        <MoonLoaderComponent />
      </div>
    </Modal>
  );
};

class WalletDepositModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressCopied: false,
      isWalletCreated: this.isWalletCreatedFn()
    };
  }

  componentDidMount() {
    this.props.getUserSelectedWalletDetails(
      this.props.access_token,
      this.props.selectedCurrency
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.addressCopied && this.state.addressCopied) {
      this.clearAddressCopiedState = setTimeout(() => {
        this.setState({ addressCopied: false });
      }, 10000);
    }
  }

  componentWillUnmount() {
    this.props.clearUserSelectedWalletDetails();
    clearTimeout(this.clearAddressCopiedState);
  }

  isWalletCreatedFn = () => {
    const { selectedCurrency, user_wallets } = this.props;
    return !_.some(user_wallets.wallets, (wallet) => {
      return wallet.attributes.currency === selectedCurrency;
    });
  };

  handleCopyToClipboard = (text) => {
    this.setState({ addressCopied: true });
    copyTextToClipboard(text);
  };

  handleRegenerateWalletAddress = () => {
    this.props.regenerateUserSelectedWalletAddress(
      this.props.access_token,
      this.props.selectedCurrency
    );
  };

  render() {
    switch (this.props.user_wallet_detail.status) {
      case REQUEST_STATUS.NOT_DEFINED:
        return <Loading {...this.props} />;
      case REQUEST_STATUS.PENDING:
        return <Loading {...this.props} />;
      default:
        return (
          <Modal {...this.props} closeIcon>
            <div className="wallet-deposit-modal-wrapper">
              <Text
                color="light-grey"
                content={`userDashboard.walletModal.header.${this.props.selectedCurrency}`}
                translation
                className="header"
              />
              <Text
                type="intro"
                color="light-grey"
                content="userDashboard.walletModal.description"
                data={{ selectedCurrency: this.props.selectedCurrency }}
                className="content"
                translation
              />
              {this.state.isWalletCreated && (
                <div className="wallet-created">
                  <Text
                    content="userDashboard.walletModal.walletCreated"
                    translation
                  />
                </div>
              )}
              <div className="wallet-address">
                <Text
                  color="light-grey"
                  content="userDashboard.walletModal.walletAddress.header"
                  translation
                  className="header"
                />
                <div
                  className="wallet-address-zone"
                  onClick={() => {
                    this.handleCopyToClipboard(
                      this.props.user_wallet_detail.address
                    );
                  }}
                >
                  {this.state.addressCopied && (
                    <Text
                      color="light-grey"
                      className="copied-zone"
                      content="userDashboard.walletModal.walletAddress.copied"
                      translation
                    />
                  )}
                  <Text
                    color="light-grey"
                    content={this.props.user_wallet_detail.address}
                    className="address"
                  />
                  <Icon
                    base="regular"
                    icon={{
                      name: 'clone',
                      color: 'grey-5'
                    }}
                    className="icon"
                  />
                </div>
                {this.props.selectedCurrency !== 'USDC' && (
                  <div
                    onClick={() => {
                      this.handleRegenerateWalletAddress();
                    }}
                  >
                    <Text
                      color="light-grey"
                      content="userDashboard.walletModal.walletAddress.regenerate"
                      translation
                      className="regenerate"
                      textAlign="right"
                    />
                  </div>
                )}
                {this.props.user_wallet_detail.status ===
                  REQUEST_STATUS.FAILURE && (
                  <div className="error">
                    <FormElements.ValidationErrors
                      content={`userDashboard.walletModal.failure.${this.props.user_wallet_detail.error}`}
                      translation
                    />
                  </div>
                )}
              </div>
              <div className="wallet-qr-code">
                <img
                  src={`data:image/svg+xml;base64, ${this.props.user_wallet_detail.qr_code}`}
                  className="qr-code"
                  alt="qr_code"
                />
              </div>
              <div className="actions">
                <Button
                  content="userDashboard.walletModal.actions.done"
                  translation
                  color="white"
                  bgColor="orange-3"
                  borderColor="orange-3"
                  className="button"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                />
              </div>
            </div>
          </Modal>
        );
    }
  }
}

export default WalletDepositModal;
