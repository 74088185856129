import { all, put, takeLatest } from 'redux-saga/effects';
import apiGenerator from 'Utils/Helpers/api.helpers';
import { getStatusCodeFamily, sagaDelayer } from 'Utils/Helpers/saga.helpers';
import { STATUS_TYPE } from 'Constants/GlobalConstants/Global.constants';
import * as ProjectDetailConstants from 'Constants/ProjectDetail/ProjectDetail.constants';

function* retrieveProjectDetailsWithProjectIdSaga(action) {
  const { projectId } = action.payload;
  const url = `/projects/${projectId}`;
  const api = apiGenerator('get')(url);
  try {
    const response = yield api;
    const { data, status } = response;

    yield sagaDelayer(500);
    if (getStatusCodeFamily(status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ProjectDetailConstants.RETRIEVE_WITH_PROJECT_ID_SUCCESS,
        payload: { data }
      });
    } else {
      yield put({
        type: ProjectDetailConstants.RETRIEVE_WITH_PROJECT_ID_FAILURE
      });
    }
  } catch (err) {
    yield put({
      type: ProjectDetailConstants.RETRIEVE_WITH_PROJECT_ID_FAILURE
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ProjectDetailConstants.RETRIEVE_WITH_PROJECT_ID_PENDING,
      retrieveProjectDetailsWithProjectIdSaga
    )
  ]);
}
