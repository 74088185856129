import React from 'react';

import { transformBalance } from 'Utils/Helpers/component.helpers';
import { Text } from 'UILib';

const ListingHeader = (props) => {
  return (
    <div className="listing-header-zone">
      <Text
        color="blue-3"
        type="cta"
        transform="uppercase"
        content="projectListing.listingHeader.header"
        className="header"
        translation
      />
      <Text
        type="cta"
        content="projectListing.listingHeader.subheader"
        className="subheader"
        translation
      />
      <div className="short-informations-wrapper">
        <div className="short-information">
          <Text
            content="projectListing.listingHeader.shortInformations.projects.header"
            translation
            transform="uppercase"
            className="short-header"
          />
          <Text
            content="projectListing.listingHeader.shortInformations.projects.subheader"
            data={{ project_count: props.project_count }}
            translation
            transform="uppercase"
            className="short-subheader"
          />
        </div>
        <div className="short-information">
          <Text
            content="projectListing.listingHeader.shortInformations.shares.header"
            translation
            transform="uppercase"
            className="short-header"
          />
          <Text
            content="projectListing.listingHeader.shortInformations.shares.subheader"
            data={{ share_amount: transformBalance('600000', 0) }}
            translation
            className="short-subheader"
          />
        </div>
        <div className="short-information">
          <Text
            content="projectListing.listingHeader.shortInformations.investment.header"
            translation
            transform="uppercase"
            className="short-header"
          />
          <Text
            content="projectListing.listingHeader.shortInformations.investment.subheader"
            data={{ investment_amount: transformBalance('600000', 0) }}
            translation
            className="short-subheader"
          />
        </div>
        <div className="short-information">
          <Text
            content="projectListing.listingHeader.shortInformations.available.header"
            translation
            transform="uppercase"
            className="short-header"
          />
          <Text
            content="projectListing.listingHeader.shortInformations.available.subheader"
            data={{ available_country_count: 'Europe' }}
            translation
            transform="uppercase"
            className="short-subheader"
          />
        </div>
      </div>
    </div>
  );
};

export default ListingHeader;
