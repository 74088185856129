import React from 'react';
import { Link } from 'react-router-dom';

import GlobalLayout from 'Layouts/Global.layout';

import { Container, Text, Icon, Button } from 'UILib';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import 'Components/Exchange/exchange.style.scss';

export default class Success extends React.Component {
  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <div className="exchange-success-wrapper">
            <div className="success-header-zone">
              <Icon
                base="regular"
                icon={{ name: 'check-circle', color: 'green-3' }}
                className="icon"
              />
              <div className="success-header-content">
                <Text
                  type="h1"
                  textAlign="left"
                  content="exchange.success.header"
                  translation
                  className="success-header"
                />
                <Text
                  type="intro"
                  textAlign="left"
                  content="exchange.success.subheader"
                  translation
                  className="success-text"
                />
              </div>
            </div>
            <div className="success-general-zone">
              {/* This section needs a design to be implemented */}
            </div>
            <div className="success-action-zone">
              <Link to={APP_ROUTES.DASHBOARD}>
                <Button
                  content="exchange.success.actions.dashboard"
                  translation
                  borderColor="orange-3"
                  color="white"
                  bgColor="orange-3"
                  className="button"
                />
              </Link>
            </div>
          </div>
        </GlobalLayout>
      </Container>
    );
  }
}
