import React from 'react';
import * as _ from 'lodash';

import GlobalLayout from 'Layouts/Global.layout';
import KYCStepHeader from 'Components/KYC/components/KYCStepHeader.component';

import { Container, Text, Button, Icon, FormElements } from 'UILib';
import 'Components/KYC/kyc.style.scss';
import { KYC_STEP } from 'Constants/KYC/KYC.constants';
import {
  URL_TEST_PATTERN,
  REQUEST_STATUS
} from 'Constants/GlobalConstants/Global.constants.js';

class Address extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: this.getAddressState('address'),
      country: this.getAddressState('country'),
      city: this.getAddressState('city'),
      document: this.getAddressState('document'),
      validation_errors: false
    };
  }

  getAddressState = (field) => {
    if (_.has(this.props.kyc.persistent_data, 'address_information')) {
      if (field !== 'document') {
        return this.props.kyc.persistent_data.address_information[field];
      } else {
        if (
          this.props.kyc.persistent_data.address_information.from === 'frontend'
        ) {
          return this.props.kyc.persistent_data.address_information.document;
        } else {
          return this.props.kyc.persistent_data.address_information.files[0]
            .url;
        }
      }
    } else {
      if (field !== 'document') {
        return '';
      } else {
        return null;
      }
    }
  };

  handleOnChangeFile = (file) => {
    this.setState({ document: file, validation_errors: false });
  };

  handleFieldChange = (field, value) => {
    this.setState({
      [field]: value,
      validation_errors: false
    });
  };

  checkValidationErrorsAndSubmit = () => {
    if (
      this.state.address !== '' &&
      this.state.country !== '' &&
      this.state.city !== '' &&
      this.state.document !== null
    ) {
      _.has(this.props.kyc.persistent_data, 'address_information')
        ? this.props.kycFlowAddressPatch(
            this.props.account.access_token,
            this.state
          )
        : this.props.kycFlowAddress(
            this.props.account.access_token,
            this.state
          );
    } else {
      this.setState({ validation_errors: true });
    }
  };

  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <div className="kyc-page-wrapper">
            <div className="kyc-address-wrapper">
              <KYCStepHeader currentStep={4} />
              <div className="section-wrapper">
                <Text
                  content="kyc.address.header"
                  translation
                  className="header"
                />
                <Text
                  content="kyc.address.content"
                  translation
                  className="content"
                />
                <div className="address-form-wrapper">
                  <div className="form-row">
                    <FormElements.Input
                      type="text"
                      name="address"
                      value={this.state['address']}
                      onChange={(e) =>
                        this.handleFieldChange('address', e.target.value)
                      }
                      placeholder="kyc.address.form.address"
                      translation
                      className="input"
                    />
                  </div>
                  <div className="form-row">
                    <div className="left-pane">
                      <FormElements.Input
                        type="text"
                        name="country"
                        value={this.state['country']}
                        onChange={(e) =>
                          this.handleFieldChange('country', e.target.value)
                        }
                        placeholder="kyc.address.form.country"
                        translation
                        className="input"
                      />
                    </div>
                    <div className="right-pane">
                      <FormElements.Input
                        type="text"
                        name="city"
                        value={this.state['city']}
                        onChange={(e) =>
                          this.handleFieldChange('city', e.target.value)
                        }
                        placeholder="kyc.address.form.city"
                        translation
                        className="input"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <Text
                      content="kyc.address.form.subheader"
                      translation
                      className="subcontent"
                    />
                  </div>
                  <div className="form-row">
                    <div className="upload-section">
                      <div className="section">
                        <div
                          className="dropzone"
                          onClick={() => {
                            this.document.click();
                          }}
                        >
                          <input
                            type="file"
                            ref={(ref) => (this.document = ref)}
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              this.handleOnChangeFile(event.target.files[0]);
                            }}
                          />
                          {this.state.document ? (
                            <React.Fragment>
                              <img
                                src={
                                  URL_TEST_PATTERN.test(this.state.document)
                                    ? this.state.document
                                    : URL.createObjectURL(this.state.document)
                                }
                                alt="document"
                                className="image"
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Icon
                                base="regular"
                                icon={{ name: 'clone', color: 'grey-5' }}
                                className="icon"
                              />
                              <Text
                                content="kyc.address.form.files"
                                translation
                                className="text"
                              />
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.validation_errors && (
                  <div className="error-zone">
                    <FormElements.ValidationErrors
                      content="kyc.address.validation_error"
                      translation
                    />
                  </div>
                )}
                <div className="action-zone">
                  <Button
                    content="kyc.document.action.back"
                    translation
                    borderColor="orange-3"
                    bgColor="white"
                    color="orange-3"
                    className="back"
                    onClick={() => {
                      this.props.kycMoveToGivenStep(KYC_STEP.PROFILE);
                    }}
                  />
                  {this.props.kyc.status === REQUEST_STATUS.PENDING ? (
                    <Button
                      type="submit"
                      color="white"
                      bgColor="orange-3"
                      borderColor="orange-3"
                      loading
                      className="continue"
                    />
                  ) : (
                    <Button
                      content="kyc.document.action.continue"
                      translation
                      borderColor="orange-3"
                      bgColor="orange-3"
                      color="white"
                      className="continue"
                      onClick={() => {
                        this.checkValidationErrorsAndSubmit();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </GlobalLayout>
      </Container>
    );
  }
}
export default Address;
