import React from 'react';

import { transformBalance } from 'Utils/Helpers/component.helpers';
import { Text, Tag } from 'UILib';

const Header = (props) => {
  return (
    <div className="header-zone">
      <div className="left-pane">
        {props.information.tags && (
          <div className="tag-zone">
            {props.information.tags.map((tag, idx) => (
              <Tag
                content={tag}
                transform="uppercase"
                className="tag"
                key={idx}
              />
            ))}
          </div>
        )}
        <Text
          type="h1"
          content={props.information.name || ''}
          textAlign="left"
          color="black"
          className="plant-header"
        />
        <Text
          type="h4"
          content={props.information.owner_company || ''}
          textAlign="left"
          color="black"
          className="plant-owner"
        />
        <Text
          type="intro"
          content={
            props.statistics.project_owner_project_count > 1
              ? 'projectDetail.headerZone.projects'
              : 'projectDetail.headerZone.project'
          }
          data={{
            project_count: props.statistics.project_owner_project_count || ''
          }}
          transform="title"
          translation
          color="grey-3"
          textAlign="left"
          className="project-count"
        />
      </div>
      <div className="right-pane">
        <div className="project-statistics">
          <div className="viewer-zone">
            <Text
              type="intro"
              content={transformBalance(
                props.statistics.total_view.toString(),
                0
              )}
              color="black"
              className="header"
            />
            <Text
              type="intro"
              content="projectDetail.headerZone.viewer_count"
              transform="title"
              translation
              color="grey-3"
              className="subheader"
            />
          </div>
          <div className="backer-zone">
            <Text
              type="intro"
              content={transformBalance(
                props.statistics.backer_count.toString(),
                0
              )}
              color="black"
              className="header"
            />
            <Text
              type="intro"
              content="projectDetail.headerZone.backer_count"
              transform="title"
              translation
              color="grey-3"
              className="subheader"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
