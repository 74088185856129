import Start from 'Components/Investment/Start.component';
import Amount from 'Components/Investment/Amount.component';
import Confirm from 'Components/Investment/Confirm.component';
import InsufficientFunds from 'Components/Investment/InsufficientFunds.component';
import Success from 'Components/Investment/Success.component';
import Failure from 'Components/Investment/Failure.component';

import { INVESTMENT_STEP } from 'Constants/Investment/Investment.constants';

export const investmentFlow = {
  flow: [
    { step: INVESTMENT_STEP.START, component: Start },
    { step: INVESTMENT_STEP.AMOUNT, component: Amount },
    { step: INVESTMENT_STEP.CONFIRM, component: Confirm },
    { step: INVESTMENT_STEP.INSUFFICIENT_FUNDS, component: InsufficientFunds }
  ],
  success: { component: Success },
  failure: { component: Failure }
};
