import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import { Container, Text, Button } from 'UILib';

import 'Components/WIP/WorkInProgress.style.scss';

const WorkInProgress = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div className="work-in-progress-zone">
          <Text content="workInProgress.text" translation className="text" />
          <div className="action-zone">
            <Button
              content="workInProgress.action"
              translation
              bgColor="orange-3"
              borderColor="orange-3"
              color="white"
              className="action"
              onClick={() => {
                window.location.href = process.env.REACT_APP_LANDING_PAGE;
              }}
            />
            <Button
              content="workInProgress.goBack"
              translation
              bgColor="white"
              borderColor="orange-3"
              color="orange-3"
              className="action"
              onClick={() => {
                props.history.goBack();
              }}
            />
          </div>
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default WorkInProgress;
