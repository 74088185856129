import axios from 'axios';

const apiGenerator = (type, access_token = null) => {
  let headers = {
    'content-type': 'application/json'
  };

  if (access_token) {
    headers['Authorization'] = `Bearer ${access_token}`;
  }

  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers
  };

  switch (type) {
    case 'get':
      return (url, options = {}) =>
        axios.get(url, { ...defaultOptions, ...options });
    case 'post':
      return (url, data, options = {}) => {
        return axios.post(url, data, { ...defaultOptions, ...options });
      };
    case 'patch':
      return (url, data, options = {}) => {
        return axios.patch(url, data, { ...defaultOptions, ...options });
      };
    default:
      return null;
  }
};

export default apiGenerator;
