import React, { Component } from 'react';

import MoonLoaderComponent from 'Components/Reusable/Loading/MoonLoader.component';
import GlobalLayout from 'Layouts/Global.layout';
import { Container, Card } from 'UILib';

import 'Components/Authentication/authentication.style.scss';

class RequestUserInfo extends Component {
  componentDidMount() {
    this.props.userLoginGetUserInformation(this.props.login.access_token);
  }

  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <Card type="auth" centered className="authentication-card-centered">
            <MoonLoaderComponent />
          </Card>
        </GlobalLayout>
      </Container>
    );
  }
}

export default RequestUserInfo;
