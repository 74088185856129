import { TOC_LINK } from 'Constants/GlobalConstants/Global.constants';

export const exchange = {
  trade: {
    confirmation: {
      header: 'Are you sure?',
      subheader:
        'You are making a trade between 2 currencies, right now. Please be sure that this is what you are intending.',
      yes: 'YES',
      no: 'NO'
    },
    header: 'Exchange Assets',
    subheader:
      'Your conversion will be completed from the recent exchange rate that voult platform has provided and your source and target balances will be updated according to this conversion.',
    convertFrom: {
      header: 'Convert From',
      subheader: 'Funds will decrease from selected wallet.'
    },
    convertTo: {
      header: 'Convert To',
      subheader: 'Funds will be added to from selected wallet.'
    },
    amount: {
      from: {
        header: 'Conversion Amount',
        placeholder: 'Conversion Amount'
      },
      to: {
        header: 'Converted Amount',
        placeholder: 'Converted Amount'
      },
      safeOrder: 'Safe Order'
    },
    rate: {
      header: 'Current Exchange Rate',
      againstDollar: '1 ${currency} = $ ${rate}',
      fromTo: '${to} / ${from}: ${rate} ${from}',
      informer:
        'Please select from and to currencies for seeing current exchange rates.'
    },
    toc: `I've agree the <a target='_blank' href=${TOC_LINK}>Terms and Conditions.</a>`,
    validation_errors:
      'Please control your "Convert From", "Convert To", amount sections along with accepting the terms of use, then submit again.',
    submit: 'Make Conversion',
    information: {
      content1:
        'Safe Order guarantees your conversion by providing %1 safety margin on top of the market exchange rate to prevent failures due to instant market rate changes.',
      content2: 'Your orders are executed through Binance.'
    },
    safeOrder: 'Place a Safe Order',
    exchangeRatesServerError:
      'There seems to be a problem regarding to exchange rates server, please try again later.'
  },
  failure: {
    header: 'Sorry, your conversion has been failed.',
    subheader:
      'Your conversion has been failed, it can be due to blockchain related problems. If you feel that was our problem, please retry or reach us to inform us.',
    actions: {
      retry: 'Retry Conversion',
      dashboard: 'Go To Dashboard'
    }
  },
  success: {
    header: 'Congrats! Your conversion has been completed.',
    subheader: 'Your conversion request has been completed.',
    actions: {
      dashboard: 'Go To Dashboard'
    }
  }
};
