import * as _ from 'lodash';

import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

export const determineFlowComponent = (flowPattern, state) => {
  const { flow, success, failure } = flowPattern;
  const { step, status } = state;

  /**
   * Returns flow escape components based on the proposed status
   * These are modular components using localization method for rendering
   * step-dependent components
   */
  switch (status) {
    case REQUEST_STATUS.SUCCESS:
      return success.component;
    case REQUEST_STATUS.FAILURE:
      return failure.component;
    default:
      const currentStep = _.find(flow, { step });

      if (currentStep.options) {
        /**
         * If current step contains options value
         * function should return component that has a key value of
         * options joined together
         */
        const optionKey = _.map(
          currentStep.options,
          (option) => state[option]
        ).join('');
        return currentStep.component[optionKey];
      }

      return currentStep.component;
  }
};
