import React from 'react';
import cx from 'classnames';
import * as _ from 'lodash';

import './FormElements.style.scss';

const LEFT_KEY = 37;
const RIGHT_KEY = 39;
const BACKSPACE_KEY = 8;

export default class Digit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: this._generateInputArray(this.props.digits, this.props.value)
    };

    this.digitInput = [];
  }

  componentDidMount() {
    this.digitInput[0].select();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.error && this.props.error) {
      this.digitInput[0].select();
    }
    if (prevProps.value !== '' && this.props.value === '') {
      const input = this._generateInputArray(
        this.props.digits,
        this.props.value
      );
      this.setState({ input });
    }
  }

  _generateInputArray = (digits, value) => {
    let input = [];
    for (let i = 0; i < digits; i += 1) {
      const val = value.toString()[i] || '';
      input.push(val);
    }
    return input;
  };

  _handleFocus = (e) => {
    const targetValue = e.target.value.toString();
    const currTargetId = parseInt(e.target.id, 10);

    if (targetValue !== '') {
      this.digitInput[currTargetId].select();
    }
  };

  _handleDigitChange = (e) => {
    let value = e.target.value;
    let currTargetId = parseInt(e.target.id, 10);
    let input = this.state.input.slice();
    const positionalValue = input[currTargetId];
    const hasValidNewTarget = currTargetId + 1 < input.length;

    if (this.props.type === 'number') {
      value = value.replace(/[^\d]/g, '');
    }

    if (value.length === 1 && positionalValue.length === 0) {
      input[currTargetId] = value;
    } else if (value.length === 1 && positionalValue.length === 1) {
      input[currTargetId] = value;
    } else if (value.length > 1 && positionalValue.length === 1) {
      input[currTargetId] = _.replace(value, positionalValue, '');
    }

    this.setState({ input });
    this.props.onChange(input.join(''));

    if (hasValidNewTarget) {
      const nextTarget = this.digitInput[currTargetId + 1];
      nextTarget.focus();
      nextTarget.select();
    } else {
      const currTarget = this.digitInput[currTargetId];
      currTarget.blur();
    }
  };

  _handleKeyDown = (e) => {
    const currTargetId = parseInt(e.target.id, 10);
    const currTarget = this.digitInput[currTargetId];
    const prevTarget = this.digitInput[currTargetId - 1];
    const nextTarget = this.digitInput[currTargetId + 1];

    switch (e.keyCode) {
      case LEFT_KEY:
        e.preventDefault();

        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        } else {
          currTarget.blur();
        }
        break;
      case RIGHT_KEY:
        e.preventDefault();

        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        } else {
          currTarget.blur();
        }
        break;
      case BACKSPACE_KEY:
        e.preventDefault();
        this.digitInput[currTargetId].value = '';
        const input = this.state.input.slice();
        input[currTargetId] = '';
        this.setState({ input });
        this.props.onChange(input.join(''));

        if (currTarget.value === '') {
          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          } else {
            currTarget.focus();
          }
        }
        break;
      default:
        const keyValue = String.fromCharCode(e.keyCode);
        if (this.props.type === 'number' && /[^\d]/g.test(keyValue)) {
          e.preventDefault();
        }
        break;
    }
  };

  render() {
    const { type, className, error } = this.props;
    const { input } = this.state;
    const baseCN = 'digit';
    const errorCN = error && `${baseCN}-error`;
    const digitCx = cx(baseCN, errorCN, className);
    return (
      <div className="digit-container">
        {input.map((value, idx) => (
          <input
            ref={(ref) => (this.digitInput[idx] = ref)}
            key={idx}
            id={idx}
            value={value}
            type={type}
            onFocus={(e) => this._handleFocus(e)}
            onChange={(e) => this._handleDigitChange(e)}
            onKeyDown={(e) => this._handleKeyDown(e)}
            className={digitCx}
          />
        ))}
      </div>
    );
  }
}
