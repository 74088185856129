import * as InitConstants from 'Constants/AppInit/init.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

const initialState = {
  controls: {
    user_actions_completed: false
  }
};

export default function init(state = initialState, action) {
  switch (action.type) {
    case InitConstants.NO_ACCESS_TOKEN_PRESENT_ON_INIT:
      return modifyReducer(state, {
        controls: {
          ...state.controls,
          user_actions_completed: true
        }
      });
    case InitConstants.RETRIEVE_USER_INFORMATION_ON_INIT_SUCCESS:
      return modifyReducer(state, {
        controls: {
          ...state.controls,
          user_actions_completed: true
        }
      });
    case InitConstants.RETRIEVE_USER_INFORMATION_ON_INIT_FAILURE:
      return modifyReducer(state, {
        controls: {
          ...state.controls,
          user_actions_completed: true
        }
      });
    default:
      return state;
  }
}
