import React from 'react';
import * as _ from 'lodash';
import { Translate } from 'react-localize-redux';

import { Button, FormElements } from 'UILib';

const generateLocationOptions = (countryFilterList) => {
  let locationOptions = countryFilterList.map((country, idx) => {
    return { key: idx + 1, value: country, text: country };
  });

  locationOptions.unshift({
    key: 0,
    value: null,
    text: 'Clear Location Filter'
  });
  return locationOptions;
};

const BooleanFilters = (props) => {
  return (
    <Button
      content={props.content}
      translation={props.translation}
      bgColor="white"
      borderColor={props.filterConfigs[props.config] ? 'blue-3' : 'grey-4'}
      color={props.filterConfigs[props.config] ? 'blue-3' : 'grey-4'}
      className="filter-buttons"
      onClick={() => {
        props.changeFilterConfigs(
          props.config,
          !props.filterConfigs[props.config]
        );
      }}
    />
  );
};

const SelectableFilters = (props) => {
  return (
    <div className="single-filter-zone">
      <div className="filter-header">
        {props.translation ? <Translate id={props.header} /> : props.header}
      </div>
      <div className="filter-selectors">
        {props.options.map((option, idx) => (
          <Button
            key={idx}
            content={option.content}
            translation={props.translation}
            bgColor="white"
            borderColor={
              _.includes(props.filterConfigs[props.config], option.value)
                ? 'blue-3'
                : 'grey-4'
            }
            color={
              _.includes(props.filterConfigs[props.config], option.value)
                ? 'blue-3'
                : 'grey-4'
            }
            className="filter-buttons"
            onClick={() => {
              props.changeFilterConfigs(props.config, option.value);
            }}
          />
        ))}
      </div>
    </div>
  );
};

const FilterSection = (props) => {
  return (
    <div className="filter-section-zone">
      <div className="general-filter-area">
        <div className="general-filters">
          <BooleanFilters
            content="projectListing.filterSection.generalFilters.open"
            translation={true}
            config="open"
            filterConfigs={props.filterConfigs}
            changeFilterConfigs={props.changeFilterConfigs}
          />
          <BooleanFilters
            content="projectListing.filterSection.generalFilters.trending"
            translation={true}
            config="trending"
            filterConfigs={props.filterConfigs}
            changeFilterConfigs={props.changeFilterConfigs}
          />
          <BooleanFilters
            content="projectListing.filterSection.generalFilters.highYield"
            translation={true}
            config="highYield"
            filterConfigs={props.filterConfigs}
            changeFilterConfigs={props.changeFilterConfigs}
          />
          <BooleanFilters
            content="projectListing.filterSection.generalFilters.selected"
            translation={true}
            config="selected"
            filterConfigs={props.filterConfigs}
            changeFilterConfigs={props.changeFilterConfigs}
          />
          <BooleanFilters
            content="projectListing.filterSection.generalFilters.audited"
            translation={true}
            config="audited"
            filterConfigs={props.filterConfigs}
            changeFilterConfigs={props.changeFilterConfigs}
          />
        </div>
        <div className="general-filters">
          <Button
            content="projectListing.filterSection.more"
            translation={true}
            bgColor="white"
            borderColor={props.showMoreFilterOptions ? 'blue-3' : 'grey-4'}
            color={props.showMoreFilterOptions ? 'blue-3' : 'grey-4'}
            onClick={() => {
              props.toggleShowMoreFilterOptions();
            }}
          />
        </div>
      </div>
      {props.showMoreFilterOptions && (
        <div className="more-filter-area">
          <SelectableFilters
            header="projectListing.filterSection.selectableFilters.type.header"
            translation={true}
            options={[
              {
                content:
                  'projectListing.filterSection.selectableFilters.type.solar',
                value: 'solar'
              },
              {
                content:
                  'projectListing.filterSection.selectableFilters.type.wind',
                value: 'wind'
              },
              {
                content:
                  'projectListing.filterSection.selectableFilters.type.hydro',
                value: 'hydro'
              }
            ]}
            config="type"
            filterConfigs={props.filterConfigs}
            changeFilterConfigs={props.changeFilterConfigs}
          />
          <SelectableFilters
            header="projectListing.filterSection.selectableFilters.capacity.header"
            translation={true}
            options={[
              {
                content:
                  'projectListing.filterSection.selectableFilters.capacity.small',
                value: 'small'
              },
              {
                content:
                  'projectListing.filterSection.selectableFilters.capacity.medium',
                value: 'mid'
              },
              {
                content:
                  'projectListing.filterSection.selectableFilters.capacity.large',
                value: 'large'
              }
            ]}
            config="capacity"
            filterConfigs={props.filterConfigs}
            changeFilterConfigs={props.changeFilterConfigs}
          />
          <SelectableFilters
            header="projectListing.filterSection.selectableFilters.stage.header"
            translation={true}
            options={[
              {
                content:
                  'projectListing.filterSection.selectableFilters.stage.early',
                value: 'early'
              },
              {
                content:
                  'projectListing.filterSection.selectableFilters.stage.built',
                value: 'built'
              },
              {
                content:
                  'projectListing.filterSection.selectableFilters.stage.onGoing',
                value: 'onGoing'
              }
            ]}
            config="stage"
            filterConfigs={props.filterConfigs}
            changeFilterConfigs={props.changeFilterConfigs}
          />
          <div className="single-filter-zone">
            <div className="filter-header">
              <Translate id="projectListing.filterSection.selectableFilters.location.header" />
            </div>
            <div className="filter-selectors">
              <FormElements.Dropdown
                placeholder="Please Choose From List"
                name="location"
                handleChange={(name, value) => {
                  if (value !== props.filterConfigs.location) {
                    props.changeFilterConfigs(name, value);
                  }
                }}
                value={props.filterConfigs.location}
                options={generateLocationOptions(props.countryFilterList)}
                className="location-dropdown"
                activeColor={props.filterConfigs.location && '#0033FF'}
                dropdownStyle={
                  props.filterConfigs.location
                    ? { height: '36px', border: '1px solid #0033FF' }
                    : { height: '36px', border: '1px solid #777777' }
                }
                dropdownMenuStyle={{
                  paddingLeft: '20px'
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterSection;
