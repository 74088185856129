import * as Yup from 'yup';

const LoginValidationSchema = (require_recaptcha) => {
  const validationObj = {
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    password: Yup.string()
      .min(8, 'Too Short!')
      .max(32, 'Too Long!')
      .required('Required')
  };

  if (require_recaptcha) {
    validationObj.recaptcha_response = Yup.string().required('Required');
  }

  return Yup.object().shape(validationObj);
};

export { LoginValidationSchema };
