import React from 'react';

export const LeftArrow = (props) => {
  return (
    <div className="back-arrow" onClick={props.handleGoToPrevSlide}>
      <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true" />
    </div>
  );
};

export const RightArrow = (props) => {
  return (
    <div className="next-arrow" onClick={() => props.handleGoToNextSlide()}>
      <i className="fa fa-chevron-circle-right fa-2x" aria-hidden="true" />
    </div>
  );
};
