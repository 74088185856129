import * as _ from 'lodash';

const icons = {
  userDashboard: {
    activationMessage: {
      phoneActivationMessage: 'mobile-alt',
      kycVerificationMessage: 'user-check',
      kycApprovalMessage: 'coffee'
    }
  },
  authentication: {
    register: { success: 'envelope-open', failure: 'times-circle' },
    login: { success: 'envelope-open' },
    verifyRegister: {
      success: 'check-circle',
      failure: 'times-circle'
    },
    resetPassword: {
      failure: 'times-circle',
      success: 'check-circle',
      sendVerification: {
        email: 'envelope',
        sms: 'mobile-alt'
      }
    }
  },
  logout: 'check-circle'
};

export const determineIcon = (position) => {
  return _.get(icons, position);
};
