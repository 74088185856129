import {
  INVESTMENT_FLOW_RESET,
  INVESTMENT_FLOW_START_PENDING,
  INVESTMENT_TIMER_REDUCTION,
  INVESTMENT_FLOW_AMOUNT_PENDING,
  INVESTMENT_FLOW_CONFIRM_PENDING
} from 'Constants/Investment/Investment.constants';

export function investmentFlowReset() {
  return {
    type: INVESTMENT_FLOW_RESET
  };
}

export function investmentStart(projectId, access_token) {
  return {
    type: INVESTMENT_FLOW_START_PENDING,
    payload: { projectId, access_token }
  };
}

export function investmentTimerReduction() {
  return {
    type: INVESTMENT_TIMER_REDUCTION
  };
}

export function investmentAmount(
  projectId,
  access_token,
  transaction_id,
  amount
) {
  return {
    type: INVESTMENT_FLOW_AMOUNT_PENDING,
    payload: { projectId, access_token, transaction_id, amount }
  };
}

export function investmentConfirm(projectId, access_token, transaction_id) {
  return {
    type: INVESTMENT_FLOW_CONFIRM_PENDING,
    payload: { projectId, access_token, transaction_id }
  };
}
