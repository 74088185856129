export const projectDetail = {
  hangingNavBar: {
    menu: {
      overview: 'OVERVIEW',
      information: 'INFORMATION',
      financial: 'FINANCE',
      technology: 'TECHNOLOGY',
      market: 'MARKET'
    },
    action: {
      buyshares: 'BUY SHARES'
    }
  },
  headerZone: {
    project: '${project_count} Project',
    projects: '${project_count} Projects',
    viewer_count: 'Viewers',
    backer_count: 'Backers'
  },
  heroZone: {
    project_highlighted:
      'ONLY ${remaining_days} DAYS LEFT - COMMIT YOUR SHARES NOW',
    general_information_titles: {
      type: 'type',
      status: 'status',
      location: 'location',
      capacity: 'plant capacity',
      audited: 'audited by'
    },
    information_zone_titles: {
      amount_to_be_raised: 'amount to be raised',
      annual_effective_rate: 'expected return',
      investment_rate: 'offered share',
      investment_start_date: 'start date',
      raised_capital: 'raised capital'
    },
    cta: {
      button: 'BUY TO GET FUNDS',
      commitment: 'BUY SHARES',
      link: 'cash out at any time.'
    }
  },
  buyShares: {
    earn: 'You will earn',
    calculated:
      '<b>${amount} USD</b> in a total period of <b>${lifetime} years</b>.',
    cta: 'BUY SHARE',
    subheader:
      'Need to cash out? Sell your shares any time you want. Learn more about our risk free investment model here.'
  },
  projectOverview: {
    header: 'Project Overview',
    cards: {
      financial: {
        header: 'Financial',
        subheader: 'Project IRR'
      },
      technical: {
        header: 'Technical',
        subheader: 'Capacity'
      },
      market: {
        header: 'Market',
        subheader: 'Pricing Regime Type'
      }
    },
    details: 'details'
  },
  projectInformation: {
    header: 'Project Information',
    generalInformations: {
      owner: 'owner',
      constructor: 'constructor',
      auditor: 'auditor',
      plant_capacity: 'plant capacity',
      annual_production: 'annual production'
    },
    locationInformations: {
      owner_company: 'Owner',
      landsize: 'land size',
      coordinates: 'coordinates',
      altitude: 'altitude',
      slope: 'slope',
      sunshine: 'hours of sunlight',
      specific_production: 'Specific Production'
    }
  },
  roadmap: {
    project_highlighted: '${remaining_days} DAYS UNTIL SALE PERIOD STARTS'
  },
  financialDetails: {
    header: 'Financial Details',
    labels: {
      financial_summary: 'Financial Summary',
      investment_contribution: 'Investment & Contribution'
    },
    revenues: {
      price: {
        header: 'Price',
        value: '${value} USD/kWh'
      },
      after_pr_price: {
        header: 'After FIT Price',
        value: '${value} USD/kWh'
      },
      annual_revenue: {
        header: 'Revenue',
        value: '${value} USD/year'
      },
      form_of_compensation: {
        header: 'Form of Compensation',
        value: '${value}'
      },
      price_regime_duration: {
        header: 'Price Regime Duration',
        value: '${value} years'
      },
      fit_price: {
        header: 'FIT Price',
        value: '${value} USD/kWh'
      }
    },
    credit: {
      annual_effective_rate: {
        header: 'Annual Effective Rate',
        value: '${value} [UNIT]'
      },
      institution_name: {
        header: 'Institution Name',
        value: '${value}'
      },
      payback_period: {
        header: 'Payback Period',
        value: '${value}'
      },
      date_of_credit: {
        header: 'Date of Credit',
        value: '${value}'
      },
      credit_amount: {
        header: 'Credit Amount',
        value: '$ ${value} USD'
      },
      start_date: {
        header: 'Start Date',
        value: '${value} '
      },
      end_date: {
        header: 'End Date',
        value: '${value} '
      }
    },
    opex: {
      periodic_cost: {
        header: 'Transmission Cost',
        value: '${value} USD/year'
      },
      maintenance_cost: {
        header: 'Maintenance Cost',
        value: '${value} USD/year'
      },
      labor_cost: {
        header: 'Labor Cost',
        value: '${value} USD/year'
      },
      insurance_type: {
        header: 'Insurance Type',
        value: '${value}'
      },
      insurance_cost: {
        header: 'Insurance Cost',
        value: '${value} USD/year'
      }
    },
    capex: {
      equipment: {
        header: 'Equipment Cost',
        value: '${value} USD'
      },
      construction: {
        header: 'Construction Cost',
        value: '${value} USD'
      },
      annual_revenue: {
        header: 'Revenue',
        value: '${value} USD/year'
      },
      opexTotal: {
        header: 'Total Opex',
        value: '${value} USD/year'
      },
      ebitda: {
        header: 'EBITDA',
        value: '${value} USD/year'
      }
    },
    investment_performance: {
      irr: {
        header: 'Project IRR',
        value: '${value}%'
      },
      effective_irr: {
        header: 'Estimated Return Rate',
        value: '${value}%'
      },
      total_estimated_revenue: {
        header: 'Total Estimated Revenue',
        value: '${value} USD'
      },
      project_estimated_lifetime: {
        header: 'Share Lifetime',
        value: '${value} years'
      }
    },
    contribution_details: {
      share_count: {
        header: 'Share Count',
        value: '${value}'
      },
      share_price: {
        header: 'Share Price',
        value: '${value} USD'
      },
      offer_percentage: {
        header: 'Offer Percentage',
        value: '${value}%'
      },
      amount_to_be_raised: {
        header: 'Amount to be Raised',
        value: '${value} USD'
      }
    },
    dates: {
      sale_period_start: {
        header: 'Sale Start Date',
        value: '${value}'
      },
      sale_period_end: {
        header: 'Sale End Date',
        value: '${value}'
      },
      dividend_period_start: {
        header: 'Dividend Start Date',
        value: '${value}'
      },
      dividend_period_end: {
        header: 'Dividend End Date',
        value: '${value}'
      }
    },
    youShouldKnow: {
      header: 'Key Takeaways',
      description:
        '* Provided financial details are calculated as estimations, those values are estimated according to recent annual finances.'
    }
  },
  technologyDetails: {
    header: 'Technology Details',
    labels: {
      production: 'Production',
      modules: 'Technical',
      contractors_suppliers: 'Constructors & Suppliers'
    },
    production: {
      peak_power: {
        header: 'Peak Power',
        value: '${value}'
      },
      annual_sunshine: {
        header: 'Annual Sunshine',
        value: '${value} hours/year'
      },
      effective_power: {
        header: 'Effective Power',
        value: '${value} [UNIT]'
      },
      annual_production: {
        header: 'Annual Production',
        value: '${value}'
      },
      annual_energy_production: {
        header: 'Annual Energy Production',
        value: '${value} kWh'
      },
      daily_average_sunny_hours: {
        header: 'Daily Average Sunny Hours',
        value: '${value} hours/day'
      },
      guaranteed_system_performance_ratio: {
        header: 'Guaranteed System Performance Ratio',
        value: '${value}%'
      },
      hours_of_sunlight: {
        header: 'Hours of Sunlight',
        value: '${value} hours/year'
      },
      simulation_performance_ratio: {
        header: 'Simulation Performance Ratio',
        value: '${value}%'
      },
      specific_production: {
        header: 'Specific Production',
        value: '${value} kWh/kWp/year'
      }
    },
    panel_details: {
      type: {
        header: 'Type',
        value: '${value} [UNIT]'
      },
      power: {
        header: 'Power',
        value: '${value} [UNIT]'
      },
      lifetime: {
        header: 'Lifetime',
        value: '${value} years'
      },
      producer: {
        header: 'Producer',
        value: '${value}'
      },
      quantity: {
        header: 'Quantity',
        value: '${value}'
      },
      guarantee: {
        header: 'Guarantee',
        value: '${value} years'
      },
      efficiency: {
        header: 'Efficiency',
        value: '${value}%'
      }
    },
    construction_details: {
      cables: {
        header: 'Cables',
        value: '${value}'
      },
      pv_area: {
        header: 'PV Area',
        value: '${value} sqm'
      },
      array_type: {
        header: 'Array Type',
        value: '${value}'
      },
      module_power: {
        header: 'Module Power',
        value: '${value} Wp'
      },
      dc_to_ac_ratio: {
        header: 'DC-to-AC ratio',
        value: '${value} %'
      },
      epc_contractor: {
        header: 'EPC Contractor',
        value: '${value}'
      },
      mv_transformer: {
        header: 'MV Transformer',
        value: '${value}'
      },
      module_supplier: {
        header: 'Module Supplier',
        value: '${value}'
      },
      number_of_arrays: {
        header: 'Number of Arrays',
        value: '${value}'
      },
      module_dimensions: {
        header: 'Module Dimensions',
        value: '${value}'
      },
      module_manufacturer: {
        header: 'Module Manufacturer',
        value: '${value}'
      },
      om_service_provider: {
        header: 'OM Service Provider',
        value: '${value}'
      },
      number_of_pv_modules: {
        header: 'Number of PV Modules',
        value: '${value}'
      },
      power_to_array_ratio: {
        header: 'Power-to-Array Ratio',
        value: '${value} kWp'
      },
      row_to_row_clearance: {
        header: 'Row to Row Clearance',
        value: '${value} meters'
      },
      total_number_of_scbs: {
        header: 'Total Number of SCBs',
        value: '${value}'
      },
      mechanical_construction: {
        header: 'Mechanical Construction',
        value: '${value} tons of steel'
      },
      transformer_power_level: {
        header: 'Transformer Power Level',
        value: '${value} kVA'
      },
      module_manufacturing_units: {
        header: 'Module Manufacturing Units',
        value: '${value}'
      },
      mounting_system_and_method: {
        header: 'Mounting System',
        value: '${value}'
      },
      module_distribution_company: {
        header: 'Module Distribution Company',
        value: '${value}'
      },
      inverter_type: {
        header: 'Inverter Type',
        value: '${value}'
      },
      inverter_quantity: {
        header: 'Inverter Quantity',
        value: '${value}'
      },
      substation: {
        header: 'Substation',
        value: '${value}'
      },
      distribution_region: {
        header: 'Distribution Region',
        value: '${value}'
      },
      connection_to_distribution_system: {
        header: 'Connection to Distribution',
        value: '${value} Dist. Line'
      }
    },
    inverter_monitoring: {
      type: {
        header: 'Type',
        value: '${value}'
      },
      brand: {
        header: 'Brand',
        value: '${value}'
      },
      capacity: {
        header: 'Capacity',
        value: '${value} [UNIT]'
      },
      quantity: {
        header: 'Quantity',
        value: '${value}'
      },
      guarantee: {
        header: 'Guarantee',
        value: '${value} years'
      },
      monitoring: {
        header: 'Monitoring',
        value: '${value}'
      },
      access_to_information: {
        header: 'Access to Information',
        value: '${value} [UNIT]'
      }
    },
    contractors_suppliers: {
      module_manufacturer: {
        header: 'Module Manufacturer',
        value: '${value}'
      },
      module_manufacturing_units: {
        header: 'Module Manufacturing Units',
        value: '${value}'
      },
      epc_contractor: {
        header: 'EPC Contractor',
        value: '${value}'
      },
      mv_transformer: {
        header: 'MV Transformer',
        value: '${value}'
      },
      module_supplier: {
        header: 'Module Supplier',
        value: '${value}'
      },
      om_service_provider: {
        header: 'OM Service Provider',
        value: '${value}'
      },
      inverter_manufacturer: {
        header: 'Inverter Manufacturer',
        value: '${value}'
      },
      inverter_brand: {
        header: 'Inverter Brand',
        value: '${value}'
      },
      monitoring_system: {
        header: 'Monitoring System',
        value: '${value}'
      },
      module_distribution_company: {
        header: 'Module Distribution Company',
        value: '${value}'
      },

      cables: {
        header: 'Cables',
        value: '${value}'
      }
    },
    youShouldKnow: {
      header: 'Key Takeaways'
    }
  },
  marketDetails: {
    header: 'Market Details',
    labels: {
      market_index: 'Market Index',
      price: 'Price',
      investment: 'Investment'
    },
    price: {
      fit_price: {
        header: 'Fit Price',
        value: '${value} USD/kWh'
      },
      market_rank: {
        header: 'Market Rank',
        value: '${value}'
      },
      fit_duration: {
        header: 'Fit Duration',
        value: '${value} years'
      },
      market_price: {
        header: 'Market Price',
        value: '${value} USD/kWh'
      },
      pricing_regime_type: {
        header: 'Pricing Regime Type',
        value: '${value}'
      }
    },
    investment: {
      total_investment: {
        header: 'Total Investment',
        value: '${value} USD'
      },
      last_year_investment: {
        header: 'Last Year Investment',
        value: '${value} USD'
      },
      corporate_tax: {
        header: 'Corporate Tax',
        value: '${value}%'
      },
      profit_share_tax: {
        header: 'Profit Share Tax',
        value: '${value}%'
      }
    },
    youShouldKnow: {
      header: 'Key Takeaways'
    }
  },
  fileZone: {
    download: 'Download'
  }
};
