import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import './FormElements.style.scss';

export default class Checkbox extends React.Component {
  render() {
    const {
      name,
      label,
      error,
      checked,
      setFieldValue,
      onChange,
      translation,
      style,
      className
    } = this.props;
    const baseCN = 'checkbox';
    const errorCN = error && `${baseCN}-error`;

    const CheckboxCX = cx(baseCN, errorCN, className);

    // TODO:
    /**
     * This label value should be a component that can
     * take children for a possible react-router-dom Linking
     * activity, such as TOC link on register form
     */
    return (
      <Translate>
        {({ translate }) => (
          <div className={CheckboxCX} style={style}>
            <input
              type="checkbox"
              name={name}
              onChange={onChange}
              checked={checked}
            />
            <label
              htmlFor={name}
              onClick={() => {
                setFieldValue(name, !checked);
              }}
            >
              {translation ? translate(label) : label}
            </label>
          </div>
        )}
      </Translate>
    );
  }
}
