import React from 'react';
import cx from 'classnames';

import StackedIcon from 'UILib/Icon/StackedIcon';
import './Icon.style.scss';

export default class Icon extends React.Component {
  static Stacked = StackedIcon;
  render() {
    const { base, icon, style, className } = this.props;

    // icon is an object with name, size, fixedWidth, color, rotate, flip, animation options

    const baseCN = 'icon';
    const faBaseCN = base ? `fa${base[0]}` : 'fas';
    const faIconCN = `fa-${icon.name}`;
    const faIconSizeCN = `fa-${icon.size}`;
    const faIconFixedWidthCN = icon.fixedWidth && 'fa-fw';
    const faIconColorCN = icon.color && `${baseCN}-color-${icon.color}`;
    const faIconRotateCN = icon.rotate && `fa-rotate-${icon.rotate}`;
    const faIconFlipCN = icon.flip && `fa-flip-${icon.flip}`;
    const faIconAnimationCN = icon.animation && 'fa-spin';

    const IconCX = cx(
      baseCN,
      faBaseCN,
      faIconCN,
      faIconSizeCN,
      faIconFixedWidthCN,
      faIconColorCN,
      faIconRotateCN,
      faIconFlipCN,
      faIconAnimationCN,
      className
    );

    return <i className={IconCX} style={style} />;
  }
}
