import React from 'react';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import { Wallet, Button, Header, Text } from 'UILib';

import {
  BALANCE_PRECISION,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';
import 'Components/Account/UserDashboard/userDashboard.style.scss';

const calculateTotalBalance = (wallets) => {
  return _.reduce(
    wallets,
    (totalBalance, wallet) => {
      return totalBalance + _.get(wallet, 'attributes.usd_balance');
    },
    0
  );
};

const getWalletBasedOnActiveCurrency = (
  wallets,
  walletCurrency,
  activeCurrency
) => {
  return _.get(
    _.find(wallets, ['attributes.currency', walletCurrency]),
    `attributes.${activeCurrency}_balance`,
    0
  );
};

const determineWalletStatusWithUserVerificationLevel = (verification_level) => {
  return (
    verification_level === 'registered' ||
    verification_level === 'email_verified'
  );
};

const determineUserHasWallet = (wallets, type) => {
  return _.some(wallets, ['attributes.currency', type]);
};

const WalletPane = (props) => {
  return (
    <div className="wallet-pane">
      <Wallet.Card
        size="small"
        type="total"
        disabled={determineWalletStatusWithUserVerificationLevel(
          props.account.user_informations.attributes.verification_level
        )}
        balance={calculateTotalBalance(props.account.user_wallets.wallets)}
        currency="USD"
      />
      <div className="action-buttons">
        <Link to={APP_ROUTES.EXCHANGE}>
          <Button
            bgColor="grey-0"
            borderColor="grey-6"
            color="grey-6"
            content="userDashboard.walletPane.actions.exchange"
            className="button"
            translation
          />
        </Link>
        <Link to={APP_ROUTES.WITHDRAW}>
          <Button
            bgColor="grey-0"
            borderColor="grey-6"
            color="grey-6"
            content="userDashboard.walletPane.actions.withdraw"
            className="button"
            translation
          />
        </Link>
      </div>
      <Header
        content="userDashboard.walletPane.headers.investment"
        translation
        lined
        color="light-grey"
        bgColor="grey-0"
        className="header-section"
      />
      <Wallet.Card
        size="small"
        type="wallet"
        walletCurrency="usdc"
        disabled={determineWalletStatusWithUserVerificationLevel(
          props.account.user_informations.attributes.verification_level
        )}
        hasWallet={determineUserHasWallet(
          props.account.user_wallets.wallets,
          'USDC'
        )}
        balance={getWalletBasedOnActiveCurrency(
          props.account.user_wallets.wallets,
          'USDC',
          props.activeCurrency
        )}
        decimal={
          props.activeCurrency === 'raw'
            ? BALANCE_PRECISION.CRYPTO
            : BALANCE_PRECISION.FIAT
        }
        currency={props.activeCurrency === 'raw' ? 'USDC' : 'USD'}
        translation
        disabledOnClick={() => props.showDisabledWalletModal()}
        onClick={() => props.showWalletModal('USDC')}
      />
      <Header
        content="userDashboard.walletPane.headers.custody"
        translation
        lined
        color="light-grey"
        bgColor="grey-0"
        className="header-section"
      />
      <Wallet.Card
        size="small"
        type="wallet"
        walletCurrency="btc"
        disabled={determineWalletStatusWithUserVerificationLevel(
          props.account.user_informations.attributes.verification_level
        )}
        hasWallet={determineUserHasWallet(
          props.account.user_wallets.wallets,
          'BTC'
        )}
        balance={getWalletBasedOnActiveCurrency(
          props.account.user_wallets.wallets,
          'BTC',
          props.activeCurrency
        )}
        decimal={
          props.activeCurrency === 'raw'
            ? BALANCE_PRECISION.CRYPTO
            : BALANCE_PRECISION.FIAT
        }
        currency={props.activeCurrency === 'raw' ? 'BTC' : 'USD'}
        translation
        disabledOnClick={() => props.showDisabledWalletModal()}
        className="wallet-margin"
        onClick={() => props.showWalletModal('BTC')}
      />
      <Wallet.Card
        size="small"
        type="wallet"
        walletCurrency="eth"
        disabled={determineWalletStatusWithUserVerificationLevel(
          props.account.user_informations.attributes.verification_level
        )}
        hasWallet={determineUserHasWallet(
          props.account.user_wallets.wallets,
          'ETH'
        )}
        balance={getWalletBasedOnActiveCurrency(
          props.account.user_wallets.wallets,
          'ETH',
          props.activeCurrency
        )}
        decimal={
          props.activeCurrency === 'raw'
            ? BALANCE_PRECISION.CRYPTO
            : BALANCE_PRECISION.FIAT
        }
        currency={props.activeCurrency === 'raw' ? 'ETH' : 'USD'}
        translation
        disabledOnClick={() => props.showDisabledWalletModal()}
        className="wallet-margin"
        onClick={() => props.showWalletModal('ETH')}
      />
      <Wallet.CurrencySwitch
        align="right"
        active={props.activeCurrency}
        toggleAction={(currency) => {
          props.changeActiveCurrency(currency);
        }}
      />

      <span>
        <Text
          type="intro"
          color="light-grey"
          content="userDashboard.walletPane.accountManagement"
          translation
          className="management-action"
        />
      </span>
    </div>
  );
};

export default WalletPane;
