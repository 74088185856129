import React from 'react';

import { Icon, Text, Timeline } from 'UILib';
import { determineRemainingDays } from 'Utils/Helpers/component.helpers';

import 'Components/ProjectDetail/projectDetail.style.scss';

const parseRemainingDays = (date) => {
  return Math.floor(determineRemainingDays(date));
};

const shouldRenderHighlight = (date) => {
  return determineRemainingDays(date) > 0;
};

const Roadmap = (props) => {
  return (
    <div className="roadmap-zone">
      {shouldRenderHighlight(props.roadmapDates.sale_period.start_date) && (
        <div className="highlight">
          <Icon
            base="solid"
            icon={{ name: 'star', color: 'white' }}
            className="icon"
          />
          <Text
            type="intro"
            content="projectDetail.roadmap.project_highlighted"
            data={{
              remaining_days: parseRemainingDays(
                props.roadmapDates.sale_period.start_date
              )
            }}
            translation
            color="white"
            className="text"
          />
        </div>
      )}
      <Timeline
        order={props.roadmapOrder}
        data={props.roadmapDates}
        indicatedEvent="sale_period"
        className="roadmap-div"
      />
    </div>
  );
};

export default Roadmap;
