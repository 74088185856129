export const WITHDRAW_STEP = {
  CREATE: 'create',
  CONFIRM: 'confirm'
};

export const WITHDRAW_TYPE = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDC: 'USDC'
};

export const WITHDRAW_FLOW_RESET = 'WITHDRAW_FLOW_RESET';
export const CHANGE_ACTIVE_CURRENCY_TO_WITHDRAW =
  'CHANGE_ACTIVE_CURRENCY_TO_WITHDRAW';
export const CREATE_WITHDRAW_PENDING = 'CREATE_WITHDRAW_PENDING';
export const CREATE_WITHDRAW_SUCCESS = 'CREATE_WITHDRAW_SUCCESS';
export const CREATE_WITHDRAW_FAILURE = 'CREATE_WITHDRAW_FAILURE';
export const CONFIRM_WITHDRAW_PENDING = 'CONFIRM_WITHDRAW_PENDING';
export const CONFIRM_WITHDRAW_SUCCESS = 'CONFIRM_WITHDRAW_SUCCESS';
export const CONFIRM_WITHDRAW_FAILURE = 'CONFIRM_WITHDRAW_FAILURE';
export const WITHDRAW_CONFIRM_NO_TIME_FAILURE =
  'WITHDRAW_CONFIRM_NO_TIME_FAILURE';
