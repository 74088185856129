import {
  RESET_PASSWORD_FLOW_RESET,
  RESET_PASSWORD_START_PENDING,
  RESET_PASSWORD_SEND_VERIFICATION_PENDING,
  RESET_PASSWORD_VERIFY_CODE_EMAIL_PENDING,
  RESET_PASSWORD_VERIFY_CODE_OTP_PENDING,
  RESET_PASSWORD_VERIFY_CODE_OTP_FAILURE,
  RESET_PASSWORD_VERIFY_USER_INFORMATION_PENDING,
  RESET_PASSWORD_EXECUTE_PENDING
} from 'Constants/Authentication/ResetPassword.constants';
import { removeFromLocalStorage } from 'Utils/Helpers/localstorage.helpers';

export function resetPasswordFlowReset() {
  return {
    type: RESET_PASSWORD_FLOW_RESET
  };
}

export function resetPasswordFlowStart(formState) {
  return {
    type: RESET_PASSWORD_START_PENDING,
    payload: { formState }
  };
}

export function resetPasswordFlowSendVerification(email, action_token, option) {
  return {
    type: RESET_PASSWORD_SEND_VERIFICATION_PENDING,
    payload: { email, action_token, option }
  };
}

export function resetPasswordFlowVerifyCodeEmail(verification_code) {
  removeFromLocalStorage('access_token');
  return {
    type: RESET_PASSWORD_VERIFY_CODE_EMAIL_PENDING,
    payload: { verification_code }
  };
}

export function resetPasswordFlowVerifyCodeOTP(email, action_token, otp_code) {
  return {
    type: RESET_PASSWORD_VERIFY_CODE_OTP_PENDING,
    payload: { email, action_token, otp_code }
  };
}

export function resetPasswordFlowVerifyCodeOTPFailure() {
  return {
    type: RESET_PASSWORD_VERIFY_CODE_OTP_FAILURE,
    payload: {
      client_code: 701
    }
  };
}

export function resetPasswordFlowVerifyUserInformation(
  email,
  action_token,
  national_id,
  date_of_birth
) {
  return {
    type: RESET_PASSWORD_VERIFY_USER_INFORMATION_PENDING,
    payload: { email, action_token, national_id, date_of_birth }
  };
}

export function resetPasswordFlowExecute(formState) {
  return {
    type: RESET_PASSWORD_EXECUTE_PENDING,
    payload: { formState }
  };
}
