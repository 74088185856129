import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { determineFlowComponent } from 'Utils/Helpers/flow.helpers';
import {
  resetPasswordFlowReset,
  resetPasswordFlowStart,
  resetPasswordFlowSendVerification,
  resetPasswordFlowVerifyCodeOTP,
  resetPasswordFlowVerifyCodeOTPFailure,
  resetPasswordFlowVerifyUserInformation,
  resetPasswordFlowExecute
} from 'Actions/Authentication/ResetPassword.actions';

import { resetpasswordflow } from 'Containers/Authentication/Flows/ResetPassword.flow';

import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import {
  RESET_PASSWORD_STEP,
  RESET_PASSWORD_TYPE
} from 'Constants/Authentication/ResetPassword.constants';
import * as validations from 'Containers/Authentication/Validations/ResetPassword.validation';

class ResetPasswordContainer extends Component {
  componentWillUnmount() {
    this.props.resetPasswordFlowReset();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      step,
      status,
      verification_type,
      email,
      action_token,
      verification_options
    } = nextProps.resetPassword;

    if (
      /**
       * CASE #1 - User has a single verification_options, which is by design "email"
       * When START step returns a user with this property, it will prevent Flow Component
       * rendering & directly take action based on the user's only verification_options
       */
      step === RESET_PASSWORD_STEP.SEND_VERIFICATION &&
      status === REQUEST_STATUS.NOT_DEFINED &&
      verification_type === RESET_PASSWORD_TYPE.NOT_DEFINED &&
      verification_options.length === 1
    ) {
      this.props.resetPasswordFlowSendVerification(
        email,
        action_token,
        verification_options[0]
      );
      return false;
    } else if (
      step === RESET_PASSWORD_STEP.SEND_VERIFICATION &&
      status === REQUEST_STATUS.PENDING &&
      verification_type === verification_options[0] &&
      verification_options.length === 1
    ) {
      return false;
    }
    return true;
  }

  render() {
    const ElementTag = determineFlowComponent(
      resetpasswordflow,
      this.props.resetPassword
    );
    return <ElementTag {...this.props} {...validations} />;
  }
}

const mapStateToProps = (state) => {
  return {
    resetPassword: state.resetPassword
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetPasswordFlowReset,
      resetPasswordFlowStart,
      resetPasswordFlowSendVerification,
      resetPasswordFlowVerifyCodeOTP,
      resetPasswordFlowVerifyCodeOTPFailure,
      resetPasswordFlowVerifyUserInformation,
      resetPasswordFlowExecute
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
