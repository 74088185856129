import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import { Icon, Text } from 'UILib';
import { transformBalance } from 'Utils/Helpers/component.helpers';

import './Wallet.style.scss';

export default class WalletCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false
    };
  }

  walletMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  walletMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const {
      size,
      type,
      walletCurrency,
      disabled,
      hasWallet,
      balance,
      decimal,
      currency,
      disabledOnClick,
      onClick,
      style,
      className
    } = this.props;

    const [integer, float] = transformBalance(balance, decimal);

    const baseCN = 'wallet';
    const sizeCN = `${baseCN}-${size}`;
    const typeCN = `${baseCN}-${size}-${type}`;

    const disabledCN = disabled && `${baseCN}-disabled`;

    const hoverCN =
      this.state.isHovered &&
      type === 'wallet' &&
      `${baseCN}-${size}-${type}-hovered`;

    const walletCX = cx(baseCN, sizeCN, typeCN, hoverCN, className);
    const disabledWalletCX = cx(baseCN, sizeCN, typeCN, disabledCN, className);

    switch (type) {
      case 'wallet':
        return hasWallet ? (
          <div
            className={walletCX}
            style={style}
            onMouseEnter={() => {
              this.walletMouseEnter();
            }}
            onMouseLeave={() => {
              this.walletMouseLeave();
            }}
            onClick={() => {
              onClick();
            }}
          >
            {this.state.isHovered ? (
              <div className="deposit">Deposit</div>
            ) : (
              <div className="add">
                <Icon base="solid" icon={{ name: 'plus', color: 'grey-6' }} />
              </div>
            )}
            <Translate>
              {({ translate }) => (
                <div className="header-zone">
                  <div className="header">
                    {translate(
                      `userDashboard.walletPane.${walletCurrency}.header`
                    )}
                  </div>
                  <div className="subheader">
                    {translate(
                      `userDashboard.walletPane.${walletCurrency}.subheader`
                    )}
                  </div>
                </div>
              )}
            </Translate>
            <div className="balance-zone">
              <div>
                <span className="integer">{integer}</span>
                <span className="float">{float}</span>
                <span className="currency">{currency}</span>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={disabled ? disabledWalletCX : walletCX}
            style={style}
            onMouseEnter={() => {
              !disabled && this.walletMouseEnter();
            }}
            onMouseLeave={() => {
              !disabled && this.walletMouseLeave();
            }}
            onClick={() => {
              !disabled ? onClick() : disabledOnClick();
            }}
          >
            <Translate>
              {({ translate }) => (
                <div className="header-zone">
                  <div className="header">
                    {translate(
                      `userDashboard.walletPane.${walletCurrency}.header`
                    )}
                  </div>
                  <div className="subheader">
                    {translate(
                      `userDashboard.walletPane.${walletCurrency}.disabled`
                    )}
                  </div>
                </div>
              )}
            </Translate>
            <div className="balance-zone">
              <div className="create-now">
                <Icon
                  base="solid"
                  icon={{ name: 'plus', color: 'grey-3' }}
                  className="plus"
                />
                <Text content="Create Now" color="grey-3" className="text" />
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className={walletCX} style={style}>
            <Translate>
              {({ translate }) => (
                <div className="header-zone">
                  <div className="header">
                    {translate('userDashboard.walletPane.total.header')}
                  </div>
                  <div className="subheader">
                    {translate('userDashboard.walletPane.total.subheader')}
                  </div>
                </div>
              )}
            </Translate>
            <div className="balance-zone">
              <div>
                <span className="integer">{integer}</span>
                <span className="float">{float}</span>
                <span className="currency">{currency}</span>
              </div>
            </div>
          </div>
        );
    }
  }
}
