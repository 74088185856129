import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { determineFlowComponent } from 'Utils/Helpers/flow.helpers';
import {
  phoneActivationFlowReset,
  phoneActivationFlowStart,
  phoneActivationFlowAdd,
  phoneActivationAddErrorClearing,
  phoneActivationFlowConfirm,
  phoneActivationConfirmNoTime
} from 'Actions/Account/PhoneActivation.actions';

import { phoneactivationflow } from 'Containers/Account/Flows/PhoneActivation.flow';
import * as validations from 'Containers/Account/Validations/PhoneActivation.validation';

class PhoneActivationContainer extends Component {
  componentWillUnmount() {
    this.props.phoneActivationFlowReset();
  }

  render() {
    const ElementTag = determineFlowComponent(
      phoneactivationflow,
      this.props.phoneActivation
    );
    return <ElementTag {...this.props} {...validations} />;
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    phoneActivation: state.phoneActivation
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      phoneActivationFlowReset,
      phoneActivationFlowStart,
      phoneActivationFlowAdd,
      phoneActivationAddErrorClearing,
      phoneActivationFlowConfirm,
      phoneActivationConfirmNoTime
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneActivationContainer);
