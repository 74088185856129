export const userDashboard = {
  phoneActivationMessage: {
    header:
      'Hello, please verify your phone number to deposit money into your wallets.',
    content:
      "To provide a secure investment experience, we don't permit creating any transaction before verifying an account.",
    action: 'Verify Account'
  },
  kycVerificationMessage: {
    header:
      'Almost done! You have to verify your national identity to invest in energy projects.',
    content:
      'Please, verify your national identity to invest in listed energy projects and withdraw money from your account.',
    action: 'Verify Identity'
  },
  kycApprovalMessage: {
    header:
      'Thank you for your submission! It’s now our turn to review your submission.',
    content:
      'We’ll review your submission and inform you about the result as soon as possible.'
  },
  walletPane: {
    total: {
      header: 'Total Balance',
      subheader: 'Cumulative Balance of Wallets'
    },
    usdc: {
      header: 'USDC Wallet',
      subheader: 'Balance',
      disabled: "You don't have a USDC wallet."
    },
    btc: {
      header: 'BTC Wallet',
      subheader: 'Balance',
      disabled: "You don't have a BTC wallet."
    },
    eth: {
      header: 'ETH Wallet',
      subheader: 'Balance',
      disabled: "You don't have a ETH wallet."
    },
    usd: {
      header: 'USD Wallet',
      subheader: 'Balance',
      disabled: "You don't have a USD wallet."
    },
    actions: {
      exchange: 'Exchange',
      withdraw: 'Withdraw'
    },
    headers: {
      investment: 'Investment Wallet',
      custody: 'Custody Wallets'
    },
    accountManagement: 'Account Management'
  },
  walletModal: {
    header: {
      BTC: 'Deposit Bitcoin',
      ETH: 'Deposit Ethereum',
      USDC: 'Deposit USD Coin'
    },
    description:
      'By depositing tokens to this address, you agree to our deposit recovery policy. Depositing tokens other than ${selectedCurrency} to this address may result in your funds being lost.',
    failure: {
      REGENERATE_USER_SELECTED_WALLET_ADDRESS_FAILURE:
        'It seems that you have reached maximum number of regeneration. Please, try again later.',
      GET_USER_SELECTED_WALLET_DETAILS_FAILURE:
        'Something went wrong. We could not list your wallets, please refresh the page or contact us about the problem.'
    },
    walletCreated: 'Your wallet has been created.',
    walletAddress: {
      header: 'Wallet Address',
      regenerate: 'Regenerate Wallet Address',
      copied: 'Copied'
    },
    actions: {
      how: 'How To Do',
      done: 'Done'
    }
  },
  disabledWalletModal: {
    header: 'Verify Your Phone Number',
    text:
      'You need to verify your phone number to create a wallet. Only phone number verified users can create a wallet to provide secure investment experience.',
    cta: 'Start Phone Activation'
  }
};
