import React from 'react';
import { Button } from 'UILib';

const LoadMore = (props) => {
  return (
    <div className="load-more-zone">
      <Button
        content="projectListing.loadMore.action"
        translation
        color="grey-4"
        bgColor="white"
        borderColor="grey-4"
        className="load-more-button"
        onClick={() => {
          props.handleLoadMoreProjects();
        }}
      />
    </div>
  );
};

export default LoadMore;
