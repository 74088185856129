import { register } from './translations/register.locale';
import { verifyRegister } from './translations/verifyRegister.locale';
import { login } from './translations/login.locale';
import { resetPassword } from './translations/resetPassword.locale';
import { phoneActivation } from './translations/phoneActivation.locale';
import { userDashboard } from './translations/UserDashboard/userDashboard.locale';
import { status_codes } from './translations/status_codes.locale';
import { projectDetail } from './translations/ProjectDetail/projectDetail.locale';
import { projectListing } from './translations/ProjectListing/projectListing.locale';
import { investmentTable } from './translations/UILib/table.locale';
import { commitment } from './translations/commitment.locale';
import { investment } from './translations/investment.locale';
import { logout } from './translations/logout.locale';
import { workInProgress } from './translations/workInProgress.locale';
import { withdraw } from './translations/withdraw.locale';
import { exchange } from './translations/exchange.locale';
import { kyc } from './translations/kyc.locale';
import { notFound } from './translations/notFound.locale';

export default {
  register,
  verifyRegister,
  login,
  resetPassword,
  phoneActivation,
  userDashboard,
  status_codes,
  projectDetail,
  investmentTable,
  commitment,
  investment,
  projectListing,
  logout,
  workInProgress,
  withdraw,
  exchange,
  kyc,
  notFound
};
