import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import { Text } from 'UILib';

import './Timeline.style.scss';

export default class Timeline extends React.Component {
  isDateInPast = (date) => {
    return moment.duration(moment(date).diff(moment(new Date()))).asDays() < 0;
  };

  determineCurrentEvent = () => {
    let pastEvents = [];
    this.props.order.forEach((event) => {
      if (this.isDateInPast(this.props.data[event].start_date)) {
        pastEvents.push(event);
      }
    });
    return pastEvents.pop();
  };

  render() {
    const { order, data, indicatedEvent, style, className } = this.props;

    const baseCN = 'timeline';

    const TimelineCX = cx(baseCN, className);

    const currentEvent = this.determineCurrentEvent();
    return (
      <div className="timeline-wrapper">
        <div className={TimelineCX} style={style}>
          {order.map((event, idx) => {
            const inPast = this.isDateInPast(data[event].start_date);
            return (
              <div
                className={`event-wrapper event-width-${order.length}`}
                key={idx}
              >
                <div
                  className={`event-circle-${
                    inPast ? 'past' : 'future'
                  } ${event === indicatedEvent && 'event-circle-sale'}`}
                />
                {currentEvent === event && (
                  <div className="event-circle-current-shadow" />
                )}
                <div className={`event-line-${inPast ? 'past' : 'future'}`} />
                {event === indicatedEvent && (
                  <div className="event-circle-sale-shadow" />
                )}

                <div
                  className={
                    idx % 2 === 0 ? 'event-text-top' : 'event-text-bottom'
                  }
                >
                  <Text
                    content={event}
                    transform="title"
                    className={`event-text-header ${currentEvent === event &&
                      'event-text-header-current'} ${event === indicatedEvent &&
                      'event-text-header-indicated'} ${
                      inPast
                        ? 'event-text-header-past'
                        : 'event-text-header-future'
                    }`}
                  />
                  <Text
                    content={`Start Date: ${moment(
                      data[event].start_date
                    ).format('DD.MM.YYYY')}`}
                    transform="capitalize"
                    className={`event-text-content ${
                      inPast
                        ? 'event-text-content-past'
                        : 'event-text-content-future'
                    }`}
                  />
                  <Text
                    content={`End Date: ${moment(data[event].end_date).format(
                      'DD.MM.YYYY'
                    )}`}
                    transform="capitalize"
                    className={`event-text-content ${
                      inPast
                        ? 'event-text-content-past'
                        : 'event-text-content-future'
                    }`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
