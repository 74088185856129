import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Text, Button, Icon } from 'UILib';

import 'Components/Account/UserDashboard/userDashboard.style.scss';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

class ActivateYourAccountModal extends Component {
  render() {
    return (
      <Modal {...this.props} closeIcon>
        <div className="disabled-wallet-modal-wrapper">
          <Text
            type="h1"
            content="userDashboard.disabledWalletModal.header"
            translation
            className="header"
          />
          <Icon
            base="solid"
            icon={{ name: 'mobile-alt', color: 'grey-2' }}
            className="icon"
          />
          <Text
            type="intro"
            content="userDashboard.disabledWalletModal.text"
            translation
            className="text"
          />
          <Link to={APP_ROUTES.PHONE_ACTIVATION}>
            <Button
              color="white"
              borderColor="orange-3"
              bgColor="orange-3"
              content="userDashboard.disabledWalletModal.cta"
              translation
              className="action"
            />
          </Link>
        </div>
      </Modal>
    );
  }
}

export default ActivateYourAccountModal;
