import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  kycFlowReset,
  kycMoveToGivenStep,
  kycGetCurrentStep,
  kycFlowStart,
  kycFlowPersonal,
  kycFlowPersonalPatch,
  kycFlowDocuments,
  kycFlowDocumentsPatch,
  kycFlowProfile,
  kycFlowProfilePatch,
  kycFlowAddress,
  kycFlowAddressPatch,
  kycFlowSubmit
} from 'Actions/KYC/KYC.actions';

import { determineFlowComponent } from 'Utils/Helpers/flow.helpers';

import { kycFlow } from 'Containers/KYC/Flows/KYC.flow';

import { initialValues } from 'Containers/KYC/InitialValues/kyc.initialvalues';
import validationSchemas from 'Containers/KYC/Validations/kyc.validations';
import { KYC_STEP } from 'Constants/KYC/KYC.constants';
import {
  REQUEST_STATUS,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';

class KYCContainer extends Component {
  componentDidMount() {
    if (
      this.props.account.user_informations.attributes.verification_level !==
      'phone_verified'
    ) {
      this.props.history.push(APP_ROUTES.DASHBOARD);
    }
    this.props.kycGetCurrentStep(this.props.account.access_token);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.kyc.step !== KYC_STEP.START &&
      this.props.kyc.step === KYC_STEP.START &&
      this.props.kyc.status === REQUEST_STATUS.NOT_DEFINED
    ) {
      this.props.kycFlowStart(this.props.account.access_token);
    }
    if (
      prevProps.kyc.step !== KYC_STEP.GET_STEP &&
      this.props.kyc.step === KYC_STEP.GET_STEP &&
      this.props.kyc.status === REQUEST_STATUS.NOT_DEFINED
    ) {
      this.props.kycGetCurrentStep(this.props.account.access_token);
    }
  }

  render() {
    const ElementTag = determineFlowComponent(kycFlow, this.props.kyc);
    return (
      <ElementTag
        {...this.props}
        {...this.state}
        initialValues={initialValues}
        {...validationSchemas}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    kyc: state.kyc
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      kycFlowReset,
      kycMoveToGivenStep,
      kycGetCurrentStep,
      kycFlowStart,
      kycFlowPersonal,
      kycFlowPersonalPatch,
      kycFlowDocuments,
      kycFlowDocumentsPatch,
      kycFlowProfile,
      kycFlowProfilePatch,
      kycFlowAddress,
      kycFlowAddressPatch,
      kycFlowSubmit
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCContainer);
