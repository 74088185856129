import React, { Component } from 'react';
import moment from 'moment';

import { Icon, Text, Button, Slider } from 'UILib';

import {
  elementIsVisibleInViewport,
  transformBalance,
  determineRemainingDays
} from 'Utils/Helpers/component.helpers';

import 'Components/ProjectDetail/projectDetail.style.scss';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';
const HeroZoneInformationDisplay = (props) => {
  return (
    <div className={`information ${!props.mobile && 'not-visible'}`}>
      <Text
        type="intro"
        content={props.header}
        translation
        textAlign="left"
        className="header"
        transform="toUpper"
      />
      <Text
        type="intro"
        content={props.subheader}
        textAlign="left"
        className="subheader"
        transform={
          props.subheaderTransform === undefined || props.subheaderTransform
            ? 'toUpper'
            : null
        }
      />
    </div>
  );
};

class HeroZone extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (this.heroZoneElement) {
      this.props.toggleHangingNavbarVisible(
        !elementIsVisibleInViewport(this.heroZoneElement, true)
      );
    }
  };

  parseRemainingDays = (endDate) => {
    return Math.floor(determineRemainingDays(endDate));
  };

  shouldRenderHighlight = (endDate) => {
    return determineRemainingDays(endDate) > 0;
  };

  render() {
    return (
      <div className="hero-zone">
        {this.shouldRenderHighlight(
          this.props.contribution.sale_period.start_date
        ) && (
          <div className="highlight">
            <Icon
              base="solid"
              icon={{ name: 'star', color: 'white' }}
              className="icon"
            />
            <Text
              type="intro"
              content="projectDetail.heroZone.project_highlighted"
              data={{
                remaining_days: this.parseRemainingDays(
                  this.props.contribution.sale_period.start_date
                )
              }}
              translation
              color="white"
              className="text"
            />
          </div>
        )}
        <div className="hero-wrapper" ref={(el) => (this.heroZoneElement = el)}>
          <div className="general-information">
            {this.props.information.type && (
              <HeroZoneInformationDisplay
                mobile={false}
                header="projectDetail.heroZone.general_information_titles.type"
                subheader={this.props.information.type}
              />
            )}
            {this.props.information.status && (
              <HeroZoneInformationDisplay
                mobile={true}
                header="projectDetail.heroZone.general_information_titles.status"
                subheader={this.props.information.status.replace(/_/g, ' ')}
              />
            )}
            {(this.props.information.city ||
              this.props.information.country) && (
              <HeroZoneInformationDisplay
                mobile={true}
                header="projectDetail.heroZone.general_information_titles.location"
                subheader={`${this.props.information.city} / ${this.props.information.country}`}
              />
            )}
            {this.props.information.plant_capacity && (
              <HeroZoneInformationDisplay
                mobile={true}
                header="projectDetail.heroZone.general_information_titles.capacity"
                subheader={`${this.props.information.plant_capacity} kWp`}
                subheaderTransform={false}
              />
            )}
            {this.props.information.auditor && (
              <HeroZoneInformationDisplay
                mobile={false}
                header="projectDetail.heroZone.general_information_titles.audited"
                subheader={this.props.information.auditor}
              />
            )}
          </div>
          <div className="project-images">
            <Slider className="image" images={this.props.heroZone.visuals} />
          </div>
          <div className="project-information">
            <div className="information-zone">
              <div className="information-line flex-information-line">
                {this.props.contribution.compensation_details
                  .amount_to_be_raised && (
                  <HeroZoneInformationDisplay
                    header="projectDetail.heroZone.information_zone_titles.amount_to_be_raised"
                    subheader={`${transformBalance(
                      this.props.contribution.compensation_details
                        .amount_to_be_raised,
                      0
                    )} USD`}
                  />
                )}
                {this.props.financial.investment_performance.effective_irr && (
                  <HeroZoneInformationDisplay
                    header="projectDetail.heroZone.information_zone_titles.annual_effective_rate"
                    subheader={`${this.props.financial.investment_performance.effective_irr} %`}
                  />
                )}
              </div>
              <div className="information-line flex-information-line">
                {this.props.contribution.compensation_details
                  .offer_percentage && (
                  <HeroZoneInformationDisplay
                    header="projectDetail.heroZone.information_zone_titles.investment_rate"
                    subheader={`${this.props.contribution.compensation_details.offer_percentage} %`}
                  />
                )}
                {this.props.contribution.sale_period.start_date && (
                  <HeroZoneInformationDisplay
                    header="projectDetail.heroZone.information_zone_titles.investment_start_date"
                    subheader={moment(
                      this.props.contribution.sale_period.start_date
                    ).format('DD.MM.YYYY')}
                  />
                )}
              </div>
              <div className="information-line">
                <Text
                  type="intro"
                  content={
                    this.props.investment_status
                      ? 'projectDetail.heroZone.information_zone_titles.raised_capital'
                      : ''
                  }
                  translation
                  transform="toUpper"
                  textAlign="left"
                  className="header"
                />
                <Text
                  type="intro"
                  content={
                    this.props.investment_status
                      ? `${transformBalance(
                          this.props.statistics.raised_capital,
                          2
                        ).join('')} USD`
                      : ''
                  }
                  transform="toUpper"
                  textAlign="left"
                  className="subheader extended-subheader"
                />
              </div>
            </div>
            <div className="cta-zone">
              <Button
                content={
                  this.props.investment_status
                    ? 'projectDetail.heroZone.cta.button'
                    : 'projectDetail.heroZone.cta.commitment'
                }
                translation
                bgColor="green-4"
                borderColor="green-4"
                color="white"
                className="button"
                onClick={() => {
                  this.props.history.push(
                    this.props.investment_status
                      ? APP_ROUTES.INVESTMENT_WITH_ID(this.props.statistics.id)
                      : APP_ROUTES.COMMITMENT_WITH_ID(this.props.statistics.id)
                  );
                }}
              />
              <Text
                type="intro"
                content="projectDetail.heroZone.cta.link"
                translation
                transform="capitalize"
                color="grey-3"
                className="info"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeroZone;
