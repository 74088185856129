import Start from 'Components/Account/PhoneActivation/Start.component';
import Add from 'Components/Account/PhoneActivation/Add.component';
import Confirm from 'Components/Account/PhoneActivation/Confirm.component';
import Failure from 'Components/Account/PhoneActivation/Failure.component';
import Success from 'Components/Account/PhoneActivation/Success.component';

import { PHONE_ACTIVATION_STEP } from 'Constants/Account/PhoneActivation.constants';

export const phoneactivationflow = {
  flow: [
    {
      step: PHONE_ACTIVATION_STEP.START,
      component: Start
    },
    {
      step: PHONE_ACTIVATION_STEP.ADD,
      component: Add
    },
    {
      step: PHONE_ACTIVATION_STEP.CONFIRM,
      component: Confirm
    }
  ],
  success: {
    component: Success
  },
  failure: {
    component: Failure
  }
};
