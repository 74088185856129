import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import MoonLoader from 'Components/Reusable/Loading/MoonLoader.component';
import { Container, Card } from 'UILib';

import 'Components/Authentication/authentication.style.scss';

const VerifyCodeEmail = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <Card type="auth" centered className="authentication-card-centered">
          <MoonLoader />
        </Card>
      </GlobalLayout>
    </Container>
  );
};

export default VerifyCodeEmail;
