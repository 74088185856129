import React from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import GlobalLayout from 'Layouts/Global.layout';
import MoonLoader from 'Components/Reusable/Loading/MoonLoader.component';
import { Container, Card, Text, Icon } from 'UILib';

import { determineIcon } from 'Utils/Helpers/icon.helpers';
import { RESET_PASSWORD_TYPE } from 'Constants/Authentication/ResetPassword.constants';
import {
  REQUEST_STATUS,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';

import 'Components/Authentication/authentication.style.scss';

const hasVerificationOption = (arr, option) => {
  return _.includes(arr, option);
};

const OptionButtonContent = (props) => {
  return (
    <React.Fragment>
      <Icon
        base={props.type === 'email' ? 'regular' : 'solid'}
        icon={{
          name: determineIcon(
            `authentication.resetPassword.sendVerification.${props.type}`
          ),
          color: props.activeStatus ? 'orange-3' : 'grey-1',
          size: '5x'
        }}
        className="icon"
      />
      <Text
        type="h4"
        content={`resetPassword.send_verification.${props.type}_text`}
        color={props.activeStatus ? 'orange-3' : 'grey-1'}
        translation
      />
    </React.Fragment>
  );
};

const LoadingContent = () => {
  return <MoonLoader color="#ff6600" />;
};

const OptionButton = (props) => {
  const activeStatus = hasVerificationOption(
    props.resetPassword.verification_options,
    props.type
  );
  return activeStatus ? (
    <div
      className="option-button"
      onClick={() => {
        props.resetPasswordFlowSendVerification(
          props.resetPassword.email,
          props.resetPassword.action_token,
          props.type
        );
      }}
    >
      {props.resetPassword.verification_type === props.type &&
      props.resetPassword.status === REQUEST_STATUS.PENDING ? (
        <LoadingContent />
      ) : (
        <OptionButtonContent {...props} activeStatus={activeStatus} />
      )}
    </div>
  ) : (
    <div className="option-button">
      <OptionButtonContent {...props} activeStatus={activeStatus} />
    </div>
  );
};

const SendVerification = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <Card type="auth" centered className="custom-form-zone">
          <Text
            type="h1"
            content="resetPassword.send_verification.header"
            translation
            className="send-verification-header"
          />
          <Container className="send-verification-container">
            <Text
              type="intro"
              content="resetPassword.send_verification.description"
              className="descriptive-text"
              translation
            />
            <Container className="option-selection">
              <OptionButton {...props} type={RESET_PASSWORD_TYPE.EMAIL} />
              <OptionButton {...props} type={RESET_PASSWORD_TYPE.SMS} />
            </Container>
          </Container>
          <Text type="intro" className="bottom-section">
            <Translate id="resetPassword.send_verification.footer.content" />{' '}
            <Link to={APP_ROUTES.LOGIN}>
              <Translate id="resetPassword.send_verification.footer.cta" />
            </Link>
          </Text>
        </Card>
      </GlobalLayout>
    </Container>
  );
};

export default SendVerification;
