import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import KYCStepHeader from 'Components/KYC/components/KYCStepHeader.component';

import { Container, Text, Button } from 'UILib';
import 'Components/KYC/kyc.style.scss';

const Continue = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div className="kyc-page-wrapper">
          <div className="kyc-continue-wrapper">
            <KYCStepHeader />
            <div className="section-wrapper">
              <Text
                content="kyc.continue.header"
                translation
                className="header"
              />
              <Text
                content="kyc.continue.content"
                translation
                className="content"
              />
              <Button
                content="kyc.continue.action"
                translation
                borderColor="orange-3"
                bgColor="orange-3"
                color="white"
                className="application"
                onClick={() => {
                  props.kycMoveToGivenStep(props.kyc.previous_kyc_step);
                }}
              />
            </div>
          </div>
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default Continue;
