import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';

import { Container, Text, Button, Icon } from 'UILib';
import 'Components/KYC/kyc.style.scss';

const Failure = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div className="kyc-page-wrapper">
          <div className="kyc-success-wrapper">
            <div className="section-wrapper">
              <div className="step-wrapper">
                <Icon
                  base="regular"
                  icon={{
                    name: 'times-circle',
                    color: 'orange-3'
                  }}
                  className="results-icon"
                />
              </div>
              <Text
                content="kyc.failure.header"
                translation
                className="header"
              />
              <Text
                content="kyc.failure.content"
                translation
                className="content"
              />
              <div className="action-zone">
                <Button
                  content="kyc.failure.action.reset"
                  translation
                  borderColor="orange-3"
                  bgColor="white"
                  color="orange-3"
                  className="back"
                  onClick={() => {
                    props.kycFlowReset();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default Failure;
