import { all, put, takeLatest } from 'redux-saga/effects';

import apiGenerator from 'Utils/Helpers/api.helpers';
import {
  getStatusCodeFamily,
  apiErrorHandler,
  sagaDelayer
} from 'Utils/Helpers/saga.helpers';
import {
  API_ENDPOINTS,
  STATUS_TYPE
} from 'Constants/GlobalConstants/Global.constants';
import * as ProjectListingConstants from 'Constants/ProjectListing/ProjectListing.constants';

function* retrieveProjectListSaga() {
  const api = apiGenerator('get')(
    API_ENDPOINTS.PROJECTS.GET_LISTABLE_INVESTABLE_PROJECTS
  );
  try {
    const response = yield api;

    yield sagaDelayer(2000);
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ProjectListingConstants.RETRIEVE_PROJECT_LIST_SUCCESS,
        payload: response.data.projects
      });
    }
  } catch (err) {
    yield put({
      type: ProjectListingConstants.RETRIEVE_PROJECT_LIST_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ProjectListingConstants.RETRIEVE_PROJECT_LIST_PENDING,
      retrieveProjectListSaga
    )
  ]);
}
