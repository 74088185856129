import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import { determineIcon } from 'Utils/Helpers/icon.helpers';
import { Container, Card, Icon, Text, Button } from 'UILib';

import 'Components/Logout/Logout.style.scss';

const Logout = () => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <Card type="auth" centered className="logout-card">
          <Icon
            base="regular"
            icon={{
              name: determineIcon('logout'),
              color: 'green-3',
              size: '10x'
            }}
            className="icon"
          />
          <Text content="logout.success" translation className="text" />
          <Button
            content="logout.action"
            translation
            className="action"
            bgColor="orange-3"
            borderColor="orange-3"
            color="white"
            onClick={() => {
              window.location.href = process.env.REACT_APP_LANDING_PAGE;
            }}
          />
        </Card>
      </GlobalLayout>
    </Container>
  );
};

export default Logout;
