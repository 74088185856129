import {
  RESET_COMMITMENT_TO_PROJECT,
  COMMITMENT_TO_PROJECT_PENDING
} from 'Constants/Commitment/Commitment.constants';

export function resetCommitmentToProject() {
  return {
    type: RESET_COMMITMENT_TO_PROJECT
  };
}

export function commitmentToProject(projectId, access_token, commitmentAmount) {
  return {
    type: COMMITMENT_TO_PROJECT_PENDING,
    payload: { projectId, access_token, commitmentAmount }
  };
}
