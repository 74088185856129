import * as Yup from 'yup';
import moment from 'moment';

const getSelectedDateObject = (value) => {
  var dateString = value.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  const year = dateString[1];
  const month = parseInt(dateString[2], 10) - 1;
  const date = dateString[3];

  return new Date(year, month, date);
};

const PersonalValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  nationality: Yup.string().required('Required'),
  national_id: Yup.string()
    .min(8, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  date_of_birth: Yup.string()
    .required('Required')
    .test('is-legal', 'Too young or too old', (value) => {
      const selectedDate = getSelectedDateObject(value);
      const age = moment().diff(selectedDate, 'years');

      return age >= 18 && age < 100;
    })
});

export default { PersonalValidationSchema };
