import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import GlobalLayout from 'Layouts/Global.layout';
import { Container, Card, Text, FormElements, Button } from 'UILib';
import { hasValidationError } from 'Utils/Helpers/component.helpers';
import {
  REQUEST_STATUS,
  RECAPTCHA_SITE_KEY,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';

import 'Components/Authentication/authentication.style.scss';

const initialValues = {
  email: '',
  recaptcha_response: ''
};

class Start extends Component {
  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <Card type="auth" centered className="custom-form-zone">
            <Text
              type="h1"
              content="resetPassword.start.header"
              translation
              className="header"
            />
            <Text
              type="intro"
              content="resetPassword.start.description"
              className="reset-password-start-description"
              translation
            />
            <Container className="custom-form-container">
              <Card.Content>
                <Formik
                  initialValues={initialValues}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={
                    this.props.ResetPasswordStartValidationSchema
                  }
                  onSubmit={(values, { submitForm, setSubmitting }) => {
                    this.props.resetPasswordFlowStart(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Container className="form-container">
                        <FormElements.Input
                          type="email"
                          name="email"
                          error={errors.email && touched.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="resetPassword.start.email_placeholder"
                          translation
                        />
                        <Container className="recaptcha-container">
                          <ReCAPTCHA
                            ref={(r) => (this.captcha = r)}
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={(value) => {
                              setFieldValue('recaptcha_response', value);
                            }}
                          />
                        </Container>
                        {hasValidationError(errors) && (
                          <Container className="error-container">
                            <FormElements.ValidationErrors
                              content="resetPassword.start.validation_errors"
                              translation
                            />
                          </Container>
                        )}
                        <Container className="submit-container">
                          <Button
                            bgColor="orange-3"
                            borderColor="orange-3"
                            color="white"
                            type="submit"
                            content={
                              this.props.resetPassword.status ===
                              REQUEST_STATUS.PENDING
                                ? 'resetPassword.start.loading'
                                : 'resetPassword.start.submit'
                            }
                            translation
                          />
                        </Container>
                      </Container>
                    </form>
                  )}
                </Formik>
              </Card.Content>
            </Container>
            <Text type="intro" className="bottom-section">
              <Translate id="resetPassword.start.footer.content" />{' '}
              <Link to={APP_ROUTES.LOGIN}>
                <Translate id="resetPassword.start.footer.cta" />
              </Link>
            </Text>
          </Card>
        </GlobalLayout>
      </Container>
    );
  }
}

export default Start;
