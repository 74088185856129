import * as Yup from 'yup';

const RegisterValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(32, 'Too Long!')
    .required('Required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null])
    .required('Required'),
  terms_of_service_accept: Yup.bool()
    .test(
      'terms_of_service_accept',
      'Must agree to Terms of Service',
      (value) => value === true
    )
    .required('Required')
});

export default RegisterValidationSchema;
