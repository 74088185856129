import * as LoginConstants from 'Constants/Authentication/Login.constants';
import { LOGIN_STEP } from 'Constants/Authentication/Login.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { USER_VERIFICATION_LEVEL } from 'Constants/Account/Account.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';
import { saveToLocalStorage } from 'Utils/Helpers/localstorage.helpers';

const initialState = {
  step: LOGIN_STEP.LOGIN,
  status: REQUEST_STATUS.NOT_DEFINED,
  require_recaptcha: false,
  access_token: null,
  error: null,
  history: [],
  otp_code_tries: 0
};

export default function login(state = initialState, action) {
  switch (action.type) {
    // --- FLOW RESET ---
    case LoginConstants.USER_LOGIN_FLOW_RESET:
      return modifyReducer(state, initialState);
    // --- LOGIN Conditions ---
    case LoginConstants.USER_LOGIN_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case LoginConstants.USER_LOGIN_SUCCESS:
      return handleUserLoginSuccess(state, action);
    case LoginConstants.USER_LOGIN_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.NOT_DEFINED,
        error: action.payload
      });
    case LoginConstants.USER_LOGIN_REQUIRE_RECAPTCHA:
      return modifyReducer(state, {
        status: REQUEST_STATUS.NOT_DEFINED,
        error: null,
        require_recaptcha: true
      });
    // --- LOGIN_WITH_MFA Conditions
    case LoginConstants.USER_LOGIN_WITH_MFA_PENDING:
      return modifyReducer(state, {
        step: LOGIN_STEP.MFA,
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case LoginConstants.USER_LOGIN_WITH_MFA_FAILURE:
      return handleOTPFailure(state, action);
    case LoginConstants.USER_LOGIN_WITH_MFA_EXPIRE:
      return modifyReducer(state, {
        step: LOGIN_STEP.MFA,
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    case LoginConstants.USER_LOGIN_WITH_MFA_SUCCESS:
      return modifyReducer(state, {
        step: LOGIN_STEP.REQUEST_USER_INFO,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
    // --- REQUEST_USER_INFO Conditions
    case LoginConstants.USER_LOGIN_GET_USER_INFORMATION_PENDING:
      return modifyReducer(state, {
        step: LOGIN_STEP.REQUEST_USER_INFO,
        status: REQUEST_STATUS.NOT_DEFINED,
        error: null
      });
    case LoginConstants.USER_LOGIN_GET_USER_INFORMATION_FAILURE:
      return modifyReducer(state, {
        step: LOGIN_STEP.REQUEST_USER_INFO,
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    case LoginConstants.USER_LOGIN_GET_USER_INFORMATION_SUCCESS:
      return handleGetUserInformationSuccess(state, action);
    case LoginConstants.USER_LOGIN_RESEND_VERIFICATION_EMAIL:
      return state;
    default:
      return state;
  }
}

function handleUserLoginSuccess(state, action) {
  const { client_code, access_token } = action.payload;

  return modifyReducer(state, {
    step: client_code === 250 ? LOGIN_STEP.MFA : LOGIN_STEP.REQUEST_USER_INFO,
    status: REQUEST_STATUS.NOT_DEFINED,
    access_token: access_token,
    history: [...state.history, action.payload]
  });
}

function handleOTPFailure(state, action) {
  const { otp_code_tries } = state;

  switch (otp_code_tries) {
    case 2:
      return modifyReducer(state, {
        step: LOGIN_STEP.MFA,
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    default:
      return modifyReducer(state, {
        step: LOGIN_STEP.MFA,
        status: REQUEST_STATUS.NOT_DEFINED,
        otp_code_tries: state.otp_code_tries + 1,
        error: action.payload
      });
  }
}

function handleGetUserInformationSuccess(state, action) {
  const { attributes, access_token } = action.payload;

  switch (attributes.verification_level) {
    case USER_VERIFICATION_LEVEL.REGISTERED:
      return modifyReducer(state, {
        step: LOGIN_STEP.RESEND_VERIFICATION_EMAIL,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
    default:
      saveToLocalStorage('access_token', access_token);
      return modifyReducer(state, {
        step: LOGIN_STEP.REDIRECT_TO_DASHBOARD,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
  }
}
