import React from 'react';
import * as _ from 'lodash';
import { Translate } from 'react-localize-redux';

import { Text, FormElements } from 'UILib';
import { transformBalance } from 'Utils/Helpers/component.helpers';
import { INVESTMENT_AMOUNTS } from 'Constants/Investment/Investment.constants';

const PricingItem = (props) => {
  return (
    <div
      onClick={() => {
        props.changeSelectedInvestmentAmount(props.amount);
      }}
      className={`investment-pricing-item ${props.selectedInvestmentAmount ===
        props.amount && 'investment-pricing-active-item'}`}
    >
      {props.preferred && (
        <div className="preferred">
          <Translate id="investment.investmentPane.preferred" />
        </div>
      )}
      <Text
        type="intro"
        content={transformBalance(props.amount, 0)}
        className="pricing-header"
      />
      <Text
        type="intro"
        content={<Translate id="investment.investmentPane.usdc" />}
        className="pricing-subheader"
      />
    </div>
  );
};

const calculateTotalBalance = (wallets) => {
  return _.reduce(
    wallets,
    (totalBalance, wallet) => {
      return totalBalance + _.get(wallet, 'attributes.usd_balance');
    },
    0
  );
};

const InvestmentPane = (props) => {
  return (
    <div className="investment-pane">
      <div className="investment-selection-zone">
        <div className="investment-pricing-table">
          {INVESTMENT_AMOUNTS.map((amount, idx) => (
            <PricingItem
              key={idx}
              amount={amount}
              preferred={amount === '50000'}
              selectedInvestmentAmount={props.selectedInvestmentAmount}
              changeSelectedInvestmentAmount={
                props.changeSelectedInvestmentAmount
              }
            />
          ))}
        </div>
        <div className="investment-pricing-calculation-area">
          <Text
            content="You can also buy shares with a custom amount."
            className="header"
            color="light-grey"
          />
          <div className="form-zone">
            <FormElements.Input
              type="number"
              name="toBeRaised"
              placeholder="Amount to be Raised"
              min="0"
              value={props.customInvestmentAmount.toBeRaised}
              onChange={(e) => {
                props.determineCustomInvestmentAmountCalculator(
                  e.target.name,
                  e.target.value
                );
              }}
              input="input"
            />
            <FormElements.Input
              type="number"
              name="sharesToBuy"
              placeholder="Shares to Buy"
              value={props.customInvestmentAmount.sharesToBuy}
              onChange={(e) => {
                props.determineCustomInvestmentAmountCalculator(
                  e.target.name,
                  e.target.value
                );
              }}
              input="input"
            />
          </div>
          <div
            onClick={() => {
              props.useAllMyBalanceAction(
                calculateTotalBalance(props.account.user_wallets.wallets)
              );
            }}
            className="subheader-zone"
          >
            <Text
              content={`Use all your USDX: ${transformBalance(
                calculateTotalBalance(props.account.user_wallets.wallets),
                0
              )} USDX`}
              className="subheader"
              color="light-grey"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentPane;
