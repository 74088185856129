import * as WithdrawConstants from 'Constants/Withdraw/Withdraw.constants';
import {
  WITHDRAW_STEP,
  WITHDRAW_TYPE
} from 'Constants/Withdraw/Withdraw.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

const initialState = {
  step: WITHDRAW_STEP.CREATE,
  type: WITHDRAW_TYPE.BTC,
  status: REQUEST_STATUS.NOT_DEFINED,
  error: null,
  history: [],
  access_token: null,
  transaction_id: null,
  transaction_createdAt: null,
  otp_code_tries: 0,
  amount: null,
  address: null
};

export default function withdraw(state = initialState, action) {
  switch (action.type) {
    // --- FLOW RESET ---
    case WithdrawConstants.WITHDRAW_FLOW_RESET:
      return initialState;
    case WithdrawConstants.CHANGE_ACTIVE_CURRENCY_TO_WITHDRAW:
      return modifyReducer(state, {
        type: action.payload.currency
      });
    case WithdrawConstants.CREATE_WITHDRAW_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING
      });
    case WithdrawConstants.CREATE_WITHDRAW_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload.error,
        transaction_createdAt: new Date(),
        amount: action.payload.quantity,
        address: action.payload.address
      });
    case WithdrawConstants.CREATE_WITHDRAW_SUCCESS:
      return modifyReducer(state, {
        step: WITHDRAW_STEP.CONFIRM,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload],
        access_token: action.payload.access_token,
        transaction_id: action.payload.transaction_id,
        amount: action.payload.quantity,
        address: action.payload.address,
        transaction_createdAt: new Date()
      });
    case WithdrawConstants.CONFIRM_WITHDRAW_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING
      });
    case WithdrawConstants.CONFIRM_WITHDRAW_FAILURE:
      return handleOTPFailure(state, action);
    case WithdrawConstants.CONFIRM_WITHDRAW_SUCCESS:
      return modifyReducer(state, {
        status: REQUEST_STATUS.SUCCESS,
        history: [...state.history, action.payload]
      });
    case WithdrawConstants.WITHDRAW_CONFIRM_NO_TIME_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    default:
      return state;
  }
}

function handleOTPFailure(state, action) {
  const { otp_code_tries } = state;

  if (otp_code_tries === 2) {
    return modifyReducer(state, {
      status: REQUEST_STATUS.FAILURE,
      error: action.payload
    });
  }
  return modifyReducer(state, {
    status: REQUEST_STATUS.NOT_DEFINED,
    otp_code_tries: state.otp_code_tries + 1,
    error: action.payload
  });
}
