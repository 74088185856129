import React from 'react';

import { Icon } from 'UILib';
import 'Components/KYC/kyc.style.scss';

const KYCStepHeader = (props) => {
  return (
    <div className="step-wrapper">
      <Icon
        base="regular"
        icon={{
          name: 'id-card',
          color: 1 <= props.currentStep ? 'grey-6' : 'grey-2'
        }}
        className="step-icon"
      />
      <Icon
        base="solid"
        icon={{ name: 'ellipsis-h', color: 'grey-2' }}
        className="dots-icon"
      />
      <Icon
        base="regular"
        icon={{
          name: 'file',
          color: 2 <= props.currentStep ? 'grey-6' : 'grey-2'
        }}
        className="step-icon"
      />
      <Icon
        base="solid"
        icon={{ name: 'ellipsis-h', color: 'grey-2' }}
        className="dots-icon"
      />
      <Icon
        base="regular"
        icon={{
          name: 'image',
          color: 3 <= props.currentStep ? 'grey-6' : 'grey-2'
        }}
        className="step-icon"
      />
      <Icon
        base="solid"
        icon={{ name: 'ellipsis-h', color: 'grey-2' }}
        className="dots-icon"
      />
      <Icon
        base="solid"
        icon={{
          name: 'map-marker-alt',
          color: 4 <= props.currentStep ? 'grey-6' : 'grey-2'
        }}
        className="step-icon"
      />
      <Icon
        base="solid"
        icon={{ name: 'ellipsis-h', color: 'grey-2' }}
        className="dots-icon"
      />
      <Icon
        base="regular"
        icon={{
          name: 'check-circle',
          color: 5 <= props.currentStep ? 'green-3' : 'grey-2'
        }}
        className="step-icon"
      />
    </div>
  );
};

export default KYCStepHeader;
