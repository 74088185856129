import React from 'react';

import AuthenticationResult from 'Components/Authentication/Reusable/AuthenticationResult.component';
import { determineIcon } from 'Utils/Helpers/icon.helpers';

const Failure = (props) => {
  return (
    <AuthenticationResult
      header={{
        type: 'h1',
        content: 'resetPassword.failure.header',
        translation: true
      }}
      icon={{
        base: 'regular',
        name: determineIcon('authentication.resetPassword.failure'),
        color: 'black',
        size: '10x'
      }}
      description={{
        type: 'intro',
        color: 'grey-5',
        content:
          props.resetPassword.error.client_code === 403
            ? 'resetPassword.failure.errors.403'
            : `status_codes.client_codes.${props.resetPassword.error.client_code}`,
        translation: true
      }}
      link={{
        to: '/',
        bgColor: 'orange-3',
        borderColor: 'orange-3',
        color: 'white',
        content: 'resetPassword.failure.cta',
        translation: true
      }}
    />
  );
};

export default Failure;
