import React from 'react';
import { Link } from 'react-router-dom';

import GlobalLayout from 'Layouts/Global.layout';
import InvestmentHeader from 'Components/Investment/Components/InvestmentHeader.component';
import WalletPane from 'Components/Account/UserDashboard/Components/WalletPane.component';
import ResultPane from 'Components/Investment/Components/ResultPane.component';

import { transformBalance } from 'Utils/Helpers/component.helpers';
import { Container, Text, Button, Icon } from 'UILib';
import 'Components/Investment/investment.style.scss';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

const Success = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div className="investment-page-wrapper">
          <InvestmentHeader
            information={props.projectDetail.attributes.properties.information}
            statistics={props.projectDetail.attributes.statistics}
          />
          <div className="investment-page-result-wrapper">
            <div className="investment-page-result-left-pane-wrapper">
              <div className="investment-result-success-header-zone">
                <Icon
                  base="regular"
                  icon={{ name: 'check-circle', color: 'green-3' }}
                  className="icon"
                />
                <div className="text-area">
                  <Text
                    content="investment.success.header"
                    translation
                    className="header"
                  />
                  <Text
                    content="investment.success.subheader"
                    translation
                    data={{
                      amount: parseFloat(props.investment.amount),
                      shares: props.customInvestmentAmount.sharesToBuy
                    }}
                    className="subheader"
                  />
                </div>
              </div>
              <div className="investment-result-selection-panel">
                <ResultPane {...props} />
              </div>
              <div className="investment-general-information-zone">
                <div className="information">
                  <Text
                    content="investment.investmentHeader.amountToBeRaised"
                    translation
                    color="grey-3"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.projectDetail.attributes.properties.contribution
                        .compensation_details.amount_to_be_raised,
                      0
                    )} USD`}
                    color="black"
                    className="header"
                  />
                </div>
                <div className="information">
                  <Text
                    content="investment.investmentHeader.raisedCapital"
                    translation
                    color="grey-3"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.projectDetail.attributes.statistics.raised_capital,
                      0
                    )} USD`}
                    color="black"
                    className="header"
                  />
                </div>
                <div className="information">
                  <Text
                    content="investment.investmentHeader.totalShares"
                    translation
                    color="grey-3"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.projectDetail.attributes.properties.contribution
                        .compensation_details.share_count,
                      0
                    )} Shares`}
                    color="black"
                    className="header"
                  />
                </div>
              </div>
              <div className="investment-action-zone">
                <Link to={APP_ROUTES.DASHBOARD}>
                  <Button
                    content="investment.actionDashboard"
                    translation
                    className="action"
                    color="white"
                    bgColor="orange-3"
                    borderColor="orange-3"
                  />
                </Link>
              </div>
            </div>
            <WalletPane {...props} />
          </div>
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default Success;
