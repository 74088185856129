import React, { Component } from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import AuthenticationResult from 'Components/Authentication/Reusable/AuthenticationResult.component';
import MoonLoader from 'Components/Reusable/Loading/MoonLoader.component';
import { Container, Card, Text } from 'UILib';
import { determineIcon } from 'Utils/Helpers/icon.helpers';

import 'Components/Authentication/authentication.style.scss';

const Pending = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <Card type="auth" centered className="authentication-card-centered">
          <Text
            type="h3"
            content="verifyRegister.pending.content"
            translation
          />
          <MoonLoader />
        </Card>
      </GlobalLayout>
    </Container>
  );
};

const Success = (props) => {
  return (
    <AuthenticationResult
      component="VerifyRegisterSuccess"
      header={{
        type: 'h1',
        content: 'verifyRegister.success.header',
        translation: true
      }}
      icon={{
        base: 'regular',
        name: determineIcon('authentication.verifyRegister.success'),
        color: 'green-3',
        size: '10x'
      }}
      description={{
        type: 'intro',
        color: 'grey-5',
        content: 'verifyRegister.success.description',
        translation: true
      }}
      link={{
        to: '/',
        bgColor: 'orange-3',
        borderColor: 'orange-3',
        color: 'white',
        content: 'verifyRegister.success.footer.cta',
        translation: true
      }}
    />
  );
};

const Failure = (props) => {
  return (
    <AuthenticationResult
      component="VerifyRegisterFailure"
      header={{
        type: 'h1',
        content: 'verifyRegister.failure.header',
        translation: true
      }}
      icon={{
        base: 'regular',
        name: determineIcon('authentication.verifyRegister.failure'),
        color: 'black',
        size: '10x'
      }}
      description={{
        type: 'intro',
        color: 'grey-5',
        content: 'verifyRegister.failure.description',
        translation: true
      }}
      link={{
        to: '/',
        bgColor: 'orange-3',
        borderColor: 'orange-3',
        color: 'white',
        content: 'verifyRegister.failure.footer.cta',
        translation: true
      }}
    />
  );
};

export default class VerifyRegister extends Component {
  static Pending = Pending;
  static Success = Success;
  static Failure = Failure;
}
