import * as _ from 'lodash';

import * as AccountConstants from 'Constants/Account/Account.constants';
import * as UserDashboardConstants from 'Constants/Account/UserDashboard.constants';
import { RETRIEVE_USER_INFORMATION_ON_INIT_SUCCESS } from 'Constants/AppInit/init.constants';
import { USER_LOGIN_GET_USER_INFORMATION_SUCCESS } from 'Constants/Authentication/Login.constants';
import { USER_VERIFICATION_LEVEL } from 'Constants/Account/Account.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

export const initialState = {
  access_token: null,
  user_informations: {},
  user_wallets: {
    status: REQUEST_STATUS.NOT_DEFINED,
    wallets: []
  },
  user_wallet_detail: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: null,
    currency: null,
    address: null,
    qr_code: null
  },
  user_invested_projects: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: null,
    projects: []
  },
  user_investable_projects: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: null,
    projects: []
  },
  user_commited_projects: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: null,
    projects: [],
    user_commitments: []
  }
};

export default function account(state = initialState, action) {
  switch (action.type) {
    // --- APP INIT Based Reductions
    case RETRIEVE_USER_INFORMATION_ON_INIT_SUCCESS: {
      const { access_token, ...rest } = action.payload;
      return modifyReducer(state, {
        access_token,
        user_informations: { ...rest }
      });
    }
    // --- LOGIN Based Reductions
    case USER_LOGIN_GET_USER_INFORMATION_SUCCESS: {
      const { access_token, attributes, ...rest } = action.payload;
      if (
        _.get(attributes, 'verification_level') !==
        USER_VERIFICATION_LEVEL.REGISTERED
      ) {
        return modifyReducer(state, {
          access_token: access_token,
          user_informations: { attributes, ...rest }
        });
      }
      return state;
    }
    // --- ACCOUNT Based Reductions
    case AccountConstants.CLEAR_ACCESS_TOKEN_FROM_STORE: {
      return modifyReducer(state, {
        access_token: null
      });
    }
    // --- USER_DASHBOARD Based Reductions
    // --- GET_USER_WALLETS Based Reductions
    case UserDashboardConstants.GET_USER_WALLETS_PENDING: {
      return modifyReducer(state, {
        user_wallets: { ...state.user_wallets, status: REQUEST_STATUS.PENDING }
      });
    }
    case UserDashboardConstants.GET_USER_WALLETS_SUCCESS: {
      return modifyReducer(state, {
        user_wallets: {
          ...state.user_wallets,
          status: REQUEST_STATUS.SUCCESS,
          wallets: action.payload
        }
      });
    }
    case UserDashboardConstants.GET_USER_WALLETS_FAILURE: {
      return modifyReducer(state, {
        user_wallets: { ...state.user_wallets, status: REQUEST_STATUS.FAILURE }
      });
    }
    // --- GET_USER_SELECTED_WALLET_DETAILS Based Reductions
    case UserDashboardConstants.GET_USER_SELECTED_WALLET_DETAILS_PENDING: {
      return modifyReducer(state, {
        user_wallet_detail: {
          ...state.user_wallet_detail,
          status: REQUEST_STATUS.PENDING
        }
      });
    }
    case UserDashboardConstants.GET_USER_SELECTED_WALLET_DETAILS_SUCCESS: {
      return modifyReducer(state, {
        user_wallet_detail: {
          ...state.user_wallet_detail,
          status: REQUEST_STATUS.SUCCESS,
          currency: action.payload.currency,
          address: action.payload.address,
          qr_code: action.payload.qr_code
        }
      });
    }
    case UserDashboardConstants.GET_USER_SELECTED_WALLET_DETAILS_FAILURE: {
      return modifyReducer(state, {
        user_wallet_detail: {
          ...state.user_wallet_detail,
          status: REQUEST_STATUS.FAILURE,
          error: UserDashboardConstants.GET_USER_SELECTED_WALLET_DETAILS_FAILURE
        }
      });
    }
    // --- REGENERATE_USER_SELECTED_WALLET_ADDRESS Based Reductions
    case UserDashboardConstants.REGENERATE_USER_SELECTED_WALLET_ADDRESS_PENDING: {
      return modifyReducer(state, {
        user_wallet_detail: {
          ...state.user_wallet_detail,
          status: REQUEST_STATUS.SUCCESS
        }
      });
    }
    case UserDashboardConstants.REGENERATE_USER_SELECTED_WALLET_ADDRESS_SUCCESS: {
      return modifyReducer(state, {
        user_wallet_detail: {
          ...state.user_wallet_detail,
          status: REQUEST_STATUS.SUCCESS,
          currency: action.payload.currency,
          address: action.payload.address,
          qr_code: action.payload.qr_code
        }
      });
    }
    case UserDashboardConstants.REGENERATE_USER_SELECTED_WALLET_ADDRESS_FAILURE: {
      return modifyReducer(state, {
        user_wallet_detail: {
          ...state.user_wallet_detail,
          status: REQUEST_STATUS.FAILURE,
          error:
            UserDashboardConstants.REGENERATE_USER_SELECTED_WALLET_ADDRESS_FAILURE
        }
      });
    }
    // --- CLEAR_USER_SELECTED_WALLET_DETAILS Based Reductions
    case UserDashboardConstants.CLEAR_USER_SELECTED_WALLET_DETAILS: {
      return modifyReducer(state, {
        user_wallet_detail: {
          ...state.user_wallet_detail,
          status: REQUEST_STATUS.NOT_DEFINED,
          currency: null,
          address: null,
          qr_code: null
        }
      });
    }
    // --- GET_USER_INVESTED_PROJECTS Based Reductions
    case UserDashboardConstants.GET_USER_DASHBOARD_PROJECTS_PENDING: {
      return modifyReducer(state, {
        user_invested_projects: {
          ...state.user_invested_projects,
          status: REQUEST_STATUS.PENDING
        },
        user_investable_projects: {
          ...state.user_investable_projects,
          status: REQUEST_STATUS.PENDING
        }
      });
    }
    case UserDashboardConstants.GET_USER_INVESTED_PROJECTS_SUCCESS: {
      return modifyReducer(state, {
        user_invested_projects: {
          ...state.user_invested_projects,
          status: REQUEST_STATUS.SUCCESS,
          error: null,
          projects: action.payload
        }
      });
    }
    case UserDashboardConstants.GET_USER_INVESTED_PROJECTS_FAILURE: {
      return modifyReducer(state, {
        user_invested_projects: {
          ...state.user_invested_projects,
          status: REQUEST_STATUS.FAILURE,
          error: action.payload
        }
      });
    }
    case UserDashboardConstants.GET_USER_INVESTABLE_PROJECTS_SUCCESS: {
      return modifyReducer(state, {
        user_investable_projects: {
          ...state.user_investable_projects,
          status: REQUEST_STATUS.SUCCESS,
          error: null,
          projects: action.payload
        }
      });
    }
    case UserDashboardConstants.GET_USER_INVESTABLE_PROJECTS_FAILURE: {
      return modifyReducer(state, {
        user_investable_projects: {
          ...state.user_investable_projects,
          status: REQUEST_STATUS.FAILURE,
          error: action.payload
        }
      });
    }
    case UserDashboardConstants.GET_USER_COMMITED_PROJECTS_PENDING: {
      return modifyReducer(state, {
        user_commited_projects: {
          ...state.user_commited_projects,
          status: REQUEST_STATUS.PENDING
        }
      });
    }
    case UserDashboardConstants.GET_USER_COMMITED_PROJECTS_SUCCESS: {
      return modifyReducer(state, {
        user_commited_projects: {
          ...state.user_commited_projects,
          status: REQUEST_STATUS.SUCCESS,
          error: null,
          projects: action.payload.projects,
          user_commitments: action.payload.user_commitments
        }
      });
    }
    case UserDashboardConstants.GET_USER_COMMITED_PROJECTS_FAILURE: {
      return modifyReducer(state, {
        user_commited_projects: {
          ...state.user_commited_projects,
          status: REQUEST_STATUS.FAILURE,
          error: action.payload
        }
      });
    }
    default:
      return state;
  }
}
