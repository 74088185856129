import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import HangingNavBar from 'Components/ProjectDetail/Components/HangingNavBar.component';
import Header from 'Components/ProjectDetail/Components/Header.component';
import HeroZone from 'Components/ProjectDetail/Components/HeroZone.component';
import BuyShares from 'Components/ProjectDetail/Components/BuyShares.component';
import ProjectOverview from 'Components/ProjectDetail/Components/ProjectOverview.component';
import ProjectInformation from 'Components/ProjectDetail/Components/ProjectInformation.component';
import Roadmap from 'Components/ProjectDetail/Components/Roadmap.component';
import FinancialDetails from 'Components/ProjectDetail/Components/FinancialDetails.component';
import TechnologyDetails from 'Components/ProjectDetail/Components/TechnologyDetails.component';
import MarketDetails from 'Components/ProjectDetail/Components/MarketDetails.component';

import Footer from 'Components/Reusable/Footer/Footer.component';

import { Container, Text } from 'UILib';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import 'Components/ProjectDetail/projectDetail.style.scss';

const ProjectDetailComponent = (props) => {
  const { projectDetail, heroZone, roadmapDates, roadmapOrder } = props;
  return (
    <Container type="page" bgColor="white">
      <HangingNavBar
        information={projectDetail.attributes.properties.information}
        investment_status={projectDetail.attributes.investment_status}
        statistics={projectDetail.attributes.statistics}
        handleActiveSection={props.handleActiveSection}
        activeSection={props.activeSection}
        hangingNavbarVisible={props.hangingNavbarVisible}
      />
      <GlobalLayout minWidth>
        <div className="project-detail-border" />
        <div className="project-detail-page-wrapper">
          <div
            className="buy-shares"
            onClick={() => {
              props.history.push(
                projectDetail.attributes.investment_status
                  ? APP_ROUTES.INVESTMENT_WITH_ID(
                      projectDetail.attributes.statistics.id
                    )
                  : APP_ROUTES.COMMITMENT_WITH_ID(
                      projectDetail.attributes.statistics.id
                    )
              );
            }}
          >
            <Text
              content="projectDetail.heroZone.cta.commitment"
              translation
              color="white"
              className="text"
            />
          </div>
          <Header
            information={projectDetail.attributes.properties.information}
            statistics={projectDetail.attributes.statistics}
          />
          <HeroZone
            history={props.history}
            investment_status={projectDetail.attributes.investment_status}
            information={projectDetail.attributes.properties.information}
            contribution={projectDetail.attributes.properties.contribution}
            financial={projectDetail.attributes.properties.financial}
            statistics={projectDetail.attributes.statistics}
            heroZone={heroZone}
            toggleHangingNavbarVisible={props.toggleHangingNavbarVisible}
          />
          <BuyShares
            history={props.history}
            investment_status={projectDetail.attributes.investment_status}
            statistics={projectDetail.attributes.statistics}
            financial={projectDetail.attributes.properties.financial}
            contribution={projectDetail.attributes.properties.contribution}
          />
          <ProjectOverview
            information={projectDetail.attributes.properties.information}
            financial={projectDetail.attributes.properties.financial}
            technology={projectDetail.attributes.properties.technology}
            market={projectDetail.attributes.properties.market}
            activeSection={props.activeSection}
            handleActiveSection={props.handleActiveSection}
          />
          <ProjectInformation
            information={projectDetail.attributes.properties.information}
            technology={projectDetail.attributes.properties.technology}
            activeSection={props.activeSection}
          />
          <Roadmap roadmapDates={roadmapDates} roadmapOrder={roadmapOrder} />
          <FinancialDetails
            market={projectDetail.attributes.properties.market}
            financial={projectDetail.attributes.properties.financial}
            contribution={projectDetail.attributes.properties.contribution}
            activeSection={props.activeSection}
          />
          <TechnologyDetails
            technology={projectDetail.attributes.properties.technology}
            activeSection={props.activeSection}
          />
          <MarketDetails
            market={projectDetail.attributes.properties.market}
            handleActiveSection={props.handleActiveSection}
            activeSection={props.activeSection}
          />
        </div>
      </GlobalLayout>
      <Footer />
    </Container>
  );
};

export default ProjectDetailComponent;
