export const LOGIN_STEP = {
  LOGIN: 'login',
  MFA: 'mfa',
  REQUEST_USER_INFO: 'request_user_info',
  RESEND_VERIFICATION_EMAIL: 'resend_verification_email',
  REDIRECT_TO_DASHBOARD: 'redirect_to_dashboard'
};

export const USER_LOGIN_FLOW_RESET = 'USER_LOGIN_FLOW_RESET';
export const USER_LOGIN_PENDING = 'USER_LOGIN_PENDING';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGIN_REQUIRE_RECAPTCHA = 'USER_LOGIN_REQUIRE_RECAPTCHA';
export const USER_LOGIN_WITH_MFA_PENDING = 'USER_LOGIN_WITH_MFA_PENDING';
export const USER_LOGIN_WITH_MFA_SUCCESS = 'USER_LOGIN_WITH_MFA_SUCCESS';
export const USER_LOGIN_WITH_MFA_FAILURE = 'USER_LOGIN_WITH_MFA_FAILURE';
export const USER_LOGIN_WITH_MFA_EXPIRE = 'USER_LOGIN_WITH_MFA_EXPIRE';
export const USER_LOGIN_GET_USER_INFORMATION_PENDING =
  'USER_LOGIN_GET_USER_INFORMATION_PENDING';
export const USER_LOGIN_GET_USER_INFORMATION_SUCCESS =
  'USER_LOGIN_GET_USER_INFORMATION_SUCCESS';
export const USER_LOGIN_GET_USER_INFORMATION_FAILURE =
  'USER_LOGIN_GET_USER_INFORMATION_FAILURE';

export const USER_LOGIN_RESEND_VERIFICATION_EMAIL =
  'USER_LOGIN_RESEND_VERIFICATION_EMAIL';
