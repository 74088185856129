import React, { Component } from 'react';

import { Text } from 'UILib';

import {
  scrollElementIntoView,
  isItActiveSection,
  transformBalance
} from 'Utils/Helpers/component.helpers';

import 'Components/ProjectDetail/projectDetail.style.scss';

const GeneralProjectInformationDisplay = (props) => {
  return (
    <div className={props.className}>
      <Text
        type="intro"
        content={props.header}
        translation
        textAlign="left"
        className="header"
        transform="toUpper"
      />
      <Text
        type="intro"
        content={props.subheader}
        textAlign="left"
        className="subheader"
        transform={
          props.subheaderTransform === undefined || props.subheaderTransform
            ? 'toUpper'
            : null
        }
      />
    </div>
  );
};

class ProjectInformation extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (isItActiveSection(prevProps, this.props, 'information')) {
      scrollElementIntoView(this.projectInformationElement);
    }
  }

  getLocationImages = (files) => {
    let locationImages = [];
    locationImages = files.filter((file) => {
      return file.attributes.kind === 'location_image';
    });
    return locationImages;
  };

  render() {
    let locationImages = [];
    if (this.props.information.files) {
      locationImages = this.getLocationImages(this.props.information.files);
    }
    return (
      <React.Fragment>
        <div
          className="project-information-zone"
          ref={(el) => {
            this.projectInformationElement = el;
          }}
        >
          <Text
            type="h1"
            color="black"
            content="projectDetail.projectInformation.header"
            translation
            transform="toUpper"
            className="header"
          />
          <div className="general-project-information-zone">
            <GeneralProjectInformationDisplay
              className="information"
              header="projectDetail.projectInformation.generalInformations.owner"
              subheader={this.props.information.owner_company || ''}
            />
            <GeneralProjectInformationDisplay
              className="information"
              header="projectDetail.projectInformation.generalInformations.constructor"
              subheader={this.props.information.constructor_company || ''}
            />
            <GeneralProjectInformationDisplay
              className="information"
              header="projectDetail.projectInformation.generalInformations.auditor"
              subheader={this.props.information.auditor || ''}
            />
            <GeneralProjectInformationDisplay
              className="information"
              header="projectDetail.projectInformation.generalInformations.plant_capacity"
              subheader={
                this.props.information.plant_capacity
                  ? `${this.props.information.plant_capacity} kWp`
                  : ''
              }
              subheaderTransform={false}
            />
            <GeneralProjectInformationDisplay
              className="information"
              header="projectDetail.projectInformation.generalInformations.annual_production"
              subheader={
                this.props.technology.production_details
                  .annual_energy_production
                  ? `${transformBalance(
                      this.props.technology.production_details
                        .annual_energy_production,
                      0
                    )} kWh`
                  : ''
              }
              subheaderTransform={false}
            />
          </div>
          <div className="map-project-information-zone">
            {(() => {
              if (locationImages.length > 0) {
                return (
                  <React.Fragment>
                    <a
                      href="https://goo.gl/maps/ytwTmoT5MfoE4z6F7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={locationImages[0].attributes.url}
                        alt={locationImages[0].attributes.name}
                        className="map"
                      />
                    </a>
                    <a
                      href="https://goo.gl/maps/ytwTmoT5MfoE4z6F7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={locationImages[1].attributes.url}
                        alt={locationImages[1].attributes.name}
                        className="map"
                      />
                    </a>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <div className="map-placeholder" />
                    <div className="map-placeholder" />
                  </React.Fragment>
                );
              }
            })()}
            <div className="land-information-wrapper">
              <div className="information-line">
                <GeneralProjectInformationDisplay
                  className="information"
                  header="projectDetail.projectInformation.locationInformations.landsize"
                  subheader={
                    this.props.information.geography &&
                    this.props.information.geography.land_size
                      ? `${transformBalance(
                          this.props.information.geography.land_size,
                          0
                        )} sqm`
                      : ''
                  }
                />
                <GeneralProjectInformationDisplay
                  className="information"
                  header="projectDetail.projectInformation.locationInformations.coordinates"
                  subheader={
                    this.props.information.geography &&
                    this.props.information.geography.latitude &&
                    this.props.information.geography.longitude
                      ? `${this.props.information.geography.latitude.substring(
                          0,
                          7
                        )} - ${this.props.information.geography.longitude.substring(
                          0,
                          7
                        )}`
                      : ''
                  }
                />
              </div>
              <div className="information-line">
                <GeneralProjectInformationDisplay
                  className="information"
                  header="projectDetail.projectInformation.locationInformations.altitude"
                  subheader={
                    this.props.information.geography &&
                    this.props.information.geography.altitude
                      ? `${transformBalance(
                          this.props.information.geography.altitude,
                          0
                        )} meters`
                      : ''
                  }
                />
                <GeneralProjectInformationDisplay
                  className="information"
                  header="projectDetail.projectInformation.locationInformations.sunshine"
                  subheader={
                    this.props.technology.production_details.hours_of_sunlight
                      ? `${this.props.technology.production_details.hours_of_sunlight} hours / year`
                      : ''
                  }
                />
              </div>
              <div className="information-line">
                <GeneralProjectInformationDisplay
                  className="information-full"
                  header="projectDetail.projectInformation.locationInformations.specific_production"
                  subheader={
                    this.props.technology.production_details.specific_production
                      ? `${transformBalance(
                          this.props.technology.production_details
                            .specific_production,
                          0
                        )} kWh / kWp / YEAR`
                      : ''
                  }
                  subheaderTransform={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-project-information-zone">
          <Text
            type="h1"
            color="black"
            content="projectDetail.projectInformation.header"
            translation
            transform="toUpper"
            className="header"
          />
          <div className="map-project-information-zone">
            {(() => {
              if (locationImages.length > 0) {
                return (
                  <React.Fragment>
                    <a
                      href="https://goo.gl/maps/ytwTmoT5MfoE4z6F7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={locationImages[0].attributes.url}
                        alt={locationImages[0].attributes.name}
                        className="map"
                      />
                    </a>
                    <a
                      href="https://goo.gl/maps/ytwTmoT5MfoE4z6F7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={locationImages[1].attributes.url}
                        alt={locationImages[1].attributes.name}
                        className="map"
                      />
                    </a>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <div className="map-placeholder" />
                    <div className="map-placeholder" />
                  </React.Fragment>
                );
              }
            })()}
          </div>
          <div className="mobile-information-wrapper">
            <div className="zone">
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.locationInformations.owner_company"
                subheader={this.props.information.owner_company || ''}
              />
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.locationInformations.sunshine"
                subheader={
                  this.props.technology.production_details.hours_of_sunlight
                    ? `${this.props.technology.production_details.hours_of_sunlight} hours / year`
                    : ''
                }
              />
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.locationInformations.landsize"
                subheader={
                  this.props.information.geography &&
                  this.props.information.geography.land_size
                    ? `${transformBalance(
                        this.props.information.geography.land_size,
                        0
                      )} sqm`
                    : ''
                }
              />
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.locationInformations.altitude"
                subheader={
                  this.props.information.geography &&
                  this.props.information.geography.altitude
                    ? `${transformBalance(
                        this.props.information.geography.altitude,
                        0
                      )} meters`
                    : ''
                }
              />
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.locationInformations.specific_production"
                subheader={
                  this.props.technology.production_details.specific_production
                    ? `${transformBalance(
                        this.props.technology.production_details
                          .specific_production,
                        0
                      )} kWh / kWp / YEAR`
                    : ''
                }
                subheaderTransform={false}
              />
            </div>
            <div className="zone">
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.generalInformations.constructor"
                subheader={this.props.information.constructor_company || ''}
              />
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.generalInformations.plant_capacity"
                subheader={
                  this.props.information.plant_capacity
                    ? `${this.props.information.plant_capacity} kWp`
                    : ''
                }
                subheaderTransform={false}
              />
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.generalInformations.annual_production"
                subheader={
                  this.props.technology.production_details
                    .annual_energy_production
                    ? `${transformBalance(
                        this.props.technology.production_details
                          .annual_energy_production,
                        0
                      )} kWh`
                    : ''
                }
                subheaderTransform={false}
              />
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.generalInformations.auditor"
                subheader={this.props.information.auditor || ''}
              />
              <GeneralProjectInformationDisplay
                className="information"
                header="projectDetail.projectInformation.locationInformations.coordinates"
                subheader={
                  this.props.information.geography &&
                  this.props.information.geography.latitude &&
                  this.props.information.geography.longitude
                    ? `${this.props.information.geography.latitude.substring(
                        0,
                        7
                      )} - ${this.props.information.geography.longitude.substring(
                        0,
                        7
                      )}`
                    : ''
                }
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProjectInformation;
