import * as InvestmentConstants from 'Constants/Investment/Investment.constants';
import { INVESTMENT_STEP } from 'Constants/Investment/Investment.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

const initialState = {
  step: INVESTMENT_STEP.START,
  status: REQUEST_STATUS.NOT_DEFINED,
  access_token: null,
  project_id: null,
  transaction_id: null,
  outstanding_share: null,
  amount: 0,
  datetime: null,
  error: null,
  history: [],
  remainingTime: 180
};

export default function investment(state = initialState, action) {
  switch (action.type) {
    // --- FLOW RESET ---
    case InvestmentConstants.INVESTMENT_FLOW_RESET:
      return modifyReducer(state, initialState);
    // --- FLOW START Conditions ---
    case InvestmentConstants.INVESTMENT_FLOW_START_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case InvestmentConstants.INVESTMENT_FLOW_START_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload,
        datetime: new Date()
      });
    case InvestmentConstants.INVESTMENT_FLOW_START_SUCCESS:
      return modifyReducer(state, {
        step: INVESTMENT_STEP.AMOUNT,
        status: REQUEST_STATUS.NOT_DEFINED,
        access_token: action.payload.access_token,
        project_id: action.payload.projectId,
        transaction_id: action.payload.transaction_id,
        outstanding_share: action.payload.details.outstanding_share,
        error: null,
        history: [...state.history, action.payload]
      });
    case InvestmentConstants.INVESTMENT_TIMER_REDUCTION:
      return handleTimerReduction(state, action);
    case InvestmentConstants.INVESTMENT_FLOW_AMOUNT_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case InvestmentConstants.INVESTMENT_FLOW_AMOUNT_FAILURE:
      if (
        action.payload.client_code ===
        InvestmentConstants.INSUFFICIENT_FUNDS_CODE
      ) {
        return modifyReducer(state, {
          status: REQUEST_STATUS.NOT_DEFINED,
          step: INVESTMENT_STEP.INSUFFICIENT_FUNDS,
          error: action.payload,
          datetime: new Date()
        });
      } else {
        return modifyReducer(state, {
          status: REQUEST_STATUS.FAILURE,
          error: action.payload,
          datetime: new Date()
        });
      }
    case InvestmentConstants.INVESTMENT_FLOW_AMOUNT_SUCCESS:
      return modifyReducer(state, {
        step: INVESTMENT_STEP.CONFIRM,
        status: REQUEST_STATUS.NOT_DEFINED,
        access_token: action.payload.access_token,
        project_id: action.payload.projectId,
        error: null,
        amount: action.payload.amount,
        datetime: new Date(),
        history: [...state.history, action.payload]
      });
    case InvestmentConstants.INVESTMENT_FLOW_CONFIRM_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case InvestmentConstants.INVESTMENT_FLOW_CONFIRM_FAILURE:
      if (
        action.payload.client_code ===
        InvestmentConstants.INSUFFICIENT_FUNDS_CODE
      ) {
        return modifyReducer(state, {
          status: REQUEST_STATUS.NOT_DEFINED,
          step: INVESTMENT_STEP.INSUFFICIENT_FUNDS,
          error: action.payload,
          datetime: new Date()
        });
      } else {
        return modifyReducer(state, {
          status: REQUEST_STATUS.FAILURE,
          error: action.payload,
          datetime: new Date()
        });
      }
    case InvestmentConstants.INVESTMENT_FLOW_CONFIRM_SUCCESS:
      return modifyReducer(state, {
        step: INVESTMENT_STEP.NOT_DEFINED,
        status: REQUEST_STATUS.SUCCESS,
        access_token: action.payload.access_token,
        project_id: action.payload.projectId,
        error: null,
        history: [...state.history, action.payload]
      });
    default:
      return state;
  }
}

const handleTimerReduction = (state, reduction) => {
  if (state.remainingTime > 0) {
    return modifyReducer(state, {
      remainingTime: state.remainingTime - 1
    });
  } else {
    return modifyReducer(state, {
      status: REQUEST_STATUS.FAILURE
    });
  }
};
