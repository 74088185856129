export const GET_USER_WALLETS_PENDING = 'GET_USER_WALLETS_PENDING';
export const GET_USER_WALLETS_SUCCESS = 'GET_USER_WALLETS_SUCCESS';
export const GET_USER_WALLETS_FAILURE = 'GET_USER_WALLETS_FAILURE';

export const GET_USER_SELECTED_WALLET_DETAILS_PENDING =
  'GET_USER_SELECTED_WALLET_DETAILS_PENDING';
export const GET_USER_SELECTED_WALLET_DETAILS_SUCCESS =
  'GET_USER_SELECTED_WALLET_DETAILS_SUCCESS';
export const GET_USER_SELECTED_WALLET_DETAILS_FAILURE =
  'GET_USER_SELECTED_WALLET_DETAILS_FAILURE';
export const REGENERATE_USER_SELECTED_WALLET_ADDRESS_PENDING =
  'REGENERATE_USER_SELECTED_WALLET_ADDRESS_PENDING';
export const REGENERATE_USER_SELECTED_WALLET_ADDRESS_SUCCESS =
  'REGENERATE_USER_SELECTED_WALLET_ADDRESS_SUCCESS';
export const REGENERATE_USER_SELECTED_WALLET_ADDRESS_FAILURE =
  'REGENERATE_USER_SELECTED_WALLET_ADDRESS_FAILURE';
export const CLEAR_USER_SELECTED_WALLET_DETAILS =
  'CLEAR_USER_SELECTED_WALLET_DETAILS';

export const GET_USER_DASHBOARD_PROJECTS_PENDING =
  'GET_USER_DASHBOARD_PROJECTS_PENDING';
export const GET_USER_INVESTED_PROJECTS_SUCCESS =
  'GET_USER_INVESTED_PROJECTS_SUCCESS';
export const GET_USER_INVESTED_PROJECTS_FAILURE =
  'GET_USER_INVESTED_PROJECTS_FAILURE';
export const GET_USER_INVESTABLE_PROJECTS_SUCCESS =
  'GET_USER_INVESTABLE_PROJECTS_SUCCESS';
export const GET_USER_INVESTABLE_PROJECTS_FAILURE =
  'GET_USER_INVESTABLE_PROJECTS_FAILURE';
export const GET_USER_COMMITED_PROJECTS_PENDING =
  'GET_USER_COMMITED_PROJECTS_PENDING';
export const GET_USER_COMMITED_PROJECTS_SUCCESS =
  'GET_USER_COMMITED_PROJECTS_SUCCESS';
export const GET_USER_COMMITED_PROJECTS_FAILURE =
  'GET_USER_COMMITED_PROJECTS_FAILURE';
