import * as Yup from 'yup';

const WithdrawValidationSchema = Yup.object().shape({
  walletAddress: Yup.string()
    .min(32, 'Too Short!')
    .max(45, 'Too Long!')
    .required('Required'),
  amount: Yup.number().required('Required'),
  terms_of_service_accept: Yup.bool()
    .test(
      'terms_of_service_accept',
      'Must agree to Terms of Service',
      (value) => value === true
    )
    .required('Required')
});

export { WithdrawValidationSchema };
