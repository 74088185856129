import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import apiGenerator from 'Utils/Helpers/api.helpers';

import {
  API_ENDPOINTS,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';
import * as AccountConstants from 'Constants/Account/Account.constants';

function* logOutUserSaga(action) {
  const { access_token } = action.payload;

  const api = apiGenerator('post', access_token)(API_ENDPOINTS.LOGOUT, {
    token: access_token
  });

  yield api;
  yield put(push(APP_ROUTES.LOGOUT));
  yield put({
    type: AccountConstants.CLEAR_ACCESS_TOKEN_FROM_STORE
  });
}

export default function* root() {
  yield all([takeLatest(AccountConstants.LOG_OUT_USER, logOutUserSaga)]);
}
