import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';

import LoadingContainer from 'Containers/Loading/Loading.container';
import ProjectListingComponent from 'Components/ProjectListing/ProjectListing.component';

import { retrieveProjectList } from 'Actions/ProjectListing/ProjectListing.actions';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';

class ProjectListingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryFilterList: [],
      filteredProjects: [],
      showMoreFilterOptions: false,
      filteredProjectsShowAmount: 5,
      filterConfigs: {
        open: false,
        trending: false,
        highYield: false,
        selected: false,
        audited: false,
        type: [],
        capacity: [],
        stage: [],
        location: null
      }
    };
  }

  componentDidMount() {
    this.props.retrieveProjectList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.projectList.projects,
        this.props.projectList.projects
      )
    ) {
      this.setState({ filteredProjects: this.props.projectList.projects });
      this.generateCountryFilterList();
    }

    if (!_.isEqual(prevState.filterConfigs, this.state.filterConfigs)) {
      this.handleProjectFilters();
    }
  }

  generateCountryFilterList = () => {
    const countryList = _.uniq(
      this.props.projectList.projects.map((project) => {
        return project.attributes.properties.information.country;
      })
    );
    this.setState({
      countryFilterList: countryList
    });
  };

  toggleShowMoreFilterOptions = () => {
    this.setState({ showMoreFilterOptions: !this.state.showMoreFilterOptions });
  };

  changeFilterConfigs = (config, value) => {
    if (_.isBoolean(this.state.filterConfigs[config])) {
      this.setState({
        filterConfigs: { ...this.state.filterConfigs, [config]: value }
      });
    } else if (_.isArray(this.state.filterConfigs[config])) {
      this.setState({
        filterConfigs: {
          ...this.state.filterConfigs,
          [config]: _.includes(this.state.filterConfigs[config], value)
            ? _.without(this.state.filterConfigs[config], value)
            : [...this.state.filterConfigs[config], value]
        }
      });
    } else {
      this.setState({
        filterConfigs: {
          ...this.state.filterConfigs,
          [config]: _.isEqual(this.state.filterConfigs[config], value)
            ? null
            : value
        }
      });
    }
  };

  handleProjectFilters = () => {
    let filteredProjects = this.props.projectList.projects;

    _.keys(this.state.filterConfigs).forEach((key) => {
      switch (key) {
        case 'open':
          if (this.state.filterConfigs.open) {
            filteredProjects = _.filter(filteredProjects, (project) => {
              return project.attributes.investment_status;
            });
          }
          break;
        case 'type':
          if (this.state.filterConfigs.type.length > 0) {
            filteredProjects = _.filter(filteredProjects, (project) => {
              return _.includes(
                this.state.filterConfigs.type,
                project.attributes.properties.information.type
              );
            });
          }
          break;
        case 'capacity':
          if (this.state.filterConfigs.capacity.length > 0) {
            filteredProjects = _.filter(filteredProjects, (project) => {
              const plant_capacity = parseFloat(
                project.attributes.properties.information.plant_capacity
              );
              let capacityIndicator;
              if (plant_capacity < 1) {
                capacityIndicator = 'small';
              } else if (plant_capacity > 5) {
                capacityIndicator = 'large';
              } else {
                capacityIndicator = 'mid';
              }
              return _.includes(
                this.state.filterConfigs.capacity,
                capacityIndicator
              );
            });
          }
          break;
        case 'stage':
          if (this.state.filterConfigs.stage.length > 0) {
            filteredProjects = _.filter(filteredProjects, (project) => {
              const { status } = project.attributes.properties.information;
              let statusIndicator;
              if (status === 'development' || status === 'ready_to_build') {
                statusIndicator = 'early';
              } else if (status === 'turn_key') {
                statusIndicator = 'built';
              } else {
                statusIndicator = 'onGoing';
              }

              return _.includes(
                this.state.filterConfigs.stage,
                statusIndicator
              );
            });
          }
          break;
        case 'location':
          if (this.state.filterConfigs.location !== null) {
            filteredProjects = _.filter(filteredProjects, (project) => {
              return (
                project.attributes.properties.information.country ===
                this.state.filterConfigs.location
              );
            });
          }
          break;
        default:
          // Checks trending, selected, audited, high yield
          if (this.state.filterConfigs[key]) {
            filteredProjects = _.filter(filteredProjects, (project) => {
              return _.some(
                project.attributes.properties.information.tags,
                (tag) => {
                  return tag.toLowerCase() === _.startCase(key).toLowerCase();
                }
              );
            });
          }
          break;
      }
      this.setState({
        filteredProjects: filteredProjects,
        filteredProjectsShowAmount: 5
      });
    });
  };

  handleLoadMoreProjects = () => {
    const showAmount = this.state.filteredProjectsShowAmount + 3;

    this.setState({
      filteredProjectsShowAmount:
        showAmount > this.props.projectList.projects.length
          ? this.props.projectList.projects.length
          : showAmount
    });
  };

  render() {
    const { projectList } = this.props;
    const {
      countryFilterList,
      filteredProjects,
      filteredProjectsShowAmount,
      showMoreFilterOptions,
      filterConfigs
    } = this.state;

    switch (projectList.status) {
      case REQUEST_STATUS.SUCCESS:
        return (
          <ProjectListingComponent
            projects={projectList.projects}
            countryFilterList={countryFilterList}
            filteredProjects={filteredProjects}
            filteredProjectsShowAmount={filteredProjectsShowAmount}
            showMoreFilterOptions={showMoreFilterOptions}
            filterConfigs={filterConfigs}
            toggleShowMoreFilterOptions={this.toggleShowMoreFilterOptions}
            changeFilterConfigs={this.changeFilterConfigs}
            handleLoadMoreProjects={this.handleLoadMoreProjects}
          />
        );
      case REQUEST_STATUS.FAILURE:
        return (
          <div>
            {/* TODO: Discuss with serdar for a component architecture on this case */}
            This is a custom failure page should retrieve client code based
            errors
          </div>
        );
      default:
        return <LoadingContainer />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    projectList: state.projectList
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveProjectList
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectListingContainer);
