import React from 'react';
import cx from 'classnames';

import './Navbar.style.scss';

export default class Navbar extends React.Component {
  render() {
    const { children, type, bgColor, color, style, className } = this.props;

    const baseCN = 'navbar';
    const typeCN = `${baseCN}-${type}`;
    const bgColorCN = bgColor
      ? `${baseCN}-background-color-${bgColor}`
      : `${baseCN}-background-color-white`;
    const colorCN = color
      ? `${baseCN}-color-${color}`
      : `${baseCN}-color-grey-6`;

    const CX = cx(baseCN, typeCN, bgColorCN, colorCN, className);
    return (
      <div className={CX} style={style}>
        {children}
      </div>
    );
  }
}
