import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getStatusCodeFamily } from 'Utils/Helpers/saga.helpers';

import {
  STATUS_TYPE,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';

class AuthenticatedRoute extends Component {
  componentDidMount() {
    if (this.props.routeExtender.redirectBack !== null) {
      this.props.history.push(this.props.routeExtender.redirectBack);
    }
  }

  shouldRedirectToLogin = () => {
    const { access_token, user_informations } = this.props.account;

    return access_token === null ||
      user_informations === {} ||
      getStatusCodeFamily(user_informations.client_code) !== STATUS_TYPE.SUCCESS
      ? true
      : false;
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        render={(props) =>
          this.shouldRedirectToLogin() ? (
            <Redirect
              to={{
                pathname: APP_ROUTES.LOGIN
              }}
            />
          ) : (
            <Component {...rest} />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routeExtender: state.routeExtender,
    account: state.account
  };
};

export default withRouter(connect(mapStateToProps)(AuthenticatedRoute));
