import React, { Component } from 'react';
import numeral from 'numeral';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';

import en from 'Locales/en';

class LocalizationWrapper extends Component {
  // TODO: This HOC requires a store state check until default language value is filled
  // Preferably a loader may be????
  constructor(props) {
    super(props);

    this.props.initialize({
      // initializing localization provider with language options
      languages: [{ name: 'English', code: 'en' }],
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true, // escape all html attributes in the translations as a global option
        defaultLanguage: 'en'
      }
    });
    // Adding all translation data that are exported from language folders wia index.js to localize provider
    this.props.addTranslationForLanguage(en, 'en');

    numeral.register('locale', 'default', {
      delimiters: {
        thousands: ' ',
        decimal: '.'
      },
      abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T'
      },
      currency: {
        symbol: '$'
      }
    });

    numeral.locale('default');
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}
export default withLocalize(LocalizationWrapper);
