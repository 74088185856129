import React from 'react';
import cx from 'classnames';

import './Wallet.style.scss';

export default class CurrencySwitch extends React.Component {
  render() {
    const { align, active, toggleAction, style, className } = this.props;
    const baseCN = 'currency-switch';

    const zoneCN = `${baseCN}-zone`;
    const alignCN = `${baseCN}-align-${align}`;
    const CurrencySwitchZoneCX = cx(zoneCN, alignCN);

    const CurrencySwitchCX = cx(baseCN, className);

    return (
      <div className={CurrencySwitchZoneCX}>
        <div className={CurrencySwitchCX} style={style}>
          <div
            className={`item ${active === 'usd' && 'active'}`}
            onClick={() => toggleAction('usd')}
          >
            USD
          </div>
          <div
            className={`item ${active === 'raw' && 'active'}`}
            onClick={() => toggleAction('raw')}
          >
            CUR
          </div>
        </div>
      </div>
    );
  }
}
