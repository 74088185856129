import Login from 'Components/Authentication/Login/Login.component';
import LoginWithMFA from 'Components/Authentication/Login/LoginWithMFA.component';
import RequestUserInfo from 'Components/Authentication/Login/RequestUserInfo.component';
import ResendVerificationEmail from 'Components/Authentication/Login/ResendVerificationEmail.component';
import Failure from 'Components/Authentication/Login/Failure.component';

import { LOGIN_STEP } from 'Constants/Authentication/Login.constants';

export const loginFlow = {
  flow: [
    {
      step: LOGIN_STEP.LOGIN,
      component: Login
    },
    {
      step: LOGIN_STEP.MFA,
      component: LoginWithMFA
    },
    {
      step: LOGIN_STEP.REQUEST_USER_INFO,
      component: RequestUserInfo
    },
    {
      step: LOGIN_STEP.RESEND_VERIFICATION_EMAIL,
      component: ResendVerificationEmail
    }
  ],
  failure: {
    component: Failure
  }
};
