import React from 'react';
import { Link } from 'react-router-dom';

import WalletPane from 'Components/Account/UserDashboard/Components/WalletPane.component';

import { Icon, Text, Button } from 'UILib';
import { transformBalance } from 'Utils/Helpers/component.helpers';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

const CommitmentSuccess = (props) => {
  return (
    <div className="commitment-main-wrapper">
      <div className="commitment-success-pane">
        <div className="commitment-information-zone">
          <Icon
            base="regular"
            icon={{ name: 'check-circle', color: 'green-3', size: '10x' }}
          />
          <div className="information-right-zone">
            <Text
              type="intro"
              content="commitment.success.header"
              translation
              textAlign="left"
              className="header"
            />
            <Text
              type="intro"
              content={
                props.selectedCommitmentAmount !== 'later'
                  ? 'commitment.success.description'
                  : 'commitment.success.decideLater'
              }
              data={{
                name:
                  props.projectDetail.attributes.properties.information.name,
                amount: transformBalance(props.selectedCommitmentAmount, 0)
              }}
              translation
              textAlign="left"
              className="description"
            />
          </div>
        </div>
        <div className="commitment-completion-zone">
          <div>
            <Text type="intro" content="Investment Amount" className="header" />
            <Text
              type="intro"
              translation
              content={
                props.selectedCommitmentAmount !== 'later'
                  ? 'commitment.commitmentPane.investment_amount'
                  : 'commitment.commitmentPane.decide_later'
              }
              data={
                props.selectedCommitmentAmount !== 'later' && {
                  amount: transformBalance(
                    parseInt(props.selectedCommitmentAmount, 10),
                    0
                  )
                }
              }
              className="subheader"
            />
          </div>
          <Link to={APP_ROUTES.DASHBOARD}>
            <Button
              bgColor="orange-3"
              borderColor="orange-3"
              color="white"
              content="commitment.success.action"
              translation
              className="action"
            />
          </Link>
        </div>
      </div>
      <WalletPane {...props} />
    </div>
  );
};

export default CommitmentSuccess;
