export const kyc = {
  continue: {
    header: 'You’ve an unsubmitted application.',
    content:
      "We have seen that you have an ongoing application that you haven't submitted yet. You can continue to your application to complete missing fields and review previously filled information.",
    action: 'Continue to Application'
  },
  personal: {
    header: 'Personal Identity Information',
    content:
      "ID verification is required to prevent fraud and keep the community safe. Please provide your personally identifying information with your full name, birth date, country and identity number as written on your government documents.",
    form: {
      first_name: 'Name',
      nationality: 'Country of Birth',
      last_name: 'Surname',
      national_id: 'ID Number',
      date_of_birth: 'Date of Birth',
      action: 'Continue'
    },
    validation_error: 'Please check indicated area then submit the form again.'
  },
  document: {
    header: 'Identity Verification Documents',
    content:
      "Please provide a valid government issued picture identification document. Ensure that your document is valid and not expired, without hole punches or other modifications ",
    button: {
      licence: 'Driving License',
      passport: 'Passport',
      id: 'National ID',
      other: 'Other'
    },
    dropzone: {
      front: 'Front Side',
      rear: 'Rear Side',
      text: 'Upload File .pdf .png .jpeg'
    },
    validation_error:
      'Please make sure that you have installed documents and selected a document type, then submit the form again.',
    action: {
      back: 'Back',
      continue: 'Continue'
    }
  },
  profile: {
    header: 'Take a Selfie of You',
    content:
      "Please take an instant selfie. Face the camera directly and include from your shoulders to the top of your head, similar to a passport or ID photo.",
    selfie: 'Give Permission for Camera Access',
    zone: {
      text: 'Take a Instant Photo'
    },
    dropzone: {
      text: 'Access to Camera'
    },
    validation_error:
      'Please make sure that you have taken upload a photo or take a photo via webcam, then submit again.',
    action: {
      back: 'Back',
      continue: 'Continue'
    }
  },
  address: {
    header: 'Address Details & Verification',
    content:
      "Please provide your residential address that you're currently living.",
    form: {
      address: 'Address',
      country: 'Country of Residence',
      city: 'City',
      subheader:
        'Please provide a valid address check document such as gas, electricity, water, internet, landline telephone not older than 3 months',
      files: 'Upload File .pdf .png .jpeg'
    },
    validation_error:
      'Please make sure that you have provided required address information and a document of proof, then submit again.'
  },
  submit: {
    header: 'Thanks! It’s almost done!',
    content:
      "Here we go! You completed identity verification process and now your submission is ready for our review. Please check your provided information once and submit them to us for our review.",
    personal: {
      name: 'Name',
      surname: 'Surname',
      date_of_birth: 'Date of Birth',
      nationality: 'Place of Birth',
      national_id: 'ID Number'
    },
    address: {
      address: 'Address',
      country: 'Country',
      city: 'City'
    },
    documents: {
      passport: 'Passport',
      licence: 'Driving Licence',
      id: 'National ID',
      other: 'Other',
      address: 'Address',
      profile: 'Selfie'
    },
    action: {
      back: 'Review',
      submit: 'Submit'
    }
  },
  success: {
    header: 'Thanks for completing your identification procedure',
    content:
      "It's done! Now, our team will review your submission for further security and notify you as soon as possible. Once, your application will be accepted you will be eligible to invest in projects on voult. Stay tuned!",
    action: {
      dashboard: 'Return to Dashboard'
    }
  },
  failure: {
    header: 'There was a problem during your application. Please try again.',
    content:
      "Sorry, there is a problem about your submission. It could be about the documents or information that you've submitted.",
    action: {
      reset: 'Try Again'
    }
  }
};
