import React from 'react';
import { Translate } from 'react-localize-redux';

import { Text, Button } from 'UILib';
import { transformBalance } from 'Utils/Helpers/component.helpers';
import { COMMITMENT_AMOUNTS } from 'Constants/Commitment/Commitment.constants';

const PricingItem = (props) => {
  return (
    <div
      onClick={() => {
        props.changeSelectedCommitmentAmount(props.amount);
      }}
      className={`commitment-pricing-item ${props.selectedCommitmentAmount ===
        props.amount && 'commitment-pricing-active-item'}`}
    >
      {props.preferred && (
        <div className="preferred">
          <Translate id="commitment.commitmentPane.preferred" />
        </div>
      )}
      <Text
        type="intro"
        content={
          props.decideLater ? (
            <Translate id="commitment.commitmentPane.decide" />
          ) : (
            transformBalance(props.amount, 0)
          )
        }
        className="pricing-header"
      />
      <Text
        type="intro"
        content={
          props.decideLater ? (
            <Translate id="commitment.commitmentPane.later" />
          ) : (
            <Translate id="commitment.commitmentPane.usdc" />
          )
        }
        className="pricing-subheader"
      />
    </div>
  );
};

const calculateAnnualReturn = (amount, irr) => {
  return ((parseInt(amount, 10) * irr) / 100) * 12;
};

const CommitmentPane = (props) => {
  const {
    effective_irr
  } = props.projectDetail.attributes.properties.financial.investment_performance;
  return (
    <div className="commitment-pane">
      <div className="commitment-selection-zone">
        <Text
          type="intro"
          content="commitment.commitmentPane.header"
          translation
          className="header"
        />
        <Text
          type="intro"
          content="commitment.commitmentPane.subheader"
          translation
          className="subheader"
        />
        <div className="commitment-pricing-table">
          {COMMITMENT_AMOUNTS.map((amount, idx) => (
            <PricingItem
              key={idx}
              amount={amount}
              preferred={amount === '50000'}
              decideLater={amount === 'later'}
              selectedCommitmentAmount={props.selectedCommitmentAmount}
              changeSelectedCommitmentAmount={
                props.changeSelectedCommitmentAmount
              }
            />
          ))}
        </div>
      </div>
      <div className="commitment-calculation-zone">
        <div>
          <Text type="intro" content="Investment Amount" className="header" />
          <Text
            type="intro"
            translation
            content={
              props.selectedCommitmentAmount !== 'later'
                ? 'commitment.commitmentPane.investment_amount'
                : 'commitment.commitmentPane.decide_later'
            }
            data={
              props.selectedCommitmentAmount !== 'later' && {
                amount: transformBalance(
                  parseInt(props.selectedCommitmentAmount, 10),
                  0
                )
              }
            }
            className={`subheader ${props.selectedCommitmentAmount ===
              'later' && 'decide-later'}`}
          />
        </div>
        <div>
          <Text
            type="intro"
            content="Estimated Annual Income"
            className="header"
          />
          <Text
            type="intro"
            translation
            content={
              props.selectedCommitmentAmount !== 'later'
                ? 'commitment.commitmentPane.investment_amount'
                : 'commitment.commitmentPane.effective_irr'
            }
            data={
              props.selectedCommitmentAmount !== 'later'
                ? {
                    amount: transformBalance(
                      calculateAnnualReturn(
                        props.selectedCommitmentAmount,
                        effective_irr
                      ),
                      0
                    )
                  }
                : { irr: effective_irr }
            }
            className={`subheader calculation ${props.selectedCommitmentAmount ===
              'later' && 'irr'}`}
          />
        </div>
      </div>
      <div className="submission-zone">
        <Text
          type="intro"
          content="commitment.commitmentPane.finePrint"
          translation
          className="left-pane"
        />
        <Button
          color="white"
          bgColor="orange-3"
          borderColor="orange-3"
          content="commitment.commitmentPane.action"
          translation
          className="action-button"
          onClick={() => {
            props.showConfirmationModal();
          }}
        />
      </div>
    </div>
  );
};

export default CommitmentPane;
