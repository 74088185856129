import {
  PHONE_ACTIVATION_FLOW_RESET,
  PHONE_ACTIVATION_START_PENDING,
  PHONE_ACTIVATION_ADD_PENDING,
  PHONE_ACTIVATION_ADD_ERROR_CLEARING,
  PHONE_ACTIVATION_CONFIRM_PENDING,
  PHONE_ACTIVATION_CONFIRM_NO_TIME_FAILURE
} from 'Constants/Account/PhoneActivation.constants';

export function phoneActivationFlowReset() {
  return {
    type: PHONE_ACTIVATION_FLOW_RESET
  };
}

export function phoneActivationFlowStart(access_token) {
  return {
    type: PHONE_ACTIVATION_START_PENDING,
    payload: { access_token }
  };
}

export function phoneActivationFlowAdd(
  access_token,
  action_token,
  phone_number
) {
  return {
    type: PHONE_ACTIVATION_ADD_PENDING,
    payload: { access_token, action_token, phone_number }
  };
}

export function phoneActivationAddErrorClearing() {
  return { type: PHONE_ACTIVATION_ADD_ERROR_CLEARING };
}

export function phoneActivationFlowConfirm(
  access_token,
  action_token,
  otp_code
) {
  return {
    type: PHONE_ACTIVATION_CONFIRM_PENDING,
    payload: { access_token, action_token, otp_code }
  };
}

export function phoneActivationConfirmNoTime() {
  return {
    type: PHONE_ACTIVATION_CONFIRM_NO_TIME_FAILURE,
    payload: {
      client_code: 701
    }
  };
}
