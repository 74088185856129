import * as PhoneActivationConstants from 'Constants/Account/PhoneActivation.constants';
import { PHONE_ACTIVATION_STEP } from 'Constants/Account/PhoneActivation.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

const initialState = {
  step: PHONE_ACTIVATION_STEP.START,
  status: REQUEST_STATUS.NOT_DEFINED,
  error: null,
  history: [],
  access_token: null,
  action_token: null,
  otp_code_tries: 0,
  phone: null
};

export default function phoneActivation(state = initialState, action) {
  switch (action.type) {
    // --- FLOW RESET ---
    case PhoneActivationConstants.PHONE_ACTIVATION_FLOW_RESET:
      return initialState;
    // --- START Conditions ---
    case PhoneActivationConstants.PHONE_ACTIVATION_START_PENDING:
      const { access_token } = action.payload;
      /**
       * When browser reseted or refreshed on /account/phone-activation url, steps given below is occuring
       * - access_token is retrieved from localStorage due to AppInit Logic
       * - access_token can be null or <string>
       * - if access_token is null, the flow should end with failure screen so user should be taken out of the flow
       * - if access_token is a <string>, it means that a stored access_token is retrieved and phone activation flow can start
       */
      if (access_token) {
        return modifyReducer(state, {
          status: REQUEST_STATUS.PENDING,
          access_token: access_token
        });
      } else {
        return modifyReducer(state, {
          status: REQUEST_STATUS.FAILURE,
          error: { client_code: 700 }
        });
      }
    case PhoneActivationConstants.PHONE_ACTIVATION_START_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        status: REQUEST_STATUS.NO_REQUEST,
        history: [...state.history, action.payload],
        access_token: action.payload.access_token,
        action_token: action.payload.action_token
      });
    case PhoneActivationConstants.PHONE_ACTIVATION_START_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    // --- ADD Conditions ---
    case PhoneActivationConstants.PHONE_ACTIVATION_ADD_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case PhoneActivationConstants.PHONE_ACTIVATION_ADD_ERROR_CLEARING:
      return modifyReducer(state, {
        error: null
      });
    case PhoneActivationConstants.PHONE_ACTIVATION_ADD_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        status: REQUEST_STATUS.NO_REQUEST,
        history: [...state.history, action.payload],
        action_token: action.payload.action_token,
        phone: action.payload.phone
      });
    case PhoneActivationConstants.PHONE_ACTIVATION_ADD_FAILURE:
      if (action.payload.client_code === 409) {
        return modifyReducer(state, {
          status: REQUEST_STATUS.NOT_DEFINED,
          error: action.payload
        });
      } else {
        return modifyReducer(state, {
          status: REQUEST_STATUS.FAILURE,
          error: action.payload
        });
      }

    // --- CONFIRM Conditions ---
    case PhoneActivationConstants.PHONE_ACTIVATION_CONFIRM_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case PhoneActivationConstants.PHONE_ACTIVATION_CONFIRM_FAILURE:
      return handleOTPFailure(state, action);
    case PhoneActivationConstants.PHONE_ACTIVATION_CONFIRM_SUCCESS:
      return modifyReducer(state, { status: REQUEST_STATUS.SUCCESS });
    case PhoneActivationConstants.PHONE_ACTIVATION_CONFIRM_NO_TIME_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    default:
      return state;
  }
}

function handleOTPFailure(state, action) {
  const { otp_code_tries } = state;

  if (otp_code_tries === 2) {
    return modifyReducer(state, {
      status: REQUEST_STATUS.FAILURE,
      error: action.payload
    });
  }
  return modifyReducer(state, {
    status: REQUEST_STATUS.NOT_DEFINED,
    otp_code_tries: state.otp_code_tries + 1,
    error: action.payload
  });
}
