import React from 'react';
import cx from 'classnames';

import './FormElements.style.scss';

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: this.props.value,
      isDropdownVisible: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  _handleSelectClick = () => {
    this.setState({ isDropdownVisible: !this.state.isDropdownVisible });
  };

  _handleOptionClick = (name, value) => {
    this.setState({
      selectedValue: value,
      isDropdownVisible: !this.state.isDropdownVisible
    });
    this.props.handleChange(name, value);
  };

  _handleClickOutside = (event) => {
    if (this.dropdownNode && !this.dropdownNode.contains(event.target)) {
      this.setState({ isDropdownVisible: false });
    }
  };

  render() {
    const {
      placeholder,
      fluid,
      color,
      activeColor,
      bgColor,
      borderColor,
      error,
      name,
      options,
      dropdownStyle,
      dropdownMenuStyle,
      className
    } = this.props;

    const baseCN = 'dropdown-container';
    const fluidCN = fluid && `${baseCN}-fluid`;

    const colorCN = color
      ? `${baseCN}-color-${borderColor}`
      : `${baseCN}-color-grey-5`;
    const bgColorCN = bgColor
      ? `${baseCN}-background-color-${bgColor}`
      : `${baseCN}-background-color-white`;
    const borderColorCN = this.state.isDropdownVisible
      ? `${baseCN}-border-color-blue-2`
      : borderColor
      ? `${baseCN}-border-color-${borderColor}`
      : `${baseCN}-border-color-grey-2`;
    const errorBorderColorCN = error && `${baseCN}-error-border-color`;

    const DropdownCX = cx(
      baseCN,
      fluidCN,
      colorCN,
      bgColorCN,
      borderColorCN,
      errorBorderColorCN
    );

    const selectBaseCN = 'select-container';

    const SelectCX = cx(selectBaseCN, className);

    return (
      <div
        className={DropdownCX}
        ref={(r) => (this.dropdownNode = r)}
        style={dropdownStyle}
      >
        <div className={SelectCX} onClick={this._handleSelectClick}>
          <span className="value" style={{ color: activeColor }}>
            {this.state.selectedValue ? this.state.selectedValue : placeholder}
          </span>
          <i
            className={`fa ${
              this.state.isDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'
            }`}
            style={{ color: activeColor }}
          />
        </div>
        {this.state.isDropdownVisible && (
          <ul className="dropdown-menu">
            {options.map((option) => (
              <li
                key={option.key}
                onClick={() => {
                  this._handleOptionClick(name, option.value);
                }}
                style={dropdownMenuStyle}
              >
                {option.text}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
