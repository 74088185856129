import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import ListingHeader from 'Components/ProjectListing/Components/ListingHeader.component';
import FilterSection from 'Components/ProjectListing/Components/FilterSection.component.js';
import ProjectCard from 'Components/ProjectListing/Components/ProjectCard.component.js';
import MobileProjectCard from 'Components/ProjectListing/Components/MobileProjectCard.component';
import LoadMore from 'Components/ProjectListing/Components/LoadMore.component';
import Footer from 'Components/Reusable/Footer/Footer.component';

import { Container, Text } from 'UILib';

import 'Components/ProjectListing/projectListing.style.scss';

const ProjectListingComponent = (props) => {
  return (
    <Container type="page" bgColor="white">
      <GlobalLayout minWidth>
        <div className="project-listing-border" />
        <div className="project-listing-page-wrapper">
          <ListingHeader
            project_count={props.projects.length}
            available_country_count={props.countryFilterList.length}
          />
          <FilterSection
            countryFilterList={props.countryFilterList}
            showMoreFilterOptions={props.showMoreFilterOptions}
            filterConfigs={props.filterConfigs}
            toggleShowMoreFilterOptions={props.toggleShowMoreFilterOptions}
            changeFilterConfigs={props.changeFilterConfigs}
          />
          {props.filteredProjects.length === 0 && (
            <div className="project-card-zone no-border">
              <Text
                content="projectListing.projectCard.noProjectFound"
                translation
                className="no-project-found"
              />
            </div>
          )}
          {props.filteredProjects
            .slice(0, props.filteredProjectsShowAmount)
            .map((project, idx) => (
              <React.Fragment key={idx}>
                <ProjectCard project={project} />
                <MobileProjectCard project={project} />
              </React.Fragment>
            ))}
          {props.filteredProjectsShowAmount < props.filteredProjects.length && (
            <LoadMore handleLoadMoreProjects={props.handleLoadMoreProjects} />
          )}
        </div>
      </GlobalLayout>
      <Footer />
    </Container>
  );
};

export default ProjectListingComponent;
