import * as _ from 'lodash';

export const getTextElement = (type) => {
  let ElementTag,
    ElementCN = '';

  if (_.includes(['h1', 'h2', 'h3', 'h4', 'p'], type)) {
    ElementTag = type;
  } else if (type === 'body') {
    ElementTag = 'p';
  } else {
    ElementTag = 'div';
    ElementCN = `text-${type}`;
  }
  return { ElementTag, ElementCN };
};
