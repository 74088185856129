import Trade from 'Components/Exchange/Trade.component';
import Success from 'Components/Exchange/Success.component';
import Failure from 'Components/Exchange/Failure.component';

import { EXCHANGE_STEP } from 'Constants/Exchange/Exchange.constants';

export const exchangeFlow = {
  flow: [
    {
      step: EXCHANGE_STEP.TRADE,
      component: Trade
    }
  ],
  success: {
    component: Success
  },
  failure: {
    component: Failure
  }
};
