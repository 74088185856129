import React from 'react';
import * as _ from 'lodash';

import { Text } from 'UILib';

const TabDataColumn = (props) => {
  return (
    <div className="data-column">
      <div className="tab-content-vertical">
        {props.title && (
          <Text
            type="intro"
            content={props.title}
            className="general-tab-header"
          />
        )}
        <div className="tab-content">
          <div className="header-column">
            {_.keys(props.data).map((key, idx) => (
              <Text
                content={
                  !_.includes(props.noTranslation, key)
                    ? `projectDetail.${props.family}.${props.from}.${key}.header`
                    : key
                }
                translation={!_.includes(props.noTranslation, key)}
                key={idx}
                className="header"
              />
            ))}
          </div>
          <div className="value-column">
            {_.keys(props.data).map((key, idx) => (
              <Text
                content={
                  !_.includes(props.noTranslation, key)
                    ? `projectDetail.${props.family}.${props.from}.${key}.value`
                    : props.data[key]
                }
                data={{ value: props.data[key] }}
                translation={!_.includes(props.noTranslation, key)}
                key={idx}
                className="value"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabDataColumn;
