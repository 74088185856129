export const RESET_PASSWORD_STEP = {
  START: 'start',
  SEND_VERIFICATION: 'send_verification',
  VERIFY_CODE: 'verify_code',
  VERIFY_USER_INFORMATION: 'verify_user_information',
  EXECUTE: 'execute'
};

export const RESET_PASSWORD_TYPE = {
  NOT_DEFINED: null,
  EMAIL: 'email',
  SMS: 'sms'
};

export const RESET_PASSWORD_FLOW_RESET = 'RESET_PASSWORD_FLOW_RESET';
export const RESET_PASSWORD_START_PENDING = 'RESET_PASSWORD_START_PENDING';
export const RESET_PASSWORD_START_SUCCESS = 'RESET_PASSWORD_START_SUCCESS';
export const RESET_PASSWORD_START_FAILURE = 'RESET_PASSWORD_START_FAILURE';
export const RESET_PASSWORD_SEND_VERIFICATION_PENDING =
  'RESET_PASSWORD_SEND_VERIFICATION_PENDING';
export const RESET_PASSWORD_SEND_VERIFICATION_SUCCESS =
  'RESET_PASSWORD_SEND_VERIFICATION_SUCCESS';
export const RESET_PASSWORD_SEND_VERIFICATION_FAILURE =
  'RESET_PASSWORD_SEND_VERIFICATION_FAILURE';
export const RESET_PASSWORD_VERIFY_CODE_EMAIL_PENDING =
  'RESET_PASSWORD_VERIFY_CODE_EMAIL_PENDING';
export const RESET_PASSWORD_VERIFY_CODE_EMAIL_SUCCESS =
  'RESET_PASSWORD_VERIFY_CODE_EMAIL_SUCCESS';
export const RESET_PASSWORD_VERIFY_CODE_EMAIL_FAILURE =
  'RESET_PASSWORD_VERIFY_CODE_EMAIL_FAILURE';
export const RESET_PASSWORD_VERIFY_CODE_OTP_PENDING =
  'RESET_PASSWORD_VERIFY_CODE_OTP_PENDING';
export const RESET_PASSWORD_VERIFY_CODE_OTP_SUCCESS =
  'RESET_PASSWORD_VERIFY_CODE_OTP_SUCCESS';
export const RESET_PASSWORD_VERIFY_CODE_OTP_FAILURE =
  'RESET_PASSWORD_VERIFY_CODE_OTP_FAILURE';
export const RESET_PASSWORD_VERIFY_USER_INFORMATION_PENDING =
  'RESET_PASSWORD_VERIFY_USER_INFORMATION_PENDING';
export const RESET_PASSWORD_VERIFY_USER_INFORMATION_SUCCESS =
  'RESET_PASSWORD_VERIFY_USER_INFORMATION_SUCCESS';
export const RESET_PASSWORD_VERIFY_USER_INFORMATION_FAILURE =
  'RESET_PASSWORD_VERIFY_USER_INFORMATION_FAILURE';
export const RESET_PASSWORD_EXECUTE_PENDING = 'RESET_PASSWORD_EXECUTE_PENDING';
export const RESET_PASSWORD_EXECUTE_SUCCESS = 'RESET_PASSWORD_EXECUTE_SUCCESS';
export const RESET_PASSWORD_EXECUTE_FAILURE = 'RESET_PASSWORD_EXECUTE_FAILURE';
