import * as _ from 'lodash';

export const textTransform = (text, transform) => {
  switch (transform) {
    case 'uppercase':
      return _.upperCase(text);
    case 'toUpper':
      return _.toUpper(text);
    case 'lowercase':
      return _.lowerCase(text);
    case 'toLower':
      return _.toLower(text);
    case 'title':
      return _.startCase(text);
    case 'capitalize':
      return _.capitalize(text);
    default:
      return text;
  }
};
