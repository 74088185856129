export const projectListing = {
  listingHeader: {
    header: 'Energy is now public',
    subheader: 'Discover the New Way of Energy Investment',
    shortInformations: {
      projects: {
        header: 'Power Plants',
        subheader: '${project_count} Projects'
      },
      shares: {
        header: 'Offered Shares',
        subheader: '${share_amount} SHARES'
      },
      investment: {
        header: 'Amount to be raised',
        subheader: '${investment_amount} USD'
      },
      available: {
        header: 'Available In',
        subheader: '${available_country_count}'
      }
    }
  },
  filterSection: {
    generalFilters: {
      open: 'Open',
      trending: 'Trending',
      highYield: 'High Yield',
      selected: 'Selected',
      audited: 'Audited'
    },
    more: 'More Filters',
    selectableFilters: {
      type: {
        header: 'Project Type',
        solar: 'Solar',
        wind: 'Wind',
        hydro: 'Hydro'
      },
      capacity: {
        header: 'Capacity',
        small: 'Small',
        medium: 'Medium',
        large: 'Large'
      },
      stage: {
        header: 'Project Stage',
        early: 'Early',
        built: 'Built',
        onGoing: 'On Going'
      },
      location: {
        header: 'Plant Location'
      }
    }
  },
  projectCard: {
    noProjectFound:
      'There are no listed projects based on your filter criteria',
    informations: {
      capacity: {
        header: 'CAPACITY',
        subheader: '${plant_capacity} MW'
      },
      company: {
        header: 'COMPANY'
      },
      return: {
        header: 'RETURN',
        subheader: '${effective_irr} %'
      },
      stage: {
        header: 'STAGE'
      }
    },
    actions: {
      investmentOpen: {
        header: 'Shares Sold',
        subheader: '$${amount}',
        viewers: 'Viewers',
        backers: 'Backers',
        until: '${daysLeft} until sale ends',
        buyShares: 'BUY SHARES'
      },
      investmentClose: {
        header: 'Investment Starts At',
        viewDetails: 'VIEW DETAILS'
      }
    }
  },
  loadMore: {
    action: 'More Projects'
  }
};
