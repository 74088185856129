import GetStep from 'Components/KYC/GetStep.component';
import Continue from 'Components/KYC/Continue.component';
import Start from 'Components/KYC/Start.component';
import Personal from 'Components/KYC/Personal.component';
import Documents from 'Components/KYC/Documents.component';
import Profile from 'Components/KYC/Profile.component';
import Address from 'Components/KYC/Address.component';
import Submit from 'Components/KYC/Submit.component';
import Success from 'Components/KYC/Success.component';
import Failure from 'Components/KYC/Failure.component';

import { KYC_STEP } from 'Constants/KYC/KYC.constants';

export const kycFlow = {
  flow: [
    { step: KYC_STEP.GET_STEP, component: GetStep },
    { step: KYC_STEP.CONTINUE, component: Continue },
    { step: KYC_STEP.START, component: Start },
    { step: KYC_STEP.PERSONAL, component: Personal },
    { step: KYC_STEP.DOCUMENTS, component: Documents },
    { step: KYC_STEP.PROFILE, component: Profile },
    { step: KYC_STEP.ADDRESS, component: Address },
    { step: KYC_STEP.SUBMIT, component: Submit }
  ],
  success: { component: Success },
  failure: { component: Failure }
};
