export const LOG_OUT_USER = 'LOG_OUT_USER';
export const CLEAR_ACCESS_TOKEN_FROM_STORE = 'CLEAR_ACCESS_TOKEN_FROM_STORE';

export const USER_VERIFICATION_LEVEL = {
  REGISTERED: 'registered',
  EMAIL_VERIFIED: 'email_verified',
  PHONE_VERIFIED: 'phone_verified',
  DOCUMENT_PENDING: 'document_pending',
  DOCUMENT_VERIFIED: 'document_verified'
};
