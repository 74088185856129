import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import './FormElements.style.scss';

import { Icon } from 'UILib';

export default class ValidationErrors extends React.Component {
  // TODO: Icon component will change when fonts are determined and Icon reusable component defined
  /**
   * This label value should be a component that can
   * take children for a possible react-router-dom Linking
   * activity, such as TOC link on register form
   */
  render() {
    const { content, translation } = this.props;
    const baseCN = 'validation-errors';

    const ValidationErrorsCX = cx(baseCN);

    return (
      <Translate>
        {({ translate }) => (
          <div className={ValidationErrorsCX}>
            <Icon base="regular" icon={{ name: 'times-circle' }} />
            <span>{translation ? translate(content) : content}</span>
          </div>
        )}
      </Translate>
    );
  }
}
