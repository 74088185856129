import {
  USER_LOGIN_FLOW_RESET,
  USER_LOGIN_PENDING,
  USER_LOGIN_WITH_MFA_PENDING,
  USER_LOGIN_WITH_MFA_EXPIRE,
  USER_LOGIN_GET_USER_INFORMATION_PENDING,
  USER_LOGIN_RESEND_VERIFICATION_EMAIL
} from 'Constants/Authentication/Login.constants';

export function userLoginFlowReset() {
  return {
    type: USER_LOGIN_FLOW_RESET
  };
}

export function userLogin(formState) {
  return {
    type: USER_LOGIN_PENDING,
    payload: { formState }
  };
}

export function userLoginWithMFA(access_token, otp_code) {
  return {
    type: USER_LOGIN_WITH_MFA_PENDING,
    payload: { access_token, otp_code }
  };
}

export function userLoginWithMFAExpire() {
  return {
    type: USER_LOGIN_WITH_MFA_EXPIRE,
    payload: {
      client_code: 701
    }
  };
}

export function userLoginGetUserInformation(access_token) {
  return {
    type: USER_LOGIN_GET_USER_INFORMATION_PENDING,
    payload: { access_token }
  };
}

export function userLoginResendVerificationEmail(access_token) {
  return {
    type: USER_LOGIN_RESEND_VERIFICATION_EMAIL,
    payload: { access_token }
  };
}
