import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import GlobalLayout from 'Layouts/Global.layout';

import { Container, Text, Icon, Button } from 'UILib';
import {
  genericComponentTimer,
  transformBalance
} from 'Utils/Helpers/component.helpers';
import { WITHDRAW_TYPE } from 'Constants/Withdraw/Withdraw.constants';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import 'Components/Withdraw/withdraw.style.scss';

class Success extends React.Component {
  componentDidMount() {
    this.getUserWallets();
    this.getUserWalletsTimer = genericComponentTimer(
      this.getUserWallets,
      30000
    );
  }

  componentWillUnmount() {
    clearInterval(this.getUserWalletsTimer);
  }

  getUserWallets = () => {
    this.props.getUserWallets(this.props.account.access_token);
  };

  getWalletBasedOnSelectedCurrency = (
    wallets,
    walletCurrency,
    activeCurrency
  ) => {
    return _.get(
      _.find(wallets, ['attributes.currency', walletCurrency]),
      `attributes.${activeCurrency}_balance`,
      0
    );
  };

  render() {
    const balance = this.getWalletBasedOnSelectedCurrency(
      this.props.account.user_wallets.wallets,
      this.props.withdraw.type,
      'raw'
    );
    const [integer, float] = transformBalance(
      balance,
      this.props.withdraw.type !== WITHDRAW_TYPE.USDC ? 8 : 2
    );
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <div className="withdraw-success-wrapper">
            <div className="success-header-zone">
              <Icon
                base="regular"
                icon={{ name: 'check-circle', color: 'green-3' }}
                className="icon"
              />
              <div className="success-header-content">
                <Text
                  type="h1"
                  textAlign="left"
                  content="withdraw.success.header"
                  translation
                  className="success-header"
                />
                <Text
                  type="intro"
                  textAlign="left"
                  content="withdraw.success.subheader"
                  translation
                  className="success-text"
                />
              </div>
            </div>
            <div className="success-general-zone">
              <div>
                <Text
                  type="p"
                  textAlign="left"
                  content="withdraw.success.content.amount.header"
                  translation
                  className="success-large-header"
                />
                <Text
                  type="p"
                  textAlign="left"
                  content={`${this.props.withdraw.amount} ${
                    this.props.withdraw.type
                  }`}
                  className="success-large-text"
                />
              </div>
              <div>
                <Text
                  type="p"
                  textAlign="left"
                  content="withdraw.success.content.address.header"
                  translation
                  className="success-header"
                />
                <Text
                  type="p"
                  textAlign="left"
                  content={`${this.props.withdraw.address}`}
                  className="success-text"
                />
              </div>
              <div>
                <Text
                  type="p"
                  textAlign="left"
                  content="withdraw.success.content.datetime.header"
                  translation
                  className="success-header"
                />
                <Text
                  type="p"
                  textAlign="left"
                  content={`${moment(
                    this.props.withdraw.transaction_createdAt
                  ).format('DD.MM.YYYY - HH:mm ([GMT] Z)')}`}
                  className="success-text"
                />
              </div>
              <div>
                <Text
                  type="p"
                  textAlign="left"
                  content="withdraw.success.content.currentBalance.header"
                  data={{ type: this.props.withdraw.type }}
                  translation
                  className="success-header"
                />
                <Text
                  type="p"
                  textAlign="left"
                  content={`${integer}${float} ${this.props.withdraw.type}`}
                  className="success-text"
                />
              </div>
            </div>
            <div className="success-action-zone">
              <Link to={APP_ROUTES.DASHBOARD}>
                <Button
                  content="withdraw.success.actions.dashboard"
                  translation
                  borderColor="orange-3"
                  color="white"
                  bgColor="orange-3"
                  className="button"
                />
              </Link>
            </div>
          </div>
        </GlobalLayout>
      </Container>
    );
  }
}

export default Success;
