import {
  WITHDRAW_FLOW_RESET,
  CHANGE_ACTIVE_CURRENCY_TO_WITHDRAW,
  CREATE_WITHDRAW_PENDING,
  CONFIRM_WITHDRAW_PENDING,
  WITHDRAW_CONFIRM_NO_TIME_FAILURE
} from 'Constants/Withdraw/Withdraw.constants';

export function withdrawFlowReset() {
  return {
    type: WITHDRAW_FLOW_RESET
  };
}

export function changeActiveCurrencyToWithdraw(currency) {
  return {
    type: CHANGE_ACTIVE_CURRENCY_TO_WITHDRAW,
    payload: { currency }
  };
}

export function createWithdraw(access_token, address, currency, quantity) {
  return {
    type: CREATE_WITHDRAW_PENDING,
    payload: { access_token, address, currency, quantity }
  };
}

export function confirmWithdraw(access_token, transaction_id, otp_code) {
  return {
    type: CONFIRM_WITHDRAW_PENDING,
    payload: { access_token, transaction_id, otp_code }
  };
}

export function withdrawConfirmNoTime() {
  return {
    type: WITHDRAW_CONFIRM_NO_TIME_FAILURE,
    payload: {
      client_code: 701
    }
  };
}
