import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as _ from 'lodash';

import GlobalLayout from 'Layouts/Global.layout';
import {
  hasValidationError,
  getErrorContent
} from 'Utils/Helpers/component.helpers';
import { Container, Card, Text, FormElements, Button } from 'UILib';
import {
  REQUEST_STATUS,
  RECAPTCHA_SITE_KEY,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';

import 'Components/Authentication/authentication.style.scss';

const initialValues = {
  email: '',
  password: '',
  recaptcha_response: ''
};

class Login extends Component {
  _handleSubmit = (values) => {
    const { require_recaptcha } = this.props.login;
    values.grant_type = 'password';
    if (!require_recaptcha) {
      values = _.omit(values, ['recaptcha_response']);
    }
    this.props.userLogin(values);
  };

  _determineSubmitButtonClass = (errors) => {
    const hasError = hasValidationError(errors) || this.props.login.error;
    const hasRecaptcha = this.props.login.require_recaptcha;

    return hasError || hasRecaptcha
      ? 'submit-container-with-error'
      : 'login-submit-container';
  };

  render() {
    const definedClientCodes = [401];
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <Card type="auth" centered className="login-form-zone">
            <Text
              type="h1"
              content="login.login.header"
              translation
              className="header"
            />
            <Text
              type="intro"
              content="login.login.description"
              translation
              className="description"
            />
            <Card.Content>
              <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={this.props.LoginValidationSchema(
                  this.props.login.require_recaptcha
                )}
                onSubmit={(values, { submitForm, setSubmitting }) => {
                  this.props.login.require_recaptcha && this.captcha.reset();
                  this._handleSubmit(values);
                  values.recaptcha_response = '';
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Container className="form-container">
                      <FormElements.Input
                        type="email"
                        name="email"
                        error={errors.email && touched.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="login.login.email_placeholder"
                        translation
                      />
                      <FormElements.Input
                        type="password"
                        name="password"
                        maxLength={32}
                        error={errors.password && touched.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="login.login.password_placeholder"
                        translation
                      />
                      <Text type="p" className="reset-password">
                        <Link to={APP_ROUTES.RESET_PASSWORD}>
                          <Translate id="login.login.reset_password_link" />
                        </Link>
                      </Text>
                      {this.props.login.require_recaptcha && (
                        <Container className="recaptcha-container">
                          <ReCAPTCHA
                            ref={(r) => (this.captcha = r)}
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={(value) => {
                              setFieldValue('recaptcha_response', value);
                            }}
                          />
                        </Container>
                      )}
                      {hasValidationError(errors) || this.props.login.error ? (
                        <Container className="error-container">
                          <FormElements.ValidationErrors
                            content={getErrorContent(
                              this.props.login.error,
                              errors,
                              'login.login.validation_error',
                              'login.login.failure.errors',
                              definedClientCodes
                            )}
                            translation
                          />
                        </Container>
                      ) : null}
                      <Container
                        className={this._determineSubmitButtonClass(errors)}
                      >
                        <Button
                          bgColor="orange-3"
                          borderColor="orange-3"
                          color="white"
                          type="submit"
                          disabled={
                            this.props.login.require_recaptcha &&
                            values.recaptcha_response === ''
                          }
                          content={
                            this.props.login.status === REQUEST_STATUS.PENDING
                              ? 'login.login.loading_label'
                              : 'login.login.submit_label'
                          }
                          translation
                        />
                      </Container>
                    </Container>
                  </form>
                )}
              </Formik>
            </Card.Content>
            <Text type="intro" className="bottom-section">
              <Translate id="login.login.footer.content" />{' '}
              <Link to={APP_ROUTES.REGISTER}>
                <Translate id="login.login.footer.cta" />
              </Link>
            </Text>
          </Card>
        </GlobalLayout>
      </Container>
    );
  }
}

export default Login;
