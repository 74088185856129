import * as KYCConstants from 'Constants/KYC/KYC.constants';
import { KYC_STEP } from 'Constants/KYC/KYC.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

const initialState = {
  step: KYC_STEP.GET_STEP,
  status: REQUEST_STATUS.NOT_DEFINED,
  access_token: null,
  has_kyc_submission: false,
  previous_kyc_step: null,
  error: null,
  history: []
};

export default function kyc(state = initialState, action) {
  switch (action.type) {
    case KYCConstants.KYC_FLOW_RESET:
      return initialState;
    case KYCConstants.KYC_MOVE_TO_GIVEN_STEP:
      return modifyReducer(state, {
        step: action.payload.step,
        status: REQUEST_STATUS.NOT_DEFINED
      });
    case KYCConstants.KYC_GET_CURRENT_STEP_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING
      });
    case KYCConstants.KYC_GET_CURRENT_STEP_FAILURE:
      return handleKYCGetStepFailure(state, action);
    case KYCConstants.KYC_GET_CURRENT_STEP_SUCCESS:
      return modifyReducer(state, {
        step: KYC_STEP.CONTINUE,
        status: REQUEST_STATUS.NOT_DEFINED,
        has_kyc_submission: true,
        previous_kyc_step: action.payload.next_step,
        persistent_data: action.payload.data,
        access_token: action.payload.access_token,
        history: [...state.history, action.payload]
      });
    case KYCConstants.KYC_FLOW_START_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.START,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_START_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
    case KYCConstants.KYC_FLOW_START_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        access_token: action.payload.access_token,
        error: action.payload
      });

    case KYCConstants.KYC_FLOW_PERSONAL_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.PERSONAL,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_PERSONAL_PATCH_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.PERSONAL,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_PERSONAL_SUCCESS:
      return modifyReducer(state, {
        persistent_data: {
          ...state.persistent_data,
          personal_information: action.payload.personal_information
        },
        step: action.payload.next_step,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
    case KYCConstants.KYC_FLOW_PERSONAL_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        access_token: action.payload.access_token,
        error: action.payload
      });
    case KYCConstants.KYC_FLOW_DOCUMENTS_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.DOCUMENTS,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_DOCUMENTS_PATCH_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.DOCUMENTS,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_DOCUMENTS_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        persistent_data: {
          ...state.persistent_data,
          identification_documents: action.payload.identification_documents
        },
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
    case KYCConstants.KYC_FLOW_DOCUMENTS_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        access_token: action.payload.access_token,
        error: action.payload
      });
    case KYCConstants.KYC_FLOW_PROFILE_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.PROFILE,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_PROFILE_PATCH_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.PROFILE,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_PROFILE_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        persistent_data: {
          ...state.persistent_data,
          profile_picture: action.payload.profile_picture
        },
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
    case KYCConstants.KYC_FLOW_PROFILE_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        access_token: action.payload.access_token,
        error: action.payload
      });
    case KYCConstants.KYC_FLOW_ADDRESS_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.ADDRESS,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_ADDRESS_PATCH_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.ADDRESS,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_ADDRESS_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        persistent_data: {
          ...state.persistent_data,
          address_information: action.payload.address_information
        },
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
    case KYCConstants.KYC_FLOW_ADDRESS_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        access_token: action.payload.access_token,
        error: action.payload
      });
    case KYCConstants.KYC_FLOW_SUBMIT_PENDING:
      return modifyReducer(state, {
        step: KYC_STEP.SUBMIT,
        status: REQUEST_STATUS.PENDING,
        has_kyc_submission: false,
        previous_kyc_step: null
      });
    case KYCConstants.KYC_FLOW_SUBMIT_SUCCESS:
      return modifyReducer(state, {
        status: REQUEST_STATUS.SUCCESS,
        history: [...state.history, action.payload]
      });
    case KYCConstants.KYC_FLOW_SUBMIT_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        access_token: action.payload.access_token,
        error: action.payload
      });
    default:
      return state;
  }
}

function handleKYCGetStepFailure(state, action) {
  if (action.payload.client_code === 404) {
    return modifyReducer(state, {
      step: KYC_STEP.START,
      status: REQUEST_STATUS.NOT_DEFINED,
      access_token: action.payload.access_token,
      history: [...state.history, action.payload]
    });
  } else {
    return modifyReducer(state, {
      step: KYC_STEP.GET_STEP,
      status: REQUEST_STATUS.FAILURE,
      access_token: action.payload.access_token
    });
  }
}
