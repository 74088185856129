import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
import { Icon } from 'UILib';

import './Button.style.scss';

export default class Button extends React.Component {
  render() {
    const {
      fluid,
      bgColor,
      borderColor,
      loading,
      color,
      disabled,
      type,
      content,
      onClick,
      translation,
      style,
      className
    } = this.props;

    const baseCN = 'button';
    const fluidCN = fluid && `${baseCN}-fluid`;
    const bgColorCN = bgColor
      ? `${baseCN}-background-color-${bgColor}`
      : `${baseCN}-background-color-white`;
    const borderColorCN = borderColor
      ? `${baseCN}-border-color-${borderColor}`
      : `${baseCN}-border-color-black`;
    const colorCN = color
      ? `${baseCN}-color-${color}`
      : `${baseCN}-color-black`;

    const ButtonCX = cx(
      baseCN,
      fluidCN,
      bgColorCN,
      borderColorCN,
      colorCN,
      className
    );

    return loading ? (
      <button
        disabled={disabled}
        type={type}
        style={style}
        className={ButtonCX}
      >
        <Icon
          base="solid"
          icon={{ name: 'circle-notch', rotate: true, animation: true }}
        />
      </button>
    ) : (
      <Translate>
        {({ translate }) => (
          <button
            disabled={disabled}
            type={type}
            onClick={onClick}
            style={style}
            className={ButtonCX}
          >
            {translation ? translate(content) : content}
          </button>
        )}
      </Translate>
    );
  }
}
