import React from 'react';
import cx from 'classnames';

import './Card.style.scss';

export default class CardContent extends React.Component {
  render() {
    const { children, textAlign, color, style, className } = this.props;
    const ElementTag = 'div';
    const baseCN = 'card-content';
    const textAlignCN = textAlign
      ? `${baseCN}-align-${textAlign}`
      : `${baseCN}-align-center`;
    const colorCN = color
      ? `${baseCN}-color-${color}`
      : `${baseCN}-color-grey-6`;

    const CardContentCX = cx(baseCN, textAlignCN, colorCN, className);

    return (
      <ElementTag className={CardContentCX} style={style}>
        {children}
      </ElementTag>
    );
  }
}
