import { all, put, takeLatest } from 'redux-saga/effects';

import apiGenerator from 'Utils/Helpers/api.helpers';
import {
  getStatusCodeFamily,
  apiErrorHandler
} from 'Utils/Helpers/saga.helpers';
import {
  API_ENDPOINTS,
  STATUS_TYPE
} from 'Constants/GlobalConstants/Global.constants';
import * as WithdrawConstants from 'Constants/Withdraw/Withdraw.constants';

function* createWithdrawSaga(action) {
  const { access_token, address, currency, quantity } = action.payload;
  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.WITHDRAW.CREATE,
    { address, currency, quantity }
  );

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WithdrawConstants.CREATE_WITHDRAW_SUCCESS,
        payload: { ...response.data, access_token, quantity, address }
      });
    }
  } catch (err) {
    yield put({
      type: WithdrawConstants.CREATE_WITHDRAW_FAILURE,
      payload: { error: apiErrorHandler(err), quantity, address }
    });
  }
}

function* confirmWithdrawSaga(action) {
  const { access_token, transaction_id, otp_code } = action.payload;

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.WITHDRAW.CONFIRM,
    { transaction_id, otp_code }
  );

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WithdrawConstants.CONFIRM_WITHDRAW_SUCCESS,
        payload: { ...response.data }
      });
    }
  } catch (err) {
    yield put({
      type: WithdrawConstants.CONFIRM_WITHDRAW_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(WithdrawConstants.CREATE_WITHDRAW_PENDING, createWithdrawSaga),
    takeLatest(WithdrawConstants.CONFIRM_WITHDRAW_PENDING, confirmWithdrawSaga)
  ]);
}
