/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';

import './Bar.style.scss';

export default class HorizontalBar extends React.Component {
  render() {
    const { barLabel, size, base, colors } = this.props;
    return (
      <div className="horizontal-bar">
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
          `}
        >
          <div
            css={css`
              position: relative;
              z-index: 100;
              height: 20px;
              width: ${`${size}%`};
              background-color: ${colors[0]};
              &:before {
                content: '';
                position: absolute;
                left: 100%;
                bottom: ${`${base / 4}px`};
                height: 100%;
                width: ${`${base / 2}px`};
                background-color: ${colors[1]};
                transform: skewY(-45deg);
              }
              &:after {
                content: '';
                position: absolute;
                left: ${`${base / 4}px`};
                top: ${`${-base / 2}px`};
                height: ${`${base / 2}px`};
                width: 100%;
                background-color: ${colors[2]};
                transform: skewX(-45deg);
              }
            `}
          />
          <div
            css={css`
              position: relative;
              left: 40px;
              font-size: 14px;
              color: ${colors[0]};
            `}
            className="bar-label"
          >
            {barLabel}
          </div>
        </div>
      </div>
    );
  }
}
