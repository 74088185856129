import React, { Component } from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import MoonLoader from 'Components/Reusable/Loading/MoonLoader.component';
import { Container, Card, Text } from 'UILib';

import 'Components/Account/PhoneActivation/phoneActivation.style.scss';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

class Start extends Component {
  constructor(props) {
    super(props);

    const {
      verification_level
    } = this.props.account.user_informations.attributes;

    if (this.props.computedMatch.path === '/account/phone-activation') {
      if (
        verification_level === 'phone_verified' ||
        verification_level === 'document_pending' ||
        verification_level === 'document_verified'
      ) {
        this.props.history.push(APP_ROUTES.DASHBOARD);
      }
    }
  }
  componentDidMount() {
    const { access_token } = this.props.account;
    setTimeout(() => {
      this.props.phoneActivationFlowStart(access_token);
    }, 600);
  }

  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <Card bgColor="white" type="auth" centered className="start-card">
            <MoonLoader />
            <Text
              type="h2"
              content="phoneActivation.start.header"
              translation
            />
          </Card>
        </GlobalLayout>
      </Container>
    );
  }
}

export default Start;
