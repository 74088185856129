import React from 'react';
import cx from 'classnames';

import './Container.style.scss';

export default class Container extends React.Component {
  render() {
    const { children, type, bgColor, style, className } = this.props;
    const baseCN = 'container';
    const typeCN = type && `${baseCN}-${type}`;
    const bgColorCN = bgColor
      ? `${baseCN}-background-color-${bgColor}`
      : `${baseCN}-background-color-white`;
    const CX = cx(baseCN, typeCN, bgColorCN, className);
    return (
      <div className={CX} style={style}>
        {children}
      </div>
    );
  }
}
