import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { determineFlowComponent } from 'Utils/Helpers/flow.helpers';
import {
  userLoginFlowReset,
  userLogin,
  userLoginWithMFA,
  userLoginWithMFAExpire,
  userLoginGetUserInformation,
  userLoginResendVerificationEmail
} from 'Actions/Authentication/Login.actions';

import { loginFlow } from 'Containers/Authentication/Flows/Login.flow';
import { LOGIN_STEP } from 'Constants/Authentication/Login.constants';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import * as validations from 'Containers/Authentication/Validations/Login.validation';

class LoginContainer extends Component {
  componentWillUnmount() {
    this.props.userLoginFlowReset();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { step } = nextProps.login;
    if (step === LOGIN_STEP.REDIRECT_TO_DASHBOARD) {
      nextProps.history.push(APP_ROUTES.DASHBOARD);
      return false;
    }
    return true;
  }

  render() {
    const ElementTag = determineFlowComponent(loginFlow, this.props.login);
    return <ElementTag {...this.props} {...validations} />;
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      userLoginFlowReset,
      userLogin,
      userLoginWithMFA,
      userLoginWithMFAExpire,
      userLoginGetUserInformation,
      userLoginResendVerificationEmail
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
