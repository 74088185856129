export const verifyRegister = {
  pending: {
    content: 'Verifying...'
  },
  success: {
    header: 'Cheers, welcome to voult!',
    description:
      'Discover the new way of energy investment. We’ll be happy to see you as a part of our journey.',
    footer: {
      cta: 'Back to Home'
    }
  },
  failure: {
    header: 'Houston, we have a problem.',
    description:
      'Your registration request has been interrupted due to some problems. Please, check your provided information and retry a while later to register.',
    footer: {
      cta: 'Back to Home'
    }
  }
};
