import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { determineFlowComponent } from 'Utils/Helpers/flow.helpers';

import {
  exchangeFlowReset,
  retrieveCurrentExchangeRates,
  tradeExchange
} from 'Actions/Exchange/Exchange.actions';

import { exchangeFlow } from 'Containers/Exchange/Flows/Exchange.flow';
import * as validations from 'Containers/Exchange/Validations/Exchange.validation';

class ExchangeContainer extends Component {
  componentDidMount() {
    this.props.retrieveCurrentExchangeRates(this.props.account.access_token);
    this.retrieveCurrentExchangeRatesTimer = setTimeout(() => {
      this.props.retrieveCurrentExchangeRates(this.props.account.access_token);
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.retrieveCurrentExchangeRatesTimer);
    this.props.exchangeFlowReset();
  }

  render() {
    const ElementTag = determineFlowComponent(
      exchangeFlow,
      this.props.exchange
    );
    return <ElementTag {...this.props} {...validations} />;
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    exchange: state.exchange
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      exchangeFlowReset,
      retrieveCurrentExchangeRates,
      tradeExchange
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeContainer);
