export const investmentTable = {
  invested: {
    header: {
      location: 'LOCATION',
      invested: 'INVESTED',
      shares: 'SHARES',
      earnings: 'EARNINGS'
    },
    noInvestmentFound: 'You haven’t invested any project, yet.',
    table: {
      invested: '${invested} USD',
      actions: {
        buy: 'Buy',
        sell: 'Sell'
      },
      total: {
        total: 'TOTAL',
        invested: '${invested} USD',
        earnings: '${earnings} USD',
        details: 'Details'
      }
    },
    error: {
      custom:
        'Something went wrong. We could not list projects, please refresh the page or contact us about the problem.'
    }
  },
  investable: {
    header: {
      type: 'TYPE',
      total: 'TOTAL INVESTMENT',
      backers: 'BACKERS',
      irr: 'RETURN'
    },
    noInvestableFound: 'There are no more investable projects yet, we are always looking for more projects to list.',
    audited: 'AUDITED',
    table: {
      total: '${total_investment} USD',
      actions: {
        buy: 'Buy'
      }
    },
    error: {
      custom:
        'Something went wrong. We could not list projects, please refresh the page or contact us about the problem.'
    }
  }
};
