import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import GlobalLayout from 'Layouts/Global.layout';
import AuthenticationResult from 'Components/Authentication/Reusable/AuthenticationResult.component';
import { Container, Card, Text, FormElements, Button } from 'UILib';
import { determineIcon } from 'Utils/Helpers/icon.helpers';
import { determineErrorLocale } from 'Utils/Helpers/component.helpers';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import 'Components/Authentication/authentication.style.scss';

const Form = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <Card type="auth" centered className="register-form-zone">
          <Container>
            <Text
              type="h1"
              content="register.form.header"
              translation
              className="header"
            />
            <Text
              type="intro"
              content="register.form.description"
              translation
              className="description"
            />
            <Card.Content>
              <Formik
                initialValues={props.initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={props.validationSchema}
                onSubmit={(values, { submitForm, setSubmitting }) => {
                  props.handleSubmit(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Container className="form-container">
                      <FormElements.Input
                        type="email"
                        name="email"
                        error={errors.email && touched.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="register.form.email_placeholder"
                        translation
                      />
                      <FormElements.Input
                        type="password"
                        name="password"
                        maxLength={32}
                        error={errors.password && touched.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="register.form.password_placeholder"
                        translation
                      />
                      <FormElements.Input
                        type="password"
                        name="password_confirmation"
                        maxLength={32}
                        error={
                          errors.password_confirmation &&
                          touched.password_confirmation
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="register.form.confirm_password_placeholder"
                        translation
                      />

                      <FormElements.Checkbox
                        name="terms_of_service_accept"
                        error={
                          errors.terms_of_service_accept &&
                          touched.terms_of_service_accept
                        }
                        checked={values.terms_of_service_accept}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        label="register.form.toc_aggrement_label"
                        translation
                      />
                      {props.hasValidationError(errors) && (
                        <Container className="error-container">
                          <FormElements.ValidationErrors
                            content="register.form.validation_error"
                            translation
                          />
                        </Container>
                      )}
                      <Container
                        className={
                          props.hasValidationError(errors)
                            ? 'submit-container-with-error'
                            : 'submit-container'
                        }
                      >
                        <Button
                          bgColor="orange-3"
                          borderColor="orange-3"
                          color="white"
                          type="submit"
                          content={
                            isSubmitting
                              ? 'register.form.loading_label'
                              : 'register.form.submit_label'
                          }
                          translation
                        />
                      </Container>
                    </Container>
                  </form>
                )}
              </Formik>
            </Card.Content>
          </Container>
          <Text type="intro" className="bottom-section">
            <Translate id="register.form.footer.content" />{' '}
            <Link to={APP_ROUTES.LOGIN}>
              <Translate id="register.form.footer.cta" />
            </Link>
          </Text>
        </Card>
      </GlobalLayout>
    </Container>
  );
};

const Success = () => {
  return (
    <AuthenticationResult
      component="RegisterSuccess"
      header={{
        type: 'h1',
        content: 'register.success.header',
        translation: true
      }}
      icon={{
        base: 'regular',
        name: determineIcon('authentication.register.success'),
        color: 'orange-3',
        size: '10x'
      }}
      description={{
        type: 'intro',
        color: 'grey-5',
        content: 'register.success.description',
        translation: true
      }}
      link={{
        to: '/',
        bgColor: 'orange-3',
        borderColor: 'orange-3',
        color: 'white',
        content: 'register.success.footer.cta',
        translation: true
      }}
    />
  );
};

const Failure = (props) => {
  const definedClientCodes = [409];
  const errorLocaleCode = determineErrorLocale(
    props.register.error.client_code,
    definedClientCodes
  );
  return (
    <AuthenticationResult
      component="RegisterFailure"
      header={{
        type: 'h1',
        content: 'register.failure.header',
        translation: true
      }}
      icon={{
        base: 'regular',
        name: determineIcon('authentication.register.failure'),
        color: 'black',
        size: '10x'
      }}
      description={{
        type: 'intro',
        color: 'grey-5',
        content: `register.failure.errors.${errorLocaleCode}`,
        translation: true
      }}
      onClick={{
        bgColor: 'orange-3',
        borderColor: 'orange-3',
        color: 'white',
        content: 'register.failure.footer.cta',
        translation: true,
        action: props.userRegisterStateReset
      }}
    />
  );
};

export default class Register extends Component {
  static Form = Form;
  static Success = Success;
  static Failure = Failure;
}
