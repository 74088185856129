import * as ExchangeConstants from 'Constants/Exchange/Exchange.constants';
import { EXCHANGE_STEP } from 'Constants/Exchange/Exchange.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

const initialState = {
  step: EXCHANGE_STEP.TRADE,
  status: REQUEST_STATUS.NOT_DEFINED,
  error: null,
  history: [],
  access_token: null,
  exchange_rates: [],
  submitted_state: {}
};

export default function exchange(state = initialState, action) {
  switch (action.type) {
    // --- FLOW RESET ---
    case ExchangeConstants.EXCHANGE_FLOW_RESET:
      return initialState;
    case ExchangeConstants.RETRIEVE_CURRENT_EXCHANGE_RATES_PENDING:
      return state;
    case ExchangeConstants.RETRIEVE_CURRENT_EXCHANGE_RATES_SUCCESS:
      return modifyReducer(state, {
        access_token: action.payload.access_token,
        exchange_rates: action.payload.exchange_rates,
        error: null
      });
    case ExchangeConstants.RETRIEVE_CURRENT_EXCHANGE_RATES_FAILURE:
      return modifyReducer(state, {
        error: action.payload
      });
    case ExchangeConstants.TRADE_EXCHANGE_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        access_token: action.payload.access_token,
        submitted_state: action.payload.state
      });
    case ExchangeConstants.TRADE_EXCHANGE_SUCCESS:
      return modifyReducer(state, {
        status: REQUEST_STATUS.SUCCESS
      });
    case ExchangeConstants.TRADE_EXCHANGE_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    default:
      return state;
  }
}
