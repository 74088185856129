import React from 'react';
import ImageLoader from 'react-load-image';

import MoonLoader from 'Components/Reusable/Loading/MoonLoader.component';

const replacement = require('Assets/Logo/voult_image.svg');

class ImagePreloader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false
    };
  }

  componentDidMount() {
    this.showLoaderTimeout = setTimeout(() => {
      this.setState({ showLoader: true });
    }, 150);
  }

  componentWillUnmount() {
    clearTimeout(this.showLoaderTimeout);
  }

  render() {
    return (
      <div className="preloader">{this.state.showLoader && <MoonLoader />}</div>
    );
  }
}

export default class Slide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      transitioned: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.image !== this.props.image) {
      this.setState({ loaded: false, transitioned: false });
    }
    if (!prevState.loaded && this.state.loaded) {
      this.transitionedTimeout = setTimeout(() => {
        this.setState({ transitioned: true });
      }, 0);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.transitionedTimeout);
  }

  render() {
    return (
      <ImageLoader
        src={this.props.image}
        wrapperProps={{ style: { height: '100%' } }}
        onLoad={() => {
          this.setState({ loaded: true });
        }}
      >
        <img
          className={`image ${this.state.transitioned && 'image-loaded'}`}
          alt="project"
        />
        <div className="placeholder">
          <img src={replacement} alt="replacement" />
        </div>
        <ImagePreloader />
      </ImageLoader>
    );
  }
}
