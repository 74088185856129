import * as Yup from 'yup';

const ResetPasswordStartValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  recaptcha_response: Yup.string().required('Required')
});

const ResetPasswordExecuteValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(32, 'Too Long!')
    .required('Required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null])
    .required('Required')
});

const ResetPasswordVerifyUserInformationValidationSchema = Yup.object().shape({
  national_id: Yup.string()
    .min(8, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  date_of_birth: Yup.string().required('Required')
});

export {
  ResetPasswordStartValidationSchema,
  ResetPasswordExecuteValidationSchema,
  ResetPasswordVerifyUserInformationValidationSchema
};
