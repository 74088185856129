import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import apiGenerator from 'Utils/Helpers/api.helpers';
import {
  getStatusCodeFamily,
  apiErrorHandler,
  sagaDelayer
} from 'Utils/Helpers/saga.helpers';

import {
  STATUS_TYPE,
  API_ENDPOINTS
} from 'Constants/GlobalConstants/Global.constants';
import * as ResetPasswordConstants from 'Constants/Authentication/ResetPassword.constants';

function* resetPasswordFlowStartSaga(action) {
  const { formState } = action.payload;
  const api = apiGenerator('post')(
    API_ENDPOINTS.RESET_PASSWORD.START,
    formState
  );
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ResetPasswordConstants.RESET_PASSWORD_START_SUCCESS,
        payload: { ...response.data, email: formState.email }
      });
    }
  } catch (err) {
    yield put({
      type: ResetPasswordConstants.RESET_PASSWORD_START_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* resetPasswordFlowSendVerificationSaga(action) {
  const { email, action_token, option } = action.payload;
  const api = apiGenerator('post')(
    API_ENDPOINTS.RESET_PASSWORD.SEND_VERIFICATION,
    {
      email,
      action_token,
      option
    }
  );
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ResetPasswordConstants.RESET_PASSWORD_SEND_VERIFICATION_SUCCESS,
        payload: { ...response.data }
      });
    }
    yield null;
  } catch (err) {
    yield put({
      type: ResetPasswordConstants.RESET_PASSWORD_SEND_VERIFICATION_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* resetPasswordFlowVerifyCodeEmailSaga(action) {
  const { verification_code } = action.payload;
  const api = apiGenerator('post')(API_ENDPOINTS.RESET_PASSWORD.VERIFY_CODE, {
    code: verification_code
  });

  try {
    yield put(push('/reset-password')); // re-entering reset password flow
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_EMAIL_SUCCESS,
        payload: { ...response.data }
      });
    }
  } catch (err) {
    yield put({
      type: ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_EMAIL_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* resetPasswordFlowVerifyCodeOTPSaga(action) {
  const { email, action_token, otp_code } = action.payload;
  const api = apiGenerator('post')(API_ENDPOINTS.RESET_PASSWORD.VERIFY_CODE, {
    email,
    action_token,
    otp_code
  });
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_OTP_SUCCESS,
        payload: { ...response.data }
      });
    }
  } catch (err) {
    yield put({
      type: ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_OTP_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* resetPasswordFlowVerifyUserInformationSaga(action) {
  const { email, action_token, national_id, date_of_birth } = action.payload;
  const api = apiGenerator('post')(
    API_ENDPOINTS.RESET_PASSWORD.VERIFY_USER_INFORMATION,
    { email, action_token, national_id, date_of_birth }
  );
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type:
          ResetPasswordConstants.RESET_PASSWORD_VERIFY_USER_INFORMATION_SUCCESS,
        payload: { ...response.data }
      });
    }
  } catch (err) {
    yield put({
      type:
        ResetPasswordConstants.RESET_PASSWORD_VERIFY_USER_INFORMATION_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* resetPasswordFlowExecuteSaga(action) {
  const { formState } = action.payload;
  const api = apiGenerator('post')(
    API_ENDPOINTS.RESET_PASSWORD.EXECUTE,
    formState
  );
  yield sagaDelayer();
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ResetPasswordConstants.RESET_PASSWORD_EXECUTE_SUCCESS,
        payload: { ...response.data }
      });
    }
  } catch (err) {
    yield put({
      type: ResetPasswordConstants.RESET_PASSWORD_EXECUTE_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ResetPasswordConstants.RESET_PASSWORD_START_PENDING,
      resetPasswordFlowStartSaga
    ),
    takeLatest(
      ResetPasswordConstants.RESET_PASSWORD_SEND_VERIFICATION_PENDING,
      resetPasswordFlowSendVerificationSaga
    ),
    takeLatest(
      ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_EMAIL_PENDING,
      resetPasswordFlowVerifyCodeEmailSaga
    ),
    takeLatest(
      ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_OTP_PENDING,
      resetPasswordFlowVerifyCodeOTPSaga
    ),
    takeLatest(
      ResetPasswordConstants.RESET_PASSWORD_VERIFY_USER_INFORMATION_PENDING,
      resetPasswordFlowVerifyUserInformationSaga
    ),
    takeLatest(
      ResetPasswordConstants.RESET_PASSWORD_EXECUTE_PENDING,
      resetPasswordFlowExecuteSaga
    )
  ]);
}
