import { TOC_LINK } from 'Constants/GlobalConstants/Global.constants';

export const withdraw = {
  create: {
    confirmation: {
      header: 'Are you sure?',
      subheader:
        'You are making a withdraw action right now. Please be sure that this is what you are intending.',
      yes: 'YES',
      no: 'NO'
    },
    header: 'Withdraw Money',
    subheader:
      'Your conversion will be completed from the recent exchange rate that voult platform has provided and your source and target balances will be updated according to this conversion.',
    form: {
      header: 'Withdraw from',
      subheader: 'Funds will decrease from selected wallet.',
      wallet: {
        BTC: 'Bitcoin',
        ETH: 'Ethereum',
        USDC: 'USDC'
      },
      walletAddress: 'Wallet Address',
      amount: 'Amount',
      toc: `I've agree the <a target='_blank' href=${TOC_LINK}>Terms and Conditions.</a>`,
      action: 'Withdraw Money',
      validation_error:
        'Please check the indicated area then submit the form again.',
      failure: {
        errors: {
          0: 'Something went wrong, please try to withdraw again or contact us if problem persists.',
          480: 'You tried to withdraw more than you currently have, please try again.',
          423: 'You reached maximum withdraw limit. Please try again later.'
        }
      }
    }
  },
  confirm: {
    header: 'Withdraw Money',
    subheader:
      'You can withdraw BTC, ETH or USDC from your voult Wallet to your other wallets. Please provide your own wallet address and the amount that you want to withdraw. Due to network costs, transaction amount could be decreased from the amount you want to withdraw.',
    card: {
      header: 'Use the code that we sent to your phone number.',
      subheader:
        'We sent an 6-digit one time password to your registered phone number.',
      action: 'Submit',
      validation_error:
        'Please check indicated area then submit the form again.',
      failure: {
        errors: {
          0: 'Something went wrong, please try to withdraw again or contact us if problem persists.',
          400: '6-digit one time password you provided seems to be wrong. Please try again later.'
        }
      }
    }
  },
  failure: {
    header: 'Sorry, your transfer has been failed.',
    subheader:
      'Your transfer request has been failed, it can be due to blockchain network problems. If you feel that was our problem, please retry or reach us to inform us.',
    content: {
      amount: {
        header: 'Withdrawal Amount'
      },
      address: {
        header: 'Target Wallet Address'
      },
      datetime: {
        header: 'Date & Time'
      },
      currentBalance: {
        header: 'Current ${type} Balance'
      }
    },
    actions: {
      retry: 'Retry',
      dashboard: 'Go To Dashboard'
    }
  },
  success: {
    header: 'Congrats! Your transfer has been completed.',
    subheader:
      'Your transfer request has been completed and submitted to blockchain network. Transfer process can take some time to successfuly completed.',
    content: {
      amount: {
        header: 'Withdrawal Amount'
      },
      address: {
        header: 'Target Wallet Address'
      },
      datetime: {
        header: 'Date & Time'
      },
      currentBalance: {
        header: 'Current ${type} Balance'
      }
    },
    actions: {
      dashboard: 'Go To Dashboard'
    }
  }
};
