import React from 'react';

import AuthenticationResult from 'Components/Authentication/Reusable/AuthenticationResult.component';

const Failure = (props) => {
  return (
    <AuthenticationResult
      header={{
        type: 'h1',
        content: 'login.failure.header',
        translation: true
      }}
      icon={{
        base: 'regular',
        name: 'times-circle',
        color: 'black',
        size: '10x'
      }}
      description={{
        type: 'intro',
        color: 'grey-5',
        content: 'login.failure.description',
        translation: true
      }}
      onClick={{
        bgColor: 'orange-3',
        borderColor: 'orange-3',
        color: 'white',
        content: 'login.failure.footer.cta',
        translation: true,
        action: props.userLoginFlowReset
      }}
    />
  );
};

export default Failure;
