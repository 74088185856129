import Create from 'Components/Withdraw/Create.component';
import Confirm from 'Components/Withdraw/Confirm.component';
import Success from 'Components/Withdraw/Success.component';
import Failure from 'Components/Withdraw/Failure.component';

import { WITHDRAW_STEP } from 'Constants/Withdraw/Withdraw.constants';

export const withdrawFlow = {
  flow: [
    {
      step: WITHDRAW_STEP.CREATE,
      component: Create
    },
    {
      step: WITHDRAW_STEP.CONFIRM,
      component: Confirm
    }
  ],
  success: {
    component: Success
  },
  failure: {
    component: Failure
  }
};
