import { all, put, takeLatest } from 'redux-saga/effects';

import apiGenerator from 'Utils/Helpers/api.helpers';
import {
  getStatusCodeFamily,
  apiErrorHandler
} from 'Utils/Helpers/saga.helpers';
import {
  API_ENDPOINTS,
  STATUS_TYPE
} from 'Constants/GlobalConstants/Global.constants';
import * as ExchangeConstants from 'Constants/Exchange/Exchange.constants';

function* retrieveCurrentExchangeRatesSaga(action) {
  const { access_token } = action.payload;

  const api = apiGenerator('get', access_token)(
    API_ENDPOINTS.EXCHANGE.RETRIEVE_RATES
  );

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ExchangeConstants.RETRIEVE_CURRENT_EXCHANGE_RATES_SUCCESS,
        payload: {
          exchange_rates: response.data.exchange_rates,
          access_token: access_token
        }
      });
    }
  } catch (err) {
    yield put({
      type: ExchangeConstants.RETRIEVE_CURRENT_EXCHANGE_RATES_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

function* tradeExchangeSaga(action) {
  const { access_token, state } = action.payload;

  const apiBody = {
    from: state.from.currency,
    to: state.to.currency,
    quantity: parseFloat(state.from.amount)
  };

  const api = apiGenerator('post', access_token)(
    API_ENDPOINTS.EXCHANGE.TRADE,
    apiBody
  );

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ExchangeConstants.TRADE_EXCHANGE_SUCCESS,
        payload: {}
      });
    }
  } catch (err) {
    yield put({
      type: ExchangeConstants.TRADE_EXCHANGE_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ExchangeConstants.RETRIEVE_CURRENT_EXCHANGE_RATES_PENDING,
      retrieveCurrentExchangeRatesSaga
    ),
    takeLatest(ExchangeConstants.TRADE_EXCHANGE_PENDING, tradeExchangeSaga)
  ]);
}
