export const login = {
  login: {
    header: 'Hey, Welcome back.',
    description:
      'Please, provide your registered e-mail address and your account password to log in.',
    email_placeholder: 'E-Mail Address',
    password_placeholder: 'Password',
    reset_password_link: 'Forgot Password',
    submit_label: 'Login',
    loading_label: 'Loading...',
    footer: {
      content: 'Not a member?',
      cta: 'Sign Up Now!'
    },
    validation_error: 'Please check indicated area then submit the form again.',
    failure: {
      errors: {
        0: 'Something went wrong, please try again to log in.',
        401: "Wrong e-mail or password. Please check the information that you've provided."
      }
    }
  },
  mfa: {
    header: 'Additional Security Verification',
    description:
      'We need to verify your acccount for further security. Please provide the 6-digit one time password that we sent to your registered phone number.',
    submit_label: 'Submit',
    loading_label: 'Loading...',
    footer: {
      content: 'Not a member?',
      cta: 'Sign Up Now!'
    },
    failure: {
      errors: {
        0: 'Something went wrong, please try again to log in.',
        400: "Wrong one time password. Please check the information that you've provided and try again.",
        401: 'Your session is expired. Please to log in again.'
      }
    }
  },
  success: {
    header: 'Here we go!',
    description:
      "Welcome on board! You've successfully logged in. <br />You will be redirected to your dashboard in ${remaining} seconds.",
    footer: {
      content: 'Not a member?',
      cta: 'Sign Up Now!'
    }
  },
  failure: {
    header: 'Houston, we have a problem.',
    description:
      "Something went wrong while you're trying to login to your account, please try again.",
    footer: {
      cta: 'Retry'
    }
  },
  resend_verification: {
    header: 'One More Step to Continue',
    description:
      "It seems that you haven't verified your e-mail yet. Please verify your registered e-mail with the link that you received before or resend a verification e-mail.",
    footer: {
      cta: 'Resend Verification E-mail'
    }
  }
};
