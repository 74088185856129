import {
  EXCHANGE_FLOW_RESET,
  RETRIEVE_CURRENT_EXCHANGE_RATES_PENDING,
  TRADE_EXCHANGE_PENDING
} from 'Constants/Exchange/Exchange.constants';

export function exchangeFlowReset() {
  return {
    type: EXCHANGE_FLOW_RESET
  };
}

export function retrieveCurrentExchangeRates(access_token) {
  return {
    type: RETRIEVE_CURRENT_EXCHANGE_RATES_PENDING,
    payload: { access_token }
  };
}

export function tradeExchange(access_token, state) {
  return {
    type: TRADE_EXCHANGE_PENDING,
    payload: { access_token, state }
  };
}
