import React from 'react';
import { Link } from 'react-router-dom';

import GlobalLayout from 'Layouts/Global.layout';

import { Container, Text, Icon, Button } from 'UILib';
import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

import 'Components/Exchange/exchange.style.scss';

class Failure extends React.Component {
  render() {
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <div className="exchange-failure-wrapper">
            <div className="failure-header-zone">
              <Icon
                base="regular"
                icon={{ name: 'times-circle', color: 'grey-6' }}
                className="icon"
              />
              <div className="failure-header-content">
                <Text
                  type="h1"
                  textAlign="left"
                  content="exchange.failure.header"
                  translation
                  className="failure-header"
                />
                <Text
                  type="intro"
                  textAlign="left"
                  content="exchange.failure.subheader"
                  translation
                  className="failure-text"
                />
              </div>
            </div>
            <div className="failure-general-zone">
              {/* This section needs a design to be implemented */}
            </div>
            <div className="failure-action-zone">
              <Button
                content="exchange.failure.actions.retry"
                translation
                borderColor="orange-3"
                color="orange-3"
                bgColor="white"
                className="button"
                onClick={() => {
                  this.props.exchangeFlowReset();
                }}
              />
              <Link to={APP_ROUTES.DASHBOARD}>
                <Button
                  content="exchange.failure.actions.dashboard"
                  translation
                  borderColor="orange-3"
                  color="white"
                  bgColor="orange-3"
                  className="button"
                />
              </Link>
            </div>
          </div>
        </GlobalLayout>
      </Container>
    );
  }
}

export default Failure;
