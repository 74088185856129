import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';

import { retrieveProjectDetailWithProjectId } from 'Actions/ProjectDetail/ProjectDetail.actions';

import { APP_ROUTES } from 'Constants/GlobalConstants/Global.constants';

class InvestmentRoute extends Component {
  componentDidMount() {
    this.props.projectDetail.projectId === null &&
      this.props.retrieveProjectDetailWithProjectId(
        this.props.computedMatch.params.projectId
      );
  }

  shouldRedirectInvestmentOrCommitment = () => {
    if (this.props.projectDetail.projectId !== null) {
      const permittedRoute = this.props.projectDetail.attributes
        .investment_status
        ? 'investment'
        : 'commitment';
      return (
        _.last(_.split(this.props.computedMatch.path, '/')) !== permittedRoute
      );
    }
  };

  determineRedirectRoute = () => {
    const { projectId } = this.props.computedMatch.params;
    return this.props.projectDetail.attributes.investment_status
      ? APP_ROUTES.INVESTMENT_WITH_ID(projectId)
      : APP_ROUTES.COMMITMENT_WITH_ID(projectId);
  };

  render() {
    const { component: Component } = this.props;
    return (
      <Route
        render={(props) =>
          this.shouldRedirectInvestmentOrCommitment() ? (
            <Redirect to={{ pathname: this.determineRedirectRoute() }} />
          ) : (
            <Component />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    projectDetail: state.projectDetail
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveProjectDetailWithProjectId
    },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InvestmentRoute)
);
