import React from 'react';

import GlobalLayout from 'Layouts/Global.layout';
import KYCStepHeader from 'Components/KYC/components/KYCStepHeader.component';
import MoonLoader from 'Components/Reusable/Loading/MoonLoader.component';

import { Container } from 'UILib';
import 'Components/KYC/kyc.style.scss';

const GetStep = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div className="kyc-page-wrapper">
          <div className="kyc-start-wrapper">
            <KYCStepHeader />
            <div className="start-loader">
              <MoonLoader />
            </div>
          </div>
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default GetStep;
