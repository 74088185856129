import React from 'react';
import { Formik } from 'formik';

import GlobalLayout from 'Layouts/Global.layout';
import InvestmentHeader from 'Components/Investment/Components/InvestmentHeader.component';
import ConfirmPane from 'Components/Investment/Components/ConfirmPane.component';
import WalletPane from 'Components/Account/UserDashboard/Components/WalletPane.component';

import ConfirmValidationSchema from 'Containers/Investment/Validations/investment.validations';

import {
  handleOTPTimerOutput,
  transformBalance
} from 'Utils/Helpers/component.helpers';
import { Container, Text, Button, FormElements } from 'UILib';
import 'Components/Investment/investment.style.scss';

const calculation = (value, properties) => {
  const annual_revenue = parseInt(
    properties.financial.compensation_details.annual_revenue,
    10
  );
  const offer_percentage =
    parseInt(
      properties.contribution.compensation_details.offer_percentage,
      10
    ) / 100;

  const amount_to_be_raised = parseInt(
    properties.contribution.compensation_details.amount_to_be_raised,
    10
  );

  return (
    ((annual_revenue * offer_percentage * value) / amount_to_be_raised) * 10
  );
};

const initialValues = {
  investment_aggrement: false,
  validate: false
};

const Confirm = (props) => {
  const { minutes, seconds } = handleOTPTimerOutput(
    props.investment.remainingTime
  );
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <div className="investment-page-wrapper">
          <InvestmentHeader
            information={props.projectDetail.attributes.properties.information}
            statistics={props.projectDetail.attributes.statistics}
          />
          <div className="investment-page-confirm-wrapper">
            <div className="confirm-page-left-pane-wrapper">
              <div className="investment-general-information-zone">
                <div className="information">
                  <Text
                    content="investment.investmentHeader.amountToBeRaised"
                    translation
                    color="grey-3"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.projectDetail.attributes.properties.contribution
                        .compensation_details.amount_to_be_raised,
                      0
                    )} USD`}
                    color="black"
                    className="header"
                  />
                </div>
                <div className="information">
                  <Text
                    content="investment.investmentHeader.raisedCapital"
                    translation
                    color="grey-3"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.projectDetail.attributes.statistics.raised_capital,
                      0
                    )} USD`}
                    color="black"
                    className="header"
                  />
                </div>
                <div className="information">
                  <Text
                    content="investment.investmentHeader.totalShares"
                    translation
                    color="grey-3"
                    className="subheader"
                  />
                  <Text
                    content={`${transformBalance(
                      props.projectDetail.attributes.properties.contribution
                        .compensation_details.share_count,
                      0
                    )}`}
                    color="black"
                    className="header"
                  />
                </div>
              </div>
              <div className="investment-confirm-selection-panel">
                <div className="timer-zone">
                  <Text
                    content={`${minutes}:${seconds}`}
                    color="white"
                    className="timer"
                  />
                  <Text
                    content="investment.timer.complete"
                    translation
                    color="white"
                    className="text"
                  />
                </div>
                <ConfirmPane {...props} />
              </div>
              <div className="investment-confirmation-total-panel">
                <div className="information">
                  <Text
                    content="investment.calculation.estimated"
                    translation
                    color="light-grey"
                    className="subheader"
                  />
                  <Text
                    content={transformBalance(
                      calculation(
                        props.selectedInvestmentAmount,
                        props.projectDetail.attributes.properties
                      ),
                      0
                    )}
                    color="orange-3"
                    className="header"
                  />
                </div>
              </div>
              <div className="investment-action-zone">
                <Formik
                  initialValues={initialValues}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={ConfirmValidationSchema}
                  onSubmit={(values, { submitForm, setSubmitting }) => {
                    props.investmentConfirm(
                      props.projectDetail.projectId,
                      props.account.access_token,
                      props.investment.transaction_id
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-container">
                        <div className="aggrements">
                          <FormElements.Checkbox
                            name="investment_aggrement"
                            error={
                              errors.investment_aggrement &&
                              touched.investment_aggrement
                            }
                            checked={values.investment_aggrement}
                            setFieldValue={setFieldValue}
                            onChange={handleChange}
                            label="investment.confirmInformation.aggrements.investment"
                            translation
                          />
                          <FormElements.Checkbox
                            name="validate"
                            error={errors.validate && touched.validate}
                            checked={values.validate}
                            setFieldValue={setFieldValue}
                            onChange={handleChange}
                            label="investment.confirmInformation.aggrements.validate"
                            translation
                          />
                        </div>
                        <Button
                          content="investment.confirmInformation.action"
                          translation
                          className="action"
                          color="white"
                          bgColor="orange-3"
                          borderColor="orange-3"
                          type="submit"
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <WalletPane {...props} />
          </div>
        </div>
      </GlobalLayout>
    </Container>
  );
};

export default Confirm;
