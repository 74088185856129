export const PHONE_ACTIVATION_STEP = {
  START: 'start',
  ADD: 'add',
  CONFIRM: 'confirm'
};

export const PHONE_ACTIVATION_FLOW_RESET = 'PHONE_ACTIVATION_FLOW_RESET';
export const PHONE_ACTIVATION_START_PENDING = 'PHONE_ACTIVATION_START_PENDING';
export const PHONE_ACTIVATION_START_SUCCESS = 'PHONE_ACTIVATION_START_SUCCESS';
export const PHONE_ACTIVATION_START_FAILURE = 'PHONE_ACTIVATION_START_FAILURE';
export const PHONE_ACTIVATION_ADD_PENDING = 'PHONE_ACTIVATION_ADD_PENDING';
export const PHONE_ACTIVATION_ADD_SUCCESS = 'PHONE_ACTIVATION_ADD_SUCCESS';
export const PHONE_ACTIVATION_ADD_FAILURE = 'PHONE_ACTIVATION_ADD_FAILURE';
export const PHONE_ACTIVATION_ADD_ERROR_CLEARING =
  'PHONE_ACTIVATION_ADD_ERROR_CLEARING';
export const PHONE_ACTIVATION_CONFIRM_PENDING =
  'PHONE_ACTIVATION_CONFIRM_PENDING';
export const PHONE_ACTIVATION_CONFIRM_SUCCESS =
  'PHONE_ACTIVATION_CONFIRM_SUCCESS';
export const PHONE_ACTIVATION_CONFIRM_FAILURE =
  'PHONE_ACTIVATION_CONFIRM_FAILURE';
export const PHONE_ACTIVATION_CONFIRM_NO_TIME_FAILURE =
  'PHONE_ACTIVATION_CONFIRM_NO_TIME_FAILURE';
