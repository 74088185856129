import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import { ConnectedRouter } from 'connected-react-router';

import store, { history } from 'Store/store';

import LocalizationWrapper from 'Locales/LocalizationWrapper';
import AppInitContainer from 'Containers/AppInit/AppInitContainer';
import ScrollToTop from 'Routes/Helpers/ScrollToTop.component';
import MainRoutes from 'Routes/MainRoutes';

class AppProvider extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <LocalizeProvider>
            <LocalizationWrapper>
              <AppInitContainer>
                <ScrollToTop>
                  <MainRoutes />
                </ScrollToTop>
              </AppInitContainer>
            </LocalizationWrapper>
          </LocalizeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default AppProvider;
