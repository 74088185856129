import React from 'react';
import * as _ from 'lodash';

import { Text } from 'UILib';

const MobileTabDataColumn = (props) => {
  return (
    <div className="data-column">
      {_.keys(props.data).map((key, idx) => (
        <div className="data-row" key={`${idx}-mobile`}>
          <Text
            content={
              !_.includes(props.noTranslation, key)
                ? `projectDetail.${props.family}.${props.from}.${key}.header`
                : key
            }
            translation={!_.includes(props.noTranslation, key)}
            className="header"
          />
          <Text
            content={
              !_.includes(props.noTranslation, key)
                ? `projectDetail.${props.family}.${props.from}.${key}.value`
                : props.data[key]
            }
            data={{ value: props.data[key] }}
            translation={!_.includes(props.noTranslation, key)}
            className="value"
          />
        </div>
      ))}
    </div>
  );
};

export default MobileTabDataColumn;
