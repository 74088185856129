import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';

import GlobalLayout from 'Layouts/Global.layout';

import {
  handleOTPTimerOutput,
  genericComponentTimer
} from 'Utils/Helpers/component.helpers';
import { Container, Card, Text, FormElements, Button } from 'UILib';

import {
  REQUEST_STATUS,
  APP_ROUTES
} from 'Constants/GlobalConstants/Global.constants';

import 'Components/Account/PhoneActivation/phoneActivation.style.scss';

const ENTER_KEY = 13;

class Confirm extends Component {
  constructor(props) {
    super(props);

    this.state = { otp_code: '', remaining: 60, hasDigitError: false };
  }

  componentDidMount() {
    this.timer = genericComponentTimer(() => {
      this.setState({ remaining: this.state.remaining - 1 });
    });
    document.addEventListener('keydown', (e) => {
      this.handleKeyDown(e);
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    document.removeEventListener('keydown', (e) => {
      this.handleKeyDown(e);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * Resets otp_code value when a wrong otp code is entered
     */
    if (
      this.props.phoneActivation.otp_code_tries !==
      prevProps.phoneActivation.otp_code_tries
    ) {
      this.setState({ otp_code: '' });
    }
    /**
     * Concludes phone activation flow with a failure screen when timer reaches 00:00
     */
    if (this.state.remaining === 0) {
      this.props.phoneActivationConfirmNoTime();
    }
  }

  handleKeyDown = (e) => {
    e.keyCode === ENTER_KEY && this.handleContinue();
  };

  handleChange = (otp_code) => {
    this.setState({ otp_code });
  };

  handleContinue = () => {
    const { access_token, action_token } = this.props.phoneActivation;
    const { otp_code } = this.state;
    if (otp_code.length === 6) {
      this.setState({ hasDigitError: false });
      this.props.phoneActivationFlowConfirm(
        access_token,
        action_token,
        otp_code
      );
    } else {
      this.setState({ hasDigitError: true });
    }
  };

  render() {
    const { minutes, seconds } = handleOTPTimerOutput(this.state.remaining);
    const { otp_code } = this.state;
    const { otp_code_tries } = this.props.phoneActivation;
    return (
      <Container type="page" bgColor="grey-0">
        <GlobalLayout>
          <Card type="auth" centered className="confirm-card">
            <Text
              type="h1"
              content="phoneActivation.confirm.header"
              translation
              className="header"
            />
            <Container className="otp-container">
              <Container>
                <Text
                  type="intro"
                  content="phoneActivation.confirm.description"
                  className="descriptive-text"
                  translation
                />
                <Text
                  type="intro"
                  content={this.props.phoneActivation.phone}
                  className="phone-number"
                />
                <Text
                  type="h1"
                  content={`${minutes}:${seconds}`}
                  className="time"
                />
              </Container>
              <Container className="digits-container">
                <FormElements.Digit
                  type="number"
                  digits={6}
                  error={this.state.hasDigitError}
                  value={otp_code}
                  onChange={this.handleChange}
                />
                <Text
                  type="intro"
                  content={`${otp_code_tries} / 3`}
                  color="grey-2"
                  className="tries-text"
                />
              </Container>
              <Container className="generic-container">
                {this.props.phoneActivation.error && (
                  <FormElements.ValidationErrors
                    content={`status_codes.client_codes.${this.props.phoneActivation.error.client_code}`}
                    translation
                  />
                )}
              </Container>
              <Container className="right-align">
                <Button
                  content={
                    this.props.phoneActivation.status === REQUEST_STATUS.PENDING
                      ? 'phoneActivation.confirm.loading'
                      : 'phoneActivation.confirm.submit'
                  }
                  translation
                  color="white"
                  bgColor="orange-3"
                  borderColor="orange-3"
                  onClick={() => {
                    this.handleContinue();
                  }}
                />
              </Container>
            </Container>
            <Text type="intro" className="bottom-section">
              <Link to={APP_ROUTES.DASHBOARD}>
                <Translate id="phoneActivation.confirm.footer.cta" />
              </Link>
            </Text>
          </Card>
        </GlobalLayout>
      </Container>
    );
  }
}

export default Confirm;
