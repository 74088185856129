import * as ResetPasswordConstants from 'Constants/Authentication/ResetPassword.constants';
import {
  RESET_PASSWORD_STEP,
  RESET_PASSWORD_TYPE
} from 'Constants/Authentication/ResetPassword.constants';
import { REQUEST_STATUS } from 'Constants/GlobalConstants/Global.constants';
import { modifyReducer } from 'Utils/Helpers/reducer.helpers';

const initialState = {
  step: RESET_PASSWORD_STEP.START,
  verification_type: RESET_PASSWORD_TYPE.NOT_DEFINED,
  status: REQUEST_STATUS.NOT_DEFINED,
  error: null,
  history: [],
  email: '',
  action_token: null,
  verification_options: [],
  otp_code_tries: 0
};

export default function resetPassword(state = initialState, action) {
  switch (action.type) {
    // --- FLOW RESET ---
    case ResetPasswordConstants.RESET_PASSWORD_FLOW_RESET:
      return initialState;
    // --- START Conditions ---
    case ResetPasswordConstants.RESET_PASSWORD_START_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case ResetPasswordConstants.RESET_PASSWORD_START_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    case ResetPasswordConstants.RESET_PASSWORD_START_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload],
        email: action.payload.email,
        action_token: action.payload.action_token,
        verification_options: action.payload.verification_options
      });
    // --- SEND_VERIFICATION Conditions ---
    case ResetPasswordConstants.RESET_PASSWORD_SEND_VERIFICATION_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null,
        verification_type: action.payload.option
      });
    case ResetPasswordConstants.RESET_PASSWORD_SEND_VERIFICATION_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    case ResetPasswordConstants.RESET_PASSWORD_SEND_VERIFICATION_SUCCESS:
      return handleResetPasswordSendVerificationSuccess(state, action);
    // --- VERIFY_CODE - EMAIL Conditions ---
    case ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_EMAIL_PENDING:
      return modifyReducer(state, {
        step: RESET_PASSWORD_STEP.VERIFY_CODE,
        error: null,
        verification_type: RESET_PASSWORD_TYPE.EMAIL,
        status: REQUEST_STATUS.PENDING
      });
    case ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_EMAIL_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    case ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_EMAIL_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload],
        email: action.payload.email,
        action_token: action.payload.action_token
      });
    // --- VERIFY_CODE - OTP Conditions ---
    case ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_OTP_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_OTP_FAILURE:
      return handleVerifyCodeOTPFailure(state, action);
    case ResetPasswordConstants.RESET_PASSWORD_VERIFY_CODE_OTP_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
    // --- VERIFY_USER_INFORMATION Conditions ---
    case ResetPasswordConstants.RESET_PASSWORD_VERIFY_USER_INFORMATION_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case ResetPasswordConstants.RESET_PASSWORD_VERIFY_USER_INFORMATION_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    case ResetPasswordConstants.RESET_PASSWORD_VERIFY_USER_INFORMATION_SUCCESS:
      return modifyReducer(state, {
        step: action.payload.next_step,
        status: REQUEST_STATUS.NOT_DEFINED,
        history: [...state.history, action.payload]
      });
    // --- EXECUTE Conditions ---
    case ResetPasswordConstants.RESET_PASSWORD_EXECUTE_PENDING:
      return modifyReducer(state, {
        status: REQUEST_STATUS.PENDING,
        error: null
      });
    case ResetPasswordConstants.RESET_PASSWORD_EXECUTE_FAILURE:
      return modifyReducer(state, {
        status: REQUEST_STATUS.FAILURE,
        error: action.payload
      });
    case ResetPasswordConstants.RESET_PASSWORD_EXECUTE_SUCCESS:
      return modifyReducer(state, { status: REQUEST_STATUS.SUCCESS });
    default:
      return state;
  }
}

function handleResetPasswordSendVerificationSuccess(state, action) {
  const { verification_type } = state;
  let returnState = {};
  if (verification_type === RESET_PASSWORD_TYPE.EMAIL) {
    // exits flow with a success component
    returnState = modifyReducer(state, {
      status: REQUEST_STATUS.SUCCESS
    });
  } else if (verification_type === RESET_PASSWORD_TYPE.SMS) {
    returnState = modifyReducer(state, {
      step: RESET_PASSWORD_STEP.VERIFY_CODE,
      status: REQUEST_STATUS.NOT_DEFINED,
      history: [...state.history, action.payload]
    });
  } else {
    returnState = modifyReducer(state, {
      status: REQUEST_STATUS.FAILURE
    });
  }
  return returnState;
}

function handleVerifyCodeOTPFailure(state, action) {
  const { otp_code_tries } = state;

  if (otp_code_tries === 2) {
    return modifyReducer(state, {
      status: REQUEST_STATUS.FAILURE,
      error: action.payload
    });
  }
  return modifyReducer(state, {
    status: REQUEST_STATUS.NOT_DEFINED,
    otp_code_tries: state.otp_code_tries + 1
  });
}
