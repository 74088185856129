import React, { Component } from 'react';

import { Text, FormElements, Button } from 'UILib';
import './footer.style.scss';
import {
  TOC_LINK,
  PRIVACY_LINK
} from 'Constants/GlobalConstants/Global.constants';

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="footer-wrapper">
          <div className="footer-zone">
            <Text type="intro" content="READY TO JOIN?" className="subheader" />
            <Text
              type="h1"
              content="Don't Miss Our Latest News and Offerings"
              className="header"
            />
            <div className="input-zone">
              <FormElements.Input
                placeholder="Your Email"
                type="email"
                className="input"
              />
              <Button
                content="Submit"
                bgColor="blue-3"
                borderColor="white"
                color="white"
                className="cta"
              />
            </div>
            <div className="footer-text">
              <Text
                type="intro"
                content="© 2019 voult - All rights reserved."
                className="footer-left-text"
              />
              <div className="footer-right-text">
                <a target="_blank" rel="noopener noreferrer" href={TOC_LINK}>
                  <Text
                    type="intro"
                    content="Terms & Conditions"
                    className="text"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={PRIVACY_LINK}
                >
                  <Text
                    type="intro"
                    content="Privacy Policy"
                    className="text"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-footer-wrapper">
          <div className="footer-zone">
            <Text type="intro" content="READY TO JOIN?" className="subheader" />
            <Text
              type="h1"
              content="Don't Miss Our Latest News and Offerings"
              className="header"
            />
            <div className="input-zone">
              <FormElements.Input
                placeholder="Your Email"
                type="email"
                className="input"
              />
              <Button
                content="Submit"
                bgColor="blue-3"
                borderColor="white"
                color="white"
                className="cta"
              />
            </div>
            <div className="footer-text">
              <Text
                type="intro"
                content="© 2019 voult - All rights reserved."
                className="footer-left-text"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
