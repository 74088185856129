import { all, put, takeLatest } from 'redux-saga/effects';

import apiGenerator from 'Utils/Helpers/api.helpers';
import {
  getStatusCodeFamily,
  apiErrorHandler
} from 'Utils/Helpers/saga.helpers';
import {
  API_ENDPOINTS,
  STATUS_TYPE
} from 'Constants/GlobalConstants/Global.constants';
import * as CommitmentConstants from 'Constants/Commitment/Commitment.constants';

function* commitmentToProjectSaga(action) {
  const { projectId, access_token, commitmentAmount } = action.payload;
  const api = apiGenerator('post', access_token)(API_ENDPOINTS.COMMITMENT, {
    project_id: projectId,
    min: commitmentAmount,
    max: commitmentAmount + 1
  });
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: CommitmentConstants.COMMITMENT_TO_PROJECT_SUCCESS
      });
    }
  } catch (err) {
    yield put({
      type: CommitmentConstants.COMMITMENT_TO_PROJECT_FAILURE,
      payload: apiErrorHandler(err)
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      CommitmentConstants.COMMITMENT_TO_PROJECT_PENDING,
      commitmentToProjectSaga
    )
  ]);
}
