import React from 'react';
import { MoonLoader } from 'react-spinners';

const MoonLoaderComponent = (props) => {
  return (
    <MoonLoader
      sizeUnit={'px'}
      size={30}
      color={props.color || '#0033ff'}
      loading={true}
    />
  );
};

export default MoonLoaderComponent;
