import React from 'react';

import { Icon } from 'UILib';

import './Modal.style.scss';

export default class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  _handleClickOutside = (event) => {
    if (this.modalNode && !this.modalNode.contains(event.target)) {
      this.props.handleClose();
    }
  };

  render() {
    const { children, handleClose, show, closeIcon } = this.props;

    const showHideClassName = show
      ? 'modal display-block'
      : 'modal display-none';

    return (
      <div className={showHideClassName}>
        <section className="modal-main" ref={(r) => (this.modalNode = r)}>
          {children}
          {closeIcon && (
            <div className="close-icon" onClick={handleClose}>
              <Icon
                base="regular"
                icon={{ name: 'times-circle', color: 'grey-2', size: '2x' }}
              />
            </div>
          )}
        </section>
      </div>
    );
  }
}
