import React from 'react';
import { Link } from 'react-router-dom';

import GlobalLayout from 'Layouts/Global.layout';
import { Container, Card, Text, Button, Icon } from 'UILib';

import 'Components/Authentication/authentication.style.scss';

const AuthenticationResult = (props) => {
  return (
    <Container type="page" bgColor="grey-0">
      <GlobalLayout>
        <Card type="auth" centered>
          {props.header && (
            <Container className="authentication-result-container">
              <Text
                type={props.header.type}
                content={props.header.content}
                data={props.header.data}
                translation={props.header.translation}
                className="result-header"
              />
            </Container>
          )}
          <Container className="authentication-result-container authentication-result-content-container">
            {props.icon && (
              <Container className="icon">
                <Icon
                  base={props.icon.base}
                  icon={{
                    name: props.icon.name,
                    color: props.icon.color,
                    size: props.icon.size
                  }}
                />
              </Container>
            )}
            {props.description && (
              <Container className="content-text">
                <Text
                  type={props.description.type}
                  color={props.description.color}
                  content={props.description.content}
                  data={props.description.data}
                  translation={props.description.translation}
                  className="result-content"
                />
              </Container>
            )}
          </Container>
          <Container className="authentication-result-footer-container">
            {props.link && (
              <Link to={props.link.to}>
                <Button
                  bgColor={props.link.bgColor}
                  borderColor={props.link.borderColor}
                  color={props.link.color}
                  content={props.link.content}
                  translation={props.link.translation}
                />
              </Link>
            )}
            {props.onClick && (
              <Button
                bgColor={props.onClick.bgColor}
                borderColor={props.onClick.borderColor}
                color={props.onClick.color}
                content={props.onClick.content}
                translation={props.onClick.translation}
                onClick={props.onClick.action}
              />
            )}
          </Container>
        </Card>
      </GlobalLayout>
    </Container>
  );
};

export default AuthenticationResult;
